// actions/Verkaufsinformation.js

import {
  GET_VERKAUFSINFORMATIONBYID,
  CREATE_VERKAUFSINFORMATION,
  UPDATE_VERKAUFSINFORMATION,
} from '../../constants/ActionTypes';

export function getVerkaufsinformationByID(id) {
  return {
    type: GET_VERKAUFSINFORMATIONBYID,
    request: {
      op: 'get',
      path: `/@verkaufsinformation/${id}`,
    },
  };
}

export function createVerkaufsinformation(formData) {
  return {
    type: CREATE_VERKAUFSINFORMATION,
    request: {
      op: 'post',
      path: '/@verkaufsinformation',
      data: formData,
    },
  };
}

export function updateVerkaufsinformation(id, formData) {
  return {
    type: UPDATE_VERKAUFSINFORMATION,
    request: {
      op: 'patch',
      path: `/@verkaufsinformation/${id}`,
      data: formData,
    },
  };
}
