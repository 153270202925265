import React from 'react';
import { Tab, Container } from 'semantic-ui-react';
import KompetenzenTab from './KompetenzenTab';
import UnternehmenTab from './UnternehmenTab';

const panes = [
  {
    menuItem: 'Kompetenzen',
    render: () => (
      <Tab.Pane basic attached={false}>
        <KompetenzenTab />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Unternehmen',
    render: () => (
      <Tab.Pane basic attached={false}>
        <UnternehmenTab />
      </Tab.Pane>
    ),
  },
];

const TabbedInfo = () => (
  <Container
    className="tabbed-info"
    as={Tab}
    style={{
      textAlign: 'center',
      marginBottom: '104px',
    }}
    menu={{ secondary: true, pointing: true, compact: true, borderless: true }}
    panes={panes}
  />
);

export default TabbedInfo;
