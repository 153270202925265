import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  createRohstoff,
  checkRohstoffe,
  getRohstoffkategorien,
  getVistPermissions,
} from '../../../actions';

import { UnsavedChangesWarning } from 'helpers';

import ButtonArea from '../ButtonArea';

import RohstoffForm from './RohstoffForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

// TODO: Validate Input before send
//       Show which Rohstoffnummer are already taken -> Suggestions when inputting stuff
//       Implement automatic User ID from Plone User
//       Select for Status (which stati are there)

const RohstoffCreate = ({
  pathname,
  createRohstoff,
  checkRohstoffe,
  getRohstoffkategorien,
  rohstoffkategorien,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const emptyFormData = {
    rohstoffnummer: '',
    bezeichnung: '',
    brennwert_kj: '',
    brennwert_kcal: '',
    eiweiss: '',
    kohlenhydrate: '',
    zucker: '',
    fett: '',
    ges_fettsaeuren: '',
    ballaststoffe: '',
    natrium: '',
    staerke: '',
    zusatzinformationen: '',
    basis_euro: '',
    status: 1,
    kategorien: [],
  };

  const [formData, setFormData] = useState(emptyFormData);
  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [isRohstoffnummerDuplicate, setIsRohstoffnummerDuplicate] = useState(
    false,
  );
  const [isBezeichnungDuplicate, setIsBezeichnungDuplicate] = useState(false);
  const isSubmitDisabled =
    isRohstoffnummerDuplicate ||
    isBezeichnungDuplicate ||
    formData.rohstoffnummer === '' ||
    formData.bezeichnung === '';

  const [rohstoffnummerSuggestions, setRohstoffnummerSuggestions] = useState(
    [],
  );

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 4, 6];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [modified, setModified] = useState(false);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    getRohstoffkategorien({ showAll: true });
  }, [getRohstoffkategorien]);

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'rohstoffnummer') {
      checkRohstoffe(name, value)
        .then((result) => {
          if (result.value_exists === true) {
            setIsRohstoffnummerDuplicate(true);
          } else {
            setIsRohstoffnummerDuplicate(false);
          }
          if (result.suggested_values) {
            setRohstoffnummerSuggestions(result.suggested_values);
          } else {
            setRohstoffnummerSuggestions([]);
          }
        })
        .catch((error) => {
          setError(error.name);
          setErrorDetail(error.message);
        });
    }

    if (name === 'bezeichnung') {
      checkRohstoffe(name, value)
        .then((result) => {
          if (result === true) {
            setIsBezeichnungDuplicate(true);
          } else {
            setIsBezeichnungDuplicate(false);
          }
        })
        .catch((error) => {
          setError(error.name);
          setErrorDetail(error.message);
        });
    }
  };

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (!isEqual(formData, emptyFormData)) {
      setModified(true);
    } else {
      setModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSubmit = (e) => {
    setModified(false);
    e.preventDefault();

    // Remove fields with empty strings from the formData
    const cleanedFormData = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    createRohstoff(cleanedFormData)
      .then((result) => {
        const id = result.id;
        if (id) {
          history.push(
            `/controlpanel/rezeptdatenbank/rohstoffe/details?id=${id}`,
          );
        } else {
          setError(
            'Es ist etwas schiefgelaufen, Rohstoff wurde nicht angelegt.',
          );
        }
      })
      .catch((error) => {
        setError('Es ist etwas schiefgelaufen, Rohstoff wurde nicht angelegt');
        setErrorDetail(error.name + ': ' + error.message);
      });
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper rbd add">
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben für den neuen Rohstoff bereits Werte eingetragen. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Helmet title="Rohstoff Anlegen" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>Rohstoff Anlegen </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={true}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={`/controlpanel/rezeptdatenbank/rohstoffe`}
                  typeString={'den Rohstoff'}
                />
              </div>
            </div>
            <div className="rezeptdatenbank header"></div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            <div className="rdb add form rohstoffe">
              <RohstoffForm
                isBezeichnungDuplicate={isBezeichnungDuplicate}
                isRohstoffnummerDuplicate={isRohstoffnummerDuplicate}
                formData={formData}
                handleInputChange={handleInputChange}
                handleKategorienChange={handleKategorienChange}
                kategorien={rohstoffkategorien}
                selectedKategorien={formData.kategorien}
                rohstoffnummer_suggestions={rohstoffnummerSuggestions}
              />
            </div>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RohstoffCreate.propTypes = {
  pathname: PropTypes.string.isRequired,
  createRohstoff: PropTypes.func.isRequired,
  checkRohstoffe: PropTypes.func.isRequired,
  getRohstoffkategorien: PropTypes.func.isRequired,
  rohstoffkategorien: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      rohstoffkategorien: state.rohstoffkategorien.data.rohstoffkategorien,
      pathname: state.router.location.pathname,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      createRohstoff,
      checkRohstoffe,
      getRohstoffkategorien,
      getVistPermissions,
    },
  ),
)(RohstoffCreate);
