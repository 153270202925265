import { has } from 'lodash';
import {
  GET_REZEPTE,
  GET_GRUNDREZEPTE,
  GET_DELETEDREZEPTE,
  GET_REZEPTBYID,
  GET_REZEPTE_NUTRIENTS_BY_ID,
  GET_ROHSTOFFKOSTEN_BY_ID,
  CHECK_REZEPTE,
  CREATE_REZEPT,
  DELETE_REZEPT,
  UPDATE_REZEPT,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
  subrequests: {},
};

const initialStateDataArray = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
  subrequests: {},
};

export function rezepte(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rezepte: [], total_pages: 0 },
      };
    case `${GET_REZEPTE}_FAIL`:
      // if (action?.error?.message) {
      //   action.error.message = JSON.parse(
      //     action.error.response.text,
      //   ).error.message;
      // }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function rezeptNutrients(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTE_NUTRIENTS_BY_ID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTE_NUTRIENTS_BY_ID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_REZEPTE_NUTRIENTS_BY_ID}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function rohstoffKosten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFKOSTEN_BY_ID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFKOSTEN_BY_ID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_ROHSTOFFKOSTEN_BY_ID}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function grundrezepte(state = initialStateDataArray, action = {}) {
  switch (action.type) {
    case `${GET_GRUNDREZEPTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_GRUNDREZEPTE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? [...action.result] : [],
      };
    case `${GET_GRUNDREZEPTE}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(action.error.response.text);
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function deletedRezepte(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_DELETEDREZEPTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_DELETEDREZEPTE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rezepte: [], total_pages: 0 },
      };
    case `${GET_DELETEDREZEPTE}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function rezepteCheck(state = initialState, action = {}) {
  switch (action.type) {
    case `${CHECK_REZEPTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CHECK_REZEPTE}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            error: null,
            loading: false,
            loaded: true,
            subrequest: action.subrequest,
            data: has(action, 'result') ? action.result : {},
          },
        },
      };
    case `${CHECK_REZEPTE}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function rezeptByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_REZEPTBYID}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function rezeptCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_REZEPT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_REZEPT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_REZEPT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_REZEPT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_REZEPT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_REZEPT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_REZEPT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_REZEPT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_REZEPT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
