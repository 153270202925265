import { has } from 'lodash';
import {
  GET_PRODUKTKATEGORIEN,
  GET_PRODUKTKATEGORIEBYID,
  CREATE_PRODUKTKATEGORIE,
  DELETE_PRODUKTKATEGORIE,
  UPDATE_PRODUKTKATEGORIE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function produktkategorien(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUKTKATEGORIEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUKTKATEGORIEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { produktkategorien: [], total_pages: 0 },
      };
    case `${GET_PRODUKTKATEGORIEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function produktkategorieByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUKTKATEGORIEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUKTKATEGORIEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_PRODUKTKATEGORIEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function produktkategorieCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_PRODUKTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_PRODUKTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_PRODUKTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function produktkategorieDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_PRODUKTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_PRODUKTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_PRODUKTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function produktkategorieUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_PRODUKTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_PRODUKTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_PRODUKTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
