import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { addMessage, emailNotification } from '@plone/volto/actions';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '../.';

import arrowSVG from '../../icons/arrow.svg';
import infoSVG from '../../icons/info.svg';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  title: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  description: {
    id: 'Fill in this form to contact the site owners.',
    defaultMessage: 'Fill in this form to contact the site owners.',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  nameDescription: {
    id: 'Please enter your full name.',
    defaultMessage: 'Please enter your full name.',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  fromDescription: {
    id: 'Please enter your e-mail address',
    defaultMessage: 'Please enter your e-mail address',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  subjectDescription: {
    id: 'Please, enter a short summary of the message',
    defaultMessage: 'Please, enter a short summary of the message',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Please enter the message you want to send',
    defaultMessage: 'Please enter the message you want to send',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  messageandsenderrequired: {
    id: 'Both sender address and message are required',
    defaultMessage: 'Both sender address and message are required',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  successMessageSentTitle: {
    id: 'Info',
    defaultMessage: 'Info',
  },
  successMessageSentBody: {
    id:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
    defaultMessage:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
  },
  feedbackThanks: {
    id: 'Thank you for your feedback',
    defaultMessage: 'Thank you for your feedback',
  },
});

class ContactForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    addMessage: PropTypes.func.isRequired,
    emailNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  state = {
    name: '',
    subject: '',
    senderAddress: '',
    message: '',
    error: null,
    isSuccessful: false,
    agreed: false,
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, isSuccessful: false });

  handleSubmit = () => {
    const { name, subject, senderAddress, message, agreed } = this.state;

    if (senderAddress && message && agreed) {
      this.props.emailNotification(senderAddress, message, name, subject);
      this.setState({
        error: null,
        isSuccessful: true,
      });
    }
    if (!(senderAddress && message)) {
      this.setState({
        error: {
          message: this.props.intl.formatMessage(
            messages.messageandsenderrequired,
          ),
        },
      });
    }
    if (!agreed) {
      this.setState({
        error: {
          message:
            'Bitte bestätigen Sie, dass Sie unsere Datenschutzerklärung zur Kenntnis genommen haben.',
        },
      });
    }
  };

  onCheckboxChange = (e, { checked }) => {
    this.setState({ agreed: checked });
  };

  render() {
    const { name, senderAddress, message } = this.state;

    return (
      <Form method="post" onSubmit={this.handleSubmit}>
        <Form.Input
          placeholder="Name"
          name="name"
          value={name}
          onChange={this.handleChange}
        />
        <Form.Input
          placeholder="E-Mail"
          name="senderAddress"
          value={senderAddress}
          onChange={this.handleChange}
        />
        <Form.TextArea
          placeholder="Ihre Nachricht"
          name="message"
          value={message}
          onChange={this.handleChange}
        />
        <Form.Checkbox
          style={{ marginBottom: '24px' }}
          checked={this.state.agreed}
          onChange={this.onCheckboxChange}
          label={
            <label>
              Ich bin damit einverstanden, dass meine Daten zur Beantwortung
              meiner Anfrage verarbeitet werden. Näheres finden Sie in der{' '}
              <Link to="/datenschutz">Datenschutzerklärung</Link>.
            </label>
          }
        />
        <Form.Field>
          <Button className="medium brandButtonPrimary">
            <MixedTitle>Nachricht senden</MixedTitle>
            <Icon name={arrowSVG} size="20px" className="right" />
          </Button>
          {this.state.isSuccessful && (
            <Button className="small brandButtonPrimary contact-form-message">
              <Icon name={infoSVG} size="17px" className="left" />
              E-Mail gesendet
            </Button>
          )}
          {this.state.error && (
            <Button
              className="small brandButtonPrimary contact-form-message error-message"
              style={{
                backgroundColor: 'red',
                marginTop: '20px',
                marginLeft: '0',
              }}
            >
              <Icon name={infoSVG} size="17px" className="left" />
              {this.state.error.message}
            </Button>
          )}
        </Form.Field>
      </Form>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      loading: state.emailNotification.loading,
      loaded: state.emailNotification.loaded,
      error: state.emailNotification.error,
    }),
    { addMessage, emailNotification },
  ),
)(ContactForm);
