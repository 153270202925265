/**
 * Benutzer actions.
 * @module actions/Benutzer
 */

import { GET_VISTPERMISSIONS } from '../../constants/ActionTypes';

/**
 * Get Benutzer item by ID.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getVistPermissions() {
  return {
    type: GET_VISTPERMISSIONS,
    request: {
      op: 'get',
      path: `/@vist_permissions`,
    },
  };
}
