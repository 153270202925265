import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '..';

import sliderChurrini from '../../static/Slider0_Churrini_V2.jpg';
import sliderBackmalz from '../../static/Slider1_Backmalz_MB.jpg';
import sliderFunktioniertNatuerlich from '../../static/Slider2_funktioniert-natuerlich.jpg';
import sliderRezeptwelten from '../../static/Slider3_Rezeptwelten.jpg';
import sliderExploreZeelandia from '../../static/explore_Zeelandia.jpg';
import arrowSVG from '../../icons/arrow.svg';

const VideoSlide = (currentSlide) => {
  const videoRef = useRef(null);
  const video = videoRef.current;

  return (
    <div>
      <div
        className="slide slide0 video-slide"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%)`,
          backgroundSize: 'cover',
        }}
      >
        <Link to="/produkte/produktneuheiten/opa-dinkel-pur">
          <video
            id="video-slide"
            ref={videoRef}
            autoPlay={currentSlide.currentSlide === 0 ? true : false}
            muted
            loop
          >
            <source
              src="/slider_video_opa_dinkel.mp4/@@download/file"
              type="video/mp4"
            />
          </video>
        </Link>
      </div>
    </div>
  );
};

const ChurriniSlide = () => {
  return (
    <div>
      <div
        className="slide slide0"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%), url(${sliderChurrini}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <Link to="/wir-ueber-uns/aktuelles/churrini">
          <div className="countdown-wrapper" />
        </Link>
      </div>
    </div>
  );
};

const BackmalzSlide = () => {
  return (
    <div>
      <div
        className="slide slide1"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%), url(${sliderBackmalz}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <Link to="/alle-produkte/1099">
          <div className="countdown-wrapper" />
        </Link>
      </div>
    </div>
  );
};

const FunktioniertNaturlichSlide = () => {
  return (
    <div>
      <div
        className="slide slide2"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%), url(${sliderFunktioniertNatuerlich}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div>
          <div className="lead-pill">
            <h1>
              <MixedTitle>funktioniert &ndash; natürlich!</MixedTitle>
            </h1>
          </div>
          <div className="secondary-pill">
            <a href="/produkte/funktioniert-natuerlich">
              <p className="mobile hidden">
                <strong>
                  Wir stehen für Genuss, Funktionalität und Natürlichkeit.
                </strong>{' '}
                <br />
                Clean Label und Clean Tech spielen bei uns ebenso eine große
                Rolle wie eine traditionelle Herstellung, ausgezeichneter
                Geschmack und die einfache und gelingsichere Anwendung für
                unsere Kunden.
              </p>
              <p className="mobile only">
                <strong>
                  Wir stehen für Genuss, Funktionalität und Natürlichkeit.
                </strong>{' '}
                Clean Label und Clean Tech spielen bei uns ebenso eine große
                Rolle…
              </p>
              <Button className="medium brandButtonSecondary">
                <MixedTitle>Mehr lesen</MixedTitle>
                <Icon name={arrowSVG} size="23px" className="right" />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const IdeenreichSlide = () => {
  return (
    <div>
      <div
        className="slide slide3"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%), url(${sliderRezeptwelten}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <div>
          <div className="lead-pill">
            <h1>
              <MixedTitle reverse>Ideenreich backen</MixedTitle>
            </h1>
          </div>
          <div className="secondary-pill">
            <a href="/rezepte/rezeptwelten">
              <p className="mobile hidden">
                <strong>
                  Mehr als 2.300 Rezepte hält unsere umfangreiche Datenbank
                  bereit.
                </strong>
                &nbsp; Dazu gibt es Nährwerttabellen, Rohstoffkalkulationen und
                Verkaufsinformationen. Stöbern Sie gern! Lassen Sie sich
                inspirieren von der Ideen- und Themenvielfalt.
              </p>
              <p className="mobile only">
                <strong>
                  Mehr als 2.300 Rezepte hält unsere umfangreiche Datenbank
                  bereit.
                </strong>
                &nbsp; Dazu gibt es Nährwerttabellen, Rohstoffkalkulationen…
              </p>
              <Button className="medium brandButtonSecondary">
                <MixedTitle>Mehr lesen</MixedTitle>
                <Icon name={arrowSVG} size="23px" className="right" />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExploreZeelandiaSlide = () => {
  return (
    <div>
      <div
        className="slide slide1"
        style={{
          background: `linear-gradient(to bottom, rgba(8, 7, 7, 0.57) 0%, rgba(238, 238, 238, 0) 35%, transparent 100%), url(${sliderExploreZeelandia}) no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        <a href=" https://explore.zeelandia.com/de">
          <div className="countdown-wrapper" />
        </a>
      </div>
    </div>
  );
};

const HomepageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <Slider
      customPaging={(dot) => <div />}
      dots={true}
      dotsClass="slick-dots slick-thumb"
      autoplay={true}
      autoplaySpeed={currentSlide === 0 ? 10000 : 5000}
      infinite
      speed={1300}
      slidesToShow={1}
      slidesToScroll={1}
      arrows={false}
      pauseOnHover={false}
      afterChange={(index) => setCurrentSlide(index)}
    >
      <VideoSlide currentSlide={currentSlide} />
      <ExploreZeelandiaSlide />
      <BackmalzSlide />
      <IdeenreichSlide />
      <FunktioniertNaturlichSlide />
    </Slider>
  );
};

export default HomepageSlider;
