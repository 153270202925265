import React from 'react';

import { Form } from 'semantic-ui-react';

const ZutatengruppeForm = ({
  formData,
  handleInputChange,
  isZutatengruppeNameDuplicate,
}) => {
  return (
    <Form>
      <div className="bezug-info">
        <Form.Field error={isZutatengruppeNameDuplicate}>
          <label>
            <strong>Name (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isZutatengruppeNameDuplicate && (
          <div className="speech-bubble warning">
            Zutatengruppe mit diesem Namen existiert bereits
          </div>
        )}
        <p className="disclaimer">* Pflichtfeld</p>
      </div>
    </Form>
  );
};

export default ZutatengruppeForm;
