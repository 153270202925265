import React from 'react';
import RichTextField from '../RichTextField/RichTextField';
import ZutatenForm from './ZutatenForm';
import ImageWidget from '../ImageWidget';

import { Form, Dropdown } from 'semantic-ui-react';

const RezeptForm = ({
  isRezeptnummerDuplicate,
  formData,
  handleInputChange,
  bezuege,
  zutaten,
  addZutat,
  removeZutat,
  handleZutatChange,
  zutatengruppen,
  rohstoffeNames,
  produkteNames,
  grundrezepte,
  isIncompleteZutat,
  gesamtgewicht_zutaten,
  stueckgewicht,
  gebaeckgewicht,
  gesamt_gebaeckgewicht,
  kategorien = [],
  handleKategorienChange,
  selectedKategorien,
  gruppen = [],
  handleGruppenChange,
  selectedGruppen,
  codeSuggestions,
}) => {
  const renderOptions = (option) => {
    const level = option.level || 0;
    const indent = `${level * 1}em`;

    const style = {
      fontWeight: level === 0 ? 'bold' : 'normal',
      paddingLeft: indent,
    };

    return <span style={style}>{option.name}</span>;
  };

  const dropdownOptions = kategorien.map((kategorie) => ({
    key: kategorie.id,
    text: kategorie.name,
    value: kategorie.id,
    level: kategorie.level || 0,
    content: renderOptions(kategorie),
  }));

  const gruppenOptions = gruppen.map((gruppe) => ({
    key: gruppe.id,
    text: gruppe.name,
    value: gruppe.id,
    content: renderOptions(gruppe),
  }));

  // console.log(formData);

  return (
    <Form>
      <div className="meta-info">
        <Form.Field>
          <label>
            <strong>Rezeptname (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <label>
            <strong>Verkehrsbezeichnung (*):</strong>
          </label>
          <input
            type="text"
            name="verkehrsbezeichnung"
            value={formData.verkehrsbezeichnung}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        <Form.Field error={isRezeptnummerDuplicate}>
          <label>
            <strong>Rezeptnummer (*):</strong>
          </label>
          <div className="rezeptnummer-input-group">
            <input
              className="rezeptnummer-input"
              name="kennung"
              type="text"
              pattern="[A-Z]*"
              maxLength="2" // Limit input length to 2 characters
              value={formData.kennung}
              onChange={handleInputChange}
              required
            />
            <input
              className="rezeptnummer-input"
              name="rezeptcode"
              type="text"
              pattern="[0-9]*" // Allow only numeric values
              maxLength="6" // Limit input length to 6 characters
              value={formData.rezeptcode}
              onChange={handleInputChange}
              list="codeSuggestions"
              required
              autoComplete="off"
            />
            <datalist id="codeSuggestions">
              {codeSuggestions &&
                formData.rezeptcode > 99 &&
                codeSuggestions.map((suggestion) => (
                  <option value={suggestion} />
                ))}
            </datalist>
            <input
              className="rezeptnummer-input"
              name="variante"
              type="number"
              min="0"
              max="99"
              value={formData.variante}
              onChange={handleInputChange}
              required
            />
          </div>
        </Form.Field>

        {isRezeptnummerDuplicate && (
          <div className="speech-bubble warning">
            Rezeptcode und Variante bereits vergeben
          </div>
        )}

        <Form.Field>
          <label>Rezeptname für Etikett:</label>
          <input
            type="text"
            name="name_etikett"
            value={formData.name_etikett}
            onChange={handleInputChange}
            required
          />
        </Form.Field>

        <Form.Field>
          <label>Rezeptkategorien:</label>
          <Dropdown
            placeholder="Rezeptkategorie auswählen"
            name="rezeptkategorien"
            fluid
            multiple
            selection
            options={dropdownOptions}
            value={selectedKategorien}
            onChange={(e, { value }) => {
              handleKategorienChange(value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Rezeptgruppen:</label>
          <Dropdown
            placeholder="Rezeptgruppe auswählen"
            name="rezeptgruppen"
            fluid
            multiple
            selection
            options={gruppenOptions}
            value={selectedGruppen}
            onChange={(e, { value }) => {
              handleGruppenChange(value);
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Status:</label>
          <select
            name="rezeptstatus"
            value={formData.rezeptstatus}
            onChange={handleInputChange}
          >
            <option value={1}>Aktuell</option>
            <option value={2}>Stillgelegt</option>
            <option value={3}>In Bearbeitung</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Intern / Extern:</label>
          <select
            name="intern_extern"
            value={formData.intern_extern ? 'intern' : 'extern'}
            onChange={handleInputChange}
          >
            <option value="intern">Intern</option>
            <option value="extern">Extern</option>
          </select>
        </Form.Field>
        <ImageWidget
          handleInputChange={handleInputChange}
          formData={formData}
        />
        <p className="disclaimer">* Pflichtfeld</p>
      </div>

      <div className="trenner" />
      <ZutatenForm
        zutaten={zutaten}
        addZutat={addZutat}
        removeZutat={removeZutat}
        handleZutatChange={handleZutatChange}
        zutatengruppen={zutatengruppen}
        rohstoffeNames={rohstoffeNames}
        produkteNames={produkteNames}
        grundrezepte={grundrezepte}
        isIncompleteZutat={isIncompleteZutat}
      />
      <div className="trenner" />
      <div className="nutrients-info edit-rezept">
        <Form.Field>
          <label>
            <strong>Bezug (*):</strong>
          </label>
          <select
            className="bezug-select"
            name="bezuege_id"
            value={formData.bezuege_id}
            onChange={handleInputChange}
            required
          >
            <option value="">Bezug Auswählen</option>
            {bezuege &&
              bezuege.map((bezug) => (
                <option key={bezug.id} value={bezug.id}>
                  {bezug.name}
                </option>
              ))}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Teigtemperatur in °C:</label>
          <input
            type="text"
            name="teigtemperatur"
            value={formData.teigtemperatur}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Massentemperatur in °C</label>
          <input
            type="text"
            name="massentemperatur"
            value={formData.massentemperatur}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Teigruhe in Min.:</label>
          <input
            type="text"
            name="teigruhe"
            value={formData.teigruhe}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Pressen-/Zwischengare in Min.:</label>
          <input
            type="text"
            name="pressen_zwischengare"
            value={formData.pressen_zwischengare}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Teigausbeute:</label>
          <input
            type="text"
            name="teigausbeute"
            value={formData.teigausbeute}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Knetzeit (Spiralkneter, abhängig von Teiggröße und Knetsystem) in
            Min.:
          </label>
          <input
            type="text"
            name="knetzeit"
            value={formData.knetzeit}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Rührzeit in Min.:</label>
          <input
            type="text"
            name="ruehrzeit"
            value={formData.ruehrzeit}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Quellzeit:</label>
          <input
            type="text"
            name="quellzeit"
            value={formData.quellzeit}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Backtemperatur in °C:</label>
          <input
            type="text"
            name="backtemperatur"
            value={formData.backtemperatur}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Siedefetttemperatur in °C:</label>
          <input
            type="text"
            name="siedefetttemperatur"
            value={formData.siedefetttemperatur}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Backzeit in Min.:</label>
          <input
            type="text"
            name="backzeit"
            value={formData.backzeit}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Kerntemperatur in °C:</label>
          <input
            type="text"
            name="kerntemperatur"
            value={formData.kerntemperatur}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Gesamtgewicht Zutaten in g:</label>
          <p>{gesamtgewicht_zutaten && Math.round(gesamtgewicht_zutaten)} g</p>
        </Form.Field>

        <Form.Field>
          <label>Stückzahl:</label>
          <input
            type="number"
            min="1"
            name="stueckzahl"
            value={formData.stueckzahl}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Teigeinlage in g:</label>
          <input
            type="number"
            min="0"
            name="teigeinlage"
            value={formData.teigeinlage}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Pressengewicht in g:</label>
          <input
            type="number"
            min="0"
            name="pressengewicht"
            value={formData.pressengewicht}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Masseneinwaage in g:</label>
          <input
            type="number"
            min="0"
            name="masseneinwaage"
            value={formData.masseneinwaage}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Stückgewicht in g:</label>
          <p>{stueckgewicht && Math.round(stueckgewicht)} g</p>
        </Form.Field>
        <Form.Field>
          <label>Backverlust in %:</label>
          <input
            type="number"
            min="0"
            name="backverlust"
            value={formData.backverlust}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Gebäckgewicht in g:</label>
          <p>{gebaeckgewicht && Math.round(gebaeckgewicht)} g</p>
        </Form.Field>
        <Form.Field>
          <label>Gesamt-Gebäckgewicht in g:</label>
          <p>{gesamt_gebaeckgewicht && Math.round(gesamt_gebaeckgewicht)} g</p>
        </Form.Field>
      </div>
      <div className="trenner" />
      <div className="info">
        <RichTextField
          id="herstellungstext"
          title="Herstellung: "
          value={{ data: formData.herstellungstext }}
          onChange={handleInputChange}
        />
        <Form.Field>
          <label>
            <strong>Etikett</strong>
          </label>
          <textarea
            name="etikett"
            value={formData.etikett}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <Form.Field>
          <label>
            <strong>Zusatz</strong>
          </label>
          <textarea
            name="zusatz"
            value={formData.zusatz}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
      </div>
    </Form>
  );
};

export default RezeptForm;
