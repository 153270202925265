/**
 * Produkt actions.
 * @module actions/Produkt
 */

import { GET_RECIPES } from '../../constants/ActionTypes';

/**
 * Get Rezept items.
 * @function getRecipes
 * @param {Array} id Rezept ids
 * @returns {Object} Rezept action.
 */
export default function getRecipes(ids, subrequest = null) {
  return {
    type: GET_RECIPES,
    subrequest,
    request: {
      op: 'get',
      path: `/@recipes?items=${ids}`,
    },
  };
}
