import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

const ButtonArea = ({
  id,
  isDetails,
  isAdd,
  handleDelete,
  handleSubmit,
  isSubmitDisabled,
  backURL,
  editURL = '',
  editName = '',
  showEditButton = true,
  showDeleteButton = true,
  typeString = 'das Objekt',
  ...props
}) => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  return (
    <>
      <Confirm
        open={showSaveModal}
        onCancel={() => {
          setShowSaveModal(false);
        }}
        onConfirm={(e) => {
          handleSubmit(e);
          setShowSaveModal(false);
        }}
        header="Speichern bestätigen"
        content={`Wollen Sie ${typeString} speichern?`}
        cancelButton="Abbrechen"
        confirmButton="Speichern"
      />
      <Confirm
        open={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={() => {
          handleDelete();
          setShowDeleteModal(false);
        }}
        header="Löschen bestätigen"
        content={`Wollen Sie ${typeString} wirklich löschen?`}
        cancelButton="Abbrechen"
        confirmButton="Löschen"
      />
      {!isDetails ? (
        <div className="buttonArea">
          <Button
            primary
            onClick={() => {
              if (backURL) {
                history.push(backURL);
              } else {
                history.goBack();
              }
            }}
          >
            Abbrechen
          </Button>
          <Button
            primary
            onClick={() => {
              setShowSaveModal(true);
            }}
            disabled={isSubmitDisabled}
          >
            Speichern
          </Button>
          {!isAdd && showDeleteButton && (
            <Button onClick={() => setShowDeleteModal(true)}>Löschen</Button>
          )}
        </div>
      ) : (
        <div className="buttonArea">
          <Button
            primary
            onClick={() => {
              if (backURL) {
                history.push(backURL);
              } else {
                history.goBack();
              }
            }}
          >
            Zurück
          </Button>
          {showEditButton && (
            <Button
              type="New"
              onClick={() => {
                history.push(`${editURL}edit?id=${id}`);
              }}
            >
              {editName} Bearbeiten
            </Button>
          )}
          {showDeleteButton && (
            <Button onClick={() => setShowDeleteModal(true)}>Löschen</Button>
          )}
        </div>
      )}
    </>
  );
};

export default ButtonArea;
