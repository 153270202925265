/**
 * Recipe actions.
 * @module actions/recipe
 */

import { join, map, toPairs, pickBy } from 'lodash';
import { GET_RECIPE } from '../../constants/ActionTypes';

/**
 * Get Recipe items.
 * @function getRecipe
 * @param {string} id Recipe id
 * @param {string} options Options
 * @returns {Object} Recipe action.
 */
export default function getRecipe(id, options) {
  const querystring = options
    ? join(
        map(toPairs(pickBy(options)), (item) => join(item, '=')),
        '&',
      )
    : '';
  return {
    type: GET_RECIPE,
    request: {
      op: 'get',
      path: `/@recipe/${id}${querystring ? `?${querystring}` : ''}`,
    },
  };
}
