import { has } from 'lodash';
import {
  GET_BENUTZER,
  GET_BENUTZERBYID,
  CHECK_BENUTZER,
  CREATE_BENUTZER,
  UPDATE_BENUTZER,
  DELETE_BENUTZER,
  GET_PLONE_VIST_USERS,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function benutzer(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_BENUTZER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_BENUTZER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { benutzer: [], total_pages: 0 },
      };
    case `${GET_BENUTZER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}
export function benutzerCheck(state = initialState, action = {}) {
  switch (action.type) {
    case `${CHECK_BENUTZER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CHECK_BENUTZER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? action.result : '',
      };
    case `${CHECK_BENUTZER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function benutzerByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_BENUTZERBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_BENUTZERBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_BENUTZERBYID}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(action.error.response.text);
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function benutzerCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_BENUTZER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_BENUTZER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_BENUTZER}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function benutzerDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_BENUTZER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_BENUTZER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_BENUTZER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function benutzerUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_BENUTZER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_BENUTZER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_BENUTZER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function ploneVistUsers(state = initialState, action) {
  switch (action.type) {
    case `${GET_PLONE_VIST_USERS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PLONE_VIST_USERS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case `${GET_PLONE_VIST_USERS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
