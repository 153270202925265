/**
 * onapply.de job actions.
 * @module actions/jobs
 */

import { GET_ONAPPLY_JOBS } from '../../constants/ActionTypes';
import superagent from 'superagent';
import config from '@plone/volto/registry';

export const fetchJobsBegin = () => ({
  type: `${GET_ONAPPLY_JOBS}_BEGIN`,
});

export const fetchJobsSuccess = (jobs) => ({
  type: `${GET_ONAPPLY_JOBS}_SUCCESS`,
  payload: { jobs },
});

export const fetchJobsFailure = (error) => ({
  type: `${GET_ONAPPLY_JOBS}_FAIL`,
  payload: { error },
});

/**
 *
 * Get onapply jobs.
 * @function getOnapplyJobs
 * @returns {Object} request.
 */
export default function getOnapplyJobs() {
  const { ONAPPLY_URL } = config.settings;

  return (dispatch) => {
    dispatch(fetchJobsBegin());
    const request = superagent.get(ONAPPLY_URL);
    return request
      .then((response) => dispatch(fetchJobsSuccess(response.body.listings)))
      .catch((err) => dispatch(fetchJobsFailure(err)));
  };
}
