import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Grid, Image } from 'semantic-ui-react';

const Details = ({ products, recipes }) => (
  <div className="product-rezepte">
    <Grid centered stackable>
      <Grid.Column width={6}>
        <h2>Produkte</h2>
        <Card.Group stackable centered itemsPerRow={1}>
          {products.map((item) => (
            <Card key={item.id} as={Link} to={`/alle-produkte/${item.id}`}>
              <Image
                src={item.image && `${item.image['@id']}/@@images/image/mini`}
              />
              <Card.Content>
                <Card.Header>{item.name}</Card.Header>
                {/*                 <Card.Description>{item.description}</Card.Description> */}
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Grid.Column>
      <Grid.Column width={6}>
        <h2>Rezepte</h2>
        <Card.Group stackable centered itemsPerRow={1}>
          {recipes.map((item) => (
            <Card key={item.id} as={Link} to={`/alle-rezepte/${item.id}`}>
              <Image
                src={item.image && `${item.image['@id']}/@@images/image/mini`}
              />
              <Card.Content>
                <Card.Header>{item.name}</Card.Header>
                {/*                 <Card.Description>{item.description}</Card.Description> */}
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Grid.Column>
    </Grid>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Details.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
    }),
  ).isRequired,
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default Details;
