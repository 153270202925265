import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Message, Button } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getRohstoffByID,
  deleteRohstoff,
  getVistPermissions,
} from '../../../actions';
import NavHeader from '../NavHeader';
import ButtonArea from '../ButtonArea';
import NextPreviousButtons from '../NextPreviousButtons';
import MetaFooter from '../MetaFooter';
import NutrientInfo from '../NutrientInfo';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RohstoffeDetails = ({
  pathname,
  rohstoff,
  getRohstoffByID,
  deleteRohstoff,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  rohstoffe_list,
  batching_params,
}) => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 15, 2, 4, 6];
  // Define the allowed user groups for editing Produkte / Gebinde and adding new gebinde
  const showEditButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      setIsClient(true);
      getRohstoffByID(id);
    }
  }, [getRohstoffByID, id]);

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteRohstoff(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error.name);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <Helmet title="Rohstoffe" />
      <NavHeader permissions={permissions} />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <strong>
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>{rohstoff && rohstoff.bezeichnung}</h1>
                </div>
                <div className="button-container">
                  <NextPreviousButtons
                    currentItem={id}
                    itemlist={rohstoffe_list.rohstoffe}
                    totalPages={rohstoffe_list.total_pages}
                    batching_params={batching_params}
                  />
                  <ButtonArea
                    isDetails={true}
                    id={rohstoff.id}
                    handleDelete={handleDelete}
                    backURL={`/controlpanel/rezeptdatenbank/rohstoffe`}
                    showDeleteButton={showDeleteButton}
                    showEditButton={showEditButton}
                    typeString={'den Rohstoff'}
                  />
                </div>
              </div>
              <div className="rezeptdatenbank header"></div>
            </strong>
          </Segment>

          <Segment>
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Rohstoff wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/rohstoffe"
                >
                  Zurück zur Rohstoff-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                {error && (
                  <div className="error-message">
                    <h2>{error}</h2>
                  </div>
                )}
                {rohstoff && (
                  <div className="details-container">
                    <div className="meta-info">
                      <p>
                        <strong>Rohstoffnummer:</strong>{' '}
                        {rohstoff.rohstoffnummer}
                      </p>
                      <p>
                        <strong>Bezeichnung:</strong> {rohstoff.bezeichnung}
                      </p>
                      {rohstoff && rohstoff?.kategorie_name && (
                        <div className="kategories">
                          <strong>Rohstoffkategorie:</strong>
                          <div className="kategory-names">
                            {rohstoff.kategorie_name}
                          </div>
                        </div>
                      )}
                      <p>
                        <strong>Status:</strong>{' '}
                        {rohstoff.status === 1
                          ? 'Aktuell'
                          : rohstoff.status === 2
                          ? 'Stillgelegt'
                          : rohstoff.status === 3
                          ? 'In Bearbeitung'
                          : rohstoff.status}
                      </p>
                    </div>

                    <NutrientInfo data={rohstoff} />
                    <div className="trenner" />
                    <div className="info-texts">
                      <div className="info-text-box">
                        <strong>Zusatzinformationen:</strong>{' '}
                        <pre className="text-area">
                          {rohstoff.zusatzinformationen}
                        </pre>
                      </div>
                    </div>
                    <MetaFooter data={rohstoff} />
                  </div>
                )}
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RohstoffeDetails.propTypes = {
  pathname: PropTypes.string.isRequired,
  rohstoff: PropTypes.array.isRequired,
  getRohstoffByID: PropTypes.func.isRequired,
  deleteRohstoff: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      rohstoff: state.rohstoffByID.data,
      token: state.userSession.token,
      permissions: state.permissions.data,
      rohstoffe_list: state.rohstoffe.data,
      batching_params: state.batchingParameters,
    }),
    { getRohstoffByID, deleteRohstoff, getVistPermissions },
  ),
)(RohstoffeDetails);
