/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import {
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Icon as OldIcon,
  Loader,
  Tab,
} from 'semantic-ui-react';
import { Portal } from 'react-portal';
import { last } from 'lodash';
import qs from 'query-string';
import { Toolbar } from '@plone/volto/components';
import { addMessage, listActions, getContent } from '@plone/volto/actions';
import { BodyClass, asyncConnect } from '@plone/volto/helpers';
import { addFavorite, getProduct } from '../../actions';

import missingIMG from '../../static/missing.jpg';
import starSVG from '../../static/star.svg';
import config from '@plone/volto/registry';

import {
  Downloads,
  Informationen,
  InlineLoginForm,
  MixedTitle,
  MoreInfoFooter,
  Related,
  ProductRezepteSection,
} from '../.';
import VistEditDialog from '../Widgets/VistEditDialog';

/**
 *
 *
 * @class Product
 * @extends {Component}
 */
class Product extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    listActions: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    token: PropTypes.string,
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
    getProduct: PropTypes.func.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        '@id': PropTypes.string,
      }),
    }).isRequired,
    loaded: PropTypes.bool.isRequired,
    addFavorite: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    favoriteLoaded: PropTypes.bool.isRequired,
    favoriteLoading: PropTypes.bool.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    actions: null,
    token: null,
  };

  constructor(props) {
    super(props);
    this.handleProductSaved = this.handleProductSaved.bind(this);
    this.onTab = this.onTab.bind(this);
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getProduct(id);
    this.props.listActions('/');
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      const { id } = nextProps.match.params;
      this.props.getProduct(id);
    }
    if (this.props.favoriteLoading && nextProps.favoriteLoaded) {
      this.props.addMessage(
        'Produkt gemerkt',
        'Produkt wurde in Ihrem Profil gemerkt.',
        'success',
      );
    }

    if (this.props.product && this.props.product !== nextProps.product) {
      this.props.getContent(
        `${config.settings.pd_path}/${nextProps.product.productcode}`,
        null,
        'pdf',
      );
    }
  }

  handleProductSaved() {
    this.props.getProduct(this.props.product.id);
  }

  onTab(event, data) {
    this.props.history.push(`${this.props.pathname}?tab=${data.activeIndex}`);
  }

  addFavorite = () => {
    this.props.addFavorite('product', this.props.product.id);
  };

  render() {
    return (
      (this.props.loaded && (
        <section style={{ marginBottom: '50px' }}>
          <BodyClass className="product" />
          <Helmet title={this.props.product.name} />
          <Container>
            <div className="product-hero">
              {this.props.product.image ? (
                <img
                  className="product-hero-img"
                  src={`${this.props.product.image['@id']}/@@images/image`}
                  alt="rezepte"
                />
              ) : (
                <img
                  className="product-hero-img"
                  src={missingIMG}
                  alt="produkt"
                />
              )}
              <div className="product-hero-body">
                <h1>
                  <MixedTitle reverse>{this.props.product.name}</MixedTitle>
                </h1>
                <p>{this.props.product.description}</p>
                <VistEditDialog
                  vistObject={this.props.product}
                  onSaved={this.handleProductSaved}
                  hasLocalPDF={this.props.pdf?.loaded}
                />
              </div>
              <div className="product-hero-addons">
                <img src={starSVG} alt="star" onClick={this.addFavorite} />
              </div>
            </div>

            {this.props.token && (
              <Tab
                className="product-tabs"
                style={{ textAlign: 'center', marginBottom: '134px' }}
                activeIndex={this.props.tab}
                onTabChange={this.onTab}
                menu={{
                  secondary: true,
                  pointing: true,
                  compact: true,
                  borderless: true,
                  stackable: true,
                }}
                panes={[
                  {
                    menuItem: 'Informationen',
                    render: () => (
                      <Tab.Pane basic attached={false}>
                        <Informationen product={this.props.product} />
                      </Tab.Pane>
                    ),
                  },
                  this.props.product?.recipes?.length > 0 && {
                    menuItem: 'Rezepte',
                    render: () => (
                      <Tab.Pane basic attached={false}>
                        <ProductRezepteSection product={this.props.product} />
                      </Tab.Pane>
                    ),
                  },
                  (this.props?.pdf?.data ||
                    this.props.product?.downloads?.sicherheitsdatenblatt ||
                    this.props.product?.downloads?.produktdatenblatt) && {
                    menuItem: 'Download',
                    render: () => (
                      <Tab.Pane basic attached={false}>
                        <Downloads
                          name="product"
                          downloads={this.props.product.downloads}
                          localPDF={this.props?.pdf?.data}
                          canEdit={this.props.product.can_edit === 1}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            )}
            {!this.props.token && (
              <Container className="inline-login-form">
                <Grid centered>
                  <Grid.Column mobile={12} computer={6}>
                    <h2>
                      <MixedTitle>Einfach mehr Zeelandia</MixedTitle>
                      <div className="title-append">
                        Melden Sie Sich an, um unsere exklusiven Services in
                        vollem Umfang nutzen zu können.
                      </div>
                    </h2>
                    <InlineLoginForm />
                  </Grid.Column>
                </Grid>
              </Container>
            )}
          </Container>
          <Related
            type="product"
            relatedItems={this.props.product.related_products}
          />
          <MoreInfoFooter name="produkte" />
          <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              inner={
                <div>
                  <Dropdown
                    id="toolbar-personal"
                    className="personal-bar"
                    item
                    upward
                    trigger={<OldIcon name="user" size="big" />}
                  >
                    <Dropdown.Menu>
                      {this.props.actions.user &&
                        this.props.actions.user.map((item) => {
                          switch (item.id) {
                            case 'preferences':
                              return (
                                <Link
                                  key={item.id}
                                  to="/personal-preferences"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="setting" /> {item.title}
                                  </span>
                                </Link>
                              );

                            case 'plone_setup':
                              return (
                                <Link
                                  key={item.id}
                                  to="/controlpanel"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="settings" /> {item.title}
                                  </span>
                                </Link>
                              );

                            case 'logout':
                              return (
                                <Link
                                  key={item.id}
                                  to="/logout"
                                  id="toolbar-logout"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="sign out" /> {item.title}
                                  </span>
                                </Link>
                              );
                            default: {
                              return null;
                            }
                          }
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              }
            />
          </Portal>
        </section>
      )) || (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )
    );
  }
}

export default compose(
  withRouter,
  asyncConnect([
    {
      key: 'product',
      promise: async ({ location, store: { dispatch } }) => {
        return await dispatch(getProduct(last(location.pathname.split('/'))));
      },
    },
  ]),
  connect(
    (state, props) => ({
      actions: state.actions.actions,
      pathname: props.location.pathname,
      tab: qs.parse(props.location.search).tab || 0,
      token: state.userSession.token,
      product: state.product.data,
      loaded: state.product.loaded,
      favoriteLoaded: state.cart.add.loaded,
      favoriteLoading: state.cart.add.loading,
      pdf: state.content.subrequests.pdf,
    }),
    {
      addFavorite,
      addMessage,
      getProduct,
      listActions,
      getContent,
    },
  ),
)(Product);
