/**
 * Zutatengruppen actions.
 * @module actions/Produkt
 */

import {
  GET_ZUTATENGRUPPEN,
  GET_ZUTATENGRUPPEBYID,
  CREATE_ZUTATENGRUPPE,
  UPDATE_ZUTATENGRUPPE,
  DELETE_ZUTATENGRUPPE,
} from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProduct
 * @param {string} id Produkt id
 * @returns {Object} Produkt action.
 */
export function getZutatengruppen({
  showDeleted = false,
  getAll = false,
  batchingParameters = {},
}) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;

  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted) {
    return {
      type: GET_ZUTATENGRUPPEN,
      request: {
        op: 'get',
        path: `/@zutatengruppen/deleted${pathSuffix}`,
      },
    };
  } else if (getAll) {
    return {
      type: GET_ZUTATENGRUPPEN,
      request: {
        op: 'get',
        path: `/@zutatengruppen/all`,
      },
    };
  }

  return {
    type: GET_ZUTATENGRUPPEN,
    request: {
      op: 'get',
      path: `/@zutatengruppen${pathSuffix}`,
    },
  };
}

/**
 * Get Benutzer item.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getZutatengruppeByID(id) {
  return {
    type: GET_ZUTATENGRUPPEBYID,
    request: {
      op: 'get',
      path: `/@zutatengruppen/${id}`,
    },
  };
}

/**
 * Create a new Zutatengruppe.
 * @function createZutatengruppe
 * @param {Object} formData The data of the new Zutatengruppe
 * @returns {Object} Zutatengruppe action.
 */
export function createZutatengruppe(formData) {
  return {
    type: CREATE_ZUTATENGRUPPE,
    request: {
      op: 'post',
      path: '/@zutatengruppen',
      data: formData,
    },
  };
}

/**
 * Update a new Zutatengruppe.
 * @function createZutatengruppe
 * @param {Object} formData The data of the new Zutatengruppe
 * @returns {Object} Zutatengruppe action.
 */
export function updateZutatengruppe(id, formData) {
  return {
    type: UPDATE_ZUTATENGRUPPE,
    request: {
      op: 'patch',
      path: `/@zutatengruppen/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Zutatengruppe.
 * @function createZutatengruppe
 * @returns {Object} Success Message;
 */
export function deleteZutatengruppe(id) {
  return {
    type: DELETE_ZUTATENGRUPPE,
    request: {
      op: 'del',
      path: `/@zutatengruppen/${id}`,
    },
  };
}
