/**
 * Cart actions.
 * @module actions/cart/cart
 */

import {
  ADD_FAVORITE,
  DELETE_FAVORITE,
  GET_CART,
} from '../../constants/ActionTypes';

/**
 * Get cart items.
 * @function getCart
 * @returns {Object} Get cart action.
 */
export function getCart() {
  return {
    type: GET_CART,
    request: {
      op: 'get',
      path: '/@cart',
    },
  };
}

/**
 * Adds a favorite item.
 * @function addFavorite
 * @param {string} type Type
 * @param {string} id Id
 * @returns {Object} Add item to cart action.
 */
export function addFavorite(type, id) {
  return {
    type: ADD_FAVORITE,
    request: {
      op: 'post',
      path: `/@cart/${type}/${id}`,
    },
  };
}

/**
 * Delete a favorite item.
 * @function deleteFavorite
 * @param {string} type Type
 * @param {string} ids Id
 * @returns {Object} Add item to cart action.
 */
export function deleteFavorite(type, ids) {
  return {
    type: DELETE_FAVORITE,
    request:
      typeof ids === 'number' || typeof ids === 'string'
        ? {
            op: 'del',
            path: `/@cart/${type}/${ids}`,
          }
        : ids.map((id) => ({
            op: 'del',
            path: `/@cart/${type}/${id}`,
          })),
  };
}
