import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import { createGruppe, getGruppen, getVistPermissions } from '../../../actions';
import ButtonArea from '../ButtonArea';

import GruppeForm from './GruppeForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const GruppeCreate = ({
  pathname,
  createGruppe,
  getGruppen,
  gruppen,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const [formData, setFormData] = useState({
    name: '',
  });
  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [isGruppeNameDuplicate, setIsGruppeNameDuplicate] = useState(false);

  const isSubmitDisabled = formData.name === '';

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    getGruppen({ getAll: true });
  }, [getGruppen]);

  // check for duplicates!
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'name') {
      const isDuplicate = gruppen.some((gruppe) => gruppe.name === value);
      setIsGruppeNameDuplicate(isDuplicate);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Remove fields with empty strings from the formData
    const cleanedFormData = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    createGruppe(cleanedFormData)
      .then((result) => {
        const id = result.id;
        if (id) {
          history.push(`/controlpanel/rezeptdatenbank/benutzergruppen`);
        } else {
          setError('Es ist etwas schiefgelaufen, Gruppe wurde nicht angelegt.');
        }
      })
      .catch((error) => {
        setError('Es ist etwas schiefgelaufen, Gruppe wurde nicht angelegt');
        setErrorDetail(error.name + ': ' + error.message);
      });
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper rbd add">
      <Helmet title="Gruppe Anlegen" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>Gruppe Anlegen</h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={true}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={'/controlpanel/rezeptdatenbank/benutzergruppen'}
                  typeString={'die Gruppe'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            <div className="rdb add form gruppen">
              <GruppeForm
                isGruppeNameDuplicate={isGruppeNameDuplicate}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            </div>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

GruppeCreate.propTypes = {
  pathname: PropTypes.string.isRequired,
  gruppen: PropTypes.array.isRequired,
  createGruppe: PropTypes.func.isRequired,
  getGruppen: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      gruppen: state.gruppen.data.gruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    { createGruppe, getGruppen, getVistPermissions },
  ),
)(GruppeCreate);
