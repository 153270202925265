import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { Checkbox, Card, Container, Image } from 'semantic-ui-react';
import { map, xor } from 'lodash';
import { slide as SlidingMenu } from 'react-burger-menu';
import { resetSearchContent, searchContent } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '..';

import missingIMG from '../../static/missing.jpg';
import arrowdownYellowSVG from '../../static/arrowdown-yellow.svg';
import regionFilterSVG from '../../icons/filter-region.svg';
import regionFilterOpenSVG from '../../icons/filter-region-open.svg';

const filterResults = (categories, items) =>
  map(categories, (category) => ({
    title: category.title,
    items: items.filter((item) =>
      item['@id'].includes(category.id) ? item : null,
    ),
  }));

class ContactPersonListing extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchContent: PropTypes.func.isRequired,
    resetSearchContent: PropTypes.func.isRequired,
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }).isRequired,
  };

  state = { selected: [] };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.searchContent(this.props.pathname, {
      portal_type: 'jungzeelandia.SalesPerson',
      fullobjects: 1,
      b_size: 300,
    });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.searchContent(nextProps.pathname, {
        portal_type: 'jungzeelandia.SalesPerson',
        fullobjects: 1,
        b_size: 300,
      });
    }
  }

  /**
   * Component will unmount
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    this.props.resetSearchContent();
  }

  toggleSelect = (id) => {
    this.setState({
      selected: xor(this.state.selected, [id]),
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.pathname.indexOf('handwerk') !== -1 && (
          <SlidingMenu
            className="filter-menu regions"
            customBurgerIcon={<Icon name={regionFilterSVG} size="75px" />}
            customCrossIcon={<Icon name={regionFilterOpenSVG} size="75px" />}
          >
            <div className="underlined-title">
              <span>
                <h4>
                  Unsere <br />
                  Vertriebsregionen
                </h4>
              </span>
            </div>
            <ul>
              <li style={{ cursor: 'pointer' }}>
                <Checkbox
                  checked={this.state.selected.indexOf('nord') !== -1}
                  onClick={this.toggleSelect.bind(this, 'nord')}
                />
                Gebiet Nord
              </li>
              <li style={{ cursor: 'pointer' }}>
                <Checkbox
                  checked={this.state.selected.indexOf('mitte') !== -1}
                  onClick={this.toggleSelect.bind(this, 'mitte')}
                />
                Gebiet Mitte
              </li>
              <li style={{ cursor: 'pointer' }}>
                <Checkbox
                  checked={this.state.selected.indexOf('sued') !== -1}
                  onClick={this.toggleSelect.bind(this, 'sued')}
                />
                Gebiet Süd
              </li>
            </ul>
          </SlidingMenu>
        )}
        <Container className="contact-persons-listing">
          <Helmet title={this.props.content.title} />
          <h1>
            <MixedTitle>{this.props.content.title}</MixedTitle>
          </h1>
          {this.props.items.map((category) => (
            <div key={category.title}>
              <div className="icon-heading">
                <Image src={arrowdownYellowSVG} />
                <h2>
                  <strong>{category.title}</strong>
                </h2>
              </div>
              <Card.Group
                stackable
                itemsPerRow={2}
                style={{ marginBottom: '24px' }}
              >
                {category.items
                  .filter((item) =>
                    this.state.selected.length === 0 ||
                    this.state.selected.indexOf(item.gebiet) !== -1
                      ? item
                      : null,
                  )
                  .map((item) => (
                    <Card key={item['@id']}>
                      <Card.Content>
                        {item.image ? (
                          <Image src={item.image.download} />
                        ) : (
                          <Image src={missingIMG} />
                        )}
                        <div className="inner">
                          <Card.Header>
                            <h3>{item.title}</h3>
                            <span style={{ fontWeight: '300' }}>
                              {item.position}
                            </span>
                          </Card.Header>
                          <Card.Description>
                            <p>
                              mobil:
                              <br />
                              {item.mobile}
                            </p>
                            <p>
                              mail:
                              <br />
                              <a href={`mailto:${item.mail}`}>{item.mail}</a>
                            </p>
                          </Card.Description>
                        </div>
                      </Card.Content>
                      <div className="salesperson-region">
                        {item.region && [
                          <h3 key="heading">Verkaufsregionen</h3>,
                          <ul key="regions">
                            {item.region.split(/\n/).map((region) => (
                              <li key={region}>{region}</li>
                            ))}
                          </ul>,
                        ]}
                      </div>
                    </Card>
                  ))}
              </Card.Group>
            </div>
          ))}
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  (state, props) => ({
    pathname: props.location.pathname,
    items: filterResults(props.content.items, state.search.items),
  }),
  {
    resetSearchContent,
    searchContent,
  },
)(ContactPersonListing);
