/**
 * Edit Hero2 tile.
 * @module components/Tiles/Hero2/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { readAsDataURL } from 'promise-file-reader';
import { Button, Dimmer, Loader, Message } from 'semantic-ui-react';
import { compose } from 'redux';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import Countdown from 'react-countdown';
import moment from 'moment';

import { createContent } from '@plone/volto/actions';
import { getBaseUrl } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';

// import trashSVG from '../../../icons/delete.svg';
import clearSVG from '../../../icons/clear.svg';

const messages = defineMessages({
  title: {
    id: 'Titel',
    defaultMessage: 'Titel',
  },
  date: {
    id: 'Datum',
    defaultMessage: 'Datum (z.B. 1.1.2021 14:01:00)',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'h1',
  },
});

const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'p',
  },
});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTitleRenderMap,
);
const extendedDescripBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockDescriptionRenderMap,
);

/**
 * Edit image tile class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    this.onUploadImage = this.onUploadImage.bind(this);
    this.state = {
      uploading: false,
    };

    if (!__SERVER__) {
      let titleEditorState;
      let dateEditorState;
      if (props.data && props.data.title) {
        titleEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.title),
        );
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data && props.data.date) {
        dateEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.date),
        );
      } else {
        dateEditorState = EditorState.createEmpty();
      }
      this.state = {
        uploading: false,
        titleEditorState,
        dateEditorState,
      };
    }

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  /**
   * Component did update
   * @method componentDidUpdate
   * @param {Object} prevProps Prev properties
   * @param {Object} prevState Prev state
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.request.loading &&
      this.props.request.loaded &&
      prevState.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: this.props.content['@id'],
      });
    }

    if (
      this.props.data.title &&
      this.props.data.title !== prevProps.data.title &&
      !prevProps.selected
    ) {
      const contentState = stateFromHTML(this.props.data.title);
      this.setState({
        editorState: this.props.data.title
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (
      this.props.data.date &&
      this.props.data.date !== prevProps.data.date &&
      !prevProps.selected
    ) {
      const contentState = stateFromHTML(this.props.data.date);
      this.setState({
        editorState: this.props.data.date
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }
  }

  /**
   * @method onChangeTitle
   * Change Title handler
   * @param {object} titleEditorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle(titleEditorState) {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  /**
   * Change Description handler
   * @method onChangeDate
   * @param {object} dateEditorState Editor state.
   * @returns {undefined}
   */
  onChangeDate(dateEditorState) {
    this.setState({ dateEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        date: dateEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  /**
   * Upload image handler
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage({ target }) {
    const file = target.files[0];
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file.name,
        },
      });
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    moment.locale('de');
    if (__SERVER__) {
      return <div />;
    }
    return (
      <div
        onClick={() => this.props.onSelectBlock(this.props.exampletile)}
        className={['tile', 'hero', this.props.selected && 'selected']
          .filter((e) => !!e)
          .join(' ')}
      >
        {(this.props.selected || true) && !!this.props.data.url && (
          <div className="toolbar">
            <Button.Group>
              <Button
                icon
                basic
                onClick={() =>
                  this.props.onChangeBlock(this.props.block, {
                    ...this.props.data,
                    url: '',
                  })
                }
              >
                <Icon name={clearSVG} size="24px" color="#e40166" />
              </Button>
            </Button.Group>
          </div>
        )}
        <div className="product-hero-2" style={{ zIndex: '123' }}>
          {this.props.data.url ? (
            <img src={`${this.props.data.url}/@@images/image`} alt="" />
          ) : (
            <div>
              <Message>
                {this.state.uploading && (
                  <Dimmer active>
                    <Loader indeterminate>Uploading image</Loader>
                  </Dimmer>
                )}
                <center>
                  <h4>Image</h4>
                  <p>Upload a new image</p>
                  <p>
                    <label className="ui button file">
                      Browse
                      <input
                        type="file"
                        onChange={this.onUploadImage}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </p>
                </center>
              </Message>
            </div>
          )}
          <div className="product-hero-2-body">
            <Editor
              onChange={this.onChangeTitle}
              editorState={this.state.titleEditorState}
              blockRenderMap={extendedBlockRenderMap}
              handleReturn={() => true}
              placeholder={this.props.intl.formatMessage(messages.title)}
              blockStyleFn={() => ''}
            />
            <Editor
              onChange={this.onChangeDate}
              editorState={this.state.dateEditorState}
              blockRenderMap={extendedDescripBlockRenderMap}
              handleReturn={() => true}
              placeholder={this.props.intl.formatMessage(messages.date)}
              blockStyleFn={() => ''}
            />
            <div className="countdown-example">
              <Countdown
                date={moment(this.props.data.date || undefined, [
                  'DD.MM.YYYY',
                  'DD.MM.YYYY HH:mm:ss',
                  'DD.MM.YYYY HH:mm',
                ])}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
