import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Segment, Button } from 'semantic-ui-react';
import homeSVG from '@plone/volto/icons/home.svg';
import { Icon } from '@plone/volto/components';
import { some } from 'lodash';

const NavHeader = ({ permissions = [] }) => {
  const history = useHistory();
  const location = useLocation();

  // Define the buttons with their respective allowed user groups
  const buttons = [
    {
      label: 'Rohstoffe',
      path: '/controlpanel/rezeptdatenbank/rohstoffe',
      allowed_user_groups: [1, 13, 14, 15, 2, 4, 6],
    },
    {
      label: 'Rezepte',
      path: '/controlpanel/rezeptdatenbank/rezepte',
      allowed_user_groups: [1, 12, 13, 14, 15, 2, 4, 6],
    },
    {
      label: 'Produkte',
      path: '/controlpanel/rezeptdatenbank/produkte',
      allowed_user_groups: [1, 13, 14, 15, 2, 4, 6],
    },
    {
      label: 'Bezüge',
      path: '/controlpanel/rezeptdatenbank/bezuege',
      allowed_user_groups: [1],
    },
    {
      label: 'Benutzer',
      path: '/controlpanel/rezeptdatenbank/benutzer',
      allowed_user_groups: [1],
    },
  ];

  // Filter the buttons based on allowed user groups
  const allowedButtons = buttons.filter((button) =>
    some(button.allowed_user_groups, (group) => permissions.includes(group)),
  );

  return (
    <Container className="navHeader">
      <Segment className="primary">
        <div className="navHeader">
          <Button
            onClick={() => history.push('/controlpanel/rezeptdatenbank')}
            disabled={location.pathname === '/controlpanel/rezeptdatenbank'}
          >
            <Icon fitted name={homeSVG} />
            Startseite
          </Button>
          {allowedButtons.map((button, index) => (
            <Button
              key={index}
              onClick={() => history.push(button.path)}
              disabled={location.pathname === button.path}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </Segment>
    </Container>
  );
};

export default NavHeader;
