/**
 * Categories elements reducer.
 * @module reducers/categories/categories
 */
import { has } from 'lodash';

import { GET_CATEGORIES } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  items: [],
  breadcrumbs: [],
};

/**
 * categories for homepage reducer.
 * @function categories
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function categories(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CATEGORIES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_CATEGORIES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        items: has(action, 'result.items') ? action.result.items : [],
        breadcrumbs: has(action, 'result.breadcrumbs')
          ? action.result.breadcrumbs
          : [],
      };
    case `${GET_CATEGORIES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        items: [],
        breadcrumbs: [],
      };
    default:
      return state;
  }
}
