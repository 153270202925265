/**
 * Edit Hero2 block.
 * @module components/Blocks/Hero2/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { Dropdown } from 'semantic-ui-react';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { chunk, map, sortBy } from 'lodash';
import { Grid, Item } from 'semantic-ui-react';

import missingIMG from '../../../static/missing.jpg';

import {
  getRecipeCategory,
  resetRecipeCategory,
  getRecipeCategories,
} from '../../../actions';

const messages = defineMessages({
  title: {
    id: 'Titel',
    defaultMessage: 'Titel',
  },
  description: {
    id: 'Beschreibung',
    defaultMessage: 'Beschreibung',
  },
  no_results_found: {
    id: 'No results found.',
    defaultMessage: 'No results found.',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    recipecategories: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    getRecipeCategories: PropTypes.func.isRequired,
    getRecipeCategory: PropTypes.func.isRequired,
    resetRecipeCategory: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.object,
      }),
    ).isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    this.onChangeCategory = this.onChangeCategory.bind(this);
  }

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getRecipeCategories(null, 'recipes');
    this.props.resetRecipeCategory(null, true);
    if (this.props.data.category) {
      this.props.getRecipeCategory(this.props.data.category);
    }
  }

  /**
   * Change category handler
   * @method onChangeCategory
   * @param {string} category Category.
   * @returns {undefined}
   */
  onChangeCategory(category) {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      category,
    });
    this.props.getRecipeCategory(category);
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    let itemPairs = chunk(this.props.items, 2);
    return (
      <div
        onClick={() => this.props.onSelectBlock(this.props.block)}
        className={[
          'block',
          'tile',
          'passende-rezepte',
          this.props.selected && 'selected',
        ]
          .filter((e) => !!e)
          .join(' ')}
      >
        {this.props.selected && (
          <div className="toolbar" style={{ width: '400px' }}>
            <Dropdown
              options={sortBy(
                map(this.props.recipecategories, (category) => ({
                  key: category.id,
                  value: category.id,
                  text: category.name,
                })),
                'text',
              )}
              placeholder="Kategorie eingeben..."
              search
              selection
              fluid
              value={this.props.data.category || ''}
              noResultsMessage={this.props.intl.formatMessage(
                messages.no_results_found,
              )}
              onChange={(event, data) => this.onChangeCategory(data.value)}
            />
          </div>
        )}
        <div className="passende-rezepte-listing">
          <h2>Passende Rezepte</h2>
          <Grid columns={2} stackable={true}>
            {map(itemPairs, (itemPair) => (
              <Grid.Row>
                {map(itemPair, (item) => (
                  <Grid.Column>
                    <Item.Group unstackable={true}>
                      <Item>
                        <Item.Image
                          src={
                            (item.image &&
                              `${item.image['@id']}/@@images/image/mini`) ||
                            missingIMG
                          }
                          alt={item.name}
                        />
                        <Item.Content>
                          <Item.Header>{item.name}</Item.Header>
                          <Item.Description>
                            <p>
                              <Truncate lines={4}>{item.description}</Truncate>
                            </p>
                          </Item.Description>
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Grid.Column>
                ))}
              </Grid.Row>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      recipecategories: state.recipecategories.items,
      items: state.recipecategory.items,
    }),
    { getRecipeCategories, getRecipeCategory, resetRecipeCategory },
  ),
)(Edit);
