/**
 * Gruppen actions.
 * @module actions/Produkt
 */

import {
  GET_GRUPPEN,
  GET_GRUPPEBYID,
  CREATE_GRUPPE,
  UPDATE_GRUPPE,
  DELETE_GRUPPE,
} from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProduct
 * @param {string} id Produkt id
 * @returns {Object} Produkt action.
 */
export function getGruppen({
  showDeleted = false,
  getAll = false,
  batchingParameters = {},
}) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;
  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted) {
    return {
      type: GET_GRUPPEN,
      request: {
        op: 'get',
        path: `/@gruppen/deleted${pathSuffix}`,
      },
    };
  } else if (getAll) {
    return {
      type: GET_GRUPPEN,
      request: {
        op: 'get',
        path: `/@gruppen/all`,
      },
    };
  }

  return {
    type: GET_GRUPPEN,
    request: {
      op: 'get',
      path: `/@gruppen${pathSuffix}`,
    },
  };
}

/**
 * Get Benutzer item.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getGruppeByID(id) {
  return {
    type: GET_GRUPPEBYID,
    request: {
      op: 'get',
      path: `/@gruppen/${id}`,
    },
  };
}

/**
 * Create a new Gruppe.
 * @function createGruppe
 * @param {Object} formData The data of the new Gruppe
 * @returns {Object} Gruppe action.
 */
export function createGruppe(formData) {
  return {
    type: CREATE_GRUPPE,
    request: {
      op: 'post',
      path: '/@gruppen',
      data: formData,
    },
  };
}

/**
 * Update a new Gruppe.
 * @function createGruppe
 * @param {Object} formData The data of the new Gruppe
 * @returns {Object} Gruppe action.
 */
export function updateGruppe(id, formData) {
  return {
    type: UPDATE_GRUPPE,
    request: {
      op: 'patch',
      path: `/@gruppen/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Gruppe.
 * @function createGruppe
 * @returns {Object} Success Message;
 */
export function deleteGruppe(id) {
  return {
    type: DELETE_GRUPPE,
    request: {
      op: 'del',
      path: `/@gruppen/${id}`,
    },
  };
}
