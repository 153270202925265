import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Ref } from 'semantic-ui-react';
import { listActions, login } from '@plone/volto/actions';
import { Icon, Toast } from '@plone/volto/components';
import { MixedTitle } from '../.';

import arrowSVG from '../../icons/arrow.svg';
import clearSVG from '../../icons/clear.svg';
import infoSVG from '../../icons/info.svg';

import { toast } from 'react-toastify';

class ModalLoginForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    closeMenu: PropTypes.func.isRequired,
    openRegister: PropTypes.func.isRequired,
    listActions: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
    loading: null,
    token: null,
  };

  state = { email: '', password: '', scrolling: false };

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      this.props.listActions(this.props.pathname);
      this.props.closeMenu(null);
    }
  }

  handleRef = (ref) => {
    this.ref = ref;
  };

  setScrolling = () => {
    if (this.ref) {
      const { height } = this.ref.getBoundingClientRect();

      const scrolling = height >= window.innerHeight;

      if (this.state.scrolling !== scrolling) {
        this.setState({ scrolling: scrolling });
      }
    }

    this.animationRequestId = requestAnimationFrame(this.setScrolling);
  };

  componentDidMount = () => {
    this.setScrolling();
  };

  componentWillUnmount = () => {
    cancelAnimationFrame(this.animationRequestId);
    this.props.token &&
      toast.success(
        <Toast
          info
          title={`Hallo ${this.state.email}`}
          content={'Sie haben sich erfolgreich angemeldet!'}
        />,
      );
  };

  /**
   * On login handler
   * @method onLogin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onLogin = (event) => {
    const { email, password } = this.state;
    this.props.login(email, password);
    event.preventDefault();
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { email, password } = this.state;
    const scrollingClass = this.state.scrolling ? ' scrolling' : '';
    return (
      <Ref innerRef={this.handleRef}>
        <div className={`modal-login ${scrollingClass}`}>
          <Icon
            name={clearSVG}
            size="60px"
            color="#fff"
            className="close"
            onClick={this.props.closeMenu}
          />

          <h1>
            <MixedTitle>Ihr Profil.</MixedTitle>
          </h1>
          <Grid stackable>
            <Grid.Column width={6}>
              <h2>Mitglieder</h2>
              <Form onSubmit={this.onLogin}>
                <Form.Input
                  label="E-Mail"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label="Passwort"
                  name="password"
                  value={password}
                  type="password"
                  onChange={this.handleChange}
                />
                <Form.Field className="login-actions">
                  <Button
                    className="large brandButtonPrimary"
                    loading={this.props.loading}
                    style={{ marginBottom: '25px' }}
                  >
                    <MixedTitle>Jetzt einloggen</MixedTitle>
                    <Icon name={arrowSVG} size="20px" className="right" />
                  </Button>
                  <p>
                    Passwort vergessen? Kein Problem.
                    <br />
                    <Link to="/password-reset" onClick={this.props.closeMenu}>
                      Klicken Sie hier um ein neues Passwort anzufordern.
                    </Link>
                  </p>
                </Form.Field>
                {this.props.error && (
                  <Button className="small brandButtonPrimary contact-form-message error-message">
                    <Icon name={infoSVG} size="60px" className="left" />
                    {this.props.error.status === 401 ? (
                      <div>
                        <h4>Login nicht möglich:</h4>
                        <p>
                          Bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr
                          Passwort und versuchen Sie es erneut. Stellen Sie
                          sicher, dass Sie beide korrekt eingegeben haben (Groß-
                          und Kleinschreibung).
                        </p>
                      </div>
                    ) : (
                      this.props.error?.message
                    )}
                  </Button>
                )}
              </Form>
            </Grid.Column>
            <Grid.Column width={6}>
              <h2>Noch kein Mitglied?</h2>
              <p className="register">
                Wenn Sie noch keinen Benutzernamen haben, klicken Sie bitte auf
                den Button „Mitglied werden“ und füllen dann bitte das
                Registrierungsformular aus.
              </p>
              <Button
                className="large brandButtonPrimary"
                onClick={this.props.openRegister}
                loading={this.props.loading}
                style={{ marginBottom: '25px' }}
              >
                <MixedTitle>Mitglied werden</MixedTitle>
                <Icon name={arrowSVG} size="20px" className="right" />
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      </Ref>
    );
  }
}

export default connect(
  (state, props) => ({
    error: state.userSession.login.error,
    loading: state.userSession.login.loading,
    loaded: state.userSession.login.loaded,
    token: state.userSession.token,
  }),
  { listActions, login },
)(ModalLoginForm);
