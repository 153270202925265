import { React } from 'react';
import NutrientInfo from '../../NutrientInfo';

const NutrientDetails = ({ rezeptNutrients }) => {
  return (
    <div className="recipe-container">
      <div className="nutrient-info">
        <div className="nutrient-meta-info">
          <p>
            Rezeptname: <strong>{rezeptNutrients.name}</strong>
          </p>
          <p>
            Verkehrsbezeichnung:{' '}
            <strong>{rezeptNutrients.verkehrsbezeichnung}</strong>
          </p>
        </div>
        <NutrientInfo data={rezeptNutrients} isRezept={true} />
        <div className="trenner" />
        <div className="nutrient-meta-info">
          <strong>
            <p className="disclaimer">Hinweis:</p>
          </strong>
          <strong>
            <p>
              Die Nährwertangaben sind Durchschnittswerte und wurden rechnerisch
              ermittelt. Sie gelten nur für die angegebene Rezeptnummer. Es
              wurde ein Backverlust von {rezeptNutrients.backverlust}%{' '}
              berücksichtigt.
            </p>
          </strong>
        </div>
      </div>
    </div>
  );
};

export default NutrientDetails;
