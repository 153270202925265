const schemaProdukte = () => {
  return {
    title: 'Produkte',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['products'],
      },
    ],
    properties: {
      products: {
        title: 'Produkte',
        widget: 'array',
        default: [],
        vocabulary: {
          '@id': 'jungzeelandia.contenttypes.vocabularies.ProductsVocabulary',
        },
      },
    },
    required: [],
  };
};

export default schemaProdukte;
