/* eslint-disable require-jsdoc */
import { POST_NEWSLETTER_REGISTER } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
};

export default function onRegisterNewsletter(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${POST_NEWSLETTER_REGISTER}_PENDING`:
      return { ...state, loading: true, error: null };

    case `${POST_NEWSLETTER_REGISTER}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case `${POST_NEWSLETTER_REGISTER}_FAIL`:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
