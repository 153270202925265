import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import {
  Button,
  Card,
  Container,
  Image,
  Pagination,
  Segment,
} from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import { resetSearchContent, searchContent } from '@plone/volto/actions';

import { MixedTitle } from '..';
// import { getAllNewsItems, getNewsItemsPage } from '../../actions';

import arrowSVG from '../../icons/arrow.svg';
import paginationLeftSVG from '../../icons/pagination-left.svg';
import paginationRightSVG from '../../icons/pagination-right.svg';

import config from '@plone/volto/registry';

class VerkaufskonceptAlbumView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    itemsTotal: PropTypes.number.isRequired,
    searchContent: PropTypes.func.isRequired,
    resetSearchContent: PropTypes.func.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    loaded: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }).isRequired,
  };

  state = {
    currentPage: 1,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.searchContent(this.props.pathname, {
      portal_type: 'jungzeelandia.SalesConcept',
      fullobjects: 0,
      b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
      b_start:
        (this.state.currentPage - 1) * config.settings.DEFAULT_NEWS_PAGING_SIZE,
      use_solr: 1,
      sort_on: 'effective',
      sort_order: 'descending',
    });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.searchContent(nextProps.pathname, {
        portal_type: 'jungzeelandia.SalesConcept',
        fullobjects: 0,
        b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
        b_start:
          (this.state.currentPage - 1) *
          config.settings.DEFAULT_NEWS_PAGING_SIZE,
        use_solr: 1,
        sort_on: 'effective',
        sort_order: 'descending',
      });
    }
  }

  /**
   * Component will unmount
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    this.props.resetSearchContent();
  }

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState(() => ({ currentPage: activePage }));
    this.props.searchContent(this.props.pathname, {
      portal_type: 'jungzeelandia.SalesConcept',
      fullobjects: 0,
      b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
      b_start: (activePage - 1) * config.settings.DEFAULT_NEWS_PAGING_SIZE,
      use_solr: 1,
      sort_on: 'effective',
      sort_order: 'descending',
    });
  };

  render() {
    return (
      this.props.loaded && (
        <Segment
          basic
          as="section"
          className="aktuelles-view"
          style={{ marginBottom: '100px' }}
        >
          <Helmet title="Zeelandia - Verkaufskonzepte" />
          <Container>
            <h1>{this.props.content.title}</h1>
            <p>{this.props.content.description}</p>
            <Card.Group
              stackable
              centered
              itemsPerRow={2}
              style={{ marginBottom: '24px' }}
            >
              {this.props.items.map((item) => (
                <Card key={item['@id']} as={Link} to={item['@id']}>
                  <Image src={`${item['@id']}/@@images/image`} />
                  <Card.Content>
                    <Card.Header>{item.title}</Card.Header>
                    <Card.Description>{item.description}</Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{ textAlign: 'right' }}>
                      <Button className="small brandButtonSecondary ">
                        <MixedTitle>Mehr lesen</MixedTitle>
                        <Icon name={arrowSVG} size="17px" className="right" />
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
            <div style={{ textAlign: 'center' }}>
              <Pagination
                activePage={this.state.currentPage}
                totalPages={Math.ceil(
                  this.props.itemsTotal /
                    config.settings.DEFAULT_NEWS_PAGING_SIZE,
                )}
                onPageChange={this.handlePaginationChange}
                firstItem={null}
                lastItem={null}
                prevItem={
                  this.props.batching.prev
                    ? {
                        content: <Icon name={paginationLeftSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
                nextItem={
                  this.props.batching.next
                    ? {
                        content: <Icon name={paginationRightSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
              />
            </div>
          </Container>
        </Segment>
      )
    );
  }
}

export default connect(
  (state, props) => ({
    pathname: props.location.pathname,
    items: state.search.items,
    itemsTotal: state.search.total,
    batching: state.search.batching,
    loaded: state.search.loaded,
  }),
  {
    resetSearchContent,
    searchContent,
  },
)(VerkaufskonceptAlbumView);
