/**
 * Homepage jobs elements reducer.
 * @module reducers/homepage/jobs
 */
import { has } from 'lodash';
import { GET_JOBS } from '../../constants/ActionTypes';
import config from '@plone/volto/registry';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  items: [],
};

/**
 * jobs for homepage reducer.
 * @function jobs
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function jobs(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_JOBS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_JOBS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        items: has(action, 'result.items')
          ? action.result.items.map((item) => ({
              ...item,
              url: item['@id'].replace(config.settings.apiPath, ''),
            }))
          : [],
      };
    case `${GET_JOBS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        items: [],
      };
    default:
      return state;
  }
}
