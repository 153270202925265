/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { InlineSearch, ModalLoginForm, ModalRegisterForm } from '..';

import smallLogo from './logo-small.svg';
import yellowBoldRightArrowSVG from '../../static/yellow-bold-right-arrow.svg';
import mobileSearch from '../../static/mobile-search.svg';
import closeSVG from '../../static/search-close.svg';

class MobileHeader extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    userId: PropTypes.string,
  };

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static defaultProps = {
    userId: '',
  };

  state = {
    menuIsOpen: false,
    subMenuOpened: false,
    searchIsOpen: false,
    showBackdrop: false,
    loginIsOpen: false,
    registerIsOpen: false,
  };

  toggleSearchDialog = () => {
    this.setState((prevState) => ({
      searchIsOpen: !prevState.searchIsOpen,
      showBackdrop: !prevState.showBackdrop,
    }));
  };

  openMobileHeader = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  toggleSubMenu = (submenu) => {
    if (submenu !== this.state.subMenuOpened) {
      this.setState(() => ({
        subMenuOpened: submenu,
      }));
    } else {
      this.setState(() => ({
        subMenuOpened: false,
      }));
    }
  };

  closeSearchDialog = (e) => {
    e.stopPropagation();
    this.setState(() => ({
      searchIsOpen: false,
      showBackdrop: false,
    }));
  };

  closeMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState(() => ({
      menuIsOpen: false,
      loginIsOpen: false,
      registerIsOpen: false,
      showBackdrop: false,
    }));
  };

  openLogin = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      loginIsOpen: !prevState.loginIsOpen,
      menuIsOpen: false,
      showBackdrop: true,
    }));
    window.scrollTo(0, 0);
  };

  openRegister = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      loginIsOpen: false,
      registerIsOpen: !prevState.registerIsOpen,
      menuIsOpen: false,
      showBackdrop: true,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="mobile-header mobile tablet only">
          <Link to="/">
            <img src={smallLogo} alt="Mobile Logo" />
          </Link>
          <div className="mobile-header-buttons">
            <button
              onClick={this.toggleSearchDialog}
              className="mobile-search-button"
            >
              <img src={mobileSearch} alt="search" />
            </button>
            <button
              style={{ margin: 0, padding: 0 }}
              onClick={this.openMobileHeader}
              className={
                this.state.menuIsOpen
                  ? 'hamburger hamburger--spin is-active'
                  : 'hamburger hamburger--spin'
              }
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" style={{ zIndex: 99999 }} />
              </span>
            </button>
          </div>
        </div>

        <CSSTransition
          in={this.state.menuIsOpen}
          timeout={500}
          classNames="mobile-menu"
          unmountOnExit
        >
          <div
            key="myuniquekey"
            style={{
              height: '100vh',
              position: 'fixed',
              top: 0,
              right: 0,
              left: 0,
              zIndex: 3,
              width: '100vw',
            }}
          >
            <div className="mobile-menu-nav">
              <ul>
                <li>
                  <div
                    onClick={() => this.toggleSubMenu('produkte')}
                    className="mobile-menu-list-header"
                  >
                    <h3>Produkte</h3>
                    <img src={yellowBoldRightArrowSVG} alt="right-arrow" />
                  </div>
                  <ul
                    className={cx('submenu', {
                      opened: this.state.subMenuOpened === 'produkte',
                    })}
                  >
                    <li>
                      <Link onClick={this.closeMenu} to="/alle-produkte">
                        Alle Produkte
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/produkte/produktneuheiten"
                      >
                        Produktneuheiten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={this.closeMenu} to="/produkte/urgetreide">
                        Urgetreide
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/aktuelles/clean_label_produkte"
                      >
                        Clean Label
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/alle-produkte?category=17"
                      >
                        Serviceartikel
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    onClick={() => this.toggleSubMenu('rezepte')}
                    className="mobile-menu-list-header"
                  >
                    <h3>Rezepte</h3>
                    <img src={yellowBoldRightArrowSVG} alt="right-arrow" />
                  </div>
                  <ul
                    className={cx('submenu', {
                      opened: this.state.subMenuOpened === 'rezepte',
                    })}
                  >
                    <li>
                      <Link onClick={this.closeMenu} to="/alle-rezepte">
                        Alle Rezepte
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/alle-rezepte?aktuell=1"
                      >
                        Rezeptneuheiten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={this.closeMenu} to="/rezepte/rezeptwelten">
                        Rezeptwelten
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={this.closeMenu} to="/rezepte/grundrezepte">
                        Grundrezepte
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <div
                    onClick={() => this.toggleSubMenu('kompetenzen')}
                    className="mobile-menu-list-header"
                  >
                    <h3>Kompetenzen</h3>
                    <img src={yellowBoldRightArrowSVG} alt="right-arrow" />
                  </div>
                  <ul
                    className={cx('submenu', {
                      opened: this.state.subMenuOpened === 'kompetenzen',
                    })}
                  >
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/produkte/funktioniert-natuerlich"
                      >
                        funktioniert - natürlich!
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/kompetenzen/technologien"
                      >
                        Technologien
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/kompetenzen/qualitaetsmanagement"
                      >
                        Qualitätsmanagement
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/kompetenzen/individuelle-loesungen"
                      >
                        Individuelle Lösungen
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/kompetenzen/verkaufskonzepte"
                      >
                        Verkaufskonzepte
                      </Link>
                    </li>

                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/kompetenzen/mediathek"
                      >
                        Mediathek
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    onClick={() => this.toggleSubMenu('wiruberuns')}
                    className="mobile-menu-list-header"
                  >
                    <h3>Wir über uns</h3>
                    <img src={yellowBoldRightArrowSVG} alt="right-arrow" />
                  </div>
                  <ul
                    className={cx('submenu', {
                      opened: this.state.subMenuOpened === 'wiruberuns',
                    })}
                  >
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/ueber-zeelandia" // might change before roll out
                      >
                        Über Zeelandia
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/ihre-ansprechpartner"
                      >
                        Kontakt / Anfahrt
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/karriere"
                      >
                        Karriere
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/aktuelles"
                      >
                        Aktuelles
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.closeMenu}
                        to="/wir-ueber-uns/international"
                      >
                        International
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="mobile-menu-tools">
              <ul>
                {!this.props.userId && (
                  <React.Fragment>
                    <li onClick={this.openLogin}>Anmelden</li>
                    <li onClick={this.openLogin}>Merkliste</li>
                  </React.Fragment>
                )}
                {this.props.userId && (
                  <React.Fragment>
                    <li>
                      <Link onClick={this.closeMenu} to="/logout">
                        Abmelden
                      </Link>
                    </li>
                    <li>
                      <Link onClick={this.closeMenu} to="/merkliste">
                        Merkliste
                      </Link>
                    </li>
                  </React.Fragment>
                )}

                <li>
                  <Link
                    onClick={this.closeMenu}
                    to="/wir-ueber-uns/ihre-ansprechpartner"
                  >
                    Ansprechpartner
                  </Link>
                </li>
                <li>
                  <Link onClick={this.closeMenu} to="/search">
                    Suche
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={this.state.searchIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchmobiledialog"
            style={{
              height: '300px',
              position: 'fixed',
              top: '200px',
              right: 0,
              left: 0,
              zIndex: 3,
              width: '100vw',
            }}
          >
            <img
              style={{
                height: '23px',
                position: 'absolute',
                top: '20px',
                right: '20px',
              }}
              src={closeSVG}
              onClick={this.closeSearchDialog}
              alt="close"
            />
            <InlineSearch closeMenu={this.closeSearchDialog} />
          </div>
        </CSSTransition>
        <CSSTransition
          in={this.state.loginIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <ModalLoginForm
            pathname={this.props.pathname}
            closeMenu={this.closeMenu}
            openRegister={this.openRegister}
          />
        </CSSTransition>
        <CSSTransition
          in={this.state.registerIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <ModalRegisterForm closeMenu={this.closeMenu} />
        </CSSTransition>
        <CSSTransition
          in={this.state.showBackdrop}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchmobilemenubackdrop"
            className="backdrop"
            onClick={this.closeSearchDialog}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              zIndex: '2',
              background: 'rgba(255, 240, 66, 0.75)',
            }}
          />
        </CSSTransition>
      </React.Fragment>
    );
  }
}

export default MobileHeader;
