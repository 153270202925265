/**
 * Routes.
 * @module routes
 */
import { defaultRoutes } from '@plone/volto/routes';

import { App } from '@plone/volto/components';

import {
  Aktuelles,
  Homepage,
  Merkliste,
  OnapplyJobsView,
  Product,
  ProductList,
  Recipe,
  Bilddatenbank,
  MediathekView,
  InxMailRegister,
  MailError,
  MailSuccess,
  PasswordProtectedView,
} from './components';
import {
  Rezeptdatenbank,
  RohstoffeListing,
  BenutzerListing,
  BenutzerDetails,
  BenutzerCreate,
  BenutzerEdit,
  RohstoffeDetails,
  RohstoffCreate,
  RohstoffEdit,
  ProdukteListing,
  ProdukteDetails,
  ProduktCreate,
  ProduktEdit,
  GebindeCreate,
  GebindeEdit,
  RezepteListing,
  RezepteDetails,
  RezeptCreate,
  RezeptEdit,
  BezugCreate,
  BezuegeListing,
  BezugEdit,
  ZutatengruppenListing,
  ZutatengruppeCreate,
  ZutatengruppeEdit,
  VerkaufsinformationEdit,
  GruppenListing,
  GruppeCreate,
  GruppeEdit,
  RezeptkategorienListing,
  RezeptkategorieCreate,
  RezeptkategorieEdit,
  RezeptgruppenListing,
  RezeptgruppeCreate,
  RezeptgruppeEdit,
  ProduktkategorienListing,
  ProduktkategorieCreate,
  ProduktkategorieEdit,
  RohstoffkategorienListing,
  RohstoffkategorieCreate,
  RohstoffkategorieEdit,
} from './components/Rezeptdatenbank';
import config from '@plone/volto/registry';

/**
 * Routes function.
 * @function
 * @returns {Object} Routes.
 */

const routes = [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/wir-ueber-uns/aktuelles',
        component: Aktuelles,
        exact: true,
      },
      {
        path: '/wir-ueber-uns/karriere/stellenangebote',
        component: OnapplyJobsView,
        exact: true,
      },
      {
        path: '/',
        component: Homepage,
        exact: true,
      },
      {
        path: '/merkliste',
        component: Merkliste,
      },
      {
        path: '/alle-produkte',
        component: ProductList,
        exact: true,
      },
      {
        path: '/alle-produkte/:id',
        component: Product,
      },
      {
        path: '/alle-rezepte',
        component: ProductList,
        exact: true,
      },
      {
        path: '/alle-rezepte/:id',
        component: Recipe,
      },
      {
        path: '/kompetenzen/mediathek/bilddatenbank',
        component: Bilddatenbank,
        exact: true,
      },
      {
        path: '/kompetenzen/mediathek',
        component: MediathekView,
        exact: true,
      },
      {
        path: '/kompetenzen/mediathek/digitale-vorlagen',
        component: PasswordProtectedView,
        exact: true,
      },
      {
        path: '/mail-register',
        component: InxMailRegister,
        exact: true,
      },
      {
        path: '/mail-error',
        component: MailError,
        exact: true,
      },
      {
        path: '/mail-success',
        component: MailSuccess,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank',
        component: Rezeptdatenbank,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffe',
        component: RohstoffeListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffe/add',
        component: RohstoffCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffe/edit',
        component: RohstoffEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffe/details',
        component: RohstoffeDetails,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzer',
        component: BenutzerListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzer/details',
        component: BenutzerDetails,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzer/add',
        component: BenutzerCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzer/edit',
        component: BenutzerEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte',
        component: ProdukteListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte/details',
        component: ProdukteDetails,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte/add',
        component: ProduktCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte/edit',
        component: ProduktEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte/gebinde/add',
        component: GebindeCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produkte/gebinde/edit',
        component: GebindeEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezepte',
        component: RezepteListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezepte/details',
        component: RezepteDetails,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezepte/add',
        component: RezeptCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezepte/edit',
        component: RezeptEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/bezuege',
        component: BezuegeListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/bezuege/add',
        component: BezugCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/bezuege/edit',
        component: BezugEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptkategorien',
        component: RezeptkategorienListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptkategorien/add',
        component: RezeptkategorieCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptkategorien/edit',
        component: RezeptkategorieEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/zutatengruppen',
        component: ZutatengruppenListing,
        exact: true,
      },

      {
        path: '/controlpanel/rezeptdatenbank/zutatengruppen/add',
        component: ZutatengruppeCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/zutatengruppen/edit',
        component: ZutatengruppeEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezepte/verkaufsinformation/edit',
        component: VerkaufsinformationEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzergruppen',
        component: GruppenListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzergruppen/add',
        component: GruppeCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/benutzergruppen/edit',
        component: GruppeEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptgruppen',
        component: RezeptgruppenListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptgruppen/add',
        component: RezeptgruppeCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rezeptgruppen/edit',
        component: RezeptgruppeEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produktkategorien',
        component: ProduktkategorienListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produktkategorien/add',
        component: ProduktkategorieCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/produktkategorien/edit',
        component: ProduktkategorieEdit,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffkategorien',
        component: RohstoffkategorienListing,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffkategorien/add',
        component: RohstoffkategorieCreate,
        exact: true,
      },
      {
        path: '/controlpanel/rezeptdatenbank/rohstoffkategorien/edit',
        component: RohstoffkategorieEdit,
        exact: true,
      },

      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
