/**
 * Zutaten actions.
 * @module actions/Zutat
 */

import {
  GET_ZUTATEN,
  GET_ZUTATBYID,
  CREATE_ZUTAT,
  UPDATE_ZUTAT,
  DELETE_ZUTAT,
} from '../../constants/ActionTypes';

/**
 * Get Zutat items.
 * @function getZutaten
 * @param {boolean} showDeleted Whether to show deleted Zutaten or not
 * @returns {Object} Zutat action.
 */
export function getZutaten(rezept_id) {
  return {
    type: GET_ZUTATEN,
    request: {
      op: 'get',
      path: `/@zutaten/${rezept_id}`,
    },
  };
}

/**
 * Get Zutat item by ID.
 * @function getZutatByID
 * @param {string} id Zutat id
 * @returns {Object} Zutat action.
 */
export function getZutatByID(id) {
  return {
    type: GET_ZUTATBYID,
    request: {
      op: 'get',
      path: `/@zutaten/zutat/${id}`,
    },
  };
}

/**
 * Create a new Zutat.
 * @function createZutat
 * @param {Object} formData The data of the new Zutat
 * @returns {Object} Zutat action.
 */
export function createZutat(formData) {
  return {
    type: CREATE_ZUTAT,
    request: {
      op: 'post',
      path: '/@zutaten',
      data: formData,
    },
  };
}

/**
 * Update a Zutat.
 * @function updateZutat
 * @param {string} id The id of the Zutat to update
 * @param {Object} formData The data of the updated Zutat
 * @returns {Object} Zutat action.
 */
export function updateZutat(id, formData) {
  return {
    type: UPDATE_ZUTAT,
    request: {
      op: 'patch',
      path: `/@zutaten/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Zutat.
 * @function deleteZutat
 * @param {string} id The id of the Zutat to delete
 * @returns {Object} Success Message.
 */
export function deleteZutat(id) {
  return {
    type: DELETE_ZUTAT,
    request: {
      op: 'del',
      path: `/@zutaten/${id}`,
    },
  };
}
