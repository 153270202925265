export default function convertNumbersToStrings(object) {
  let obj = { ...object };
  // Iterate over each property in the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the current value is a number
      if (typeof obj[key] === 'number') {
        // Convert the number to a string
        obj[key] = obj[key].toString();
      }
      // If the value is an object, recurse
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertNumbersToStrings(obj[key]);
      }
    }
  }
  return obj;
}
