import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Image } from 'semantic-ui-react';

const Zubehor = ({ item }) => (
  <div className="product-rezepte">
    <Grid centered>
      <Grid.Column mobile={12} computer={8}>
        <Card.Group stackable centered itemsPerRow={1}>
          {[1, 2, 3, 4, 5].map(
            (index) =>
              item[`service_artikel_title_${index}`] && (
                <Card key={`zubehor-${index}`}>
                  {item[`service_artikel_image_${index}`] && (
                    <Image
                      src={item[`service_artikel_image_${index}`].download}
                    />
                  )}
                  <Card.Content>
                    <Card.Header>
                      {item[`service_artikel_title_${index}`]}
                    </Card.Header>
                    <Card.Description>
                      {item[`service_artikel_description_${index}`]}
                    </Card.Description>
                  </Card.Content>
                </Card>
              ),
          )}
        </Card.Group>
      </Grid.Column>
    </Grid>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Zubehor.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default Zubehor;
