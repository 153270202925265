// actions/batchingParameters.js

import { SET_BATCHING_PARAMETERS } from '../../constants/ActionTypes';
import { RESET_BATCHING_PARAMETERS } from '../../constants/ActionTypes';

/**
 * Set batching parameters.
 * @function setBatchingParameters
 * @param {Object} parameters - New batching parameters.
 * @returns {Object} Action.
 */
export function setBatchingParameters(parameters) {
  return {
    type: SET_BATCHING_PARAMETERS,
    payload: parameters,
  };
}

/**
 * Reset batching parameters.
 * @function reSetBatchingParameters
 * @param {Object} customDefaults - New batching parameters.
 * @returns {Object} Action.
 */
export function resetBatchingParameters(customDefaults = {}) {
  return {
    type: RESET_BATCHING_PARAMETERS,
    payload: customDefaults,
  };
}
