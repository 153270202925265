import React from 'react';
import { Container, Grid, Item, Image } from 'semantic-ui-react';

import { MixedTitle, ContactForm } from '../.';

import telephoneSVG from '../../static/telephone.svg';
import faxSVG from '../../static/fax.svg';
import envelopeSVG from '../../static/envelope.svg';
import worldSVG from '../../static/world.svg';
import youtubeSVG from '../../static/youtube.svg';
import xingSVG from '../../static/xing.svg';
import linkedinSVG from '../../static/linkedin.svg';
import facebookSVG from '../../static/icn_facebook.svg';
import instagramSVG from '../../static/icn_instagram.svg';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="desktop-footer mobile hidden">
      <Container className="footer">
        <h2>
          <MixedTitle>Haben Sie Fragen?</MixedTitle>
        </h2>
        <Grid stackable style={{ marginBottom: '168px' }}>
          <Grid.Column width={7} className="main-column">
            <p>Sie erreichen uns telefonisch, per Fax oder per E-Mail.</p>

            <div className="contact-address-wrapper">
              <Item.Group divided>
                <Item as="a" href="tel:069 95055-0">
                  <Item.Image src={telephoneSVG} />
                  <Item.Content>
                    <Item.Header>069 95055-0</Item.Header>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Image src={faxSVG} />
                  <Item.Content>
                    <Item.Header>069 95055-80</Item.Header>
                  </Item.Content>
                </Item>
                <Item as="a" href="mailto:info@zeelandia.de">
                  <Item.Image src={envelopeSVG} />
                  <Item.Content>
                    <Item.Header>info@zeelandia.de</Item.Header>
                  </Item.Content>
                </Item>
                <Item as="div" className="external-footer-links">
                  <Item.Image src={worldSVG} />
                  <Item.Content>
                    <Item.Header>
                      <div className="international-link">
                        Website Zeelandia International: <br />
                        <br />{' '}
                        <a href="https://zeelandia.com">www.zeelandia.com</a>
                      </div>
                      <div className="wissensplattform-link">
                        Wissensplattform Explore Zeelandia: <br />
                        <br />{' '}
                        <a href="https://explore.zeelandia.com/de">
                          explore.zeelandia.com/de
                        </a>
                      </div>
                    </Item.Header>
                  </Item.Content>
                </Item>
              </Item.Group>

              <div className="address">
                <p>
                  Zeelandia GmbH & Co. KG <br />
                  Homburger Landstraße 602 <br />
                  60437 Frankfurt am Main
                </p>
              </div>
            </div>
            <p>Folgen Sie uns auf unseren Zeelandia Social Media Kanälen:</p>
            <div className="social-media">
              <ul>
                <li>
                  <Image
                    as="a"
                    href="https://www.youtube.com/c/ZeelandiaGmbHCoKG"
                    src={youtubeSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.xing.com/pages/zeelandiagmbh-co-kg"
                    src={xingSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.linkedin.com/company/zeelandia-gmbh-co-kg/"
                    src={linkedinSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.facebook.com/Zeelandia.Deutschland"
                    src={facebookSVG}
                    alt=""
                  />
                </li>{' '}
                <li>
                  <Image
                    as="a"
                    href="https://www.instagram.com/zeelandia_deutschland/"
                    src={instagramSVG}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </Grid.Column>
          {/* id="kontakt" should be here and not inside for the smooth scroll to work, no clue why */}
          {/* contact form temporarily removed */}
          <Grid.Column id="kontakt" width={5} className="secondary-column">
            <ContactForm />
          </Grid.Column>
        </Grid>
        <div className="subfooter">
          <ul>
            <li>
              <a href="/impressum">Impressum</a>
            </li>
            <li>
              <a href="/datenschutz">Datenschutz</a>
            </li>
            <li>
              <a href="/einkaufs-verkaufsbedingungen">
                Einkaufs-/Verkaufsbedingungen
              </a>
            </li>
            <li>© {currentYear} Zeelandia GmbH & Co. KG</li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
