/**
 * View hero2 tile.
 * @module components/Tiles/Hero2/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment';

/**
 * View image tile class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className={['block', 'tile', 'countdown'].filter((e) => !!e).join(' ')}>
    <div className="countdown-wrapper">
      <div className="countdown">
        <div className="countdown-title">{data.title}</div>
        <div className="countdown-timer">
          {' '}
          <Countdown
            date={moment(data.date || undefined, [
              'DD.MM.YYYY',
              'DD.MM.YYYY HH:mm:ss',
              'DD.MM.YYYY HH:mm',
            ])}
          />
        </div>
      </div>
      <img src={`${data.url}/@@images/image`} alt="" />
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
