/**
 * Categories actions.
 * @module actions/categories/categories
 */

import { GET_CATEGORIES } from '../../constants/ActionTypes';

/**
 * Get categories items.
 * @function getCategories
 * @param {string} category Category
 * @param {string} type Type
 * @returns {Object} Categories action.
 */
export default function getCategories(
  category,
  type = 'recipes',
  all = undefined,
) {
  let allParam = all ? 'all=1' : '';
  return {
    type: GET_CATEGORIES,
    request: {
      op: 'get',
      path: `/@categories${
        !!parseInt(category) ? `/${category}` : ''
      }?type=${type}&${allParam}`,
    },
  };
}
