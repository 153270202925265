import { has } from 'lodash';
import {
  GET_REZEPTGRUPPEN,
  GET_REZEPTGRUPPEBYID,
  CREATE_REZEPTGRUPPE,
  DELETE_REZEPTGRUPPE,
  UPDATE_REZEPTGRUPPE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function rezeptgruppen(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTGRUPPEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTGRUPPEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rezeptgruppen: [], total_pages: 0 },
      };
    case `${GET_REZEPTGRUPPEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rezeptgruppeByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTGRUPPEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTGRUPPEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_REZEPTGRUPPEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rezeptgruppeCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_REZEPTGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_REZEPTGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_REZEPTGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptgruppeDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_REZEPTGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_REZEPTGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_REZEPTGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptgruppeUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_REZEPTGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_REZEPTGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_REZEPTGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
