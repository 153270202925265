/**
 * Homepage aktuelles elements reducer.
 * @module reducers/homepage/aktuelles
 */
import { has } from 'lodash';
import { GET_AKTUELLES } from '../../constants/ActionTypes';

import config from '@plone/volto/registry';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  items: [],
};

/**
 * aktuelles for homepage reducer.
 * @function aktuelles
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function aktuelles(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_AKTUELLES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_AKTUELLES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        items: has(action, 'result.items')
          ? action.result.items.map((item) => ({
              ...item,
              url: item['@id'].replace(config.settings.apiPath, ''),
            }))
          : [],
      };
    case `${GET_AKTUELLES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        items: [],
      };
    default:
      return state;
  }
}
