// reducers/Verkaufsinformation.js

import { has } from 'lodash';
import {
  GET_VERKAUFSINFORMATIONBYID,
  CREATE_VERKAUFSINFORMATION,
  UPDATE_VERKAUFSINFORMATION,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function verkaufsinformationByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_VERKAUFSINFORMATIONBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_VERKAUFSINFORMATIONBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_VERKAUFSINFORMATIONBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function verkaufsinformationCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_VERKAUFSINFORMATION}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_VERKAUFSINFORMATION}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_VERKAUFSINFORMATION}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function verkaufsinformationUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_VERKAUFSINFORMATION}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_VERKAUFSINFORMATION}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_VERKAUFSINFORMATION}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
