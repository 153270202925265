/**
 * Get all recipes and products reducer.
 * @module reducers/listings/listings
 */
import { has } from 'lodash';
import {
  GET_ALLNEWSITEMS,
  GET_ALLPRODUCTS,
  GET_ALLRECIPES,
} from '../../constants/ActionTypes';

import config from '@plone/volto/registry';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  items: [],
  itemsTotal: 0,
  batching: {},
};

/**
 * Products and Recipes listings reducer.
 * @function listings
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function listings(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ALLPRODUCTS}_PENDING`:
    case `${GET_ALLRECIPES}_PENDING`:
    case `${GET_ALLNEWSITEMS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ALLPRODUCTS}_SUCCESS`:
    case `${GET_ALLRECIPES}_SUCCESS`:
    case `${GET_ALLNEWSITEMS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        items: has(action, 'result.items')
          ? action.result.items.map((item) => ({
              ...item,
              url: item['@id'].replace(config.settings.apiPath, ''),
            }))
          : [],
        itemsTotal: has(action, 'result.items') ? action.result.items_total : 0,
        batching: has(action, 'result.items')
          ? { ...action.result.batching }
          : {},
      };
    case `${GET_ALLPRODUCTS}_FAIL`:
    case `${GET_ALLRECIPES}_FAIL`:
    case `${GET_ALLNEWSITEMS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        items: [],
      };
    default:
      return state;
  }
}
