import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Container, Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import { getOnapplyJobs } from '../../actions';
import { MixedTitle } from '..';

import arrowSVG from '../../icons/arrow.svg';

class OnapplyJobsView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    getOnapplyJobs: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getOnapplyJobs();
  }

  render() {
    return (
      this.props.loaded && (
        <Segment
          basic
          as="section"
          className="onapply-jobs-view aktuelles-view"
          style={{ marginBottom: '100px' }}
        >
          <Container>
            <h1>Stellenangebote</h1>
            <Card.Group
              stackable
              centered
              itemsPerRow={2}
              style={{ marginBottom: '24px' }}
            >
              {this.props.items.map((item) => (
                <Card
                  key={item.linkJobAd}
                  href={item.linkJobAd}
                  target="_blank"
                >
                  <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Description>{item.city}</Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{ textAlign: 'right' }}>
                      <Button className="small brandButtonSecondary ">
                        <MixedTitle>Mehr lesen</MixedTitle>
                        <Icon name={arrowSVG} size="17px" className="right" />
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Container>
        </Segment>
      )
    );
  }
}

export default connect(
  (state, props) => ({
    pathname: props.location.pathname,
    items: state.onapplyJobs.items,
    loaded: state.onapplyJobs.loaded,
  }),
  {
    getOnapplyJobs,
  },
)(OnapplyJobsView);
