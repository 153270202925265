/**
 * Homepage sprachmodule actions.
 * @module actions/homepage/aktuelles
 */

import { GET_AKTUELLES } from '../../constants/ActionTypes';

/**
 * Get Homepage aktuelles items.
 * @function getHomepageAktuelles
 * @returns {Object} Homepage aktuelles action.
 */
export default function getAktuelles() {
  return {
    type: GET_AKTUELLES,
    request: {
      op: 'get',
      path:
        '/wir-ueber-uns/aktuelles/@search?portal_type=News+Item&sort_on=effective&sort_order=descending&fullobjects',
    },
  };
}
