import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { login } from '@plone/volto/actions';
import jwtDecode from 'jwt-decode';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '../.';

import arrowSVG from '../../icons/arrow.svg';
import infoSVG from '../../icons/info.svg';

class InlineLoginForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    login: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
    loading: null,
    token: null,
  };

  state = { email: '', password: '' };

  /**
   * On login handler
   * @method onLogin
   * @param {Object} event Event object.
   * @returns {undefined}
   */
  onLogin = (event) => {
    const { email, password } = this.state;
    this.props.login(email, password);
    event.preventDefault();
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { email, password } = this.state;

    if (this.props.token) {
      return (
        <div className="inline-login-form">
          <h2>
            <MixedTitle
              light="Sie haben sich erfolgreich angemeldet als"
              strong={jwtDecode(this.props.token).sub}
            />
          </h2>

          <Button className="medium brandButtonPrimary" as={Link} to="/logout">
            <MixedTitle>Abmelden</MixedTitle>
            <Icon name={arrowSVG} size="20px" className="right" />
          </Button>
        </div>
      );
    }
    return (
      <Form className="inline-login-form" onSubmit={this.onLogin}>
        <Form.Input
          placeholder="Benutzername"
          name="email"
          value={email}
          onChange={this.handleChange}
        />
        <Form.Input
          placeholder="Passwort"
          name="password"
          value={password}
          type="password"
          onChange={this.handleChange}
        />
        <Form.Field className="with-register-message">
          <Button
            className="medium brandButtonPrimary"
            loading={this.props.loading}
          >
            <MixedTitle>Jetzt anmelden</MixedTitle>
            <Icon name={arrowSVG} size="20px" className="right" />
          </Button>
          <div className="register-message">
            <strong>Neuer Benutzer?</strong> Bitte füllen Sie unser{' '}
            <Link to="/?openRegister=1">Registrierungsformular</Link> aus.
          </div>
        </Form.Field>
        {this.props.error && (
          <Button
            className="small brandButtonPrimary contact-form-message"
            style={{
              backgroundColor: 'red',
              marginTop: '20px',
              marginLeft: '0',
            }}
          >
            <Icon name={infoSVG} size="17px" className="left" />
            {this.props.error.message}
          </Button>
        )}
      </Form>
    );
  }
}

export default connect(
  (state, props) => ({
    error: state.userSession.login.error,
    loading: state.userSession.login.loading,
    token: state.userSession.token,
  }),
  { login },
)(InlineLoginForm);
