import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Loader, Dimmer } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getZutatengruppen,
  updateZutatengruppe,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
} from '../../../actions';

import { BasicTable } from '../../ReactTableContent/ReactTableContent';
import { createColumnHelper } from '@tanstack/react-table';

import NavHeader from '../NavHeader';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const ZutatengruppenListing = ({
  pathname,
  zutatengruppen,
  totalPages,
  getZutatengruppen,
  updateZutatengruppe,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
  batchingParameters,
}) => {
  const currentListing = 'zutatengruppen';
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);

  // table config
  const [tableConfig, setTableConfig] = useState({
    COLUMNS: [],
    showDeleted: false,
    data: [],
    totalPages: 0,
    linkToEdit: true,
  });

  const deletedButton = !tableConfig.showDeleted
    ? 'Gelöschte Gruppen anzeigen'
    : 'Gruppen anzeigen';

  const [refreshFlag, setRefreshFlag] = useState(false);

  const columnHelper = createColumnHelper();

  const COLUMNS = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>Bezeichnung</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        if (timestamp > 0) {
          return <p>{formattedDate}</p>;
        } else {
          return <p></p>;
        }
      },
    }),
  ];

  const COLUMNS_DELETED = [
    ...COLUMNS,
    columnHelper.accessor('deleted', {
      id: 'deleted',
      header: () => <span></span>,
      cell: (info) => (
        <Button onClick={() => unDelete(info.row.original.id)}>
          Wiederherstellen
        </Button>
      ),
    }),
  ];

  // Helper function to pad zeroes for single-digit numbers
  const padZero = (num) => (num < 10 ? `0${num}` : num);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    getZutatengruppen({
      showDeleted: tableConfig.showDeleted,
      batchingParameters: batchingParameters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refreshFlag,
    getZutatengruppen,
    batchingParameters,
    tableConfig.showDeleted,
  ]);

  // attatch data to tableConfig when finished loading
  useEffect(() => {
    const columns = tableConfig.showDeleted ? COLUMNS_DELETED : COLUMNS;
    setTableConfig({
      ...tableConfig,
      data: zutatengruppen,
      COLUMNS: columns,
      totalPages: totalPages,
    });
    if (
      batchingParameters &&
      batchingParameters.currentListing !== currentListing
    ) {
      resetBatchingParameters({ currentListing: currentListing });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zutatengruppen, tableConfig.showDeleted]);

  const toggleShowDeleted = () => {
    setTableConfig({
      ...tableConfig,
      showDeleted: !tableConfig.showDeleted,
    });
    resetBatchingParameters({ currentListing: currentListing });
    setRefreshFlag(!refreshFlag);
  };

  const unDelete = (id) => {
    updateZutatengruppe(id, { deleted: 0 });
    getZutatengruppen({
      showDeleted: true,
      batchingParameters: batchingParameters,
    });
    setRefreshFlag(!refreshFlag);
  };

  if (error) {
    return <Error error={error} />;
  }
  if (!token) {
    setError({ status: 401 });
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    setError({ status: 401 });
  }
  return (
    (zutatengruppen && (
      <div className="view-wrapper">
        <Helmet title="Zutatengruppen" />
        <NavHeader permissions={permissions} />
        <Container className="controlpanel">
          <Segment.Group raised>
            <Segment className="primary">
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>
                    {tableConfig.showDeleted
                      ? 'Gelöschte Zutatengruppen'
                      : 'Zutatengruppen'}
                  </h1>
                </div>
                <div className="button-container">
                  <div className="buttonArea">
                    <Link to="zutatengruppen/add">
                      <Button className="rdb-new" primary type="New">
                        Neue Zutatengruppe
                      </Button>
                    </Link>
                    <Button
                      className="rdb-show-deleted"
                      primary
                      onClick={() => toggleShowDeleted()}
                    >
                      {deletedButton}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="rezeptdatenbank header"></div>
            </Segment>

            {zutatengruppen && tableConfig.data && (
              <Segment>
                <BasicTable
                  tableConfig={tableConfig}
                  setTableConfig={setTableConfig}
                  batchingParameters={batchingParameters}
                  setBatchingParameters={setBatchingParameters}
                />
              </Segment>
            )}
          </Segment.Group>
        </Container>
        {isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={pathname}
              hideDefaultViewButtons
              inner={
                <Link to="/controlpanel/rezeptdatenbank" className="item">
                  <Icon
                    name={backSVG}
                    className="contents circled"
                    size="30px"
                    title={intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </div>
    )) || (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )
  );
};

ZutatengruppenListing.propTypes = {
  pathname: PropTypes.string.isRequired,
  zutatengruppen: PropTypes.array.isRequired,
  totalPages: PropTypes.any.isRequired,
  getZutatengruppen: PropTypes.func.isRequired,
  updateZutatengruppe: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
  setBatchingParameters: PropTypes.func,
  resetBatchingParameters: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      totalPages: state.zutatengruppen.data.total_pages,
      zutatengruppen: state.zutatengruppen.data.zutatengruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
      batchingParameters: state.batchingParameters,
    }),
    {
      getZutatengruppen,
      updateZutatengruppe,
      getVistPermissions,
      setBatchingParameters,
      resetBatchingParameters,
    },
  ),
)(ZutatengruppenListing);
