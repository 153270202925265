import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Table } from 'semantic-ui-react';

const Informationen = ({ product }) => (
  <div className="product-info">
    <Grid centered>
      <Grid.Column
        mobile={12}
        computer={8}
        dangerouslySetInnerHTML={{ __html: product.description_long }}
      >
        {/* <p>
            Mischung aufgeschlossener ganzer Haferkörner, Saaten
            (Sonnenblumenkerne, Lein-, Chia und Hanfsamen) und Quinoasamen sowie
            mit Hafersauerteig für Müsli-Gebäckspezialitäten.
          </p>
          <ul>
            <li>
              <b>Sensationelle Frischhaltung:</b> für sehr lange Verzehrfrische
              auf natürliche Weise
            </li>
            <li>
              <b>Schonendes Druck-Kochverfahren:</b> vollständig aufgeschlossene
              Körner und Saaten
            </li>
            <li>
              <b>Unverwechselbarer Genuss mit Hafersauerteig:</b> saftige Krume,
              intakte Körner mit spürbarem Biss
            </li>
            <li>
              <b>Hohe Flexibilität:</b> direkt zu verarbeiten, vielfältige
              Anwendungsmöglichkeiten
            </li>
          </ul>
          <p>Produktprofil</p>
          <ul>
            <li>Mit Superfoods Quinoa-, Hanf- und Chiasamen</li>
            <li>
              Besonders kernig mit Haferkörnern, Leinsamen und
              Sonnenblumenkernen
            </li>
            <li>Für den genussvollen "Müsli-Moment"</li>
          </ul> */}
      </Grid.Column>
    </Grid>

    {product.packagings.length ? (
      <Grid centered className="packagings-section">
        <Grid.Column mobile={12} computer={8}>
          <Divider />
          <Table basic="very" className="packagings">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Zugabemenge:</Table.HeaderCell>
                <Table.HeaderCell className="artikelnummer">
                  Artikel-Nr.:
                </Table.HeaderCell>
                <Table.HeaderCell>Gebinde:</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {product.packagings.map((packaging) => {
                return (
                  <Table.Row key={packaging.artikelnummer}>
                    <Table.Cell>{packaging.zugabe}</Table.Cell>
                    <Table.Cell>{packaging.artikelnummer}</Table.Cell>
                    <Table.Cell>{packaging.name}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    ) : (
      ''
    )}
  </div>
);

Informationen.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      '@id': PropTypes.string,
    }),
  }).isRequired,
};

export default Informationen;
