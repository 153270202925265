import { has } from 'lodash';
import {
  GET_ZUTATENGRUPPEN,
  GET_ZUTATENGRUPPEBYID,
  CREATE_ZUTATENGRUPPE,
  DELETE_ZUTATENGRUPPE,
  UPDATE_ZUTATENGRUPPE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function zutatengruppen(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ZUTATENGRUPPEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ZUTATENGRUPPEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { zutatengruppen: [], total_pages: 0 },
      };
    case `${GET_ZUTATENGRUPPEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function zutatengruppeByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ZUTATENGRUPPEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ZUTATENGRUPPEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_ZUTATENGRUPPEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function zutatengruppeCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_ZUTATENGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_ZUTATENGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_ZUTATENGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function zutatengruppeDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_ZUTATENGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_ZUTATENGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_ZUTATENGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function zutatengruppeUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_ZUTATENGRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_ZUTATENGRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_ZUTATENGRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
