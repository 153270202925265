/**
 * Homepage sprachmodule actions.
 * @module actions/allitems/allitems
 */
import {
  GET_ALLNEWSITEMS,
  GET_ALLPRODUCTS,
  GET_ALLRECIPES,
} from '../../constants/ActionTypes';

import config from '@plone/volto/registry';

/**
 * Get all recipes items.
 * @function getAllRecipes
 * @param {string} category Category id
 * @param {string} aktuell Aktuell
 * @returns {Object} All Recipes action.
 */
export function getAllRecipes(category, aktuell) {
  const { DEFAULT_PAGING_SIZE } = config.settings;

  return {
    type: GET_ALLRECIPES,
    request: {
      op: 'get',
      path: `/@search?portal_type=jungzeelandia.Recipe&use_solr=true&sort_on=aktuell&sort_order=descending&b_size=${DEFAULT_PAGING_SIZE}${
        !!parseInt(category) ? `&category=${category}` : ''
      }${aktuell ? '&aktuell=1' : ''}`,
    },
  };
}

/**
 * Get a recipe paging resulting items.
 * @function getRecipePage
 * @param {number} page page
 * @param {string} category Category id
 * @param {string} aktuell Aktuell
 * @returns {Object} All Page items action.
 */
export function getRecipesPage(page, category, aktuell) {
  const { DEFAULT_PAGING_SIZE } = config.settings;

  const currentPage = DEFAULT_PAGING_SIZE * (page - 1);
  aktuell = parseInt(aktuell, 10);
  return {
    type: GET_ALLRECIPES,
    request: {
      op: 'get',
      path: `/@search?portal_type=jungzeelandia.Recipe&use_solr=true&sort_on=aktuell&sort_order=descending&b_start=${currentPage}&b_size=${DEFAULT_PAGING_SIZE}${
        !!parseInt(category) ? `&category=${category}` : ''
      }${aktuell ? '&aktuell=1' : ''}`,
    },
  };
}

/**
 * Get all products items.
 * @function getAllProducts
 * @param {string} category Category id
 * @param {string} aktuell Aktuell
 * @returns {Object} All Products action.
 */
export function getAllProducts(category, aktuell) {
  const { DEFAULT_PAGING_SIZE } = config.settings;
  aktuell = parseInt(aktuell, 10);
  return {
    type: GET_ALLPRODUCTS,
    request: {
      op: 'get',
      path: `/@search?portal_type=jungzeelandia.Product&use_solr=true&sort_on=aktuell&sort_order=descending&b_size=${DEFAULT_PAGING_SIZE}${
        !!parseInt(category) ? `&category=${category}` : ''
      }${aktuell ? '&aktuell=1' : ''}`,
    },
  };
}

/**
 * Get a products paging resulting items.
 * @function getProductsPage
 * @param {number} page page
 * @param {string} category Category id
 * @returns {Object} All Page items action.
 */
export function getProductsPage(page, category) {
  const { DEFAULT_PAGING_SIZE } = config.settings;
  const currentPage = DEFAULT_PAGING_SIZE * (page - 1);
  return {
    type: GET_ALLPRODUCTS,
    request: {
      op: 'get',
      path: `/@search?portal_type=jungzeelandia.Product&use_solr=true&sort_on=aktuell&sort_order=descending&b_start=${currentPage}&b_size=${DEFAULT_PAGING_SIZE}${
        !!parseInt(category) ? `&category=${category}` : ''
      }`,
    },
  };
}

/**
 * Get all aktuelles items.
 * @function getAllNewsItems
 * @returns {Object} All NewsItems action.
 */
export function getAllNewsItems() {
  const { DEFAULT_NEWS_PAGING_SIZE } = config.settings;

  return {
    type: GET_ALLNEWSITEMS,
    request: {
      op: 'get',
      path: `/@search?portal_type=News+Item&sort_on=effective&sort_order=descending&fullobjects&b_size=${DEFAULT_NEWS_PAGING_SIZE}`,
    },
  };
}

/**
 * Get a aktuelles paging resulting items.
 * @function getNewsItemsPage
 * @param {number} page page
 * @returns {Object} All Page items action.
 */
export function getNewsItemsPage(page) {
  const { DEFAULT_NEWS_PAGING_SIZE } = config.settings;
  const currentPage = DEFAULT_NEWS_PAGING_SIZE * (page - 1);
  return {
    type: GET_ALLNEWSITEMS,
    request: {
      op: 'get',
      path: `/@search?portal_type=News+Item&sort_on=effective&sort_order=descending&fullobjects&b_start=${currentPage}&b_size=${DEFAULT_NEWS_PAGING_SIZE}`,
    },
  };
}
