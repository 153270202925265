import React from 'react';

import { Form, Dropdown } from 'semantic-ui-react';

const GebindeForm = ({
  isBezeichnungDuplicate,
  isRohstoffnummerDuplicate,
  formData,
  handleInputChange,
  handleKategorienChange,
  selectedKategorien,
  kategorien = [],
  rohstoffnummer_suggestions,
}) => {
  const renderOptions = (option) => {
    const level = option.level || 0;
    const indent = `${level * 1}em`;

    const style = {
      fontWeight: level === 0 ? 'bold' : 'normal',
      paddingLeft: indent,
    };

    return <span style={style}>{option.name}</span>;
  };

  const dropdownOptions = kategorien.map((kategorie) => ({
    key: kategorie.id,
    text: kategorie.name,
    value: kategorie.id,
    level: kategorie.level || 0,
    content: renderOptions(kategorie),
  }));

  return (
    <Form>
      <div className="meta-info">
        <Form.Field error={isRohstoffnummerDuplicate}>
          <label>Rohstoffnummer (*):</label>
          <input
            name="rohstoffnummer"
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            max="999999"
            value={formData.rohstoffnummer}
            onChange={handleInputChange}
            list="suggestions"
            required
            autoComplete="off"
          />
          <datalist id="suggestions">
            {formData.rohstoffnummer > 99 &&
              rohstoffnummer_suggestions.map((suggestion) => (
                <option value={suggestion} />
              ))}
          </datalist>
        </Form.Field>
        {isRohstoffnummerDuplicate && (
          <div className="speech-bubble warning">
            Rohstoffnummer bereits vergeben
          </div>
        )}
        <Form.Field error={isBezeichnungDuplicate}>
          <label>Bezeichnung (*):</label>
          <input
            type="text"
            name="bezeichnung"
            value={formData.bezeichnung}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isBezeichnungDuplicate && (
          <div className="speech-bubble warning">
            Rohstoff mit dieser Bezeichnung existiert bereits
          </div>
        )}
        <Form.Field>
          <label>Rohstoffkategorie:</label>
          <Dropdown
            placeholder="Rohstoffkategorie auswählen"
            name="rohstoffkategorien"
            fluid
            multiple
            selection
            options={dropdownOptions}
            value={selectedKategorien}
            onChange={(e, { value }) => {
              handleKategorienChange(value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Status:</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value={1}>Aktuell</option>
            <option value={2}>Stillgelegt</option>
            <option value={3}>In Bearbeitung</option>
          </select>
        </Form.Field>
      </div>
      <div className="trenner" />
      <div className="nutrients-info">
        <Form.Field>
          <label>Brennwert ( kJ ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="brennwert_kj"
            value={formData.brennwert_kj}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Brennwert ( kcal ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="brennwert_kcal"
            value={formData.brennwert_kcal}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Eiweiss ( g ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="eiweiss"
            value={formData.eiweiss}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Kohlenhydrate ( g ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="kohlenhydrate"
            value={formData.kohlenhydrate}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>davon Zucker:</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="zucker"
            value={formData.zucker}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Fett ( g ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="fett"
            value={formData.fett}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>davon gesättigte Fettsäuren:</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="ges_fettsaeuren"
            value={formData.ges_fettsaeuren}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Ballaststoffe ( g ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="ballaststoffe"
            value={formData.ballaststoffe}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Salz ( g ):</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="natrium"
            value={formData.natrium}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Kalkulationspreis [Euro / kg]:</label>
          <input
            type="number"
            onKeyPress={(e) => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
            min="0"
            name="basis_euro"
            value={formData.basis_euro}
            onChange={handleInputChange}
          />
        </Form.Field>
      </div>
      <div className="trenner" />
      <div className="info">
        <Form.Field>
          <label>Zusatzinformationen:</label>
          <textarea
            name="zusatzinformationen"
            value={formData.zusatzinformationen}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <p className="disclaimer">* Pflichtfeld</p>
      </div>
    </Form>
  );
};

export default GebindeForm;
