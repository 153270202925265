/**
 * Personal preferences component.
 * @module components/manage/Preferences/Merkliste
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { map, xor } from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Item,
  Menu,
  Label,
} from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import { getCart, deleteFavorite } from '../../actions';
import { MixedTitle, InlineLoginForm } from '../../components';
import trashWhiteSVG from '../../icons/trash-white.svg';
import downloadWhiteSVG from '../../icons/download-white.svg';
import missingIMG from '../../static/missing.jpg';
import trashYellowButtonSVG from '../../static/trash-yellow-button.svg';
import downloadSVG from '../../static/download.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  Merkliste: {
    id: 'Watch List',
    defaultMessage: 'Watch List',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  language: {
    id: 'Language',
    defaultMessage: 'Language',
  },
  languageDescription: {
    id: 'Your preferred language',
    defaultMessage: 'Your preferred language',
  },
  saved: {
    id: 'Changes saved',
    defaultMessage: 'Changes saved',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

/**
 * Merkliste class.
 * @class Merkliste
 * @extends Component
 */
class Merkliste extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    getCart: PropTypes.func.isRequired,
    deleteFavorite: PropTypes.func.isRequired,
    recipes: PropTypes.arrayOf(PropTypes.object).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    salesConcepts: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteLoaded: PropTypes.bool.isRequired,
    deleteLoading: PropTypes.bool.isRequired,
  };

  state = {
    activeItem: 'Rezepte',
    selected: {
      product: [],
      recipe: [],
      sales_concept: [],
    },
  };

  componentDidMount() {
    this.props.getCart();
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deleteLoading && nextProps.deleteLoaded) {
      this.props.getCart();
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  toggleSelect = (type, id) => {
    this.setState((state) => ({
      selected: {
        ...state.selected,
        [type]: xor(state.selected[type], [id]),
      },
    }));
  };

  deleteFavorite = (type, id) => {
    this.props.deleteFavorite(type, id);
  };

  batchDelete = (type) => {
    this.props.deleteFavorite(type, this.state.selected[type]);
    this.setState({
      selected: {
        ...this.state.selected,
        [type]: [],
      },
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { activeItem } = this.state;
    let items;
    let type;
    switch (activeItem) {
      case 'Verkaufskonzepte':
        items = this.props.salesConcepts;
        type = 'sales_concept';
        break;
      case 'Produkte':
        items = this.props.products;
        type = 'product';
        break;
      default:
        items = this.props.recipes;
        type = 'recipe';
        break;
    }

    if (!this.props.token) {
      return (
        <Container className="inline-login-form">
          <Grid centered>
            <Grid.Column mobile={12} computer={6}>
              <h2>
                <MixedTitle>Einfach mehr Zeelandia</MixedTitle>
                <div className="title-append">
                  Melden Sie Sich an, um unsere exklusiven Services in vollem
                  Umfang nutzen zu können.
                </div>
              </h2>
              <InlineLoginForm />
            </Grid.Column>
          </Grid>
        </Container>
      );
    }

    return (
      <Container id="page-personal-preferences" className="merkliste">
        <Helmet
          title={`Zeelandia -  ${this.props.intl.formatMessage(
            messages.Merkliste,
          )}`}
        />
        <h1 style={{ marginBottom: '75px' }}>
          <MixedTitle>Ihr Profil.</MixedTitle>
        </h1>

        <Grid stackable>
          <Grid.Column width={4} className="merkliste-sidebar">
            <div className="underlined-title">
              <span>Listen</span>
            </div>
            <Menu compact borderless stackable vertical>
              <Menu.Item
                name="Rezepte"
                active={activeItem === 'Rezepte'}
                onClick={this.handleItemClick}
              >
                Rezepte
                <Label circular basic>
                  {this.props.recipes.length}
                </Label>
              </Menu.Item>
              <Menu.Item
                name="Produkte"
                active={activeItem === 'Produkte'}
                onClick={this.handleItemClick}
              >
                Produkte
                <Label circular basic>
                  {this.props.products.length}
                </Label>
              </Menu.Item>
              {/* <Menu.Item
                name="Verkaufskonzepte"
                active={activeItem === 'Verkaufskonzepte'}
                onClick={this.handleItemClick}
              >
                Verkaufskonzepte
                <Label circular basic>
                  {this.props.salesConcepts.length}
                </Label>
              </Menu.Item> */}
            </Menu>
          </Grid.Column>
          <Grid.Column width={7} className="listing-column">
            <h2>Alle {activeItem}</h2>
            <Item.Group unstackable>
              {items.map((item) => {
                let itemUrl =
                  type === 'recipe'
                    ? `/alle-rezepte/${item.id}`
                    : `/alle-produkte/${item.id}`;
                return (
                  <Item key={item['@id']}>
                    {item.image ? (
                      <Item.Image
                        size="small"
                        src={`${item.image['@id']}/@@images/image/mini`}
                        alt="rezepte"
                      />
                    ) : (
                      <Item.Image size="small" src={missingIMG} alt="rezepte" />
                    )}
                    <Item.Content>
                      <Checkbox
                        checked={
                          this.state.selected[type].indexOf(item.id) !== -1
                        }
                        onChange={() => this.toggleSelect(type, item.id)}
                      />
                      <Item.Header>
                        <Link to={itemUrl}>{item.name}</Link>
                      </Item.Header>
                      <Item.Description>{item.description}</Item.Description>
                      <Item.Extra>
                        {type === 'recipe' && (
                          <a
                            href={`https://www.zeelandia.de/api/@@rezept-pdf?id=${item.id}`}
                          >
                            <img src={downloadSVG} alt="download" />
                          </a>
                        )}
                        <img
                          src={trashYellowButtonSVG}
                          alt="trash"
                          onClick={this.deleteFavorite.bind(
                            this,
                            type,
                            item.UID ? item.UID : item.id,
                          )}
                        />
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                );
              })}
            </Item.Group>
            <div className="merkliste-actions">
              {type === 'recipe' && (
                <Button
                  as="a"
                  className="large brandButtonSecondary"
                  href={`${
                    config.settings.apiPath
                  }/@cart/download?type=${type}&${map(
                    this.state.selected[type],
                    (id) => `ids:list=${id}`,
                  ).join('&')}`}
                  disabled={this.state.selected[type].length === 0}
                >
                  <MixedTitle>Markierte downloaden</MixedTitle>
                  <Icon name={downloadWhiteSVG} size="23px" className="right" />
                </Button>
              )}
              <Button
                className="large brandButtonSecondary"
                onClick={this.batchDelete.bind(this, type)}
                disabled={this.state.selected[type].length === 0}
              >
                <MixedTitle>Markierte löschen</MixedTitle>
                <Icon name={trashWhiteSVG} size="23px" className="right" />
              </Button>
            </div>
          </Grid.Column>
        </Grid>
        <div className="merkliste-additional-actions">
          <Link to="/datenschutz">Datenschutzbestimmungen</Link>
        </div>
      </Container>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      pathname: props.location.pathname,
      token: state.userSession.token,
      recipes: state.cart.data.recipe,
      products: state.cart.data.product,
      salesConcepts: state.cart.data.sales_concept,
      deleteLoading: state.cart.delete.loading,
      deleteLoaded: state.cart.delete.loaded,
    }),
    {
      getCart,
      deleteFavorite,
    },
  ),
)(Merkliste);
