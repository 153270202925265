import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Image } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '..';

import arrowSVG from '../../icons/arrow.svg';
import ihreAnsprechpartnerPNG from '../../static/ihre-ansprechpartner.jpg';
import anfahrtIcon from '../../static/anfahrt_icon.svg';
// import industrieSVG from '../../static/industrie.svg';
import kontaktIcon from '../../static/kontakt_icon.svg';

class ContactPersonsView extends Component {
  state = {};

  render() {
    return (
      <Container
        as="section"
        className="contact-persons-sections"
        style={{ marginBottom: '50px' }}
      >
        <Helmet title="Zeelandia - Ihre Ansprechpartner" />
        <h1 style={{ marginBottom: '80px' }}>
          <MixedTitle>Ihre Ansprechpartner</MixedTitle>
        </h1>
        {this.props.content?.image ? (
          <img
            src={this.props.content?.image.scales.large.download}
            style={{ width: '100%', marginBottom: '100px' }}
          />
        ) : (
          //kept as fallback if no image is uploaded
          <Image
            src={ihreAnsprechpartnerPNG}
            style={{ width: '100%', marginBottom: '100px' }}
          />
        )}
        <Card.Group
          stackable
          centered
          itemsPerRow={1}
          style={{ marginBottom: '24px' }}
        >
          <Card>
            <Image src={kontaktIcon} className="card-icon" />
            <Card.Content>
              <Card.Header>
                <h2>
                  <MixedTitle breakline>Ihr Verkaufsservice.</MixedTitle>
                </h2>
              </Card.Header>
              <Card.Description>
                <p>
                  Die Mitarbeiter aus unserem Verkaufsservice-Team geben
                  Auskunft zu allen Fragen rund um Ihre Bestellungen. Sie
                  informieren über verschiedene Produkte und Aktionen und auch
                  über die Serviceartikel im Zeelandia-Portfolio. Und sie nehmen
                  telefonisch Ihre Bestellung entgegen, falls das nicht unser
                  Außendienstmitarbeiter bereits für Sie erledigt hat.
                </p>
                <p>
                  Alle Bestellungen werden über unser effektives
                  Warenwirtschaftssystem erfasst und verarbeitet. So garantieren
                  wir schnellste Bearbeitung und sorgen dafür, dass die
                  gewünschten Produkte Sie unverzüglich erreichen. Falls Ihnen
                  noch kein Ansprechpartner zugeordnet ist, steht Ihnen auch
                  unser Verkaufsservice für den Erstkontakt zur Verfügung.
                </p>
                <p>
                  Unsere Servicezeiten sind montags bis donnerstags von 7 bis
                  16:30 Uhr und freitags von 7 bis 14 Uhr.
                </p>
              </Card.Description>
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                }}
              >
                <Button
                  as={Link}
                  to="/wir-ueber-uns/ihre-ansprechpartner/ihr-verkaufsservice"
                  className="medium brandButtonSecondary "
                >
                  <MixedTitle>Weitere Informationen</MixedTitle>
                  <Icon name={arrowSVG} size="15px" className="right" />
                </Button>
              </div>
            </Card.Content>
          </Card>
          <Card>
            <Image src={anfahrtIcon} className="card-icon" />
            <Card.Content>
              <Card.Header>
                <h2>
                  <MixedTitle breakline light="Ihr Weg" strong="zu uns." />
                </h2>
              </Card.Header>
              <Card.Description>
                <p>
                  Sie wollen uns persönlich in Frankfurt besuchen? Auch vor Ort
                  möchten wir Sie herzlich willkommen heißen und Ihnen ein guter
                  Gastgeber sein. Im Norden von Frankfurt mitten im
                  Rhein-Main-Gebiet gelegen, sind wir mit allen Verkehrsmitteln
                  gut zu erreichen, egal ob Sie mit dem Auto, dem Zug oder dem
                  Flugzeug anreisen. Wir freuen uns auf Ihren Besuch und
                  wünschen Ihnen eine sichere und angenehme Anreise!
                </p>
              </Card.Description>
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                }}
              >
                <Button
                  as={Link}
                  to="/wir-ueber-uns/ihre-ansprechpartner/ihr-weg-zu-uns"
                  className="medium brandButtonSecondary "
                >
                  <MixedTitle>Weitere Informationen</MixedTitle>
                  <Icon name={arrowSVG} size="15px" className="right" />
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    );
  }
}

export default ContactPersonsView;
