import React, { useState } from 'react';
import { includes, isObject } from 'lodash';
import { Button, Modal, Checkbox, Form } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '@plone/volto/registry';
import { useIntl } from 'react-intl';
import Google from '@kitconcept/volto-dsgvo-banner/components/Banner/Google';
import Matomo from '@kitconcept/volto-dsgvo-banner/components/Banner/Matomo';

import { hideDSGVOBanner } from '@kitconcept/volto-dsgvo-banner/actions';

const Banner = (props) => {
  let privacy_url = config.settings.DSGVOBanner.privacy_url;
  const modules = config.settings.DSGVOBanner.modules;
  const [cookies, setCookie, removeCookie] = useCookies();
  const [configureCookies, setConfigureCookies] = useState(false);
  const showConfirmModal = !Number(cookies.confirm_cookies) || props.show;
  const intl = useIntl();

  if (isObject(privacy_url)) {
    privacy_url = privacy_url[intl.locale];
  }
  const [confirmTracking, setConfirmTracking] = useState(
    !!Number(cookies.confirm_tracking),
  );
  const [confirmYoutube, setConfirmYoutube] = useState(
    !!Number(cookies.confirm_youtube),
  );
  const [confirmFacebook, setConfirmFacebook] = useState(
    !!Number(cookies.confirm_facebook),
  );

  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);
  const options = { path: '/', expires: expiryDate };

  const confirmSelection = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    if (confirmTracking) {
      setCookie('confirm_tracking', 1, options);
      window[`ga-disable-${config.settings.DSGVOBanner.trackingId}`] = false;
    } else {
      removeCookie('confirm_tracking', options);
      window[`ga-disable-${config.settings.DSGVOBanner.trackingId}`] = true;
      removeCookie('_ga', options);
      removeCookie('_gat', options);
      removeCookie('_gid', options);
    }

    if (confirmFacebook) {
      setCookie('confirm_facebook', 1, options);
    } else {
      removeCookie('confirm_facebook', options);
    }

    if (confirmYoutube) {
      setCookie('confirm_youtube', 1, options);
    } else {
      removeCookie('confirm_youtube', options);
    }

    setCookie('confirm_cookies', 1, options);
    props.hideDSGVOBanner();
  };

  const confirmAll = () => {
    setCookie('confirm_tracking', 1, options);
    window[`ga-disable-${config.settings.DSGVOBanner.trackingId}`] = false;

    setCookie('confirm_facebook', 1, options);
    setCookie('confirm_youtube', 1, options);
    setCookie('confirm_cookies', 1, options);

    props.hideDSGVOBanner();
  };
  return (
    <>
      {config.settings.DSGVOBanner.tracker.type === 'google' && <Google />}
      {config.settings.DSGVOBanner.tracker.type === 'matomo' && <Matomo />}
      <Modal
        id="question-landing"
        open={showConfirmModal}
        dimmer="inverted"
        closeOnDimmerClick={false}
        className="dsgvo-banner"
      >
        {!configureCookies ? (
          <>
            <Modal.Content>
              <h2>Datenschutzhinweis</h2>

              <p className="cookie-text">
                Wir nutzen auf unseren Seiten Cookies und andere Technologien,
                sogenannte ID-Fingerprints, um mithilfe dieser
                Tracking-Techniken die Benutzererfahrung effizienter zu
                gestalten. Für den Einsatz derjenigen Techniken, die nicht
                unbedingt erforderlich sind, benötigen wir Ihre Erlaubnis. Diese
                Seite verwendet unterschiedliche Cookie-Typen. Sie können Ihre
                Einwilligung jederzeit von der Cookie-Erklärung auf unserer
                Website ändern oder widerrufen.
              </p>
              <p>
                Du bist unter 16 Jahre alt? Dann kannst du nicht in optionale
                Services einwilligen. Du kannst deine Eltern oder
                Erziehungsberechtigten bitten, mit dir in diese Services
                einzuwilligen.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="medium brandButtonPrimary"
                onClick={() => confirmAll()}
              >
                Alles akzeptieren
              </Button>
              <Button
                className="medium brandButtonPrimary"
                onClick={confirmSelection}
              >
                Alles ablehnen
              </Button>

              {modules.length > 0 && (
                <Button
                  className="medium brandButtonPrimary"
                  branded
                  onClick={() => setConfigureCookies(true)}
                >
                  Datenschutzeinstellung anpassen
                </Button>
              )}
            </Modal.Actions>
            <Modal.Content>
              <Link to="/impressum" onClick={confirmSelection}>
                Impressum
              </Link>
              <br />
              <Link to="/datenschutz" onClick={confirmSelection}>
                Datenschutz
              </Link>
            </Modal.Content>
          </>
        ) : (
          <>
            <Modal.Content>
              <h2>Datenschutzhinweis</h2>
              <Form>
                <Form.Field>
                  <Checkbox toggle label="Notwendig" checked disabled />
                </Form.Field>
                {includes(modules, 'tracking') && (
                  <Form.Field>
                    <Checkbox
                      toggle
                      label="Statistiken"
                      onChange={() => setConfirmTracking(!confirmTracking)}
                      checked={confirmTracking}
                    />
                  </Form.Field>
                )}
                {includes(modules, 'youtube') && (
                  <Form.Field>
                    <Checkbox
                      toggle
                      label="Youtube"
                      onChange={() => setConfirmYoutube(!confirmYoutube)}
                      checked={confirmYoutube}
                    />
                  </Form.Field>
                )}
                {includes(modules, 'facebook') && (
                  <Form.Field>
                    <Checkbox
                      toggle
                      label="Facebook"
                      onChange={() => setConfirmFacebook(!confirmFacebook)}
                      checked={confirmFacebook}
                    />
                  </Form.Field>
                )}
              </Form>
              <p>
                <br />
                <br />
                <b>Notwendig:</b>
                <br />
                Essential Services sind für die grundlegende Funktionalität der
                Website erforderlich. Sie enthalten nur technisch notwendige
                Services. Diesen Services kann nicht widersprochen werden.
              </p>
              <p>
                <b>Statistiken:</b>
                <br />
                Statistik Services wie Matomo werden benötigt, um
                pseudonymisierte Daten über die Besucher der Website zu sammeln.
                Die Daten ermöglichen es uns, die Besucher besser zu verstehen
                und die Webseite zu optimieren.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="medium brandButtonPrimary"
                onClick={() => setConfigureCookies(false)}
              >
                {'< '}
                Zurück
              </Button>
              <Button
                className="medium brandButtonPrimary"
                onClick={() => confirmAll()}
              >
                Alles akzeptieren
              </Button>
              <Button
                className="medium brandButtonPrimary"
                onClick={confirmSelection}
              >
                Speichern
              </Button>
            </Modal.Actions>
            <Modal.Content>
              <Link to="/impressum" onClick={confirmSelection}>
                Impressum
              </Link>
              <br />
              <Link to="/datenschutz" onClick={confirmSelection}>
                Datenschutz
              </Link>
            </Modal.Content>
          </>
        )}
      </Modal>
    </>
  );
};

export default connect(
  (state, props) => ({
    show: state.DSGVOBanner.show,
  }),
  {
    hideDSGVOBanner,
  },
)(Banner);
