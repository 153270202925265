import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import {
  Container,
  Dropdown,
  Grid,
  Icon as OldIcon,
  Tab,
} from 'semantic-ui-react';
import { Portal } from 'react-portal';
import { last } from 'lodash';
import { Toolbar } from '@plone/volto/components';
import { addMessage, listActions } from '@plone/volto/actions';
import { BodyClass, asyncConnect } from '@plone/volto/helpers';
import qs from 'query-string';

import { addFavorite, getRecipe } from '../../actions';
import missingIMG from '../../static/missing.jpg';

import starSVG from '../../static/star.svg';

import {
  Downloads,
  Herstellung,
  InlineLoginForm,
  Nahrwerte,
  MixedTitle,
  MoreInfoFooter,
  Related,
  Rohstoffkosten,
  Zutaten,
} from '../.';
import VistEditDialog from '../Widgets/VistEditDialog';

/**
 *
 *
 * @class Rezepte
 * @extends {Component}
 */
class Rezepte extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    pathname: PropTypes.string.isRequired,
    token: PropTypes.string,
    listActions: PropTypes.func.isRequired,
    addFavorite: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
    getRecipe: PropTypes.func.isRequired,
    rezepte: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        '@id': PropTypes.string,
      }),
      related_recipes: PropTypes.array,
      downloads: PropTypes.object,
    }).isRequired,
    rezept: PropTypes.shape({}),
    loaded: PropTypes.bool.isRequired,
    favoriteLoaded: PropTypes.bool.isRequired,
    favoriteLoading: PropTypes.bool.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    actions: null,
    token: null,
  };

  constructor(props) {
    super(props);
    this.state = { rezept: props.rezepte };
    this.handleRezeptSaved = this.handleRezeptSaved.bind(this);
    this.onTab = this.onTab.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getRecipe(id);
    this.props.listActions('');
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      const { id } = nextProps.match.params;
      this.props.getRecipe(id);
    }
    if (
      this.props.rezept.loading === true &&
      nextProps.rezept &&
      nextProps.rezept.loading === false
    ) {
      this.setState({ rezept: nextProps.rezept.data });
    }
    if (this.props.favoriteLoading && nextProps.favoriteLoaded) {
      this.props.addMessage(
        'Rezept gemerkt',
        'Rezept wurde in Ihrem Profil gemerkt.',
        'success',
      );
    }
  }

  panes = [
    {
      menuItem: 'Zutaten',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Zutaten rezept={this.state.rezept} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Herstellung',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Herstellung rezepte={this.state.rezept} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Nährwerte',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Nahrwerte rezept={this.state.rezept} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Rohstoffkosten',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Rohstoffkosten rezept={this.state.rezept} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Downloads',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Downloads name="rezepte" downloads={this.state.rezept.downloads} />
        </Tab.Pane>
      ),
    },
  ];

  handleRezeptSaved() {
    this.props.getRecipe(this.state.rezept.id);
  }

  onTab(event, data) {
    this.props.history.push(`${this.props.pathname}?tab=${data.activeIndex}`);
  }

  addFavorite = () => {
    this.props.addFavorite('recipe', this.props.rezepte.id);
  };

  render() {
    if (!this.state.rezept.data) {
      return <div />;
    }
    return (
      <section style={{ marginBottom: '50px' }}>
        <BodyClass className="rezepte" />
        <Helmet title={this.state.rezept.name} />
        <Container>
          <div className="product-hero">
            {this.state.rezept.image ? (
              <img
                className="product-hero-img"
                src={`${this.state.rezept.image['@id']}/@@images/image`}
                alt="rezepte"
              />
            ) : (
              <img
                className="product-hero-img"
                src={missingIMG}
                alt="rezepte"
              />
            )}
            <div className="product-hero-body">
              <h1>
                <MixedTitle reverse>{this.state.rezept.name}</MixedTitle>
              </h1>
              <p>
                {this.state.rezept.description}
                <br />
                {this.state.rezept.display_rezeptcode}
              </p>
              <VistEditDialog
                vistObject={this.state.rezept}
                onSaved={this.handleRezeptSaved}
              />
            </div>
            <div className="product-hero-addons">
              <img src={starSVG} alt="star" onClick={this.addFavorite} />
            </div>
          </div>

          {this.props.token && (
            <Tab
              activeIndex={this.props.tab}
              onTabChange={this.onTab}
              className="product-tabs"
              style={{
                marginBottom: '134px',
              }}
              menu={{
                secondary: true,
                pointing: true,
                compact: true,
                borderless: true,
                stackable: true,
              }}
              panes={this.panes}
            />
          )}
          {!this.props.token && (
            <Container className="inline-login-form">
              <Grid centered>
                <Grid.Column mobile={12} computer={6}>
                  <h2>
                    <MixedTitle>Einfach mehr Zeelandia</MixedTitle>
                    <div className="title-append">
                      Melden Sie Sich an, um unsere exklusiven Services in
                      vollem Umfang nutzen zu können.
                    </div>
                  </h2>
                  <InlineLoginForm />
                </Grid.Column>
              </Grid>
            </Container>
          )}
        </Container>
        <Related
          type="rezepte"
          relatedItems={this.state.rezept.related_recipes}
        />

        <MoreInfoFooter name="rezepte" />
        <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
          <Toolbar
            pathname={this.props.pathname}
            inner={
              <div>
                <Dropdown
                  id="toolbar-personal"
                  className="personal-bar"
                  item
                  upward
                  trigger={<OldIcon name="user" size="big" />}
                >
                  <Dropdown.Menu>
                    {this.props.actions.user &&
                      this.props.actions.user.map((item) => {
                        switch (item.id) {
                          case 'preferences':
                            return (
                              <Link
                                key={item.id}
                                to="/personal-preferences"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="setting" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'plone_setup':
                            return (
                              <Link
                                key={item.id}
                                to="/controlpanel"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="settings" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'logout':
                            return (
                              <Link
                                key={item.id}
                                to="/logout"
                                id="toolbar-logout"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="sign out" /> {item.title}
                                </span>
                              </Link>
                            );
                          default: {
                            return null;
                          }
                        }
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          />
        </Portal>
      </section>
    );
  }
}

export default compose(
  withRouter,
  asyncConnect([
    {
      key: 'recipe',
      promise: async ({ location, store: { dispatch } }) => {
        return await dispatch(getRecipe(last(location.pathname.split('/'))));
      },
    },
  ]),
  connect(
    (state, props) => ({
      actions: state.actions.actions,
      pathname: props.location.pathname,
      tab: qs.parse(props.location.search).tab || 0,
      token: state.userSession.token,
      rezepte: state.recipe.data,
      rezept: state.recipe,
      loaded: state.recipe.loaded,
      favoriteLoaded: state.cart.add.loaded,
      favoriteLoading: state.cart.add.loading,
    }),
    {
      addFavorite,
      addMessage,
      getRecipe,
      listActions,
    },
  ),
)(Rezepte);
