import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Portal } from 'react-portal';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import {
  Button,
  Card,
  Container,
  Dropdown,
  Form,
  Image,
  Input,
  Pagination,
  Icon as OldIcon,
  Grid,
} from 'semantic-ui-react';
import {
  Display,
  Toolbar,
  Types,
  Icon,
  Workflow,
} from '@plone/volto/components';
import { find, map } from 'lodash';
import qs from 'query-string';
import { listActions, searchContent } from '@plone/volto/actions';
import { BodyClass, getBaseUrl } from '@plone/volto/helpers';

import paginationLeftSVG from '../../icons/pagination-left.svg';
import paginationRightSVG from '../../icons/pagination-right.svg';
import downloadSVG from '../../static/download.svg';
import searchSVG from '../../icons/loupe.svg';
import deleteSVG from '@plone/volto/icons/delete.svg';

/**
 *
 *
 * @class Bilddatenbank
 * @extends {Component}
 */
class Bilddatenbank extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    itemsTotal: PropTypes.number.isRequired,
    searchContent: PropTypes.func.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.string,
  };

  state = {
    text: '',
    canSeeAll: false,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.userActions.forEach((action) => {
      if (action.id === 'plone_setup') {
        this.setState({ canSeeAll: true });
      }
    });
    this.props.listActions(getBaseUrl(this.props.pathname));
    this.props.searchContent(
      this.props.pathname,
      {
        Title: this.state.text,
        portal_type: 'Image',
        fullobjects: 1,
        b_size: 24,
        b_start: (this.props.currentPage - 1) * 24,
        showinsearch: !this.state.canSeeAll,
        use_solr: true,
      },
      'bilddatenbank',
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props.userActions.forEach((action) => {
      if (action.id === 'plone_setup') {
        this.setState({ canSeeAll: true });
      }
    });
    if (this.props.currentPage !== nextProps.currentPage) {
      this.props.searchContent(
        this.props.pathname,
        {
          Title: this.state.text,
          portal_type: 'Image',
          fullobjects: 1,
          b_size: 24,
          b_start: (nextProps.currentPage - 1) * 24,
          showinsearch: !this.state.canSeeAll,
          use_solr: true,
        },
        'bilddatenbank',
      );
    }
  }
  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText = (event, { value }) => {
    this.setState({
      text: value,
    });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.searchContent(
        this.props.pathname,
        {
          Title: value,
          portal_type: 'Image',
          fullobjects: 1,
          b_size: 24,
          b_start: (this.props.currentPage - 1) * 24,
          showinsearch: !this.state.canSeeAll,
          use_solr: true,
        },
        'bilddatenbank',
      );
    }, 1000);
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.props.history.push(`${this.props.pathname}?page=${activePage}`);
  };

  render() {
    const editAction = find(this.props.actions.object, { id: 'edit' });
    const path = getBaseUrl(this.props.pathname);
    const folderContentsAction = find(this.props.actions.object, {
      id: 'folderContents',
    });
    const historyAction = find(this.props.actions.object, { id: 'history' });
    const sharingAction = find(this.props.actions.object, {
      id: 'local_roles',
    });

    return (
      <section style={{ marginBottom: '50px' }}>
        <Helmet title="Zeelandia - Bilddatenbank" />
        <BodyClass className="bilddatenbank" />
        <Container>
          <h1>Bilddatenbank</h1>
          <Form
            onSubmit={this.onSubmit}
            className="bilddatenbank-form"
            style={{
              marginTop: 50,
              padding: '30px 0',
              borderTop: '2px solid #EBEAF1',
              textAlign: 'center',
            }}
          >
            <Form.Field className="searchbox" style={{ margin: 0 }}>
              <Input
                onChange={this.onChangeText}
                name="SearchableText"
                value={this.state.text}
                transparent
                placeholder="Suchbegriff hier eingeben"
                title="Suche"
                autoComplete="off"
              />
              <Button className="brandButtonPrimary">
                <Icon name={searchSVG} size="22px" title="Suche" />
              </Button>
            </Form.Field>
          </Form>
          <Grid
            columns={12}
            style={{
              marginBottom: '24px',
              opacity: this.props.loading ? 0.3 : 1,
            }}
          >
            {map(this.props.items, (item) => (
              <Grid.Column key={item['@id']} computer={2} tablet={3} mobile={6}>
                <Card>
                  <Link to={`${item['@id']}`}>
                    <Image
                      src={
                        item.image &&
                        item.image.scales &&
                        item.image.scales.preview &&
                        item.image.scales.preview.download
                      }
                    />
                  </Link>
                  <Card.Content>
                    <Card.Header>{item.title}</Card.Header>
                    <div className="toolbar">
                      {editAction && (
                        <Link
                          className="edit-button"
                          to={`${item['@id']}/edit`}
                        >
                          <span>Edit</span>
                        </Link>
                      )}
                      {editAction && (
                        <Link
                          className="delete-button"
                          to={`${item['@id']}/delete`}
                        >
                          <Icon name={deleteSVG} size="28px" />
                        </Link>
                      )}
                      <div className="image-type">
                        <span>
                          {item.image &&
                            item.image.filename &&
                            item.image.filename
                              .substr(item.image.filename.length - 3)
                              .toUpperCase()}
                        </span>
                      </div>
                      <a
                        className="download-button"
                        href={item.image && item.image.download}
                        download={item.image?.download}
                      >
                        <img src={downloadSVG} alt="Download" />
                      </a>
                    </div>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
          <div class="pagination-wrapper">
            <Pagination
              activePage={this.props.currentPage}
              totalPages={Math.ceil(this.props.itemsTotal / 24)}
              onPageChange={this.handlePaginationChange}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.props.batching.prev
                  ? {
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                    }
                  : null
              }
              nextItem={
                this.props.batching.next
                  ? {
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                    }
                  : null
              }
            />
          </div>
        </Container>
        <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
          <Toolbar
            pathname={this.props.pathname}
            inner={
              <div>
                {editAction && (
                  <Link to={`${path}/edit`} id="toolbar-edit" className="item">
                    <OldIcon
                      name="write"
                      size="big"
                      color="blue"
                      title={editAction.title}
                    />
                  </Link>
                )}
                {this.props.content &&
                  this.props.content.is_folderish &&
                  folderContentsAction && (
                    <Link
                      to={`${path}/contents`.replace(/\/\//g, '/')}
                      id="toolbar-folder-contents"
                      className="item"
                    >
                      <OldIcon
                        name="folder open"
                        size="big"
                        title={folderContentsAction.title}
                      />
                    </Link>
                  )}
                <Types pathname={path} />

                <Dropdown
                  id="toolbar-more"
                  item
                  trigger={<OldIcon name="ellipsis horizontal" size="big" />}
                >
                  <Dropdown.Menu>
                    <Workflow pathname={path} />
                    {/* {this.state.hasObjectButtons && <Actions pathname={path} />} */}
                    {editAction && <Display pathname={path} />}
                    {historyAction && (
                      <Link
                        to={`${path}/history`}
                        id="toolbar-history"
                        className="item"
                      >
                        <OldIcon name="clock" size="big" />{' '}
                        {historyAction.title}
                      </Link>
                    )}
                    {sharingAction && (
                      <Link
                        to={`${path}/sharing`}
                        id="toolbar-sharing"
                        className="item"
                      >
                        <OldIcon name="share" size="big" />{' '}
                        {sharingAction.title}
                      </Link>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown
                  id="toolbar-personal"
                  className="personal-bar"
                  item
                  upward
                  trigger={<OldIcon name="user" size="big" />}
                >
                  <Dropdown.Menu>
                    {this.props.actions.user &&
                      this.props.actions.user.map((item) => {
                        switch (item.id) {
                          case 'preferences':
                            return (
                              <Link
                                key={item.id}
                                to="/personal-preferences"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="setting" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'plone_setup':
                            return (
                              <Link
                                key={item.id}
                                to="/controlpanel"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="settings" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'logout':
                            return (
                              <Link
                                key={item.id}
                                to="/logout"
                                id="toolbar-logout"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="sign out" /> {item.title}
                                </span>
                              </Link>
                            );
                          default: {
                            return null;
                          }
                        }
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          />
        </Portal>
      </section>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => {
      const search = state.search.subrequests.bilddatenbank || {
        error: null,
        items: [],
        total: 0,
        loaded: false,
        loading: false,
        batching: {},
      };
      return {
        pathname: props.location.pathname,
        items: search.items,
        itemsTotal: search.total,
        batching: search.batching,
        loading: search.loading,
        actions: state.actions.actions,
        content: state.content.data,
        userActions: state.actions.actions.user,
        currentPage:
          (qs.parse(props.location.search).page &&
            parseInt(qs.parse(props.location.search).page, 10)) ||
          1,
      };
    },
    {
      listActions,
      searchContent,
    },
  ),
)(Bilddatenbank);
