import React from 'react';
import { Container } from 'semantic-ui-react';

const MailSuccess = () => (
  <Container>
    <h1>Anmeldung zu Newsletter</h1>
    <p>
      Die Anmeldung zum Mail Newsletter war erfolgreich. Bitte prüfen Sie Ihr
      Mail Postfach um die Anmeldung zu bestätigen.
    </p>
  </Container>
);
export default MailSuccess;
