import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Grid, Image, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '../.';

import missingIMG from '../../static/missing.jpg';

import arrowSVG from '../../icons/arrow.svg';

class Rezepte extends Component {
  static propTypes = {
    product: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        '@id': PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    showAll: false,
  };

  onShowAll = () => {
    this.setState({
      showAll: true,
    });
  };

  render() {
    const recipes = this.state.showAll
      ? this.props.product.recipes
      : this.props.product.recipes.slice(0, 8);

    return (
      <div className="product-rezepte">
        <Grid centered>
          <Grid.Column mobile={12} computer={8}>
            <h2>{this.props.product.name}</h2>
            <Card.Group stackable centered itemsPerRow={2}>
              {recipes.map((item) => (
                <Card
                  key={item['@id']}
                  as={Link}
                  to={`/alle-rezepte/${item.id}`}
                >
                  {item.image ? (
                    <Image src={`${item.image['@id']}/@@images/image/mini`} />
                  ) : (
                    <Image src={missingIMG} />
                  )}
                  <Card.Content>
                    <Card.Header>
                      <b>{item.display_rezeptcode}</b> {item.name}
                    </Card.Header>
                  </Card.Content>
                </Card>
              ))}
              {this.props.product.recipes.length > 8 &&
                this.state.showAll === false && (
                  <Card
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '155px',
                    }}
                  >
                    <Button
                      className="small brandButtonSecondary"
                      onClick={this.onShowAll}
                    >
                      <MixedTitle reverse>
                        alle Rezepte zum Produkt anzeigen
                      </MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </Card>
                )}
            </Card.Group>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Rezepte;
