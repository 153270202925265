const schemaRezepte = () => {
  return {
    title: 'Rezepte',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['recipes'],
      },
    ],
    properties: {
      recipes: {
        title: 'Rezepte',
        widget: 'array',
        default: [],
        vocabulary: {
          '@id': 'jungzeelandia.contenttypes.vocabularies.RecipesVocabulary',
        },
      },
    },
    required: [],
  };
};

export default schemaRezepte;
