/**
 * Cart items reducer.
 * @module reducers/cart
 */

import {
  ADD_FAVORITE,
  DELETE_FAVORITE,
  GET_CART,
} from '../../constants/ActionTypes';

const initialState = {
  get: {
    error: null,
    loaded: false,
    loading: false,
  },
  add: {
    error: null,
    loaded: false,
    loading: false,
  },
  delete: {
    error: null,
    loaded: false,
    loading: false,
  },
  data: {
    product: [],
    recipe: [],
    sales_concept: [],
  },
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Rezepte reducer.
 * @function aktuelles
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function cart(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CART}_PENDING`:
    case `${ADD_FAVORITE}_PENDING`:
    case `${DELETE_FAVORITE}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_CART}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
        data: {
          product: action.result.product,
          recipe: action.result.recipe,
          sales_concept: action.result.sales_concept,
        },
      };
    case `${ADD_FAVORITE}_SUCCESS`:
    case `${DELETE_FAVORITE}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_CART}_FAIL`:
    case `${ADD_FAVORITE}_FAIL`:
    case `${DELETE_FAVORITE}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
        data: {
          product: [],
          recipe: [],
          sales_concept: [],
        },
      };
    default:
      return state;
  }
}
