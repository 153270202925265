import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Dimmer, Loader } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getBenutzer,
  updateBenutzer,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
} from '../../../actions';
import { BasicTable } from '../../ReactTableContent/ReactTableContent';
import { createColumnHelper } from '@tanstack/react-table';
import NavHeader from '../NavHeader';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const BenutzerListing = ({
  pathname,
  benutzer,
  totalPages,
  getBenutzer,
  updateBenutzer,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
  batchingParameters,
}) => {
  const currentListing = 'benutzer';
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const [tableConfig, setTableConfig] = useState({
    COLUMNS: [],
    showDeleted: false,
    data: [],
    totalPages: 0,
    linkToEdit: false,
  });

  const deletedButton = !tableConfig.showDeleted
    ? 'Gelöschte Benutzer anzeigen'
    : 'Benutzer anzeigen';

  const [refreshFlag, setRefreshFlag] = useState(false);

  const columnHelper = createColumnHelper();

  const COLUMNS = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <span>ID</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('loginname', {
      id: 'loginname',
      header: () => <span>Anmeldename</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);
        return (
          <p>
            {date.toLocaleString('de-DE', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </p>
        );
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        if (info.getValue() === 0) {
          return <p></p>;
        }
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);
        return (
          <p>
            {date.toLocaleString('de-DE', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </p>
        );
      },
    }),
  ];

  const COLUMNS_DELETED = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <span>ID</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('loginname', {
      id: 'loginname',
      header: () => <span>Anmeldename</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);
        return (
          <p>
            {date.toLocaleString('de-DE', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </p>
        );
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);
        return (
          <p>
            {date.toLocaleString('de-DE', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </p>
        );
      },
    }),
    columnHelper.accessor('deleted', {
      id: 'deleted',
      header: () => <span></span>,
      cell: (info) => (
        <Button onClick={() => unDelete(info.row.original.id)}>
          Wiederherstellen
        </Button>
      ),
    }),
  ];

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBenutzer({
      showDeleted: tableConfig.showDeleted,
      batchingParameters: batchingParameters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFlag, getBenutzer, batchingParameters, tableConfig.showDeleted]);

  // attatch benutzer to tableConfig when finished loading and reset Batching Parameters if needed
  useEffect(() => {
    const columns = tableConfig.showDeleted ? COLUMNS_DELETED : COLUMNS;
    setTableConfig({
      ...tableConfig,
      data: benutzer,
      COLUMNS: columns,
      totalPages: totalPages,
    });

    if (
      batchingParameters &&
      batchingParameters.currentListing !== currentListing
    ) {
      resetBatchingParameters({ currentListing: currentListing });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benutzer, tableConfig.showDeleted]);

  const toggleShowDeleted = () => {
    setTableConfig({
      ...tableConfig,
      showDeleted: !tableConfig.showDeleted,
    });
    resetBatchingParameters({ currentListing: currentListing });
    setRefreshFlag(!refreshFlag);
  };

  const unDelete = (id) => {
    updateBenutzer(id, { deleted: 0 });
    getBenutzer({
      showDeleted: true,
      batchingParameters: batchingParameters,
    });
    setRefreshFlag(!refreshFlag);
  };

  if (error) {
    return <Error error={error} />;
  }

  if (!token) {
    setError({ status: 401 });
  }

  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    setError({ status: 401 });
  }
  return (
    (benutzer && (
      <div className="view-wrapper">
        <Helmet title="Benutzer" />
        <NavHeader permissions={permissions} />
        <Container className="controlpanel">
          <Segment.Group raised>
            <Segment className="primary">
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>
                    {' '}
                    {tableConfig.showDeleted
                      ? 'Gelöschte Benutzer'
                      : 'Benutzer'}{' '}
                  </h1>
                </div>
                <div className="button-container">
                  <div className="buttonArea">
                    <Link to="benutzer/add">
                      <Button className="rdb-new" primary type="New">
                        Neuer Benutzer
                      </Button>
                    </Link>
                    <Button
                      className="rdb-show-deleted"
                      primary
                      onClick={() => toggleShowDeleted()}
                    >
                      {deletedButton}
                    </Button>
                  </div>
                </div>
              </div>
            </Segment>
            {benutzer && tableConfig.data && (
              <Segment>
                <BasicTable
                  tableConfig={tableConfig}
                  setTableConfig={setTableConfig}
                  batchingParameters={batchingParameters}
                  setBatchingParameters={setBatchingParameters}
                />
              </Segment>
            )}
          </Segment.Group>
        </Container>
        {isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={pathname}
              hideDefaultViewButtons
              inner={
                <Link to="/controlpanel/rezeptdatenbank" className="item">
                  <Icon
                    name={backSVG}
                    className="contents circled"
                    size="30px"
                    title={intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </div>
    )) || (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )
  );
};

BenutzerListing.propTypes = {
  pathname: PropTypes.string.isRequired,
  benutzer: PropTypes.array.isRequired,
  getBenutzer: PropTypes.func.isRequired,
  updateBenutzer: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
  setBatchingParameters: PropTypes.func,
  resetBatchingParameters: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      benutzer: state.benutzer.data.benutzer,
      totalPages: state.benutzer.data.total_pages,
      token: state.userSession.token,
      permissions: state.permissions.data,
      batchingParameters: state.batchingParameters,
    }),
    {
      getBenutzer,
      updateBenutzer,
      getVistPermissions,
      setBatchingParameters,
      resetBatchingParameters,
    },
  ),
)(BenutzerListing);
