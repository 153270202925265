import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import { Card, Container, Image, Pagination } from 'semantic-ui-react';
import { slide as SlidingMenu } from 'react-burger-menu';
import { Icon } from '@plone/volto/components';
import { BodyClass } from '@plone/volto/helpers';
import qs from 'query-string';

import {
  getAllProducts,
  getAllRecipes,
  getCategories,
  getProductsPage,
  getRecipesPage,
} from '../../actions';

import missingIMG from '../../static/missing.jpg';
import smallBlueButton from '../../static/small-blue-button.svg';
import arrowSVG from '../../icons/arrow.svg';
import paginationLeftSVG from '../../icons/pagination-left.svg';
import paginationRightSVG from '../../icons/pagination-right.svg';
import productFilterSVG from '../../icons/product-filter.svg';
import productFilterOpenSVG from '../../icons/product-filter-open.svg';
import recipeFilterSVG from '../../icons/recipe-filter.svg';
import recipeFilterOpenSVG from '../../icons/recipe-filter-open.svg';

import config from '@plone/volto/registry';

/**
 *
 *
 * @class ProductList
 * @extends {Component}
 */

class ProductList extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    routeName: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      }),
    ).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    getAllRecipes: PropTypes.func.isRequired,
    getAllProducts: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getProductsPage: PropTypes.func.isRequired,
    getRecipesPage: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired,
    itemsTotal: PropTypes.number.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    category: PropTypes.number,
    aktuell: PropTypes.number,
    currentPage: PropTypes.number,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    category: null,
    aktuell: 0,
  };

  state = {
    menuOpen: false,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.routeName === '/alle-rezepte') {
      this.props.getRecipesPage(
        this.props.currentPage,
        this.props.category,
        this.props.aktuell,
      );
      this.props.getCategories(this.props.category);
    }
    if (this.props.routeName === '/alle-produkte') {
      this.props.getProductsPage(
        this.props.currentPage,
        this.props.category,
        this.props.aktuell,
      );
      this.props.getCategories(this.props.category, 'products');
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.pathname !== this.props.pathname ||
      nextProps.category !== this.props.category ||
      nextProps.currentPage !== this.props.currentPage ||
      nextProps.aktuell !== this.props.aktuell
    ) {
      if (nextProps.routeName === '/alle-rezepte') {
        this.props.getRecipesPage(
          nextProps.currentPage,
          nextProps.category,
          nextProps.aktuell,
        );
        this.props.getCategories(nextProps.category);
      }
      if (nextProps.routeName === '/alle-produkte') {
        this.props.getProductsPage(nextProps.currentPage, nextProps.category);
        this.props.getCategories(nextProps.category, 'products');
      }
    }
  }

  changeUrl = (category, page) => {
    let params = {};
    params.category =
      category && category !== 0 && category !== '0' ? category : undefined;
    params.page = page === 1 ? undefined : page;
    params.aktuell = this.props.aktuell;
    let paramString = qs.stringify(params);
    let url = paramString
      ? `${this.props.pathname}?${paramString}`
      : this.props.pathname;

    this.props.history.push(url);
  };

  filterByCategory = (category) => {
    this.changeUrl(category.id, 1);
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.changeUrl(this.props.category, activePage);
  };

  /**
   *
   *
   * @returns {string} string
   * @memberof ProductList
   */
  render() {
    return (
      <React.Fragment>
        <Helmet
          title={`Zeelandia - ${
            this.props.routeName === '/alle-produkte'
              ? 'Alle Produkte'
              : 'Alle Rezepte'
          }`}
        />
        <SlidingMenu
          isOpen={this.state.menuOpen}
          onStateChange={(menustate) => {
            this.setState({ menuOpen: menustate.isOpen });
          }}
          className="filter-menu"
          customBurgerIcon={
            <Icon
              name={
                this.props.routeName === '/alle-produkte'
                  ? productFilterSVG
                  : recipeFilterSVG
              }
              size="75px"
            />
          }
          customCrossIcon={
            <Icon
              name={
                this.props.routeName === '/alle-produkte'
                  ? productFilterOpenSVG
                  : recipeFilterOpenSVG
              }
              size="75px"
            />
          }
        >
          <div className="underlined-title">
            <span>
              <h4>
                {this.props.routeName === '/alle-produkte'
                  ? 'Angezeigte Produkte'
                  : 'Angezeigte Rezepte'}
              </h4>
            </span>
          </div>
          <ul className="categories-breadcrumbs">
            {this.props.breadcrumbs.length !== 0 &&
              this.props.breadcrumbs.map((breadcrumb, index) => (
                <li
                  style={{ cursor: 'pointer' }}
                  key={`bc-${breadcrumb.id}`}
                  onClick={() => this.filterByCategory(breadcrumb)}
                >
                  {breadcrumb.title}
                  {index !== this.props.breadcrumbs.length - 1 && (
                    <Icon name={arrowSVG} size="14px" />
                  )}
                </li>
              ))}
          </ul>
          <ul>
            {this.props.categories.map((category) => (
              <li
                style={{ cursor: 'pointer' }}
                key={category.id}
                onClick={() => {
                  this.filterByCategory(category);
                  !category.has_children && this.setState({ menuOpen: false });
                }}
              >
                {category.name}
                {category.has_children && <Icon name={arrowSVG} size="17px" />}
              </li>
            ))}
          </ul>
        </SlidingMenu>
        {this.props.loaded && (
          <Container
            as="section"
            className="product-list"
            style={{ marginBottom: '50px' }}
          >
            <BodyClass className="products" />

            <h1>
              {this.props.routeName === '/alle-produkte'
                ? 'Produktsortiment'
                : 'Alle Rezepte'}
            </h1>
            <p>
              {this.props.routeName === '/alle-produkte'
                ? 'Übersicht ist Trumpf! Hier finden Sie unser vielfältiges Sortiment, aus dem Sie Ihr Produkt für Ihr individuelles Gebäckangebot schnell und unkompliziert heraussuchen können. Ob Brot und Brötchen, Feine Backwaren, Füllungen und Auflagen, Sahnefonds, Glasuren, Gelees/Saftbinder oder Trennmittel/Schneideöle – alles ist mit Hilfe intelligenter Kategorien über den Produktfilter auf der linken Seite einfach auffindbar.'
                : 'Unsere Datenbank liefert einen reichen Schatz an Ideen für die Arbeit mit unseren Produkten. Mehr als 2.300 Rezepte sind inzwischen dokumentiert. Aus diesem Angebot können Sie sich vielfältige Anregungen für passende und gelingsichere Rezepte herauswählen, die sich alle rationell herstellen lassen. Nutzen Sie dafür einen der Rezeptfilter auf der linken Seite und bereichern Sie Ihr individuelles Gebäckangebot!'}
            </p>
            <Card.Group
              stackable
              centered
              itemsPerRow={4}
              style={{ marginBottom: '24px' }}
            >
              {this.props.items.map((item) => (
                <Card
                  key={item['@id']}
                  as={Link}
                  to={
                    this.props.routeName === '/alle-produkte'
                      ? `/alle-produkte/${item.id}`
                      : `/alle-rezepte/${item.id}`
                  }
                >
                  {item.image ? (
                    <Image src={`${item.image['@id']}/@@images/image/mini`} />
                  ) : (
                    <Image src={missingIMG} />
                  )}
                  <Card.Content>
                    <Card.Header>
                      {item.display_rezeptcode}{' '}
                      <span className="subtitle">{item.name}</span>
                    </Card.Header>
                    <div>
                      <img
                        style={{
                          position: 'absolute',
                          bottom: '0',
                          right: '0',
                        }}
                        src={smallBlueButton}
                        alt="Arrow"
                      />
                    </div>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
            <div className="pagination-wrapper">
              <Pagination
                activePage={this.props.currentPage}
                totalPages={Math.ceil(
                  this.props.itemsTotal / config.settings.DEFAULT_PAGING_SIZE,
                )}
                onPageChange={this.handlePaginationChange}
                firstItem={null}
                lastItem={null}
                prevItem={
                  this.props.batching.prev
                    ? {
                        content: <Icon name={paginationLeftSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
                nextItem={
                  this.props.batching.next
                    ? {
                        content: <Icon name={paginationRightSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
              />
            </div>
            <div className="sicherheitsdatenblaetter">
              <a
                href="http://sdb-pool.eu/sdb?t=f633719c197a9b0f0407deed9e6239ef"
                target="_blank"
                rel="noreferrer"
              >
                Sicherheitsdatenblätter
              </a>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      pathname: props.location.pathname,
      routeName: props.route.path,
      items: state.listings.items,
      categories: state.categories.items,
      breadcrumbs: state.categories.breadcrumbs,
      itemsTotal: state.listings.itemsTotal,
      batching: state.listings.batching,
      loaded: state.listings.loaded,
      category: qs.parse(props.location.search).category
        ? parseInt(qs.parse(props.location.search).category, 10)
        : null,
      aktuell: qs.parse(props.location.search).aktuell,
      currentPage:
        (qs.parse(props.location.search).page &&
          parseInt(qs.parse(props.location.search).page, 10)) ||
        1,
    }),
    {
      getAllProducts,
      getAllRecipes,
      getCategories,
      getProductsPage,
      getRecipesPage,
    },
  ),
)(ProductList);
