import React from 'react';
import { Container } from 'semantic-ui-react';

const MailError = () => (
  <Container>
    <h1>Fehler bei Anmeldung</h1>
    <p>
      Die Anmeldung zum Mail Newsletter ist aufgrund eines Technischen Problems
      Fehlgeschlagen. Bitte wenden Sie sich an{' '}
      <a href="mailto:info@zeelandia.de">info@zeelandia.de</a>.
    </p>
  </Container>
);
export default MailError;
