import { has } from 'lodash';
import {
  GET_ZUTATEN,
  GET_ZUTATBYID,
  CREATE_ZUTAT,
  DELETE_ZUTAT,
  UPDATE_ZUTAT,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function zutaten(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ZUTATEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ZUTATEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? [...action.result] : [],
      };
    case `${GET_ZUTATEN}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function zutatByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ZUTATBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ZUTATBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_ZUTATBYID}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function zutatCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_ZUTAT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_ZUTAT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_ZUTAT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function zutatDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_ZUTAT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_ZUTAT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_ZUTAT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function zutatUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_ZUTAT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_ZUTAT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_ZUTAT}_FAIL`:
      if (action?.error?.message) {
        action.error.message = JSON.parse(
          action.error.response.text,
        ).error.message;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
