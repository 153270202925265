/**
 * Produktkategorien actions.
 * @module actions/Produkt
 */

import {
  GET_PRODUKTKATEGORIEN,
  GET_PRODUKTKATEGORIEBYID,
  CREATE_PRODUKTKATEGORIE,
  UPDATE_PRODUKTKATEGORIE,
  DELETE_PRODUKTKATEGORIE,
} from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProduct
 * @param {string} id Produkt id
 * @returns {Object} Produkt action.
 */
export function getProduktkategorien({
  showDeleted = false,
  getAll = false,
  batchingParameters = {},
}) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;
  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted) {
    return {
      type: GET_PRODUKTKATEGORIEN,
      request: {
        op: 'get',
        path: `/@produktkategorien/deleted${pathSuffix}`,
      },
    };
  } else if (getAll) {
    return {
      type: GET_PRODUKTKATEGORIEN,
      request: {
        op: 'get',
        path: `/@produktkategorien/all`,
      },
    };
  }

  return {
    type: GET_PRODUKTKATEGORIEN,
    request: {
      op: 'get',
      path: `/@produktkategorien${pathSuffix}`,
    },
  };
}

/**
 * Get Benutzer item.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getProduktkategorieByID(id) {
  return {
    type: GET_PRODUKTKATEGORIEBYID,
    request: {
      op: 'get',
      path: `/@produktkategorien/${id}`,
    },
  };
}

/**
 * Create a new Produktkategorie.
 * @function createProduktkategorie
 * @param {Object} formData The data of the new Produktkategorie
 * @returns {Object} Produktkategorie action.
 */
export function createProduktkategorie(formData) {
  return {
    type: CREATE_PRODUKTKATEGORIE,
    request: {
      op: 'post',
      path: '/@produktkategorien',
      data: formData,
    },
  };
}

/**
 * Update a new Produktkategorie.
 * @function createProduktkategorie
 * @param {Object} formData The data of the new Produktkategorie
 * @returns {Object} Produktkategorie action.
 */
export function updateProduktkategorie(id, formData) {
  return {
    type: UPDATE_PRODUKTKATEGORIE,
    request: {
      op: 'patch',
      path: `/@produktkategorien/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Produktkategorie.
 * @function createProduktkategorie
 * @returns {Object} Success Message;
 */
export function deleteProduktkategorie(id) {
  return {
    type: DELETE_PRODUKTKATEGORIE,
    request: {
      op: 'del',
      path: `/@produktkategorien/${id}`,
    },
  };
}
