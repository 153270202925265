/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable constructor-super */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { Container, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { postNewsletterRegister } from '../../actions';
/**
 * InxmailRegister class.
 * @class InxmailRegister
 * @extends Component
 */
class InxmailRegister extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  state = {
    email: null,
    firstName: null,
    lastName: null,
    title: null,
    trackingConsent: false,
    confirmEmail: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.register !== this.props.register) {
      if (this.props.register.loaded) {
        this.props.history.push('/mail-success');
      } else if (this.props.register.error) {
        this.props.history.push('/mail-error');
      }
    }
  }

  /**
   * Hide message handler
   * @method onHideMessage
   * @returns {undefined}
   */
  onSubmit() {
    this.props.postNewsletterRegister({
      email: this.state.email,
      name: `${this.state.firstName} ${this.state.lastName}`,
      title: this.state.title,
      tracking: this.state.trackingConsent,
      confirmEmail: this.state.confirmEmail,
    });
  }

  render() {
    const filledOut =
      this.state.email &&
      this.state.firstName &&
      this.state.lastName &&
      this.state.title;

    return (
      <Container>
        <h1>Anmeldung zum Zeelandia Newsletter</h1>
        <p>
          Ich möchte regelmäßig den Newsletter der Zeelandia GmbH & Co. KG
          erhalten, der mich über Neuigkeiten zu Zeelandia-Produkten, Rezepten
          und Verkaufskonzepten informiert. Meine Einwilligung in die Zusendung
          des Newsletters kann ich jederzeit widerrufen durch Anklicken des
          Links am Ende jedes Newsletters oder durch E-Mail an{' '}
          <a href="mailto:news@newsletter.zeelandia.de">
            news@newsletter.zeelandia.de
          </a>
          .
        </p>
        <Form id="displayform" className="newsletter-form">
          <Form.Input
            type="text"
            name="email"
            label="E-Mail*"
            onChange={(event, data) => this.setState({ email: data.value })}
          />
          <Form.Input
            type="text"
            name="confirmEmail"
            label="Email wiederholen"
            className="repeat-mail"
            onChange={(event, value) =>
              this.setState({ confirmEmail: value.value })
            }
          />
          <Form.Dropdown
            labeled
            selection
            placeholder="Anrede"
            options={[
              { key: 'Herr', text: 'Herr', value: 'Herr' },
              { key: 'Frau', text: 'Frau', value: 'Frau' },
              { key: 'Divers', text: 'Divers', value: 'Divers' },
            ]}
            onChange={(event, data) =>
              this.setState({
                title: data.value,
              })
            }
          />

          <Form.Input
            type="text"
            name="Vorname"
            label="Vorname*"
            onChange={(event, value) =>
              this.setState({ firstName: value.value })
            }
          />

          <Form.Input
            type="text"
            name="Nachname"
            label="Nachname*"
            onChange={(event, value) =>
              this.setState({ lastName: value.value })
            }
          />
          <Form.Checkbox
            name="INXMAIL_TRACKINGPERMISSION"
            label="Ja, ich bin damit einverstanden, dass mein personenbezogenes Nutzungsverhalten im Newsletter erfasst und ausgewertet wird, damit die Inhalte besser auf meine persönlichen Interessen ausgerichtet werden können. Über einen Link im Newsletter kann ich diese Funktion jederzeit deaktivieren."
            onChange={() =>
              this.setState({
                trackingConsent: !this.state.trackingConsent,
              })
            }
            value={this.state.trackingConsent}
          />
          <br />
          <p>
            Die Felder, die mit einem Stern (*) markiert sind, müssen ausgefüllt
            werden. Die von Ihnen angegebenen Daten werden ausschließlich zum
            Personalisieren unseres Newsletters verwendet und nicht an Dritte
            weiter gegeben. Die Angaben sind freiwillig. Damit dieser Newsletter
            nicht ungewollt in Ihren Spamordner verschoben wird, tragen Sie
            bitte unsere Absenderadresse in Ihr persönliches Adressbuch ein. Der
            Newsletter-Versand erfolgt entsprechend unserer{' '}
            <a href="https://www.zeelandia.de/datenschutz">
              Datenschutzerklärung
            </a>
            . Die Einwilligung in die Verwendung Ihrer Daten können Sie
            jederzeit widerrufen. Der Widerruf hat keine Auswirkung auf die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung.
          </p>
          <Button
            onClick={this.onSubmit}
            className="brandButtonPrimary"
            disabled={!filledOut}
          >
            Anmelden
          </Button>
        </Form>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    actions: state.actions.actions,
    register: state.onRegisterNewsletter,
  }),
  { postNewsletterRegister },
)(InxmailRegister);
