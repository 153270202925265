import { SidebarPortal } from '@plone/volto/components';
import Data from './Data';
import View from './View';

const Edit = (props) => {
  return (
    <div>
      <View {...props} isEditMode />
      <SidebarPortal selected={props.selected}>
        <Data {...props} />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
