import React from 'react';

const MetaFooter = ({ data }) => {
  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const formattedDate = date.toLocaleDateString('ger-DE', {
      hour: 'numeric',
      minute: 'numeric',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    return formattedDate;
  };
  return (
    <>
      <div className="meta-footer">
        <p>
          <strong>Ersteller:</strong> {data.benutzer}
        </p>
        <p>
          <strong>Erstellt:</strong> {formatDateTime(data.crdate)}
        </p>
        <p>
          <strong>Bearbeitet:</strong> {formatDateTime(data.modified)}
        </p>
      </div>
    </>
  );
};

export default MetaFooter;
