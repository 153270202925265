/**
 * Bezuege actions.
 * @module actions/Produkt
 */

import {
  GET_BEZUEGE,
  GET_BEZUGBYID,
  CREATE_BEZUG,
  UPDATE_BEZUG,
  DELETE_BEZUG,
} from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProduct
 * @param {string} id Produkt id
 * @returns {Object} Produkt action.
 */
export function getBezuege({
  showDeleted = false,
  getAll = false,
  batchingParameters = {},
}) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;

  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted === true) {
    return {
      type: GET_BEZUEGE,
      request: {
        op: 'get',
        path: `/@bezuege/deleted${pathSuffix}`,
      },
    };
  } else if (getAll === true) {
    return {
      type: GET_BEZUEGE,
      request: {
        op: 'get',
        path: `/@bezuege/all`,
      },
    };
  } else {
    return {
      type: GET_BEZUEGE,
      request: {
        op: 'get',
        path: `/@bezuege${pathSuffix}`,
      },
    };
  }
}

/**
 * Get Benutzer item.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getBezugByID(id) {
  return {
    type: GET_BEZUGBYID,
    request: {
      op: 'get',
      path: `/@bezuege/${id}`,
    },
  };
}

/**
 * Create a new Bezug.
 * @function createBezug
 * @param {Object} formData The data of the new Bezug
 * @returns {Object} Bezug action.
 */
export function createBezug(formData) {
  return {
    type: CREATE_BEZUG,
    request: {
      op: 'post',
      path: '/@bezuege',
      data: formData,
    },
  };
}

/**
 * Update a new Bezug.
 * @function createBezug
 * @param {Object} formData The data of the new Bezug
 * @returns {Object} Bezug action.
 */
export function updateBezug(id, formData) {
  return {
    type: UPDATE_BEZUG,
    request: {
      op: 'patch',
      path: `/@bezuege/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Bezug.
 * @function createBezug
 * @returns {Object} Success Message;
 */
export function deleteBezug(id) {
  return {
    type: DELETE_BEZUG,
    request: {
      op: 'del',
      path: `/@bezuege/${id}`,
    },
  };
}
