import React from 'react';
import { Grid } from 'semantic-ui-react';

const Nahrwerte = ({ rezept }) => {
  let data = rezept.data;
  let naehrwerte = data.naehrwerte;
  return (
    <div className="rezepte-nahrwerte">
      <Grid centered>
        <Grid.Column mobile={12} computer={8}>
          <Grid>
            <Grid.Column width={6}>
              <h3>Durchschnittliche Nährwerte pro 100 g</h3>
              <p>
                Energie: {naehrwerte.brennwert_kj} kJ/{' '}
                {naehrwerte.brennwert_kcal} kcal
              </p>
              <p>
                Fett: {naehrwerte.fett} g, <br />
                &nbsp;&nbsp;davon gesättigte Fettsäuren:{' '}
                {naehrwerte.ges_fettsaeuren} g
              </p>
              <p>
                Kohlenhydrate: {naehrwerte.kohlenhydrate} g, <br />
                &nbsp;&nbsp;davon Zucker: {naehrwerte.zucker} g
              </p>
              <p>Ballaststoffe: {naehrwerte.ballaststoffe} g</p>
              <p>Eiweiß: {naehrwerte.eiweiss} g</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <h3>Hinweis</h3>
              <p>
                Die Nährwertangaben sind Durchschnittswerte und wurden
                rechnerisch ermittelt. Sie gelten nur für die angegebene
                Rezeptnummer.
              </p>
              <p>
                Es wurde ein Backverlust von {data.backverlust}% berücksichtigt.
              </p>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Nahrwerte;
