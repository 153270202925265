import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { filter, map, uniqBy, some } from 'lodash';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Grid, Header, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import NavHeader from './NavHeader';
import { getVistPermissions } from '../../actions';
import config from '@plone/volto/registry';

import backSVG from '@plone/volto/icons/back.svg';

import rohstoffeGIF from './icons/navi_rohstoffe_normal.gif';
import rezepteGIF from './icons/navi_rezepte_normal.gif';
import produkteGIF from './icons/navi_produkte_normal.gif';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  versionoverview: {
    id: 'Version Overview',
    defaultMessage: 'Version Overview',
  },
  moderatecomments: {
    id: 'Moderate Comments',
    defaultMessage: 'Moderate Comments',
  },
  usersandgroups: {
    id: 'Users and Groups',
    defaultMessage: 'Users and Groups',
  },
  users: {
    id: 'Users',
    defaultMessage: 'Users',
  },
  groups: {
    id: 'Groups',
    defaultMessage: 'Groups',
  },
});

const Rezeptdatenbank = ({
  controlpanelsRequest,
  pathname,
  token,
  permissions,
  getVistPermissions,
  intl,
}) => {
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    getVistPermissions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Error
    if (controlpanelsRequest.loading && controlpanelsRequest.error) {
      setError(controlpanelsRequest.error);
    }
    // eslint-disable-next-line
  }, [controlpanelsRequest.loading, controlpanelsRequest.error]);

  const controlpanels = map([
    {
      '@id': 'rezeptdatenbank/rohstoffe',
      group: 'Haupttabellen',
      title: 'Rohstoffe',
      image: rohstoffeGIF,
      allowed_user_groups: [1, 13, 14, 15, 2, 4, 6],
    },
    {
      '@id': 'rezeptdatenbank/rezepte',
      group: 'Haupttabellen',
      title: 'Rezepte',
      image: rezepteGIF,
      allowed_user_groups: [1, 12, 13, 14, 15, 2, 4, 6],
    },
    {
      '@id': 'rezeptdatenbank/produkte',
      group: 'Haupttabellen',
      title: 'Produkte',
      image: produkteGIF,
      allowed_user_groups: [1, 13, 14, 15, 2, 4, 6],
    },
    {
      '@id': 'rezeptdatenbank/bezuege',
      group: 'Haupttabellen',
      title: 'Bezüge',
      id: 'dexterity-types',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/benutzer',
      group: 'Benutzerverwaltung',
      title: 'Benutzer',
      id: 'users',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/benutzergruppen',
      group: 'Benutzerverwaltung',
      title: 'Benutzergruppen',
      // id: 'navigation',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/rezeptgruppen',
      group: 'Kategorisierung',
      title: 'Rezeptgruppen',
      id: 'language',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/rezeptkategorien',
      group: 'Kategorisierung',
      title: 'Rezeptkategorien',
      // id: 'language',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/zutatengruppen',
      group: 'Kategorisierung',
      title: 'Zutatengruppen',
      id: 'navigation',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/produktkategorien',
      group: 'Kategorisierung',
      title: 'Produktkategorien',
      id: 'navigation',
      allowed_user_groups: [1],
    },
    {
      '@id': 'rezeptdatenbank/rohstoffkategorien',
      group: 'Kategorisierung',
      title: 'Rohstoffkategorien',
      id: 'navigation',
      allowed_user_groups: [1],
    },
  ]);
  const groups = map(uniqBy(controlpanels, 'group'), 'group');
  const { controlPanelsIcons: icons } = config.settings;

  // Filter groups and control panels based on permissions
  const filteredGroups = filter(groups, (group) =>
    some(filter(controlpanels, { group }), (controlpanel) =>
      some(controlpanel.allowed_user_groups, (allowed_user_group) =>
        permissions.includes(allowed_user_group),
      ),
    ),
  );

  const filteredControlpanels = filter(controlpanels, (controlpanel) =>
    some(controlpanel.allowed_user_groups, (allowed_user_group) =>
      permissions.includes(allowed_user_group),
    ),
  );

  if (error) {
    return <Error error={error} />;
  }

  if (!token) {
    setError({ status: 401 });
  }

  return (
    <div className="view-wrapper">
      <Helmet title="Rezeptdatenbank" />
      <NavHeader permissions={permissions} />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>Rezeptdatenbank</h1>
              </div>
            </div>
          </Segment>
          {map(filteredGroups, (group) => [
            <Segment key={`header-${group}`} secondary>
              {group}
            </Segment>,
            <Segment key={`body-${group}`} attached>
              <Grid columns={6}>
                <Grid.Row>
                  {map(
                    filter(filteredControlpanels, { group }),
                    (controlpanel) => (
                      <Grid.Column key={controlpanel.id}>
                        <Link to={`/controlpanel/${controlpanel['@id']}`}>
                          <Header as="h3" icon textAlign="center">
                            {controlpanel?.image ? (
                              <img
                                className="rezeptdatenbank-icon"
                                src={controlpanel?.image}
                                size="48px"
                              />
                            ) : (
                              <Icon
                                name={icons?.[controlpanel.id] || icons.default}
                                size="48px"
                              />
                            )}
                            <Header.Content>
                              {controlpanel.title}
                            </Header.Content>
                          </Header>
                        </Link>
                      </Grid.Column>
                    ),
                  )}
                </Grid.Row>
              </Grid>
            </Segment>,
          ])}
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

Rezeptdatenbank.propTypes = {
  controlpanels: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      group: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  pathname: PropTypes.string.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      controlpanels: state.controlpanels.controlpanels,
      controlpanelsRequest: state.controlpanels.list,
      pathname: props.location.pathname,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    { getVistPermissions },
  ),
)(Rezeptdatenbank);
