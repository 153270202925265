/**
 * Search component.
 * @module components/theme/Search/Search
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { asyncConnect } from '@plone/volto/helpers';
import { Portal } from 'react-portal';
import {
  Dropdown,
  Form,
  Icon as OldIcon,
  Input,
  Button,
  Card,
  Container,
  Image,
  Pagination,
} from 'semantic-ui-react';
import { Icon, Toolbar } from '@plone/volto/components';
import { listActions } from '@plone/volto/actions';
import qs from 'query-string';

import { searchContentPaged } from '../../../../../actions';

import { solrSearchContent } from '@kitconcept/volto-solr/actions';

import searchSVG from '../../../../../icons/loupe.svg';
import searchDocSVG from '../../../../../static/search-doc.svg';
import searchnewsSVG from '../../../../../static/search-news.svg';
import searchPDFSVG from '../../../../../static/search-pdf.svg';
import searchVKSVG from '../../../../../static/search-vk.svg';
import missingIMG from '../../../../../static/missing.jpg';
import smallBlueButton from '../../../../../static/small-blue-button.svg';
import paginationLeftSVG from '../../../../../icons/pagination-left.svg';
import paginationRightSVG from '../../../../../icons/pagination-right.svg';

import config from '@plone/volto/registry';

/**
 * SearchComponent class.
 * @class SearchComponent
 * @extends Component
 */
export class SearchComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    listActions: PropTypes.func.isRequired,
    solrSearchContent: PropTypes.func.isRequired,
    searchContentPaged: PropTypes.func.isRequired,
    searchableText: PropTypes.string,
    // subject: PropTypes.string,
    // path: PropTypes.string,
    itemsTotal: PropTypes.number.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    searchableText: null,
    actions: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: props.searchableText,
      currentPage: 1,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.solrSearchContent('', {
      q: this.props.searchableText,
      use_solr: true,
      // portal_type: config.settings.DEFAULT_SEARCHABLE_TYPES,
    });
    this.props.listActions('');
  }

  /**
   * Component did update
   * @method componentDidUpdate
   * @param {Object} prevProps Prev properties
   * @param {Object} prevState Prev state
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchableText !== prevProps.searchableText) {
      this.props.solrSearchContent('', {
        q: this.props.searchableText,
      });
    }
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText = (event, { value }) => {
    this.setState({
      text: value,
    });
    // if (this.promise) {
    //   this.promise.request.abort();
    //   this.promise = undefined;
    // }

    // this.promise = this.props.solrSearchContent('', {
    //   q: value,
    // });
  };

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit = (event) => {
    this.props.solrSearchContent('', {
      SearchableText: this.state.text,
    });
    event.preventDefault();
  };

  getItemURL = (item) => {
    let type;
    if (item.portal_type) {
      type = item.portal_type;
    } else {
      type = item['@type'];
    }
    switch (type) {
      case 'jungzeelandia.Recipe':
        return `/alle-rezepte/${item.id}`;
      case 'jungzeelandia.Product':
        return `/alle-produkte/${item.id}`;
      case 'jungzeelandia.SalesConcept':
      case 'Document':
      case 'Image':
      case 'News Item':
        return item['@id'];
      default:
        break;
    }
    return '';
  };

  getImage = (item) => {
    if (item.portal_type) {
      if (item.image) {
        return <Image src={`${item.image['@id']}/@@images/image/mini`} />;
      }
      return <Image src={missingIMG} />;
    }
    switch (item['@type']) {
      case 'Document':
        return <Image src={searchDocSVG} />;
      case 'News Item':
        return <Image src={searchnewsSVG} />;
      case 'File':
        return <Image src={searchPDFSVG} />;
      case 'jungzeelandia.SalesConcept':
        return <Image src={searchVKSVG} />;
      case 'Image':
        return <Image src={`${config.settings.apiPath}${item['@id']}`} />;
      default:
        break;
    }
  };

  typeLiteralMap = (type) => {
    switch (type) {
      case 'jungzeelandia.Recipe':
        return 'Rezepte';
      case 'jungzeelandia.Product':
        return 'Produkt';
      case 'jungzeelandia.SalesConcept':
        return 'Verkaufskonzept';
      case 'Document':
        return 'Artikel';
      case 'Image':
        return 'Bild';
      case 'News Item':
        return 'Neuigkeit';
      default:
        break;
    }
    return '';
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState(() => ({ currentPage: activePage }));
    this.props.searchContentPaged(
      '',
      {
        SearchableText: this.state.text,
      },
      activePage,
    );
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container style={{ marginBottom: '50px' }}>
        <Helmet title="Search" />
        <div className="container">
          <article id="content">
            <Form
              action="/search"
              onSubmit={this.onSubmit}
              className="search-form"
              style={{ marginBottom: '60px' }}
            >
              <Form.Field className="searchbox" style={{ margin: 0 }}>
                <Input
                  onChange={this.onChangeText}
                  name="SearchableText"
                  value={this.state.text}
                  transparent
                  placeholder="Suchbegriff hier eingeben"
                  title="Suche"
                  autoComplete="off"
                />
                <Button className="brandButtonPrimary">
                  <Icon name={searchSVG} size="45px" title="Suche" />
                </Button>
              </Form.Field>
              {/* <h1 className="documentFirstHeading">
                {this.props.searchableText ? (
                  <FormattedMessage
                    id="Search results for {term}"
                    defaultMessage="Search results for {term}"
                    values={{
                      term: <q>{this.props.searchableText}</q>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="Search results"
                    defaultMessage="Search results"
                  />
                )}
              </h1> */}
              {/* <SearchTags /> */}
            </Form>
            <section className="search-results">
              <Card.Group
                stackable
                centered
                itemsPerRow={4}
                style={{ marginBottom: '44px' }}
              >
                {this.props.items.map((item) => (
                  <Card
                    key={item['@id']}
                    as={Link}
                    to={this.getItemURL(item)}
                    className={item.portal_type}
                  >
                    {this.getImage(item)}
                    <Card.Content>
                      <div className="underlined-title">
                        {item.portal_type ? (
                          <span>{this.typeLiteralMap(item.portal_type)}</span>
                        ) : (
                          <span>{this.typeLiteralMap(item['@type'])}</span>
                        )}
                      </div>
                      <Card.Header>
                        {item.display_rezeptcode ? (
                          <b>{item.display_rezeptcode} </b>
                        ) : (
                          ''
                        )}
                        {item.name || item.title}
                      </Card.Header>
                      <div>
                        <img
                          style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                          }}
                          src={smallBlueButton}
                          alt="Arrow"
                        />
                      </div>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <Pagination
                  style={{
                    padding: '20px 0',
                    borderTop: '2px solid #EBEAF1',
                    borderBottom: '2px solid #EBEAF1',
                  }}
                  activePage={this.state.currentPage}
                  totalPages={Math.ceil(
                    this.props.itemsTotal / config.settings.DEFAULT_PAGING_SIZE,
                  )}
                  onPageChange={this.handlePaginationChange}
                  firstItem={null}
                  lastItem={null}
                  prevItem={
                    this.props.batching.prev
                      ? {
                          content: (
                            <Icon name={paginationLeftSVG} size="18px" />
                          ),
                          icon: true,
                        }
                      : null
                  }
                  nextItem={
                    this.props.batching.next
                      ? {
                          content: (
                            <Icon name={paginationRightSVG} size="18px" />
                          ),
                          icon: true,
                        }
                      : null
                  }
                />
              </div>
            </section>
          </article>
        </div>
        <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
          <Toolbar
            pathname={this.props.pathname}
            inner={
              <div>
                <Dropdown
                  id="toolbar-personal"
                  className="personal-bar"
                  item
                  upward
                  trigger={<OldIcon name="user" size="big" />}
                >
                  <Dropdown.Menu>
                    {this.props.actions.user &&
                      this.props.actions.user.map((item) => {
                        switch (item.id) {
                          case 'preferences':
                            return (
                              <Link
                                key={item.id}
                                to="/personal-preferences"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="setting" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'plone_setup':
                            return (
                              <Link
                                key={item.id}
                                to="/controlpanel"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="settings" /> {item.title}
                                </span>
                              </Link>
                            );

                          case 'logout':
                            return (
                              <Link
                                key={item.id}
                                to="/logout"
                                id="toolbar-logout"
                                className="item"
                              >
                                <span>
                                  <OldIcon name="sign out" /> {item.title}
                                </span>
                              </Link>
                            );
                          default: {
                            return null;
                          }
                        }
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          />
        </Portal>
      </Container>
    );
  }
}

export default compose(
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(
          solrSearchContent('', {
            q: qs.parse(location.search).SearchableText,
            Subject: qs.parse(location.search).Subject,
          }),
        ),
    },
  ]),
  connect(
    (state, props) => {
      let searchQuery = qs.parse(props.location.search);
      return {
        actions: state.actions.actions,
        items: state.solrsearch.items,
        itemsTotal: state.search.total,
        batching: state.search.batching,
        searchableText: searchQuery.SearchableText,
        subject: searchQuery.Subject,
        path: searchQuery.path,
        pathname: props.location.pathname,
      };
    },
    { listActions, solrSearchContent, searchContentPaged },
  ),
)(SearchComponent);
