/**
 * product items reducer.
 * @module reducers/product
 */
import { has } from 'lodash';

import { GET_PRODUCT } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
};

/**
 * product reducer.
 * @function product
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function product(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUCT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUCT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_PRODUCT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}
