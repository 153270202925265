import { React } from 'react';

const NutrientInfo = ({ data, isRezept }) => {
  const formatNumber = (value, floatingPoints) => {
    if (value !== undefined) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: floatingPoints,
        maximumFractionDigits: floatingPoints,
      });
    } else {
      return value;
    }
  };
  return (
    <>
      <div className="trenner" />
      <p className="meta-info">
        <strong>100 g enthalten durchschnittlich:</strong>
      </p>
      <div className="trenner" />
      <div className="nutrients-info">
        <p>
          <strong>Brennwert ( kJ ):</strong>{' '}
          {formatNumber(data.brennwert_kj, 0)}
        </p>
        <p>
          <strong>Brennwert ( kcal ):</strong>{' '}
          {formatNumber(data.brennwert_kcal, 0)}
        </p>
        <p>
          <strong>Eiweiss ( g ):</strong> {formatNumber(data.eiweiss, 1)}
        </p>
        {isRezept ? (
          <p>
            <strong>Kohlenhydrate ( g ) (*):</strong>{' '}
            {formatNumber(data.kohlenhydrate, 1)}
          </p>
        ) : (
          <p>
            <strong>Kohlenhydrate ( g ):</strong>{' '}
            {formatNumber(data.kohlenhydrate, 1)}
          </p>
        )}
        <p>
          <strong style={{ marginLeft: '5px' }}>davon Zucker ( g ):</strong>{' '}
          {formatNumber(data.zucker, 1)}
        </p>
        <p>
          <strong>Fett ( g ):</strong> {formatNumber(data.fett, 1)}
        </p>
        <p>
          <strong style={{ marginLeft: '5px' }}>
            davon gesättigte Fettsäuren ( g ):
          </strong>{' '}
          {formatNumber(data.ges_fettsaeuren, 1)}
        </p>
        <p>
          <strong>Ballaststoffe ( g ):</strong>{' '}
          {formatNumber(data.ballaststoffe, 1)}
        </p>
        <p>
          <strong>Salz ( g ):</strong> {formatNumber(data.natrium, 2)}
        </p>
        {isRezept ? (
          <p>
            * entspricht {formatNumber(data.kohlenhydrate / 12, 1)}{' '}
            Broteinheiten
          </p>
        ) : (
          <p>
            <strong>Kalkulationspreis ( Euro / kg ):</strong>{' '}
            {formatNumber(data.basis_euro, 2)}
          </p>
        )}
      </div>
    </>
  );
};

export default NutrientInfo;
