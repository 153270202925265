import { has } from 'lodash';
import {
  GET_REZEPTKATEGORIEN,
  GET_REZEPTKATEGORIEBYID,
  CREATE_REZEPTKATEGORIE,
  DELETE_REZEPTKATEGORIE,
  UPDATE_REZEPTKATEGORIE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function rezeptkategorien(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTKATEGORIEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTKATEGORIEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rezeptkategorien: [], total_pages: 0 },
      };
    case `${GET_REZEPTKATEGORIEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rezeptkategorieByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REZEPTKATEGORIEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_REZEPTKATEGORIEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_REZEPTKATEGORIEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rezeptkategorieCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_REZEPTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_REZEPTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_REZEPTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptkategorieDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_REZEPTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_REZEPTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_REZEPTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rezeptkategorieUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_REZEPTKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_REZEPTKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_REZEPTKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
