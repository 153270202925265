/**
 * Recipe category actions.
 * @module actions/recipecategory
 */

import { POST_NEWSLETTER_REGISTER } from '../../constants/ActionTypes';

/**
 * Get recipe category items.
 * @function postNewsletterRegister
 * @param {Object} data data
 * @returns {Object} newslter register category action.
 */
export function postNewsletterRegister(data) {
  return {
    type: POST_NEWSLETTER_REGISTER,
    request: { op: 'post', path: `/@newsletter-register`, data },
  };
}
