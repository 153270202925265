import { has } from 'lodash';
import {
  GET_ROHSTOFFE,
  GET_ROHSTOFFBYID,
  CREATE_ROHSTOFF,
  CHECK_ROHSTOFFE,
  DELETE_ROHSTOFF,
  UPDATE_ROHSTOFF,
  GET_ROHSTOFFE_NAMES,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function rohstoffe(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rohstoffe: [], total_pages: 0 },
      };
    case `${GET_ROHSTOFFE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffeNames(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFE_NAMES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFE_NAMES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? [...action.result] : [],
      };
    case `${GET_ROHSTOFFE_NAMES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffeCheck(state = initialState, action = {}) {
  switch (action.type) {
    case `${CHECK_ROHSTOFFE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CHECK_ROHSTOFFE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? action.result : '',
      };
    case `${CHECK_ROHSTOFFE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_ROHSTOFFBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_ROHSTOFF}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_ROHSTOFF}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_ROHSTOFF}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rohstoffDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_ROHSTOFF}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_ROHSTOFF}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_ROHSTOFF}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rohstoffUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_ROHSTOFF}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_ROHSTOFF}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_ROHSTOFF}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
