/**
 * Rezepte actions.
 * @module actions/Rezept
 */

import {
  GET_REZEPTE,
  GET_DELETEDREZEPTE,
  GET_GRUNDREZEPTE,
  CHECK_REZEPTE,
  GET_REZEPTBYID,
  GET_REZEPTE_NUTRIENTS_BY_ID,
  GET_ROHSTOFFKOSTEN_BY_ID,
  CREATE_REZEPT,
  UPDATE_REZEPT,
  DELETE_REZEPT,
} from '../../constants/ActionTypes';

import { isEmpty } from 'lodash';

/**
 * Get Rezept items with batching parameters.
 * @function getRezepte
 * @param {number} limit - The number of items to retrieve.
 * @param {number} skip - The number of items to skip.
 * @param {string} sort_by - The column to sort by.
 * @param {boolean} asc - Sort in ascending or descending order.
 * @returns {Object} Rezept action.
 */
export function getRezepte({ showDeleted = false, batchingParameters = {} }) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
    searchField = {},
    filters = {},
  } = batchingParameters;
  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  if (searchField.value) {
    queryParams.push(`search_kategorie=${searchField.value}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }

  //Apply filters from batching params
  Object.entries(filters).map((filter) => {
    const filterValue = filter[1];
    let filterValues;
    if (!isEmpty(filterValue)) {
      if (Array.isArray(filter[1])) {
        filterValues = filterValue.map((singleValue) => {
          return singleValue.value;
        });
      } else if (typeof filterValue === 'object') {
        filterValues = filterValue.value;
      } else if (typeof filterValue === 'number') {
        filterValues = filterValue;
      } else if (typeof filterValue === 'boolean') {
        if (filterValue == true) {
          filterValues = 1;
        } else {
          filterValues = 0;
        }
      }

      pathSuffix += `&${filter[0]}=${filterValues}`;
    }
  });

  if (showDeleted) {
    return {
      type: GET_REZEPTE,
      request: {
        op: 'get',
        path: `/@rezepte/deleted${pathSuffix}`,
      },
    };
  }
  return {
    type: GET_REZEPTE,
    request: {
      op: 'get',
      path: `/@rezepte${pathSuffix}`,
    },
  };
}

/**
 * Get GrundRezept items.
 * @function getGrundrezepte
 * @returns {Object} Rezept action.
 */
export function getGrundrezepte() {
  return {
    type: GET_GRUNDREZEPTE,
    request: {
      op: 'get',
      path: `/@rezepte/grundrezepte`,
    },
  };
}

/**
 * Get deleted Rezept items with batching parameters.
 * @function getRezepte
 * @param {number} limit - The number of items to retrieve.
 * @param {number} skip - The number of items to skip.
 * @param {string} sort_by - The column to sort by.
 * @param {boolean} asc - Sort in ascending or descending order.
 * @returns {Object} Rezept action.
 */
export function getDeletedRezepte(limit, skip, sort_by, asc, searchTerms = '') {
  if (sort_by !== '') {
    sort_by = `&sort_by=${sort_by}`;
  }
  if (searchTerms !== '') {
    searchTerms = `&search=${searchTerms}`;
  }
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}${sort_by}${searchTerms}`;
  return {
    type: GET_DELETEDREZEPTE,
    request: {
      op: 'get',
      path: `/@rezepte/deleted${pathSuffix}`,
    },
  };
}

/**
 * Check Rezepte for specific value in specific field.
 * @function checkRezepte
 * @param {string} field, specified field
 * @param {string} value, specified value
 * @returns {Object} Rezept action.
 */
export function checkRezepte(field, value, subrequest) {
  return {
    type: CHECK_REZEPTE,
    subrequest,
    request: {
      op: 'get',
      path: `/@rezepte_check/${field}=${value}`,
    },
  };
}

/**
 * Get Rezept item by ID.
 * @function getRezeptByID
 * @param {string} id Rezept id
 * @returns {Object} Rezept action.
 */
export function getRezeptByID(id) {
  return {
    type: GET_REZEPTBYID,
    request: {
      op: 'get',
      path: `/@rezepte/${id}`,
    },
  };
}

/**
 * Get Rezept nutrients by ID.
 * @function getRezeptNutrientsByID()
 * @param {int} id Rezept id
 * @returns {Object} Rezept action.
 */
export function getRezeptNutrientsByID(id) {
  return {
    type: GET_REZEPTE_NUTRIENTS_BY_ID,
    request: {
      op: 'get',
      path: `/@rezepte/nutrients/${id}`,
    },
  };
}

/**
 * Get Rezept costs by ID.
 * @function getRohstoffKostenByID()
 * @param {int} id Rezept id
 * @param {int} number of pieces
 * @returns {Object} Rezept action.
 */
export function getRohstoffKostenByID(id, number_of_pieces) {
  return {
    type: GET_ROHSTOFFKOSTEN_BY_ID,
    request: {
      op: 'get',
      path: `/@rezepte/costs/${id}&${number_of_pieces}`,
    },
  };
}

/**
 * Create a new Rezept.
 * @function createRezept
 * @param {Object} formData The data of the new Rezept
 * @returns {Object} Rezept action.
 */
export function createRezept(formData) {
  return {
    type: CREATE_REZEPT,
    request: {
      op: 'post',
      path: '/@rezepte',
      data: formData,
    },
    onError: (error) => ({ error }),
  };
}

/**
 * Update a Rezept.
 * @function updateRezept
 * @param {string} id The id of the Rezept to update
 * @param {Object} formData The data of the updated Rezept
 * @returns {Object} Rezept action.
 */
export function updateRezept(id, formData) {
  return {
    type: UPDATE_REZEPT,
    request: {
      op: 'patch',
      path: `/@rezepte/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Rezept.
 * @function deleteRezept
 * @param {string} id The id of the Rezept to delete
 * @returns {Object} Success Message.
 */
export function deleteRezept(id) {
  return {
    type: DELETE_REZEPT,
    request: {
      op: 'del',
      path: `/@rezepte/${id}`,
    },
  };
}
