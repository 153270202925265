/* eslint-disable require-jsdoc */
/**
 * Homepage container.
 * @module components/Views/Homepage
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from '@plone/volto/helpers';
import { Portal } from 'react-portal';
import { Toolbar } from '@plone/volto/components';
import { listActions, getContent } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';

import { HomepageNews, HomepageSlider, Highlights, TabbedInfo } from '../.';
import { getAktuelles, getJobs, getOnapplyJobs } from '../../actions';

/**
 * @class Homepage
 * @extends {Component}
 */
class Homepage extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    listActions: PropTypes.func.isRequired,
    getContent: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    versionId: PropTypes.string,
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }),
    aktuelles: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ),
    jobs: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ),
    getAktuelles: PropTypes.func.isRequired,
    getJobs: PropTypes.func.isRequired,
    getOnapplyJobs: PropTypes.func.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    actions: null,
    content: null,
    versionId: null,
    aktuelles: [],
    jobs: [],
  };

  state = {
    isClient: false,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.listActions(this.props.pathname);
    this.props.getContent(this.props.pathname, this.props.versionId);
    this.props.getAktuelles();
    this.props.getJobs();
    this.props.getOnapplyJobs();

    this.setState({ isClient: true }, () => {
      let editButton = document.createElement('a');
      editButton.setAttribute('id', 'toolbar-edit');
      editButton.setAttribute('href', '/startseite/edit');
      editButton.setAttribute('class', 'edit item');
      editButton.setAttribute('title', 'Bearbeiten');
      editButton.setAttribute('aria-label', 'Edit');

      editButton.innerHTML = `<i title="Bearbeiten" aria-hidden="true" class="blue write big circular icon"></i>`;

      // eslint-disable-next-line no-unused-expressions
      document.querySelector('#toolbar .toolbar-actions')?.prepend(editButton);
    });
  }

  /**
   * Component did update
   * @method componentDidUpdate
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.props.listActions(this.props.pathname);
      this.props.getContent(this.props.pathname, prevProps.versionId);
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <React.Fragment>
        <Helmet title="Zeelandia Deutschland: Backzutaten, die funktionieren — natürlich!| Zeelandia GmbH & Co. KG" />
        <BodyClass className="homepage" />
        <HomepageSlider />
        <Highlights />
        <TabbedInfo />
        <div className="separator" />
        <HomepageNews
          news={this.props.aktuelles.slice(0, 4)}
          jobs={this.props.onapplyJobs.slice(0, 4)}
        />

        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')} inner={<span />}>
            <Toolbar pathname={this.props.pathname} />
          </Portal>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  (state, props) => ({
    actions: state.actions.actions,
    content: state.content.data,
    pathname: props.location.pathname,
    versionId: props.location.query && props.location.query.version_id,
    aktuelles: state.aktuelles.items,
    jobs: state.jobs.items,
    onapplyJobs: state.onapplyJobs.items,
  }),
  {
    listActions,
    getContent,
    getAktuelles,
    getJobs,
    getOnapplyJobs,
  },
)(Homepage);
