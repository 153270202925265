/**
 * Search component.
 * @module components/theme/Search/Search
 */

import React, { Component, createRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect, flattenToAppURL } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import {
  Container,
  Segment,
  Pagination,
  Button,
  Dimmer,
  Loader,
  Checkbox,
  Card,
  Image,
} from 'semantic-ui-react';
import { injectIntl, useIntl, defineMessages } from 'react-intl';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import config from '@plone/volto/registry';
import { Helmet } from '@plone/volto/helpers';
import { Toolbar, Icon } from '@plone/volto/components';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
// These imports and the legacySearchProps are only for the legacy search
import { searchContent } from '@plone/volto/actions';
import { DefaultResultItem } from '@kitconcept/volto-solr/components/theme/SolrSearch/resultItems';
import { SelectSorting } from '@kitconcept/volto-solr/components/theme/SolrSearch/SelectSorting';
import { SearchTabs } from '@kitconcept/volto-solr/components/theme/SolrSearch/SearchTabs';
import { SearchConditions } from '@kitconcept/volto-solr/components/theme/SolrSearch//SearchConditions';
import {
  queryStateFromParams,
  queryStateToParams,
} from '@kitconcept/volto-solr/components/theme/SolrSearch//SearchQuery';

import smallBlueButton from '../../../../../../static/small-blue-button.svg';
import searchSVG from '../../../../../../icons/loupe.svg';
import searchDocSVG from '../../../../../../static/search-doc.svg';
import searchnewsSVG from '../../../../../../static/search-news.svg';
import searchPDFSVG from '../../../../../../static/search-pdf.svg';
import searchVKSVG from '../../../../../../static/search-vk.svg';
import missingIMG from '../../../../../../static/missing.jpg';

const messages = defineMessages({
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  results: {
    // Ergebnisse
    id: 'results',
    defaultMessage: 'results',
  },
  searchLocalizedLabel: {
    // Suchergebnisse anzeigen für:
    id: 'searchLocalizedLabel',
    defaultMessage: 'Show results for:',
  },
  searchGlobalized: {
    // Alle webauftritte (global)
    id: 'searchGlobalized',
    defaultMessage: 'All content (global)',
  },
  searchLocalized: {
    // Nur dieser webauftritt
    id: 'searchLocalized',
    defaultMessage: 'This subfolder only',
  },
});

const SearchInput = forwardRef(
  ({ forwardRef, placeholder, className, value, onChange, onSubmit }, ref) => {
    const SolrSearchAutosuggest = config.widgets.SolrSearchAutosuggest;
    return (
      <SolrSearchAutosuggest
        inputRef={ref}
        placeholder={placeholder}
        className={className}
        value={value || ''}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  },
);

// XXX The original input - left here for testing.
// const SearchInputDefault = forwardRef(
//   ({ forwardRef, placeholder, className, value, onChange }, ref) => {
//     return (
//       <input
//         ref={ref}
//         placeholder={placeholder}
//         className={className}
//         value={value}
//        onChange={onChange}
//       />
//     );
//   },
// );

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const TranslatedInput = forwardRef(
  ({ forwardRef, placeholder, className, value, onChange, onSubmit }, ref) => {
    return (
      <SearchInput
        ref={ref}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  },
);

// Return the path prefix, or undefined, if we are on the root.
// We consider `/` and the language specific root folders `/en`, `/de` as roots
const getPathPrefix = (location) => {
  const pathPrefix = location.pathname.replace(/\/[^/]*$/, '') + '/';
  return pathPrefix.match(/^\/([^/]+\/)?$/) ? undefined : pathPrefix;
};

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const LocalCheckbox = ({ onChange, checked }) => {
  const intl = useIntl();
  return (
    <div className="search-localized">
      {intl.formatMessage(messages.searchLocalizedLabel)}
      <Checkbox
        radio
        label={intl.formatMessage(messages.searchGlobalized)}
        onChange={(e, data) => onChange(!data.checked)}
        checked={!checked}
      />
      <Checkbox
        radio
        label={intl.formatMessage(messages.searchLocalized)}
        onChange={(e, data) => onChange(data.checked)}
        checked={checked}
      />
    </div>
  );
};

/**
 * SolrSearch class.
 * @class SearchComponent
 * @extends Component
 */
class SolrSearch extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    searchAction: PropTypes.func,
    getSearchReducer: PropTypes.func,
    showSearchInput: PropTypes.bool,
    doEmptySearch: PropTypes.bool,
    contentTypeSearchResultViews: PropTypes.object,
    contentTypeSearchResultDefaultView: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    total: PropTypes.number,
    batching: PropTypes.shape({
      '@id': PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }),
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    suggestions: [],
    loaded: false,
    loading: false,
    total: 0,
    batching: null,
    searchableText: null,
    path: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...queryStateFromParams({}),
      currentPage: 1,
      isClient: false,
    };
    this.inputRef = createRef();
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    const location = this.props.history.location;
    const params = qs.parse(location.search);

    this.setState({
      ...this.queryStateFromSearchParams(),
      isClient: true,
    });
    this.doSearch(params);
    // put focus to the search input field only if the user does not come from another location
    if (this.props.showSearchInput && !params.SearchableText) {
      this.inputRef.current.focus();
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const search = nextProps.location.search;
    if (this.props.location.search !== search) {
      const params = qs.parse(search);
      this.setState(
        {
          ...queryStateFromParams(params),
          currentPage: 1,
        },
        () => this.doSearch(params),
      );
    }
  };

  searchParams = () => qs.parse(this.props.history.location.search);
  queryStateFromSearchParams = () => queryStateFromParams(this.searchParams());

  /**
   * Search based on the given search params
   * @method doSearch
   * @param {string} params The search params
   * @returns {undefined}
   */
  doSearch = (params) => {
    this.props.searchContent('', {
      ...params,
      sort_on: params.sort_on !== 'relevance' ? params.sort_on : '',
      b_start: (this.state.currentPage - 1) * config.settings.defaultPageSize,
      path_prefix: getPathPrefix(window.location),
      doEmptySearch: this.props.doEmptySearch,
      portal_type: config.settings.DEFAULT_SEARCHABLE_TYPES,
    });
    this.setState({ searchwordResult: params.SearchableText });
  };

  updateSearch = () => {
    this.props.history.replace({
      search: qs.stringify(queryStateToParams(this.state)),
    });
  };

  handleQueryPaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState({ currentPage: activePage }, () =>
      this.doSearch(this.searchParams()),
    );
  };

  onSortChange = (selectedOption, selectedSortOrder) => {
    this.setState(
      {
        currentPage: 1,
        sortOn: selectedOption,
        sortOrder: selectedSortOrder || 'ascending',
      },
      () => this.updateSearch(),
    );
  };

  setGroupSelect = (groupSelect) => {
    this.setState({ currentPage: 1, facetConditions: {}, groupSelect }, () =>
      this.updateSearch(),
    );
  };

  setLocal = (local) => {
    this.setState({ currentPage: 1, local }, () => this.updateSearch());
  };

  setFacetConditions = (facetConditions) => {
    this.setState({ facetConditions }, () => this.updateSearch());
  };

  setConditionTree = (f) =>
    this.setFacetConditions(f(this.state.facetConditions));

  onSubmit = (event) => {
    this.updateSearch();
    event.preventDefault();
  };

  typeLiteralMap = config.settings.typeLiteralMap;

  getItemURL = (item) => {
    let type;
    if (item.portal_type) {
      type = item.portal_type;
    } else {
      type = item['@type'];
    }
    let item_id;
    switch (type) {
      case 'jungzeelandia.Recipe':
        item_id = item['@id'].split('=')[item['@id'].split('=').length - 1];
        return `/alle-rezepte/${item_id}`;
      case 'jungzeelandia.Product':
        item_id = item['@id'].split('=')[item['@id'].split('=').length - 1];
        return `/alle-produkte/${item_id}`;
      case 'File':
        return `${item['@id']}`;
      case 'jungzeelandia.SalesConcept':
      case 'Document':
      case 'Page':
      case 'Image':
      case 'News Item':
        return item['@id'];
      default:
        break;
    }
    return '';
  };

  getImage = (item) => {
    if (item.extras?.image_path) {
      return (
        <Image
          src={flattenToAppURL(item.extras.image_path.replace('Plone/', ''))}
        />
      );
    } else {
      switch (item['@type']) {
        case 'jungzeelandia.Recipe':
        case 'jungzeelandia.Product':
          return <Image src={missingIMG} />;
        case 'Document':
        case 'Page':
          return <Image src={searchDocSVG} />;
        case 'News Item':
          return <Image src={searchnewsSVG} />;
        case 'File':
          return <Image src={searchPDFSVG} />;
        case 'jungzeelandia.SalesConcept':
          return <Image src={searchVKSVG} />;
        case 'Image':
          return <Image src={`${config.settings.apiPath}${item['@id']}`} />;
        default:
          break;
      }
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;

    return (
      <Segment basic id="page-search" className="header-wrapper">
        <Helmet title="Search" />
        <Container>
          {this.props.showSearchInput ? (
            <div className="search-input">
              <form className="ui form search-form" onSubmit={this.onSubmit}>
                <div className="field searchbox">
                  <TranslatedInput
                    ref={this.inputRef}
                    placeholder={'Suche'}
                    className="searchinput"
                    value={this.state.searchword}
                    onChange={(e) =>
                      this.setState({ searchword: e.target.value })
                    }
                    onSubmit={this.onSubmit}
                  />
                  <Button onClick={this.onSubmit}>
                    <Icon name={searchSVG} size="45px" title="Suche" />{' '}
                  </Button>
                </div>
              </form>
            </div>
          ) : null}
          {this.state.allowLocal &&
          getPathPrefix(this.props.history.location) !== undefined ? (
            <LocalCheckbox
              onChange={(checked) => this.setLocal(checked)}
              checked={this.state.local}
            />
          ) : null}

          <article id="content">
            {this.props.total > 0 ? (
              <>
                <header>
                  <p className="total-results">{this.props.total} Ergebnisse</p>
                  <div className="sorting-bar">
                    <SelectSorting
                      value={this.state.sortOn}
                      onChange={(selectedOption, order) => {
                        this.onSortChange(selectedOption, order);
                      }}
                    />
                  </div>
                </header>
                <SearchTabs
                  groupSelect={this.state.groupSelect}
                  setGroupSelect={(groupSelect) =>
                    this.setGroupSelect(groupSelect)
                  }
                  facetGroups={this.props.facetGroups}
                />
              </>
            ) : (
              <div className="no-results">
                {this.props.searchTerm && (
                  <>
                    <h2>Keine Ergebnisse gefunden</h2>
                    <p>
                      Leider wurden für <b>{this.props.searchTerm} </b> keine
                      Ergebnisse gefunden.
                    </p>
                    {this.props.suggestions?.length > 0 && (
                      <p>
                        Womöglich meinten Sie:
                        <ul>
                          {this.props.suggestions.map((suggestion) => {
                            return (
                              <li>
                                <Link
                                  to={`/search?SearchableText=${suggestion.suggestion[0]}`}
                                >
                                  {suggestion.suggestion[0]}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="searchContentWrapper">
              <SearchConditions
                groupSelect={this.state.groupSelect}
                facetFields={this.props.facetFields}
                conditionTree={this.state.facetConditions}
                setConditionTree={this.setConditionTree}
              />
              <Dimmer active={this.props.loading} inverted>
                <Loader indeterminate size="small">
                  <FormattedMessage id="loading" defaultMessage="Loading" />
                </Loader>
              </Dimmer>
              <section
                id="content-core"
                className={`layout-${this.state.layout} search-results`}
              >
                <Card.Group
                  stackable
                  centered
                  itemsPerRow={4}
                  style={{ marginBottom: '44px' }}
                >
                  {this.props.items.map((item) => (
                    <Card
                      key={item['@id']}
                      as={Link}
                      to={this.getItemURL(item)}
                      className={item['@type']}
                    >
                      {this.getImage(item)}
                      <Card.Content>
                        <div className="underlined-title">
                          {item.portal_type ? (
                            <span>{this.typeLiteralMap(item.portal_type)}</span>
                          ) : (
                            <span>{this.typeLiteralMap(item['@type'])}</span>
                          )}
                        </div>
                        <Card.Header>
                          {item.extras.display_rezeptcode ? (
                            <b>{item.extras.display_rezeptcode} </b>
                          ) : (
                            ''
                          )}
                          {item.name || item.title}
                        </Card.Header>
                        <div>
                          <img
                            style={{
                              position: 'absolute',
                              bottom: '0',
                              right: '0',
                            }}
                            src={smallBlueButton}
                            alt="Arrow"
                          />
                        </div>
                      </Card.Content>
                    </Card>
                  ))}
                </Card.Group>

                {this.props.batching &&
                  this.props.total / settings.defaultPageSize > 1 && (
                    <div className="search-footer">
                      <Pagination
                        activePage={this.state.currentPage}
                        totalPages={Math.ceil(
                          this.props.total / settings.defaultPageSize,
                        )}
                        onPageChange={this.handleQueryPaginationChange}
                        firstItem={null}
                        lastItem={null}
                        prevItem={{
                          content: (
                            <Icon name={paginationLeftSVG} size="18px" />
                          ),
                          icon: true,
                          'aria-disabled': !this.props.batching.prev,
                          className: !this.props.batching.prev
                            ? 'disabled'
                            : null,
                        }}
                        nextItem={{
                          content: (
                            <Icon name={paginationRightSVG} size="18px" />
                          ),
                          icon: true,
                          'aria-disabled': !this.props.batching.next,
                          className: !this.props.batching.next
                            ? 'disabled'
                            : null,
                        }}
                      />
                    </div>
                  )}
              </section>
            </div>
          </article>
        </Container>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </Segment>
    );
  }
}

// The xxxWithDefault functions are only to support the legacy search component.
const searchActionWithDefault = (searchAction) =>
  searchAction !== undefined ? searchAction : searchContent;
const getSearchReducerWithDefault = (state, { getSearchReducer }) =>
  getSearchReducer !== undefined ? getSearchReducer(state) : state.search;
const contentTypeSearchResultViewsWithDefault = (
  contentTypeSearchResultViews,
) =>
  contentTypeSearchResultViews !== undefined
    ? contentTypeSearchResultViews
    : {};
const contentTypeSearchResultDefaultViewWithDefault = (
  contentTypeSearchResultDefaultView,
) =>
  contentTypeSearchResultDefaultView !== undefined
    ? contentTypeSearchResultDefaultView
    : DefaultResultItem;

export const __test__ = connect(
  (state, props) => {
    const {
      items,
      total,
      suggestions,
      loaded,
      loading,
      batching,
      searchTerm,
    } = getSearchReducerWithDefault(state, props);
    return {
      items,
      total,
      suggestions,
      loaded,
      loading,
      batching,
      searchTerm,
      intl: state.intl,
      pathname: props.history.location.pathname,
      contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
        props.contentTypeSearchResultViews,
      ),
      contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
        props.contentTypeSearchResultDefaultView,
      ),
    };
  },
  (dispatch, { searchAction }) => ({
    searchContent: (...args) =>
      dispatch(searchActionWithDefault(searchAction)(...args)),
  }),
)(SolrSearch);

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      const {
        items,
        facetGroups,
        facetFields,
        layouts,
        total,
        suggestions,
        loaded,
        loading,
        batching,
        searchTerm,
      } = getSearchReducerWithDefault(state, props);
      return {
        items,
        facetGroups,
        facetFields,
        layouts,
        total,
        suggestions,
        loaded,
        loading,
        batching,
        searchTerm,
        intl: state.intl,
        pathname: props.history.location.pathname,
        contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
          props.contentTypeSearchResultViews,
        ),
        contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
          props.contentTypeSearchResultDefaultView,
        ),
      };
    },
    (dispatch, { searchAction }) => ({
      searchContent: (...args) => {
        dispatch(searchActionWithDefault(searchAction)(...args));
      },
    }),
  ),
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch }, searchAction }) =>
        dispatch(
          searchActionWithDefault(searchAction)('', {
            ...qs.parse(location.search),
            path_prefix: getPathPrefix(window.location),
            use_site_search_settings: 1,
            metadata_fields: ['effective', 'UID', 'start'],
            hl: 'true',
            portal_type: ['Page'],
          }),
        ),
    },
  ]),
)(SolrSearch);
