import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';
import { find } from 'lodash';
import { Helmet } from '@plone/volto/helpers';

import {
  Button,
  Card,
  Container,
  Dropdown,
  Icon as OldIcon,
  Image,
  Pagination,
  Segment,
} from 'semantic-ui-react';
import {
  Actions,
  Display,
  Types,
  Workflow,
  Icon,
  Toolbar,
} from '@plone/volto/components';
import { listActions } from '@plone/volto/actions';
import { getBaseUrl } from '@plone/volto/helpers';

import { MixedTitle } from '..';
import { getAllNewsItems, getNewsItemsPage } from '../../actions';

import arrowSVG from '../../icons/arrow.svg';
import paginationLeftSVG from '../../icons/pagination-left.svg';
import paginationRightSVG from '../../icons/pagination-right.svg';

import config from '@plone/volto/registry';

/**
 *
 *
 * @class Aktuelles
 * @extends {Component}
 */
class Aktuelles extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    pathname: PropTypes.string.isRequired,
    listActions: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    getAllNewsItems: PropTypes.func.isRequired,
    getNewsItemsPage: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired,
    itemsTotal: PropTypes.number.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }).isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    actions: null,
  };

  state = {
    currentPage: 1,
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getAllNewsItems();
    this.props.listActions('/');
  }

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState(() => ({ currentPage: activePage }));
    this.props.getNewsItemsPage(activePage);
  };

  render() {
    const path = getBaseUrl(this.props.pathname);
    const editAction = find(this.props.actions.object, { id: 'edit' });
    const folderContentsAction = find(this.props.actions.object, {
      id: 'folderContents',
    });
    const historyAction = find(this.props.actions.object, { id: 'history' });
    const sharingAction = find(this.props.actions.object, {
      id: 'local_roles',
    });

    return (
      this.props.loaded && (
        <Segment
          basic
          as="section"
          className="aktuelles-view"
          style={{ marginBottom: '100px' }}
        >
          <Helmet title="Zeelandia - Aktuelles" />
          <Container>
            <h1>{this.props.content.title}</h1>
            <p>{this.props.content.description}</p>
            <Card.Group
              stackable
              centered
              itemsPerRow={3}
              style={{ marginBottom: '24px' }}
            >
              {this.props.items.map((item) => (
                <Card key={item['@id']} as={Link} to={item.url}>
                  {item.preview_image ? (
                    <Image src={item.preview_image.download} />
                  ) : (
                    item.image && <Image src={item.image.download} />
                  )}
                  <Card.Content>
                    <Card.Header>{item.title}</Card.Header>
                    <Card.Description>{item.description}</Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{ textAlign: 'right' }}>
                      <Button className="small brandButtonSecondary ">
                        <MixedTitle>Mehr lesen</MixedTitle>
                        <Icon name={arrowSVG} size="17px" className="right" />
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
            <div style={{ textAlign: 'center' }}>
              <Pagination
                activePage={this.state.currentPage}
                totalPages={Math.ceil(
                  this.props.itemsTotal /
                    config.settings.DEFAULT_NEWS_PAGING_SIZE,
                )}
                onPageChange={this.handlePaginationChange}
                firstItem={null}
                lastItem={null}
                prevItem={
                  this.props.batching.prev
                    ? {
                        content: <Icon name={paginationLeftSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
                nextItem={
                  this.props.batching.next
                    ? {
                        content: <Icon name={paginationRightSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
              />
            </div>
          </Container>
          <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              inner={
                <div>
                  {editAction && (
                    <Link
                      to={`${path}/edit`}
                      id="toolbar-edit"
                      className="item"
                    >
                      <OldIcon
                        name="write"
                        size="big"
                        color="blue"
                        title={editAction.title}
                      />
                    </Link>
                  )}
                  {this.props.content &&
                    this.props.content.is_folderish &&
                    folderContentsAction && (
                      <Link
                        to={`${path}/contents`.replace(/\/\//g, '/')}
                        id="toolbar-folder-contents"
                        className="item"
                      >
                        <OldIcon
                          name="folder open"
                          size="big"
                          title={folderContentsAction.title}
                        />
                      </Link>
                    )}
                  <Types pathname={path} />

                  <Dropdown
                    id="toolbar-more"
                    item
                    trigger={<OldIcon name="ellipsis horizontal" size="big" />}
                  >
                    <Dropdown.Menu>
                      <Workflow pathname={path} />
                      {this.state.hasObjectButtons && (
                        <Actions pathname={path} />
                      )}
                      {editAction && <Display pathname={path} />}
                      {historyAction && (
                        <Link
                          to={`${path}/history`}
                          id="toolbar-history"
                          className="item"
                        >
                          <OldIcon name="clock" size="big" />{' '}
                          {historyAction.title}
                        </Link>
                      )}
                      {sharingAction && (
                        <Link
                          to={`${path}/sharing`}
                          id="toolbar-sharing"
                          className="item"
                        >
                          <OldIcon name="share" size="big" />{' '}
                          {sharingAction.title}
                        </Link>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    id="toolbar-personal"
                    className="personal-bar"
                    item
                    upward
                    trigger={<OldIcon name="user" size="big" />}
                  >
                    <Dropdown.Menu>
                      {this.props.actions.user &&
                        this.props.actions.user.map((item) => {
                          switch (item.id) {
                            case 'preferences':
                              return (
                                <Link
                                  key={item.id}
                                  to="/personal-preferences"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="setting" /> {item.title}
                                  </span>
                                </Link>
                              );

                            case 'plone_setup':
                              return (
                                <Link
                                  key={item.id}
                                  to="/controlpanel"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="settings" /> {item.title}
                                  </span>
                                </Link>
                              );

                            case 'logout':
                              return (
                                <Link
                                  key={item.id}
                                  to="/logout"
                                  id="toolbar-logout"
                                  className="item"
                                >
                                  <span>
                                    <OldIcon name="sign out" /> {item.title}
                                  </span>
                                </Link>
                              );
                            default: {
                              return null;
                            }
                          }
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              }
            />
          </Portal>
        </Segment>
      )
    );
  }
}

export default connect(
  (state, props) => ({
    actions: state.actions.actions,
    pathname: props.location.pathname,
    items: state.listings.items,
    itemsTotal: state.listings.itemsTotal,
    batching: state.listings.batching,
    loaded: state.listings.loaded,
    content: state.content.data,
  }),
  {
    getAllNewsItems,
    getNewsItemsPage,
    listActions,
  },
)(Aktuelles);
