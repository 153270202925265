/**
 * Action types.
 * @module constants/ActionTypes
 */

export const GET_RECIPE = 'GET_RECIPE';
export const GET_PRODUCT = 'GET_PRODUCT';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_RECIPES = 'GET_RECIPES';

export const GET_RECIPE_CATEGORY = 'GET_RECIPE_CATEGORY';
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const RESET_RECIPE_CATEGORY = 'RESET_RECIPE_CATEGORY';
export const RESET_PRODUCT_CATEGORY = 'RESET_PRODUCT_CATEGORY';

export const GET_ALLNEWSITEMS = 'GET_ALLNEWSITEMS';
export const GET_ALLRECIPES = 'GET_ALLRECIPES';
export const GET_ALLPRODUCTS = 'GET_ALLPRODUCTS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_RECIPE_CATEGORIES = 'GET_RECIPE_CATEGORIES';

export const GET_AKTUELLES = 'GET_AKTUELLES';
export const GET_HIGHLIGHTS = 'GET_HIGHLIGHTS';
export const GET_JOBS = 'GET_JOBS';
export const GET_ONAPPLY_JOBS = 'GET_ONAPPLY_JOBS';

export const GET_CART = 'GET_CART';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';

export const POST_NEWSLETTER_REGISTER = 'POST_NEWSLETTER_REGISTER';

export const GET_ROHSTOFFE = 'GET_ROHSTOFFE';
export const GET_ROHSTOFFBYID = 'GET_ROHSTOFFBYID';
export const GET_ROHSTOFFE_NAMES = 'GET_ROHSTOFFE_NAMES';
export const CHECK_ROHSTOFFE = 'CHECK_PRODUKTE';
export const CREATE_ROHSTOFF = 'CREATE_ROHSTOFF';
export const UPDATE_ROHSTOFF = 'UPDATE_ROHSTOFF';
export const DELETE_ROHSTOFF = 'DELETE_ROHSTOFF';

export const GET_BENUTZER = 'GET_BENUTZER';
export const GET_BENUTZERBYID = 'GET_BENUTZERBYID';
export const CHECK_BENUTZER = 'CHECK_BENUTZER';
export const CREATE_BENUTZER = 'CREATE_BENUTZER';
export const UPDATE_BENUTZER = 'UPDATE_BENUTZER';
export const DELETE_BENUTZER = 'DELETE_BENUTZER';
export const GET_PLONE_VIST_USERS = 'GET_PLONE_VIST_USERS';

export const GET_PRODUKTE = 'GET_PRODUKTE';
export const GET_PRODUKTE_NAMES = 'GET_PRODUKTE_NAMES';
export const GET_PRODUKTBYID = 'GET_PRODUKTBYID';
export const CREATE_PRODUKT = 'CREATE_PRODUKT';
export const CHECK_PRODUKTE = 'CHECK_PRODUKTE';
export const UPDATE_PRODUKT = 'UPDATE_PRODUKT';
export const DELETE_PRODUKT = 'DELETE_PRODUKT';

export const GET_GEBINDEBYID = 'GET_GEBINDEBYID';
export const CREATE_GEBINDE = 'CREATE_GEBINDE';
export const UPDATE_GEBINDE = 'UPDATE_GEBINDE';
export const DELETE_GEBINDE = 'DELETE_GEBINDE';

export const GET_REZEPTE = 'GET_REZEPTE';
export const GET_DELETEDREZEPTE = 'GET_DELETEDREZEPTE';
export const GET_GRUNDREZEPTE = 'GET_GRUNDREZEPTE';
export const CHECK_REZEPTE = 'CHECK_REZEPTE';
export const GET_REZEPTE_NUTRIENTS_BY_ID = '  GET_REZEPTE_NUTRIENTS_BY_ID,';
export const GET_ROHSTOFFKOSTEN_BY_ID = '  GET_ROHSTOFFKOSTEN_BY_ID,';
export const GET_REZEPTBYID = 'GET_REZEPTBYID';
export const CREATE_REZEPT = 'CREATE_REZEPT';
export const UPDATE_REZEPT = 'UPDATE_REZEPT';
export const DELETE_REZEPT = 'DELETE_REZEPT';

export const GET_BEZUEGE = 'GET_BEZUEGE';
export const GET_BEZUGBYID = 'GET_BEZUGBYID';
export const CREATE_BEZUG = 'CREATE_BEZUG';
export const UPDATE_BEZUG = 'UPDATE_BEZUG';
export const DELETE_BEZUG = 'DELETE_BEZUG';

export const GET_ZUTATENGRUPPEN = 'GET_ZUTATENGRUPPEN';
export const GET_ZUTATENGRUPPEBYID = 'GET_ZUTATENGRUPPEBYID';
export const CREATE_ZUTATENGRUPPE = 'CREATE_ZUTATENGRUPPE';
export const UPDATE_ZUTATENGRUPPE = 'UPDATE_ZUTATENGRUPPE';
export const DELETE_ZUTATENGRUPPE = 'DELETE_ZUTATENGRUPPE';

export const GET_ZUTATEN = 'GET_ZUTATEN';
export const GET_ZUTATBYID = 'GET_ZUTATBYID';
export const CREATE_ZUTAT = 'CREATE_ZUTAT';
export const UPDATE_ZUTAT = 'UPDATE_ZUTAT';
export const DELETE_ZUTAT = 'DELETE_ZUTAT';

export const GET_VERKAUFSINFORMATIONBYID = 'GET_VERKAUFSINFORMATIONBYID,';
export const CREATE_VERKAUFSINFORMATION = 'CREATE_VERKAUFSINFORMATION,';
export const UPDATE_VERKAUFSINFORMATION = 'UPDATE_VERKAUFSINFORMATION,';

export const GET_GRUPPEN = 'GET_GRUPPEN';
export const GET_GRUPPEBYID = 'GET_GRUPPEBYID';
export const CREATE_GRUPPE = 'CREATE_GRUPPE';
export const UPDATE_GRUPPE = 'UPDATE_GRUPPE';
export const DELETE_GRUPPE = 'DELETE_GRUPPE';

export const GET_REZEPTKATEGORIEN = 'GET_REZEPTKATEGORIEN';
export const GET_REZEPTKATEGORIEBYID = 'GET_REZEPTKATEGORIEBYID';
export const CREATE_REZEPTKATEGORIE = 'CREATE_REZEPTKATEGORIE';
export const UPDATE_REZEPTKATEGORIE = 'UPDATE_REZEPTKATEGORIE';
export const DELETE_REZEPTKATEGORIE = 'DELETE_REZEPTKATEGORIE';

export const GET_REZEPTGRUPPEN = 'GET_REZEPTGRUPPEN';
export const GET_REZEPTGRUPPEBYID = 'GET_REZEPTGRUPPEBYID';
export const CREATE_REZEPTGRUPPE = 'CREATE_REZEPTGRUPPE';
export const UPDATE_REZEPTGRUPPE = 'UPDATE_REZEPTGRUPPE';
export const DELETE_REZEPTGRUPPE = 'DELETE_REZEPTGRUPPE';

export const GET_PRODUKTKATEGORIEN = 'GET_PRODUKTKATEGORIEN';
export const GET_PRODUKTKATEGORIEBYID = 'GET_PRODUKTKATEGORIEBYID';
export const CREATE_PRODUKTKATEGORIE = 'CREATE_PRODUKTKATEGORIE';
export const UPDATE_PRODUKTKATEGORIE = 'UPDATE_PRODUKTKATEGORIE';
export const DELETE_PRODUKTKATEGORIE = 'DELETE_PRODUKTKATEGORIE';

export const GET_ROHSTOFFKATEGORIEN = 'GET_ROHSTOFFKATEGORIEN,';
export const GET_ROHSTOFFKATEGORIEBYID = 'GET_ROHSTOFFKATEGORIEBYID,';
export const CREATE_ROHSTOFFKATEGORIE = 'CREATE_ROHSTOFFKATEGORIE,';
export const UPDATE_ROHSTOFFKATEGORIE = 'UPDATE_ROHSTOFFKATEGORIE,';
export const DELETE_ROHSTOFFKATEGORIE = 'DELETE_ROHSTOFFKATEGORIE,';

export const GET_VISTPERMISSIONS = 'GET_VISTPERMISSIONS';

export const SET_BATCHING_PARAMETERS = 'SET_BATCHING_PARAMETERS';
export const RESET_BATCHING_PARAMETERS = 'RESET_BATCHING_PARAMETERS';
