/**
 * Homepage highlights elements reducer.
 * @module reducers/homepage/highlights
 */
import { has } from 'lodash';
import { GET_HIGHLIGHTS } from '../../constants/ActionTypes';

import config from '@plone/volto/registry';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  content: {},
};

const cleanupUrl = (url) => {
  let newUrl = url.replace(config.settings.apiPath, '');
  newUrl = newUrl.replace('https://www.zeelandia.de', '');
  return newUrl;
};

/**
 * highlights for homepage reducer.
 * @function highlights
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function highlights(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_HIGHLIGHTS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_HIGHLIGHTS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        content: has(action, 'result')
          ? {
              ...action.result,
              // Commented out until we don't have proper widgets
              // aktuellesverkaufskonzept_item: {
              //   ...action.result.aktuellesverkaufskonzept_item,
              //   url: action.result.aktuellesverkaufskonzept_item['@id'].replace(
              //     config.settings.apiPath,
              //     '',
              //   ),
              // },

              aktuellesverkaufskonzept_item: action.result
                .aktuellesverkaufskonzept_item
                ? {
                    ...action.result.aktuellesverkaufskonzept_item,
                    url: cleanupUrl(
                      action.result.aktuellesverkaufskonzept_item,
                    ),
                  }
                : {},
            }
          : {},
      };
    case `${GET_HIGHLIGHTS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        content: {},
      };
    default:
      return state;
  }
}
