import React from 'react';
import { Form } from 'semantic-ui-react';

const GebindeForm = ({ formData, handleInputChange }) => {
  return (
    <Form>
      <div className="meta-info">
        <Form.Field>
          <label>
            <strong>6 stellige Artikelnummer (*):</strong>
          </label>
          <input
            type="text"
            name="artikelnummer"
            minLength={6}
            maxLength={6}
            pattern="[0-9]*" // Allow only numeric values
            value={formData.artikelnummer}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>
            <strong>Gebindeart (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            list="gebinde-suggestions"
            value={formData.name}
            onChange={handleInputChange}
            required
            autoComplete="off"
          />
          <datalist id="gebinde-suggestions">
            <option value="Big Bag, 550 kg" />
            <option value="Big Bag, 600 kg" />
            <option value="Papiersack, 25 kg" />
            <option value="Papiersack, 15 kg" />
            <option value="Papiersack, 12,5 kg" />
            <option value="Papiersack, 10 kg" />
            <option value="Karton, 2,5 kg" />
            <option value="Karton, 5 kg" />
            <option value="Karton, 15 kg (3 x 5 kg Beutel)" />
            <option value="Stange, 15 kg" />
            <option value="Eimer, 6 kg" />
            <option value="Eimer, 12 kg" />
            <option value="Eimer, 12, 5 kg" />
            <option value="Eimer, 25 kg" />
            <option value="Karton, 3,6 L" />
            <option value="Kanister, 5 L " />
            <option value="Kanister, 15 L " />
            <option value="Container, 1000 L" />
          </datalist>
        </Form.Field>
        <Form.Field>
          <label>Zugabe in %:</label>
          <input
            type="text"
            name="zugabe"
            value={formData.zugabe}
            onChange={handleInputChange}
          />
        </Form.Field>
        <p className="disclaimer">* Pflichtfeld</p>
      </div>
    </Form>
  );
};

export default GebindeForm;
