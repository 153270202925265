/* eslint-disable no-unused-expressions */
/**
 * Search actions.
 * @module actions/search/search
 */

import { head, join, map, pickBy, toPairs } from 'lodash';

import {
  RESET_SEARCH_CONTENT,
  SEARCH_CONTENT,
} from '@plone/volto/constants/ActionTypes';

import config from '@plone/volto/registry';

/**
 * Search content function.
 * @function searchContent
 * @param {string} url Url to use as base.
 * @param {Object} options Search options.
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function searchContent(url, options, subrequest = null) {
  const { DEFAULT_PAGING_SIZE } = config.settings;
  const wordRe = /[\u00C0-\u017F\w]+/g;
  if (options && options.SearchableText) {
    let splitted = options.SearchableText.match(wordRe);
    options.SearchableText = join(map(splitted), ' ');
  }

  let queryString = [];

  const simpleQueryString = options
    ? join(
        map(
          toPairs(
            pickBy(
              options,
              (item, key) =>
                key !== 'subjects' &&
                key !== 'portal_type' &&
                key !== 'sorting',
            ),
          ),
          (item) => join(item, '='),
        ),
        '&',
      )
    : '';

  simpleQueryString
    ? (queryString = queryString.concat(simpleQueryString))
    : '';

  const typesQueryString = options
    ? join(
        head(
          map(
            pickBy(options, (item, key) => key === 'portal_type'),
            (item, key) => item.map((subject) => `portal_type:list=${subject}`),
          ),
        ),
        '&',
      )
    : '';

  typesQueryString ? (queryString = queryString.concat(typesQueryString)) : '';

  queryString = join(queryString, '&');

  return {
    type: SEARCH_CONTENT,
    subrequest,
    request: {
      op: 'get',
      path: `${url}/@search?b_size=${DEFAULT_PAGING_SIZE}${
        queryString ? `&${queryString}` : ''
      }`,
    },
  };
}

/**
 * Search content function.
 * @function searchContentPaged
 * @param {string} url Url to use as base.
 * @param {Object} options Search options.
 * @param {Object} page Search page.
 * @returns {Object} Search content action.
 */
export function searchContentPaged(url, options, page) {
  const { DEFAULT_PAGING_SIZE } = config.settings;
  const wordRe = /[\u00C0-\u017F\w]+/g;
  if (options && options.SearchableText) {
    let splitted = options.SearchableText.match(wordRe);
    options.SearchableText = join(map(splitted), ' ');
  }
  let queryString = [];

  const simpleQueryString = options
    ? join(
        map(
          toPairs(
            pickBy(
              options,
              (item, key) =>
                key !== 'subjects' &&
                key !== 'portal_type' &&
                key !== 'sorting',
            ),
          ),
          (item) => join(item, '='),
        ),
        '&',
      )
    : '';

  simpleQueryString
    ? (queryString = queryString.concat(simpleQueryString))
    : '';

  const typesQueryString = options
    ? join(
        head(
          map(
            pickBy(options, (item, key) => key === 'portal_type'),
            (item, key) => item.map((subject) => `portal_type:list=${subject}`),
          ),
        ),
        '&',
      )
    : '';

  typesQueryString ? (queryString = queryString.concat(typesQueryString)) : '';

  queryString = join(queryString, '&');
  const currentPage = DEFAULT_PAGING_SIZE * (page - 1);

  return {
    type: SEARCH_CONTENT,
    request: {
      op: 'get',
      path: `${url}/@search?b_start=${currentPage}&b_size=${DEFAULT_PAGING_SIZE}${
        queryString ? `&${queryString}` : ''
      }`,
    },
  };
}

/**
 * Reset search content function.
 * @function resetSearchContent
 * @returns {Object} Search content action.
 */
export function resetSearchContent(subrequest = null) {
  return {
    type: RESET_SEARCH_CONTENT,
    subrequest,
  };
}
