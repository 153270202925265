import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const ProduktkategorieForm = ({
  formData,
  handleInputChange,
  isProduktkategorieNameDuplicate,
  kategorien = [],
}) => {
  // Filter out the item with the same "id" as formData.id
  const filteredKategorien = kategorien.filter(
    (kategorie) =>
      kategorie.id !== formData.id && kategorie.pid !== formData.id,
  );

  // Add the additional item at the beginning of the filteredKategorien array
  const kategorienWithPlaceholder = [
    { id: 0, name: '- 1. ebene -' },
    ...filteredKategorien,
  ];

  const renderOptions = (option) => {
    const level = option.level || 0;
    const indent = `${level * 1}em`; // You can adjust the indentation here

    const style = {
      fontWeight: level === 0 ? 'bold' : 'normal',
      'padding-left': indent,
    };

    return <span style={style}>{option.name}</span>;
  };

  const dropdownOptions = kategorienWithPlaceholder.map((kategorie) => ({
    key: kategorie.id,
    text: kategorie.name,
    value: kategorie.id,
    level: kategorie.level || 0,
    content: renderOptions(kategorie),
  }));

  return (
    <Form>
      <div className="bezug-info">
        <Form.Field error={isProduktkategorieNameDuplicate}>
          <label>
            <strong>Name (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isProduktkategorieNameDuplicate && (
          <div className="speech-bubble warning">
            Produktkategorie mit diesem Namen existiert bereits
          </div>
        )}

        <Form.Field>
          <label>
            <strong>Kategorie:</strong>
          </label>
          <Dropdown
            placeholder="- 1. Ebene -"
            name="pid"
            fluid
            selection
            options={dropdownOptions}
            value={formData.pid}
            onChange={(e, { value }) => {
              handleInputChange({ target: { name: 'pid', value } });
            }}
            scrolling
          />
        </Form.Field>
        <p className="disclaimer">* Pflichtfeld</p>
      </div>
    </Form>
  );
};

export default ProduktkategorieForm;
