import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const sanitizeHerstellung = (text) => {
  let lines = text.replace('<p>', '').replace('</p>', '').split('<br />');
  lines = lines.map((line) => line.replace(/^[ ]*-/g, ''));
  lines = lines.filter((line) => line.trim() !== '');
  return lines;
};

const Herstellung = ({ rezepte }) => (
  <div className="rezepte-herstellung">
    <Grid stackable>
      <Grid.Column width={6}>
        <h3>Herstellung</h3>
        <ul className="squared-list">
          {sanitizeHerstellung(rezepte.herstellungstext).map((item) => (
            <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ul>
      </Grid.Column>
      <Grid.Column width={6}>
        <h3>Herstellungsparameter</h3>
        <table>
          {rezepte.data.herstellungsparameter.map((item) => (
            <tr key={item.key}>
              <td>{item.title}:</td>
              <td>{item.value}</td>
            </tr>
          ))}
        </table>
      </Grid.Column>
    </Grid>
  </div>
);

Herstellung.propTypes = {
  rezepte: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default Herstellung;
