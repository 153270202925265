import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some, isEqual } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateProdukt,
  getProduktByID,
  deleteProdukt,
  checkProdukte,
  updateGebinde,
  deleteGebinde,
  createGebinde,
  getProduktkategorien,
  getVistPermissions,
} from '../../../actions';

import { UnsavedChangesWarning, convertNumbersToStrings } from 'helpers';

import ButtonArea from '../ButtonArea';

import ProduktForm from './ProduktForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const ProduktEdit = ({
  pathname,
  produkt,
  getProduktByID,
  checkProdukte,
  updateProdukt,
  deleteProdukt,
  createGebinde,
  updateGebinde,
  deleteGebinde,
  getProduktkategorien,
  produktkategorien,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const [formData, setFormData] = useState({
    produktnummer: '',
    bezeichnung: '',
    rezeptnummer: '',
    intern_extern: '',
    brennwert_kj: '',
    brennwert_kcal: '',
    eiweiss: '',
    kohlenhydrate: '',
    zucker: '',
    fett: '',
    ges_fettsaeuren: '',
    ballaststoffe: '',
    natrium: '',
    staerke: '',
    produktbeschreibung_lang: '',
    produktbeschreibung_kurz: '',
    basis_euro: '',
    zusatzinformationen: '',
    is_import: 0,
    image_uuid: '',
    status: 1,
    aktuell: null,
    kategorien: [],
    image: {},
  });
  const [initialFormData, setInitialFormData] = useState({});
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isProduktnummerDuplicate, setIsProduktnummerDuplicate] = useState(
    false,
  );
  const [isBezeichnungDuplicate, setIsBezeichnungDuplicate] = useState(false);
  const [isIncompleteGebinde, setIsIncompleteGebinde] = useState(false);
  const [backURL, setBackURL] = useState(null);
  const isSubmitDisabled =
    isProduktnummerDuplicate ||
    isBezeichnungDuplicate ||
    formData.produktnummer === '' ||
    formData.bezeichnung === '' ||
    isIncompleteGebinde;

  const [gebindeList, setGebindeList] = useState([]);
  const [gebindeToDelete, setGebindeToDelete] = useState([]);
  const [produktNummerSuggestions, setProduktNummerSuggestions] = useState([]);
  const [modified, setModified] = useState(false);

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 4, 6];
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    getProduktByID(id);
    getProduktkategorien({ getAll: true });
    setBackURL(`/controlpanel/rezeptdatenbank/produkte/details?id=${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProduktByID, getProduktkategorien]);

  useEffect(() => {
    if (produkt?.gebinde?.length > 0) {
      setGebindeList(produkt.gebinde);
    }
  }, [produkt]);

  useEffect(() => {
    if (produkt) {
      const produktdata = {
        produktnummer: produkt.produktnummer,
        bezeichnung: produkt.bezeichnung,
        user_id: produkt.user_id,
        rezeptnummer: produkt.rezeptnummer,
        intern_extern: produkt.intern_extern,
        brennwert_kj: produkt.brennwert_kj,
        brennwert_kcal: produkt.brennwert_kcal,
        eiweiss: produkt.eiweiss,
        kohlenhydrate: produkt.kohlenhydrate,
        zucker: produkt.zucker,
        fett: produkt.fett,
        ges_fettsaeuren: produkt.ges_fettsaeuren,
        ballaststoffe: produkt.ballaststoffe,
        natrium: produkt.natrium,
        staerke: produkt.staerke,
        produktbeschreibung_lang:
          produkt.produktbeschreibung_lang || '<p class="tile text"></p>',
        produktbeschreibung_kurz: produkt.produktbeschreibung_kurz,
        basis_euro: produkt.basis_euro,
        zusatzinformationen: produkt.zusatzinformationen,
        is_import: produkt.is_import,
        image_uuid: produkt.image_uuid,
        status: produkt.status,
        aktuell: produkt.aktuell,
        kategorien: produkt.kategorien,
        image: produkt.image,
      };

      setFormData(produktdata);
      setInitialFormData(convertNumbersToStrings(produktdata));
    }
  }, [produkt]);

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (!isEqual(convertNumbersToStrings(formData), initialFormData)) {
      setModified(true);
    } else {
      setModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'intern_extern') {
      // For intern/extern field, convert value to a boolean
      const isIntern = value === 'intern';
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: isIntern,
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if (name === 'produktnummer') {
      checkProdukte(name, value)
        .then((result) => {
          if (
            result.value_exists === true &&
            value !== produkt.produktnummer.toString()
          ) {
            setIsProduktnummerDuplicate(true);
          } else {
            setIsProduktnummerDuplicate(false);
          }
          if (result.suggested_values) {
            setProduktNummerSuggestions(result.suggested_values);
          } else {
            setProduktNummerSuggestions([]);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }

    if (name === 'bezeichnung' && value !== produkt.bezeichnung) {
      checkProdukte(name, value)
        .then((result) => {
          if (result.value_exists === true) {
            setIsBezeichnungDuplicate(true);
          } else {
            setIsBezeichnungDuplicate(false);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const handleSubmit = async (e) => {
    setModified(false);
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await updateProdukt(id, formData);

      // update & add gebinde
      gebindeList.forEach((gebinde_data) => {
        if (gebinde_data.id) {
          updateGebinde(gebinde_data.id, gebinde_data).catch((error) => {
            setError(
              'es ist etwas schiefgelaufen, Gebinde wurde nicht angepasst: ' +
                error.name +
                ' ' +
                error.message,
            );
          });
        } else {
          gebinde_data.produkt_id = id;
          createGebinde(gebinde_data).catch((error) => {
            setError(
              'es ist etwas schiefgelaufen, Gebinde wurde nicht angelegt: ' +
                error.name +
                ' ' +
                error.message,
            );
          });
        }
      });

      // delete removed gebinde
      gebindeToDelete.forEach((gebinde_id) => {
        deleteGebinde(gebinde_id).catch((error) => {
          setError(
            'es ist etwas schiefgelaufen, Gebinde wurde nicht gelöscht: ' +
              error.name +
              ' ' +
              error.message,
          );
        });
      });

      history.push(`/controlpanel/rezeptdatenbank/produkte/details?id=${id}`);
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error);
    }
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteProdukt(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error);
    }
  };

  // GEBINDE:
  const addGebinde = (e) => {
    e.preventDefault();
    setGebindeList([
      ...gebindeList,
      {
        name: '',
        artikelnummer: '',
        zugabe: '',
      },
    ]);
  };

  const removeGebinde = (e, index) => {
    e.preventDefault();
    const removedGebinde = gebindeList[index];
    if (removedGebinde.id) {
      setGebindeToDelete([...gebindeToDelete, removedGebinde.id]);
    }
    const updatedGebinde = gebindeList.filter((_, i) => i !== index);
    setGebindeList(updatedGebinde);
  };

  const handleGebindeChange = (e, index) => {
    const { name, value } = e.target;
    const updatedGebindeList = [...gebindeList];
    const updatedGebinde = { ...updatedGebindeList[index] };
    if (name === 'artikelnummer') {
      updatedGebinde[name] = value.replace(/\D/g, '');
    } else {
      updatedGebinde[name] = value;
    }
    updatedGebindeList[index] = updatedGebinde;
    setGebindeList(updatedGebindeList);
  };

  // check if any gebinde have empty required fields
  useEffect(() => {
    const hasIncompleteGebinde = gebindeList.some((gebindeItem) => {
      return gebindeItem.artikelnummer === '' || gebindeItem.name === '';
    });

    setIsIncompleteGebinde(hasIncompleteGebinde);
  }, [gebindeList]);

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben das Produkt bearbeitet. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Helmet title="Produkt Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {produkt && produkt.bezeichnung} Bearbeiten </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={backURL}
                  showDeleteButton={showDeleteButton}
                  typeString={'das Produkt'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Produkt wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/produkte"
                >
                  Zurück zur Produkte-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                <div className="rdb edit form produkte">
                  <ProduktForm
                    formData={formData}
                    isBezeichnungDuplicate={isBezeichnungDuplicate}
                    isProduktnummerDuplicate={isProduktnummerDuplicate}
                    handleInputChange={handleInputChange}
                    gebinde={gebindeList}
                    isIncompleteGebinde={isIncompleteGebinde}
                    addGebinde={addGebinde}
                    removeGebinde={removeGebinde}
                    handleGebindeChange={handleGebindeChange}
                    handleKategorienChange={handleKategorienChange}
                    selectedKategorien={formData.kategorien}
                    kategorien={produktkategorien}
                    produknummer_suggestions={produktNummerSuggestions}
                  />
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

ProduktEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  produkt: PropTypes.object,
  getProduktByID: PropTypes.func.isRequired,
  checkProdukte: PropTypes.func.isRequired,
  updateProdukt: PropTypes.func.isRequired,
  deleteProdukt: PropTypes.func.isRequired,
  getGebindeByID: PropTypes.func.isRequired,
  updateGebinde: PropTypes.func.isRequired,
  deleteGebinde: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  getProduktkategorien: PropTypes.func.isRequired,
  produktkategorien: PropTypes.array.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      produkt: state.produktByID.data,
      produktkategorien: state.produktkategorien.data.produktkategorien,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      updateProdukt,
      getProduktByID,
      deleteProdukt,
      checkProdukte,
      createGebinde,
      updateGebinde,
      deleteGebinde,
      getProduktkategorien,
      getVistPermissions,
    },
  ),
)(ProduktEdit);
