import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateVerkaufsinformation,
  getVerkaufsinformationByID,
  getVistPermissions,
} from '../../../actions';
import VerkaufsinformationForm from './VerkaufsinformationForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const VerkaufsinformationEdit = ({
  pathname,
  verkaufsinformation,
  updateVerkaufsinformation,
  getVerkaufsinformationByID,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    rezepte_id: 0,
    deleted: 0,
    hauptbestandteile: '',
    merkmale: '',
    lagerung: '',
    verzehrtipps: '',
    hinweise: '',
  });

  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [backURL, setBackURL] = useState(null);

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 15, 2, 4, 6];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    getVerkaufsinformationByID(id);
    setBackURL(`/controlpanel/rezeptdatenbank/rezepte/details?id=${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVerkaufsinformationByID]);

  useEffect(() => {
    if (verkaufsinformation) {
      setFormData({
        rezepte_id: verkaufsinformation.rezepte_id,
        user_id: verkaufsinformation.user_id,
        deleted: verkaufsinformation.deleted,
        hauptbestandteile: verkaufsinformation.hauptbestandteile,
        merkmale: verkaufsinformation.merkmale,
        lagerung: verkaufsinformation.lagerung,
        verzehrtipps: verkaufsinformation.verzehrtipps,
        hinweise: verkaufsinformation.hinweise,
      });
    }
  }, [verkaufsinformation]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await updateVerkaufsinformation(id, formData);
      history.push(
        `/controlpanel/rezeptdatenbank/rezepte/details?id=${formData.rezepte_id}`,
      );
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error);
    }
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper rbd add">
      <Helmet title="Verkaufsinformation Bearbeiten" />
      <Container className="controlpanel rezeptdatenbank verkaufsinformation">
        <Segment.Group raised>
          <Segment className="primary">
            {verkaufsinformation && (
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>
                    Verkaufsinformation Bearbeiten: {verkaufsinformation.name}
                  </h1>
                  <h2>{verkaufsinformation.verkehrsbezeichnung}</h2>{' '}
                </div>
                <div className="button-container">
                  <div className="buttonArea">
                    <Button primary onClick={() => history.push(backURL)}>
                      Abbrechen
                    </Button>
                    <Button primary type="submit" onClick={handleSubmit}>
                      Speichern
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
            <>
              <div className="rdb edit form verkaufsinformation">
                <VerkaufsinformationForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                />
              </div>
            </>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

VerkaufsinformationEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  verkaufsinformation: PropTypes.object,
  getVerkaufsinformationByID: PropTypes.func.isRequired,
  updateVerkaufsinformation: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      verkaufsinformation: state.verkaufsinformationByID.data,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      updateVerkaufsinformation,
      getVerkaufsinformationByID,
      getVistPermissions,
    },
  ),
)(VerkaufsinformationEdit);
