/**
 * Produkt actions.
 * @module actions/Produkt
 */

import { GET_PRODUCTS } from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProducts
 * @param {array} ids Produkt ids
 * @returns {Object} Produkt action.
 */
export default function getProducts(ids, subrequest = null) {
  return {
    type: GET_PRODUCTS,
    subrequest,
    request: {
      op: 'get',
      path: `/@products?items=${ids}`,
    },
  };
}
