/**
 * View Passende Produkte component.
 * @module components/Blocks/PassendeProdukte/View
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chunk, map } from 'lodash';
import { Button, Card, Grid, Item } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';

import { MixedTitle } from '../../.';
import { getProductCategory } from '../../../actions';
import missingIMG from '../../../static/missing.jpg';
import arrowSVG from '../../../icons/arrow.svg';

/**
 * Breadcrumbs container class.
 * @class Breadcrumbs
 * @extends Component
 */
class Breadcrumbs extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getProductCategory: PropTypes.func.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.object,
      }),
    ).isRequired,
  };

  state = { showAll: false };

  onShowAll = () => {
    this.setState({
      showAll: true,
    });
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    this.props.getProductCategory(this.props.data.category);
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const items = this.state.showAll
      ? this.props.items
      : this.props.items.slice(0, 8);
    let itemPairs = chunk(items, 2);

    return (
      <div className="block tile passende-produkte">
        <div className="passende-produkte-listing">
          <h2>Passende Produkte</h2>
          <Grid columns={2} stackable={true}>
            {map(itemPairs, (itemPair) => (
              <Grid.Row>
                {map(itemPair, (item) => (
                  <Grid.Column>
                    <Item.Group unstackable={true}>
                      <Item>
                        <Item.Image
                          src={
                            (item.image &&
                              `${item.image['@id']}/@@images/image/mini`) ||
                            missingIMG
                          }
                          alt={item.name}
                        />
                        <Item.Content>
                          <Item.Header>
                            <Link to={`/alle-produkte/${item.id}`}>
                              {item.name}
                            </Link>
                          </Item.Header>
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Grid.Column>
                ))}
              </Grid.Row>
            ))}
          </Grid>
          {this.props.items.length > 8 && this.state.showAll === false && (
            <Grid>
              <Grid.Row>
                <Grid.Column stretched={true}>
                  <Card.Group stackable centered itemsPerRow={2}>
                    <Card
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '155px',
                      }}
                    >
                      <Button
                        className="small brandButtonSecondary"
                        onClick={this.onShowAll}
                      >
                        <MixedTitle reverse>alle Produkte anzeigen</MixedTitle>
                        <Icon name={arrowSVG} size="17px" className="right" />
                      </Button>
                    </Card>
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    items: state.productcategory.items,
  }),
  { getProductCategory },
)(Breadcrumbs);
