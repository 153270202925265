import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Button, Image, Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '..';
import { getHighlights } from '../../actions';

import arrowSVG from '../../icons/arrow.svg';

class Highlights extends Component {
  static propTypes = {
    highlights: PropTypes.shape({
      aktuellesverkaufskonzept_image: PropTypes.shape({
        download: PropTypes.string,
      }),
      rezeptedesmonats_image: PropTypes.shape({
        download: PropTypes.string,
      }),
      rezeptedesmonats_title: PropTypes.string,
      rezeptedesmonats_title_bold: PropTypes.string,
      rezeptedesmonats_item: PropTypes.string,
      produktneuheit_image: PropTypes.shape({
        download: PropTypes.string,
      }),
      produktneuheit_title: PropTypes.string,
      produktneuheit_title_bold: PropTypes.string,
      produktneuheit_item: PropTypes.string,
      aktuellesverkaufskonzept_title: PropTypes.string,
      aktuellesverkaufskonzept_title_bold: PropTypes.string,
      aktuellesverkaufskonzept_item: PropTypes.object,
    }).isRequired,
    loaded: PropTypes.bool.isRequired,
    getHighlights: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getHighlights();
  }

  render() {
    return (
      this.props.loaded && (
        <Segment basic padded as="section" className="highlights">
          <Card.Group stackable centered itemsPerRow={3}>
            <Card>
              {this.props.highlights.rezeptedesmonats_image && (
                <Image
                  src={this.props.highlights.rezeptedesmonats_image.download}
                />
              )}
              <Card.Content>
                <div className="underlined-title">
                  <span>Rezept des Monats</span>
                </div>
                <Card.Header>
                  <MixedTitle
                    light={this.props.highlights.rezeptedesmonats_title}
                    strong={this.props.highlights.rezeptedesmonats_title_bold}
                    breakline
                  />
                </Card.Header>
              </Card.Content>
              <Card.Content extra>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    as={Link}
                    to={`/alle-rezepte/${this.props.highlights.rezeptedesmonats_item}`}
                    className="medium brandButtonPrimary"
                  >
                    <MixedTitle>Mehr lesen</MixedTitle>
                    <Icon name={arrowSVG} size="23px" className="right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
            <Card>
              {this.props.highlights.produktneuheit_image && (
                <Image
                  src={this.props.highlights.produktneuheit_image.download}
                />
              )}
              <Card.Content>
                <div className="underlined-title">
                  <span>Produktneuheit</span>
                </div>
                <Card.Header>
                  <MixedTitle
                    light={this.props.highlights.produktneuheit_title}
                    strong={this.props.highlights.produktneuheit_title_bold}
                    breakline
                  />
                </Card.Header>
              </Card.Content>
              <Card.Content extra>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    as={Link}
                    to={`/alle-produkte/${this.props.highlights.produktneuheit_item}`}
                    className="medium brandButtonPrimary"
                  >
                    <MixedTitle>Mehr lesen</MixedTitle>
                    <Icon name={arrowSVG} size="23px" className="right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
            <Card>
              {this.props.highlights.aktuellesverkaufskonzept_image && (
                <Image
                  src={
                    this.props.highlights.aktuellesverkaufskonzept_image
                      .download
                  }
                />
              )}
              <Card.Content>
                <div className="underlined-title">
                  <span>Aktuelles Verkaufskonzept</span>
                </div>
                <Card.Header>
                  <MixedTitle
                    light={this.props.highlights.aktuellesverkaufskonzept_title}
                    strong={
                      this.props.highlights.aktuellesverkaufskonzept_title_bold
                    }
                    breakline
                  />
                </Card.Header>
              </Card.Content>
              <Card.Content extra>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    as={Link}
                    to={this.props.highlights.aktuellesverkaufskonzept_item.url}
                    className="medium brandButtonPrimary"
                  >
                    <MixedTitle>Mehr lesen</MixedTitle>
                    <Icon name={arrowSVG} size="23px" className="right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      )
    );
  }
}

export default connect(
  (state) => ({
    highlights: state.highlights.content,
    loaded: state.highlights.loaded,
  }),
  {
    getHighlights,
  },
)(Highlights);
