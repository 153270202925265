/**
 * Homepage sprachmodule actions.
 * @module actions/homepage/highlights
 */

import { GET_HIGHLIGHTS } from '../../constants/ActionTypes';

/**
 * Get Homepage highlights items.
 * @function getHighlights
 * @returns {Object} Homepage highlights action.
 */
export default function getHighlights() {
  return {
    type: GET_HIGHLIGHTS,
    request: {
      op: 'get',
      path: '/startseite',
    },
  };
}
