import PropTypes from 'prop-types';

const { bool, number, string, oneOf, shape, any, arrayOf } = PropTypes;

export const RecipeIngredientShape = shape({
  amount: number.isRequired,
  display_amount: string.isRequired,
  id: number.isRequired,
  is_base_recipe: bool.isRequired,
  is_product: bool.isRequired,
  key: string.isRequired,
  name: string.isRequired,
  pdf_display_name: string.isRequired,
});

export const RecipeIngredientGroupShape = shape({
  id: number.isRequired,
  key: string.isRequired,
  name: string.isRequired,
  amount: number.isRequired,
  display_amount: string.isRequired,
  ingredients: arrayOf(RecipeIngredientShape).isRequired,
});

export const RecipeHerstellungsparameterShape = shape({
  key: string.isRequired,
  title: string.isRequired,
  value: string.isRequired,
});

export const RecipeNaehrwerteShape = shape({
  ballaststoffe: number,
  brennwert_kcal: number,
  brennwert_kj: number,
  eiweiss: number,
  fett: number,
  ges_fettsaeuren: number,
  kohlenhydrate: number,
  natrium: number,
  staerke: number,
  zucker: number,
});
export const RecipeDataShape = shape({
  amount: number.isRequired,
  amount_factor: number.isRequired,
  bezugsgroesse: string.isRequired,
  display_amount: string.isRequired,
  herstellungsparameter: arrayOf(RecipeHerstellungsparameterShape).isRequired,
  ingredientGroups: arrayOf(RecipeIngredientGroupShape).isRequired,
  naehrwerte: RecipeNaehrwerteShape.isRequired,
});

export const ImagePreviewShape = shape({
  '@id': string.isRequired,
  '@type': string.isRequired,
  description: string.isRequired,
  review_state: any,
  title: string.isRequired,
});

export const RecipeSummaryShape = shape({
  '@id': string.isRequired,
  description: string.isRequired,
  id: number.isRequired,
  image: ImagePreviewShape,
});

export const RecipeShape = shape({
  '@id': string.isRequired,
  aktuell: oneOf([0, 1]).isRequired,
  data: RecipeDataShape.isRequired,
  naehrwerte: RecipeNaehrwerteShape.isRequired,
  units: shape({
    float: number.isRequired,
    float_formated: string.isRequired,
    int: number.isRequired,
  }),
  description: string.isRequired,
  display_rezeptcode: string.isRequired,
  downloads: shape({
    naehrwerte: string,
    rezept: string,
    verkaufinformation: string,
  }).iosRequired,
  herstellungstext: string.isRequired,
  id: number.isRequired,
  image: ImagePreviewShape,
  image_uuid: string,
  kennung: string.isRequired,
  name: string.isRequired,
  related_recipes: arrayOf(RecipeSummaryShape),
  rezeptcode: string.isRequired,
  variante: number,
  verkehrsbezeichnung: string.isRequired,
  zusatz: string.isRequired,
});

export const EditableVistObject = shape({
  '@id': string.isRequired,
  aktuell: oneOf([0, 1]).isRequired,
  image_uuid: string,
  image: ImagePreviewShape,
  name: string.isRequired,
  type: oneOf(['jungzeelandia.Recipe', 'jungzeelandia.Product']),
});
