import { has } from 'lodash';
import {
  GET_ROHSTOFFKATEGORIEN,
  GET_ROHSTOFFKATEGORIEBYID,
  CREATE_ROHSTOFFKATEGORIE,
  DELETE_ROHSTOFFKATEGORIE,
  UPDATE_ROHSTOFFKATEGORIE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function rohstoffkategorien(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFKATEGORIEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFKATEGORIEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { rohstoffkategorien: [], total_pages: 0 },
      };
    case `${GET_ROHSTOFFKATEGORIEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffkategorieByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ROHSTOFFKATEGORIEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_ROHSTOFFKATEGORIEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_ROHSTOFFKATEGORIEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function rohstoffkategorieCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_ROHSTOFFKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_ROHSTOFFKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_ROHSTOFFKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rohstoffkategorieDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_ROHSTOFFKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_ROHSTOFFKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_ROHSTOFFKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function rohstoffkategorieUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_ROHSTOFFKATEGORIE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_ROHSTOFFKATEGORIE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_ROHSTOFFKATEGORIE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
