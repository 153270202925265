import { React } from 'react';
import MetaFooter from '../../MetaFooter';

const GeneralDetails = ({ rezept }) => {
  // this is type independend:
  // text as string: does not format
  // number as string: formats number
  // number as number: formats number
  const formatNumber = (value, floatingPoints, rounding = true) => {
    // check for empty values
    if (!value) {
      return '';
    }
    // check for text as string
    else if (isNaN(Number(value))) {
      return value;
    }
    // everything else is a number at least after this step
    else {
      value = Number(value);
    }
    // we don't want to display 0's
    if (value === 0) return '';
    // check if number should get rounded (0.5 -> 1)
    if (rounding) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: floatingPoints,
        maximumFractionDigits: floatingPoints,
      });
    }
    // some field do not want rounding (stueckzahl) without rounding floating point gets cut off instead
    else {
      return parseInt(value).toLocaleString('de-DE');
    }
  };

  // check if field should get displayed in the dom
  const checkField = (field) => {
    return field !== undefined && field !== 0 && field !== '' && field !== '0';
  };

  // check if Teig Params are all empty
  const hasTeigParams =
    checkField(rezept.teigtemperatur) ||
    checkField(rezept.knetzeit) ||
    checkField(rezept.teigruhe) ||
    checkField(rezept.pressen_zwischengare) ||
    checkField(rezept.teigausbeute) ||
    checkField(rezept.teigeinlage) ||
    checkField(rezept.pressengewicht);

  // check if Masse Params are all empty
  const hasMasseParams =
    checkField(rezept.massentemperatur) ||
    checkField(rezept.ruehrzeit) ||
    checkField(rezept.quellzeit) ||
    checkField(rezept.masseneinwaage);

  return (
    <div className="recipe-container">
      {rezept && (
        <>
          <div className="meta-info">
            {rezept && (
              <p>
                <strong>Rezeptnummer:</strong>
                {rezept.kennung?.toUpperCase()} {rezept.rezeptcode}{' '}
                {rezept.variante}
              </p>
            )}
            {rezept && rezept?.gruppen_names?.length > 0 && (
              <div className="kategories">
                <strong>Rezeptgruppen:</strong>
                <div className="kategory-names">
                  {rezept.gruppen_names.map((group) => {
                    return <>{group}, </>;
                  })}
                </div>
              </div>
            )}
            {rezept && rezept?.kategorien_names?.length > 0 && (
              <div className="kategories">
                <strong>Rezeptkategorien:</strong>
                <div className="kategory-names">
                  {rezept.kategorien_names.map((kategorie) => {
                    return <>{kategorie}, </>;
                  })}
                </div>
              </div>
            )}
            <p>
              <strong>Intern/Extern:</strong>{' '}
              {rezept.intern_extern ? 'intern' : 'extern'}
            </p>
            <p>
              <strong>Status:</strong>{' '}
              {rezept.rezeptstatus === 1
                ? 'aktuell'
                : rezept.rezeptstatus === 2
                ? 'stillgelegt'
                : rezept.rezeptstatus === 3
                ? 'in Bearbeitung'
                : rezept.rezeptstatus}
            </p>
          </div>
          {/* <div className="trenner" /> */}

          {/* <div className="trenner" /> */}
          <div className="ingredients-list">
            <h3 className="ingredients">Zutaten:</h3>
            {rezept?.zutatengruppen?.map((group) => (
              <div key={group.zutatengruppen_id} className="zutatengruppe">
                <h4>
                  {group.zutatengruppeName}{' '}
                  {group.isGrundrezept &&
                    '- ' + group.grundrezeptnummer + ' (Grundrezept)'}{' '}
                  :
                </h4>
                {group.isGrundrezept ? (
                  <div className="grundrezept">
                    {group.zutaten.map((zutat) => (
                      <>
                        <div key={zutat.id} className="zutat">
                          <p>{zutat.name} </p>{' '}
                          <p>{formatNumber(zutat.menge, 0)} g</p>
                        </div>
                        {zutat.grundzutatengruppen && (
                          <div className="grundrezeptzutatengruppen">
                            {zutat.grundzutatengruppen.map(
                              (grundrezeptGroup, index) => (
                                <div className="zutatengruppe" key={index}>
                                  <strong className="headline">
                                    {grundrezeptGroup.zutatengruppeName}:
                                  </strong>
                                  {grundrezeptGroup.zutaten.map((zutat) => (
                                    <div className="zutat">
                                      <p>
                                        {zutat.name} {zutat.info}
                                      </p>
                                      <p> {formatNumber(zutat.menge, 0)} g</p>
                                    </div>
                                  ))}
                                  <div className="weight">
                                    <p>
                                      Gesamtgewicht{' '}
                                      {grundrezeptGroup.zutatengruppeName}:
                                    </p>
                                    <p>
                                      {formatNumber(
                                        grundrezeptGroup.totalWeight,
                                        2,
                                      )}{' '}
                                      g
                                    </p>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                ) : (
                  group.zutaten.map((zutat) => (
                    <div key={zutat.id} className="zutat">
                      <p>
                        {zutat.name} {zutat.info}
                      </p>
                      <p> {formatNumber(zutat.menge, 0)} g</p>
                    </div>
                  ))
                )}

                <div className="zutatengruppen weight">
                  <p>
                    Gesamtgewicht {group.zutatengruppeName}{' '}
                    {group.isGrundrezept && '(Grundrezept)'}:
                  </p>
                  <p>{formatNumber(group.totalWeight, 0)} g</p>
                </div>
              </div>
            ))}
            <div className="total recipe weight">
              <p>Gesamtgewicht Zutaten:</p>
              <p>{formatNumber(rezept?.gesamtgewicht_zutaten, 0)} g</p>
            </div>
          </div>
          {/* <div className="trenner" /> */}
          <div className="recipe-info">
            <div className="column">
              {hasTeigParams && (
                <p className="param-header">
                  <strong>Parameter Teige:</strong>
                </p>
              )}
              {checkField(rezept.teigtemperatur) && (
                <p>
                  <strong>Teigtemperatur in °C:</strong>
                  {formatNumber(rezept.teigtemperatur, 0)}
                </p>
              )}
              {checkField(rezept.knetzeit) && (
                <p>
                  <strong>
                    Knetzeit (Spiralkneter, abhängig von Teiggröße und
                    Knetsystem) in Min.:
                  </strong>
                  {formatNumber(rezept.knetzeit)}
                </p>
              )}
              {checkField(rezept.teigruhe) && (
                <p>
                  <strong>Teigruhe in Min:</strong>{' '}
                  {formatNumber(rezept.teigruhe)}
                </p>
              )}
              {checkField(rezept.pressen_zwischengare) && (
                <p>
                  <strong>Pressen-/Zwischengare in Min.:</strong>
                  {formatNumber(rezept.pressen_zwischengare, 0)}
                </p>
              )}
              {checkField(rezept.teigausbeute) && (
                <p>
                  <strong>Teigausbeute:</strong>
                  {formatNumber(rezept.teigausbeute, 0)}
                </p>
              )}
              {checkField(rezept.teigeinlage) && (
                <p>
                  <strong>Teigeinlage in g:</strong>
                  {formatNumber(rezept.teigeinlage, 0)}
                </p>
              )}
              {checkField(rezept.pressengewicht) && (
                <p>
                  <strong>Pressengewicht in g:</strong>
                  {formatNumber(rezept.pressengewicht, 0)}
                </p>
              )}
              {/* this is horrible */}
              {hasMasseParams && (
                <p className="param-header masse">
                  <strong>Parameter Masse:</strong>
                </p>
              )}
              {checkField(rezept.massentemperatur) && (
                <p>
                  <strong>Massentemperatur in °C:</strong>
                  {formatNumber(rezept.massentemperatur, 0)}
                </p>
              )}
              {checkField(rezept.ruehrzeit) && (
                <p>
                  <strong>Rührzeit in Min.:</strong>
                  {formatNumber(rezept.ruehrzeit)}
                </p>
              )}
              {checkField(rezept.quellzeit) && (
                <p>
                  <strong>Quellzeit in Min.:</strong>
                  {formatNumber(rezept.quellzeit)}
                </p>
              )}
              {checkField(rezept.masseneinwaage) && (
                <p>
                  <strong>Masseneinwaage in g:</strong>
                  {formatNumber(rezept.masseneinwaage, 0)}
                </p>
              )}
            </div>
            <div className="column">
              <p className="param-header">
                <strong>Rezeptdaten:</strong>
              </p>
              {checkField(rezept.backtemperatur) && (
                <p>
                  <strong>Backtemperatur in °C:</strong>
                  {formatNumber(rezept.backtemperatur)}
                </p>
              )}
              {checkField(rezept.siedefetttemperatur) && (
                <p>
                  <strong>Siedefetttemperatur in °C:</strong>
                  {formatNumber(rezept.siedefetttemperatur, 0)}
                </p>
              )}
              {checkField(rezept.backzeit) && (
                <p>
                  <strong>Backzeit in Min.:</strong>
                  {formatNumber(rezept.backzeit)}
                </p>
              )}
              {checkField(rezept.kerntemperatur) && (
                <p>
                  <strong>Kerntemperatur in °C:</strong>
                  {formatNumber(rezept.kerntemperatur, 0)}
                </p>
              )}
              {checkField(rezept?.gesamtgewicht_zutaten) && (
                <p>
                  <strong>Gesamtgewicht Zutaten in g:</strong>
                  {formatNumber(rezept?.gesamtgewicht_zutaten, 0)}
                </p>
              )}
              {checkField(rezept.bezug) && (
                <p>
                  <strong>Bezug:</strong>
                  {rezept.bezug}
                </p>
              )}
              {checkField(rezept.stueckzahl) && (
                <p>
                  <strong>Stückzahl:</strong>
                  {formatNumber(rezept.stueckzahl, 0, false)}
                </p>
              )}
              {checkField(rezept.stueckgewicht) && (
                <p>
                  <strong>Stückgewicht in g:</strong>
                  {formatNumber(rezept.stueckgewicht, 0)}
                </p>
              )}
              {checkField(rezept.backverlust) && (
                <p>
                  <strong>Backverlust in %:</strong>
                  {formatNumber(rezept.backverlust, 0)}
                </p>
              )}
              {checkField(rezept.gebaeckgewicht) && (
                <p>
                  <strong>Gebäckgewicht in g: </strong>
                  {formatNumber(rezept.gebaeckgewicht, 0)}
                </p>
              )}
              {checkField(rezept.gesamt_gebaeckgewicht) && (
                <p>
                  <strong>Gesamt-Gebäckgewicht in g:</strong>
                  {formatNumber(rezept.gesamt_gebaeckgewicht, 0)}
                </p>
              )}
            </div>
          </div>
          {/* <div className="trenner" /> */}
          <div className="info-texts rezepte">
            <div className="info-text-box">
              <strong>Herstellung:</strong>
              <div
                className="text-area rich-text"
                dangerouslySetInnerHTML={{
                  __html: rezept.herstellungstext,
                }}
              />
            </div>
            <div className="info-text-box">
              <strong>Zusatz:</strong>{' '}
              <pre className="text-area">{rezept.zusatz}</pre>
            </div>
          </div>
          <MetaFooter data={rezept} />
        </>
      )}
    </div>
  );
};

export default GeneralDetails;
