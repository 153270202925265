import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import { Portal } from 'react-portal';
import {
  Button,
  Card,
  Container,
  Image,
  Pagination,
  Segment,
  Icon,
} from 'semantic-ui-react';
import { Toolbar } from '@plone/volto/components';
import Types from '../Types/Types';

import { resetSearchContent, searchContent } from '@plone/volto/actions';
import { getBaseUrl } from '@plone/volto/helpers';

import { MixedTitle } from '..';

import arrowSVG from '../../icons/arrow.svg';
import paginationLeftSVG from '../../icons/pagination-left.svg';
import paginationRightSVG from '../../icons/pagination-right.svg';

import config from '@plone/volto/registry';

const removeContainer = (items, pathname) =>
  items.filter((item) => item['@id'] !== pathname);

class MediathekView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
      }),
    ).isRequired,
    itemsTotal: PropTypes.number.isRequired,
    searchContent: PropTypes.func.isRequired,
    resetSearchContent: PropTypes.func.isRequired,
    batching: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }).isRequired,
    loaded: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }).isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    error: null,
    loading: null,
    token: null,
  };

  state = {
    currentPage: 1,
    email: '',
    password: '',
    hasObjectButtons: null,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.searchContent(this.props.pathname, {
      fullobjects: 1,
      'path.depth': '1',
      b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
      b_start:
        (this.state.currentPage - 1) * config.settings.DEFAULT_NEWS_PAGING_SIZE,
      sort_on: 'effective',
      sort_order: 'descending',
      portal_type: ['Document', 'Folder'],
    });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.searchContent(nextProps.pathname, {
        fullobjects: 1,
        'path.depth': '1',
        b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
        b_start:
          (this.state.currentPage - 1) *
          config.settings.DEFAULT_NEWS_PAGING_SIZE,
        sort_on: 'effective',
        sort_order: 'descending',
        portal_type: 'Document',
      });
    }

    if (nextProps.actions.object_buttons) {
      const objectButtons = nextProps.actions.object_buttons;
      this.setState({
        hasObjectButtons: !!objectButtons.length,
      });
    }
  }

  /**
   * Component will unmount
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    this.props.resetSearchContent();
  }

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState(() => ({ currentPage: activePage }));
    this.props.searchContent(this.props.pathname, {
      fullobjects: 1,
      'path.depth': '1',
      b_size: config.settings.DEFAULT_NEWS_PAGING_SIZE,
      b_start: (activePage - 1) * config.settings.DEFAULT_NEWS_PAGING_SIZE,
      sort_on: 'effective',
      sort_order: 'descending',
      portal_type: 'Document',
    });
  };

  onLogin = (event) => {
    const { email, password } = this.state;
    this.props.login(email, password);
    event.preventDefault();
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const path = getBaseUrl(this.props.pathname);

    return (
      <Segment
        basic
        as="section"
        className="aktuelles-view"
        style={{ marginBottom: '100px' }}
      >
        <Helmet title="Zeelandia - Mediathek" />
        <Container>
          <h1>Mediathek</h1>
          {/* <p>{this.props.content.description}</p> */}
          <Card.Group
            stackable
            centered
            itemsPerRow={2}
            style={{ marginBottom: '24px' }}
          >
            {this.props.items.map((item) => (
              <Card key={item['@id']} as={Link} to={item['@id']}>
                {item.image && <Image src={item.image.download} />}
                <Card.Content>
                  <Card.Header>
                    {item.title} {item.subtitle ? item.subtitle : ''}
                  </Card.Header>
                  <Card.Description>{item.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <div style={{ textAlign: 'right' }}>
                    <Button className="small brandButtonSecondary ">
                      <MixedTitle>Mehr lesen</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <div style={{ textAlign: 'center' }}>
            {this.props.itemsTotal > 6 && (
              <Pagination
                activePage={this.state.currentPage}
                totalPages={Math.ceil(
                  this.props.itemsTotal /
                    config.settings.DEFAULT_NEWS_PAGING_SIZE,
                )}
                onPageChange={this.handlePaginationChange}
                firstItem={null}
                lastItem={null}
                prevItem={
                  this.props.batching.prev
                    ? {
                        content: <Icon name={paginationLeftSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
                nextItem={
                  this.props.batching.next
                    ? {
                        content: <Icon name={paginationRightSVG} size="18px" />,
                        icon: true,
                      }
                    : null
                }
              />
            )}
          </div>
          {this.props.token && (
            <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
              <Toolbar
                pathname={this.props.pathname}
                inner={
                  <div>
                    <Link
                      to={`${path}/contents`.replace(/\/\//g, '/')}
                      id="toolbar-folder-contents"
                      className="item"
                    >
                      <Icon
                        name="folder open"
                        size="big"
                        // title={folderContentsAction.title}
                      />
                    </Link>

                    <Types pathname={path} />
                  </div>
                }
              />
            </Portal>
          )}
        </Container>
      </Segment>
    );
  }
}

export default connect(
  (state, props) => ({
    actions: state.actions.actions,
    pathname: props.location.pathname,
    items: removeContainer(state.search.items, props.location.pathname),
    itemsTotal: state.search.total,
    batching: state.search.batching,
    loaded: state.search.loaded,
    error: state.userSession.login.error,
    loading: state.userSession.login.loading,
    token: state.userSession.token,
  }),
  {
    resetSearchContent,
    searchContent,
  },
)(MediathekView);
