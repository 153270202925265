import React from 'react';

import { Form } from 'semantic-ui-react';

const BezugForm = ({ formData, handleInputChange, isBezugNameDuplicate }) => {
  return (
    <Form>
      <div className="bezug-info">
        <Form.Field error={isBezugNameDuplicate}>
          <label>
            <strong>Bezugname (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isBezugNameDuplicate && (
          <div className="speech-bubble warning">
            Bezug mit diesem Namen existiert bereits
          </div>
        )}
        <p className="disclaimer">* Pflichtfeld</p>
      </div>
    </Form>
  );
};

export default BezugForm;
