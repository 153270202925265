import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  createProdukt,
  checkProdukte,
  createGebinde,
  getProduktkategorien,
  getVistPermissions,
} from '../../../actions';

import { UnsavedChangesWarning } from 'helpers';

import ProduktForm from './ProduktForm';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const ProduktCreate = ({
  pathname,
  createProdukt,
  checkProdukte,
  createGebinde,
  getProduktkategorien,
  produktkategorien,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const emptyFormData = {
    produktnummer: '',
    bezeichnung: '',
    intern_extern: true,
    brennwert_kj: '',
    brennwert_kcal: '',
    eiweiss: '',
    kohlenhydrate: '',
    zucker: '',
    fett: '',
    ges_fettsaeuren: '',
    ballaststoffe: '',
    natrium: '',
    produktbeschreibung_lang: '<p class="tile text"></p>',
    produktbeschreibung_kurz: '',
    basis_euro: '',
    zusatzinformationen: '',
    status: 1, // 1 = aktuell 2 = stillgelegt 3 = in Bearbeitung
    aktuell: null,
    kategorien: [],
  };

  const [formData, setFormData] = useState(emptyFormData);

  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [isProduktnummerDuplicate, setIsProduktnummerDuplicate] = useState(
    false,
  );
  const [isBezeichnungDuplicate, setIsBezeichnungDuplicate] = useState(false);
  const [isIncompleteGebinde, setIsIncompleteGebinde] = useState(false);
  const isSubmitDisabled =
    isProduktnummerDuplicate ||
    isBezeichnungDuplicate ||
    formData.produktnummer === '' ||
    formData.bezeichnung === '' ||
    isIncompleteGebinde;

  const [gebinde, setGebinde] = useState([]);

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 4, 6];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const [produktNummerSuggestions, setProduktNummerSuggestions] = useState([]);
  const [modified, setModified] = useState(false);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getProduktkategorien({ getAll: true });
    setIsClient(true);
  }, [getProduktkategorien]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'intern_extern') {
      // For intern/extern field, convert value to a boolean
      const isIntern = value === 'intern';
      setFormData((prevFormData) => ({ ...prevFormData, [name]: isIntern }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if (name === 'produktnummer') {
      checkProdukte(name, value)
        .then((result) => {
          if (result.value_exists === true) {
            setIsProduktnummerDuplicate(true);
          } else {
            setIsProduktnummerDuplicate(false);
          }
          if (result.suggested_values) {
            setProduktNummerSuggestions(result.suggested_values);
          } else {
            setProduktNummerSuggestions([]);
          }
        })
        .catch((error) => {
          setError(error.name);
          setErrorDetail(error.message);
        });
    }

    if (name === 'bezeichnung') {
      checkProdukte(name, value)
        .then((result) => {
          if (result === true) {
            setIsBezeichnungDuplicate(true);
          } else {
            setIsBezeichnungDuplicate(false);
          }
        })
        .catch((error) => {
          setError(error.name);
          setErrorDetail(error.message);
        });
    }
  };

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (!isEqual(formData, emptyFormData)) {
      setModified(true);
    } else {
      setModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSubmit = (e) => {
    setModified(false);
    e.preventDefault();

    // Remove fields with empty strings from the formData
    const cleanedFormData = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    createProdukt(cleanedFormData)
      .then((result) => {
        const id = result.id;
        if (id) {
          gebinde.forEach((gebinde_data) => {
            gebinde_data.produkt_id = id;
            createGebinde(gebinde_data).catch((error) => {
              setError(
                'es ist etwas schiefgelaufen, Gebinde wurde nicht angelegt: ' +
                  error.name +
                  ' ' +
                  error.message,
              );
            });
          });

          history.push(
            `/controlpanel/rezeptdatenbank/produkte/details?id=${id}`,
          );
        } else {
          setError(
            'Es ist etwas schiefgelaufen, Produkt wurde nicht angelegt.',
          );
        }
      })
      .catch((error) => {
        setError('Es ist etwas schiefgelaufen, Produkt wurde nicht angelegt');
        setErrorDetail(error.name + ': ' + error.message);
      });
  };

  /// GEBINDE:
  const addGebinde = (e) => {
    e.preventDefault();
    setGebinde([
      ...gebinde,
      {
        name: '',
        artikelnummer: '',
        zugabe: '',
      },
    ]);
  };

  const removeGebinde = (e, index) => {
    e.preventDefault();
    const updatedGebinde = gebinde.filter((_, i) => i !== index);
    setGebinde(updatedGebinde);
  };

  const handleGebindeChange = (e, index) => {
    const { name, value } = e.target;
    const updatedGebindeList = [...gebinde];
    const updatedGebinde = { ...updatedGebindeList[index] };
    if (name === 'artikelnummer') {
      updatedGebinde[name] = value.replace(/\D/g, '');
    } else {
      updatedGebinde[name] = value;
    }
    updatedGebindeList[index] = updatedGebinde;
    setGebinde(updatedGebindeList);
  };

  // check if any gebinde have empty required fields
  useEffect(() => {
    const hasIncompleteGebinde = gebinde.some((gebindeItem) => {
      return gebindeItem.artikelnummer === '' || gebindeItem.name === '';
    });

    setIsIncompleteGebinde(hasIncompleteGebinde);
  }, [gebinde]);

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper rbd add">
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben für das neue Produkt bereits Werte eingetragen. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Helmet title="Produkt Anlegen" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>Produkt Anlegen</h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={true}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={'/controlpanel/rezeptdatenbank/produkte'}
                  typeString={'das Produkt'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            <div className="rdb add form produkte">
              <ProduktForm
                formData={formData}
                isBezeichnungDuplicate={isBezeichnungDuplicate}
                isProduktnummerDuplicate={isProduktnummerDuplicate}
                handleInputChange={handleInputChange}
                gebinde={gebinde}
                addGebinde={addGebinde}
                handleGebindeChange={handleGebindeChange}
                removeGebinde={removeGebinde}
                isIncompleteGebinde={isIncompleteGebinde}
                handleKategorienChange={handleKategorienChange}
                selectedKategorien={formData.kategorien}
                kategorien={produktkategorien}
                produknummer_suggestions={produktNummerSuggestions}
              />
            </div>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

ProduktCreate.propTypes = {
  pathname: PropTypes.string.isRequired,
  createProdukt: PropTypes.func.isRequired,
  checkProdukte: PropTypes.func.isRequired,
  createGebinde: PropTypes.func.isRequired,
  getProduktkategorien: PropTypes.func.isRequired,
  produktkategorien: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      produktkategorien: state.produktkategorien.data.produktkategorien,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      createProdukt,
      checkProdukte,
      createGebinde,
      getProduktkategorien,
      getVistPermissions,
    },
  ),
)(ProduktCreate);
