import { React } from 'react';
import { Form } from 'semantic-ui-react';

const ResouceCosts = ({
  rohstoffKosten,
  numberOfPieces,
  setNumberOfPieces,
}) => {
  const formatNumber = (value, floatingPoints) => {
    if (value !== undefined) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: floatingPoints,
        maximumFractionDigits: floatingPoints,
      });
    } else {
      return value;
    }
  };
  return (
    <div className="recipe-container">
      <div className="nutrient-info">
        <div className="nutrient-meta-info">
          <p>
            Rezeptname: <strong>{rohstoffKosten.name}</strong>
          </p>
          <p>
            Verkehrsbezeichnung:{' '}
            <strong>{rohstoffKosten.verkehrsbezeichnung}</strong>
          </p>
        </div>
        <div className="trenner" />
        <div className="rohstoff-kosten-table">
          <div className="row header">
            <div>Nr.</div>
            <div>Bezeichnung</div>
            <div>Preis pro kg</div>
            <div>Menge</div>
            <div>Preis</div>
          </div>
          <div className="trenner" />
          {rohstoffKosten.individual_resource_costs?.map((zutat) => (
            <div className="row" key={zutat.zutat_name}>
              <div>{zutat.zutat_number}</div>
              <div>{zutat.zutat_name}</div>
              <div>{formatNumber(zutat.price_per_kg, 2)} Euro</div>
              <div>{formatNumber(zutat.amount, 2)} g</div>
              <div>{formatNumber(zutat.price, 2)} Euro</div>
            </div>
          ))}
          <div className="trenner" />
          <div className="trenner" />
          <div className="row header">
            <div>Gesamt:</div>
            <div className="dummy-cell" />
            <div className="dummy-cell" />
            <div>
              {formatNumber(rohstoffKosten.total_weight_of_pieces, 2)} g
            </div>
            <div className="total-costs">
              {formatNumber(rohstoffKosten.total_cost_of_pieces, 2)} Euro
            </div>
          </div>
          <div className="trenner" />
          <div className="numberOfPieces">
            Gesamt-Rohstoffkosten pro{'   '}
            <Form.Field>
              <input
                type="number"
                min="1"
                name="stueckzahl"
                value={numberOfPieces}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  if (!isNaN(newValue) && newValue > 0) {
                    setNumberOfPieces(newValue);
                  }
                }}
              />
            </Form.Field>
            {'    '}
            {rohstoffKosten.bezug}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResouceCosts;
