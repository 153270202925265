/**
 * Rezeptkategorien actions.
 * @module actions/Produkt
 */

import {
  GET_REZEPTKATEGORIEN,
  GET_REZEPTKATEGORIEBYID,
  CREATE_REZEPTKATEGORIE,
  UPDATE_REZEPTKATEGORIE,
  DELETE_REZEPTKATEGORIE,
} from '../../constants/ActionTypes';

/**
 * Get getRezeptkategorien
 * @function getRezeptkategorien
 * @param {Object} id Produkt id
 * @returns {Object} Produkt action.
 */
export function getRezeptkategorien({
  showDeleted = false,
  getAll = false,
  batchingParameters = {},
}) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;
  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted) {
    return {
      type: GET_REZEPTKATEGORIEN,
      request: {
        op: 'get',
        path: `/@rezeptkategorien/deleted${pathSuffix}`,
      },
    };
  } else if (getAll) {
    return {
      type: GET_REZEPTKATEGORIEN,
      request: {
        op: 'get',
        path: `/@rezeptkategorien/all`,
      },
    };
  }

  return {
    type: GET_REZEPTKATEGORIEN,
    request: {
      op: 'get',
      path: `/@rezeptkategorien${pathSuffix}`,
    },
  };
}

/**
 * Get Benutzer item.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getRezeptkategorieByID(id) {
  return {
    type: GET_REZEPTKATEGORIEBYID,
    request: {
      op: 'get',
      path: `/@rezeptkategorien/${id}`,
    },
  };
}

/**
 * Create a new Rezeptkategorie.
 * @function createRezeptkategorie
 * @param {Object} formData The data of the new Rezeptkategorie
 * @returns {Object} Rezeptkategorie action.
 */
export function createRezeptkategorie(formData) {
  return {
    type: CREATE_REZEPTKATEGORIE,
    request: {
      op: 'post',
      path: '/@rezeptkategorien',
      data: formData,
    },
  };
}

/**
 * Update a new Rezeptkategorie.
 * @function createRezeptkategorie
 * @param {Object} formData The data of the new Rezeptkategorie
 * @returns {Object} Rezeptkategorie action.
 */
export function updateRezeptkategorie(id, formData) {
  return {
    type: UPDATE_REZEPTKATEGORIE,
    request: {
      op: 'patch',
      path: `/@rezeptkategorien/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Rezeptkategorie.
 * @function createRezeptkategorie
 * @returns {Object} Success Message;
 */
export function deleteRezeptkategorie(id) {
  return {
    type: DELETE_REZEPTKATEGORIE,
    request: {
      op: 'del',
      path: `/@rezeptkategorien/${id}`,
    },
  };
}
