import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';

import loupeSVG from '../../static/loupe.svg';
import pdfSVG from '../../static/pdf-external.svg';
import AnsprechpartnerSVG from '../../static/kontakt_icon.svg';
import closeSVG from '../../static/search-close.svg';
import { InlineSearch, AuxPartners } from '..';

/**
 *
 *
 * @class AuxMenu
 * @extends {Component}
 */
class AuxMenu extends Component {
  state = {
    openedMenu: '',
    showBackdrop: false,
  };

  toggleAuxDialogs = (name) => {
    if (name !== this.state.openedMenu) {
      this.setState(() => ({
        openedMenu: name,
        showBackdrop: true,
      }));
    } else {
      this.setState(() => ({
        openedMenu: '',
        showBackdrop: false,
      }));
    }
  };

  closeMenu = (e) => {
    e.stopPropagation();
    this.setState(() => ({
      openedMenu: '',
      showBackdrop: false,
    }));
  };

  render() {
    return (
      <div className="auxmenu mobile hidden">
        <CSSTransition
          in={this.state.openedMenu !== ''}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchauxmenu"
            style={{
              minHeight: '300px',
              position: 'fixed',
              top: '200px',
              right: 0,
              left: 0,
              zIndex: -1,
              width: '100vw',
            }}
          >
            {this.state.openedMenu === 'search' && (
              <InlineSearch closeMenu={this.closeMenu} />
            )}
            {this.state.openedMenu === 'partners' && (
              <AuxPartners closeMenu={this.closeMenu} />
            )}
          </div>
        </CSSTransition>
        <ul>
          <li>
            <Button
              onClick={() => this.toggleAuxDialogs('search')}
              className={cx('brandButtonPrimary', {
                searchIsOpen: this.state.openedMenu === 'search',
              })}
            >
              {this.state.openedMenu === 'search' ? (
                <img src={closeSVG} alt="" />
              ) : (
                <React.Fragment>
                  <img src={loupeSVG} alt="" />
                  <span>Suche</span>
                </React.Fragment>
              )}
            </Button>
          </li>
          <li>
            <Button
              as="a"
              href="https://www.paperturn-view.com/de/innovation-zeelandia-de/sortimentsbersicht-zeelandia?pid=MzY363025&v=3"
              target="_blank"
              onClick={this.closeMenu}
              className="brandButtonPrimary"
            >
              <img
                src={pdfSVG}
                alt=""
                title="Sortimentsübersicht auf paperturn-view.com"
              />
              <span>Sortiments-</span>
              <span>übersicht</span>
            </Button>
          </li>
          <li>
            <Button
              onClick={() => this.toggleAuxDialogs('partners')}
              className={cx('brandButtonPrimary', {
                searchIsOpen: this.state.openedMenu === 'partners',
              })}
            >
              {this.state.openedMenu === 'partners' ? (
                <img src={closeSVG} alt="" />
              ) : (
                <React.Fragment>
                  <img src={AnsprechpartnerSVG} className="contact" alt="" />
                  <span>Ansprech-</span>
                  <span>partner</span>
                </React.Fragment>
              )}
            </Button>
          </li>
          {/* <li>
            <Button
              as={AnchorLink}
              href="#kontakt"
              onClick={this.closeMenu}
              className="brandButtonPrimary"
            >
              <img src={contactSVG} alt="" />
              <span>Kontakt</span>
            </Button>
          </li> */}
        </ul>
        <CSSTransition
          in={this.state.showBackdrop}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchauxmenubackdrop"
            className="backdrop"
            onClick={this.closeMenu}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              zIndex: '-2',
              background: 'rgba(255, 240, 66, 0.75)',
            }}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default AuxMenu;
