import React from 'react';
import { Grid, Button, Item } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { MixedTitle } from '..';

import cogsSVG from '../../static/cogs.svg';
import checkmarkSVG from '../../static/checkmark.svg';
import qmSVG from '../../static/qm.svg';

const KompetenzenTab = () => (
  <section>
    <Grid stackable>
      <Grid.Column tablet={12} computer={6} className="main-column">
        <h2>
          <MixedTitle>Unsere Kompetenzen</MixedTitle>
        </h2>
        <p>
          <strong>Zufriedene, erfolgreiche Kunden</strong> – das ist unser
          Anspruch. Deshalb entwickeln wir Produkte, die funktionieren –
          natürlich! Unser „Rundum-Sorglos-Paket“ reicht von der Lieferung
          unseres hochwertigen Sortiments über die Bereitstellung gelingsicherer
          Rezepte und attraktiver Verkaufskonzepte bis hin zu einer
          persönlichen, kompetenten Beratung unserer Kunden vor Ort. Wir
          erarbeiten auch individuelle Lösungen, die wir optimal an die
          gegebenen Bedingungen unserer Kunden anpassen. Zudem betreiben wir
          eine kontinuierliche Verbesserung der Qualität und Produktsicherheit.
          Modernste Technologien im Bereich Produktentwicklung und
          Analysemethoden runden unser Spektrum ab.
        </p>

        <div className="buttons-row">
          <Button
            as={Link}
            to="/kompetenzen/technologien"
            className="brandButtonPrimary"
          >
            <img src={cogsSVG} alt="" />
            <span>Technologien</span>
          </Button>
          <Button
            as={Link}
            to="/kompetenzen/qualitaetsmanagement"
            className="brandButtonPrimary"
          >
            <img src={qmSVG} alt="" />
            <span>
              Qualitäts-
              <br />
              management
            </span>
          </Button>
        </div>
      </Grid.Column>
      <Grid.Column tablet={12} computer={1} />
      <Grid.Column tablet={12} computer={5} className="secondary-column">
        <div
          style={{ marginTop: '40px', marginBottom: '40px' }}
          className="underlined-title"
        >
          <span>Individuelle Lösungen</span>
        </div>
        <Item.Group divided>
          <Item as={Link} to="/kompetenzen/individuelle-loesungen">
            <Item.Content>
              <Item.Description>
                <img
                  src={checkmarkSVG}
                  alt=""
                  style={{ marginRight: '15px' }}
                />
                <p>
                  Konsumenten im Blick
                  <br />
                  Trends: erkennen & nutzen
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/kompetenzen/individuelle-loesungen">
            <Item.Content>
              <Item.Description>
                <img
                  src={checkmarkSVG}
                  alt=""
                  style={{ marginRight: '15px' }}
                />
                <p>
                  funktioniert – natürlich!
                  <br />
                  Produkte: gelingsicher & clean
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/kompetenzen/individuelle-loesungen">
            <Item.Content>
              <Item.Description>
                <img
                  src={checkmarkSVG}
                  alt=""
                  style={{ marginRight: '15px' }}
                />
                <p>
                  Mehrwert durch Innovation
                  <br />
                  Ideen: zukunftsfähig & ertragreich
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/kompetenzen/individuelle-loesungen">
            <Item.Content>
              <Item.Description>
                <img
                  src={checkmarkSVG}
                  alt=""
                  style={{ marginRight: '15px' }}
                />
                <p>
                  alles aus einer Hand
                  <br />
                  Lösungen: maßgeschneidert & ganzheitlich
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/kompetenzen/individuelle-loesungen">
            <Item.Content>
              <Item.Description>
                <img
                  src={checkmarkSVG}
                  alt=""
                  style={{ marginRight: '15px' }}
                />
                <p>
                  aus der Praxis für die Praxis
                  <br />
                  Konzepte: kreativ & marktgerecht
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Grid.Column>
    </Grid>
  </section>
);

export default KompetenzenTab;
