import React from 'react';
import { Grid, Button, Item } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import zeelandiaDocSVG from '../../static/zeelandia-doc.svg';
import handsSVG from '../../static/hands.svg';
import squaredArrowSVG from '../../static/squared-arrow.svg';

const UnternehmenTab = () => (
  <section>
    <Grid stackable>
      <Grid.Column tablet={12} computer={6} className="main-column">
        <h2>
          Wir <strong>über uns</strong>
        </h2>
        <p>
          <strong>
            Backmittel und Backzutaten für Brot, Brötchen und Feine Backwaren
          </strong>{' '}
          sind unsere Leidenschaft. 90 Jahre Erfahrung in Deutschland und die
          engagierte Arbeit von rund 220 Mitarbeitern machen uns zu einem
          starken Partner für Bäcker und Konditoren in Handwerk und Industrie.
          Als Teil der weltweit erfolgreichen Zeelandia-Familie mit mehr als
          3.000 Mitarbeitern in 27 Ländern können wir auf zusätzliche
          internationale Kompetenz zurückgreifen. Das alles nutzen wir – zur
          Weiterentwicklung unseres Produktportfolios, unserer
          Marketingexpertise und unseres technologischen Know-hows.
        </p>

        <div className="buttons-row">
          <Button
            as={Link}
            to="/wir-ueber-uns/ueber-zeelandia/zeelandias_geschichte"
            className="brandButtonPrimary"
          >
            <img src={zeelandiaDocSVG} alt="" />
            <span>
              Unsere
              <br /> Geschichte
            </span>
          </Button>
          <Button
            as={Link}
            to="/wir-ueber-uns/ueber-zeelandia/zeelandia-werte"
            className="brandButtonPrimary"
          >
            <img src={handsSVG} alt="" />
            <span>
              Unsere
              <br /> Werte
            </span>
          </Button>
        </div>
      </Grid.Column>
      <Grid.Column tablet={12} computer={1} />
      <Grid.Column tablet={12} computer={5} className="secondary-column">
        <div
          style={{ marginTop: '40px', marginBottom: '40px' }}
          className="underlined-title"
        >
          <span>Karriere</span>
        </div>
        <Item.Group divided>
          <Item as={Link} to="/wir-ueber-uns/karriere">
            <Item.Content>
              <Item.Header>Berufserfahrene</Item.Header>
              <Item.Description>
                <p>
                  Neue Perspektiven? Wir freuen uns auf Bewerber, die ihr Wissen
                  bei uns einbringen.
                </p>
                <img src={squaredArrowSVG} alt="" />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/wir-ueber-uns/karriere">
            <Item.Content>
              <Item.Header>Studierende/Berufseinsteiger</Item.Header>
              <Item.Description>
                <p>
                  Durchstarten? Wir bieten Praktika und Startchancen in
                  zahlreichen Tätigkeitsfeldern.
                </p>
                <img src={squaredArrowSVG} alt="" />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/wir-ueber-uns/karriere">
            <Item.Content>
              <Item.Header>Auszubildende</Item.Header>
              <Item.Description>
                <p>
                  Traumberuf? Wir legen sehr viel Wert darauf, junge Menschen
                  fit für die Zukunft zu machen.
                </p>
                <img src={squaredArrowSVG} alt="" />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item as={Link} to="/wir-ueber-uns/karriere">
            <Item.Content>
              <Item.Header>Schüler</Item.Header>
              <Item.Description>
                <p>
                  Reinschnuppern? Wir ermöglichen Schulpraktika in vielen
                  unterschiedlichen Berufen.
                </p>
                <img src={squaredArrowSVG} alt="" />
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Grid.Column>
    </Grid>
  </section>
);

export default UnternehmenTab;
