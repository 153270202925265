import React from 'react';

import { Form } from 'semantic-ui-react';

const VerkaufsinformationForm = ({ formData, handleInputChange }) => {
  return (
    <Form>
      <div className="verkaufsinformationForm">
        <Form.Field>
          <label>
            <h3>Hauptbestandteile:</h3>
          </label>
          <textarea
            name="hauptbestandteile"
            value={formData.hauptbestandteile}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <Form.Field>
          <label>
            <h3>besondere Merkmale:</h3>
            <p className="info">Geschmack, Krume, Kruste, Füllung, Auflage</p>
          </label>
          <textarea
            name="merkmale"
            value={formData.merkmale}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <Form.Field>
          <label>
            <h3>Lagerung:</h3>
          </label>
          <textarea
            name="lagerung"
            value={formData.lagerung}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <Form.Field>
          <label>
            <h3>Verzehrtipps:</h3>
          </label>
          <textarea
            name="verzehrtipps"
            value={formData.verzehrtipps}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <Form.Field>
          <label>
            <h3>Hinweise:</h3>
            <p className="info">für die Ernährung</p>
          </label>
          <textarea
            name="hinweise"
            value={formData.hinweise}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
      </div>
    </Form>
  );
};

export default VerkaufsinformationForm;
