import { has } from 'lodash';
import { GET_VISTPERMISSIONS } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function permissions(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_VISTPERMISSIONS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_VISTPERMISSIONS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? [...action.result] : [],
      };
    case `${GET_VISTPERMISSIONS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}
