/**
 * Produkt actions.
 * @module actions/Produkt
 */

import { GET_PRODUCT } from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getProduct
 * @param {string} id Produkt id
 * @returns {Object} Produkt action.
 */
export default function getProduct(id) {
  return {
    type: GET_PRODUCT,
    request: {
      op: 'get',
      path: `/@product/${id}`,
    },
  };
}
