// actions/Gebinde.js

import {
  GET_GEBINDEBYID,
  CREATE_GEBINDE,
  UPDATE_GEBINDE,
  DELETE_GEBINDE,
} from '../../constants/ActionTypes';

// id = produkt_id
export function getGebindeByID(id) {
  return {
    type: GET_GEBINDEBYID,
    request: {
      op: 'get',
      path: `/@gebinde/${id}`,
    },
  };
}

export function createGebinde(formData) {
  return {
    type: CREATE_GEBINDE,
    request: {
      op: 'post',
      path: '/@gebinde',
      data: formData,
    },
  };
}

export function updateGebinde(id, formData) {
  return {
    type: UPDATE_GEBINDE,
    request: {
      op: 'patch',
      path: `/@gebinde/${id}`,
      data: formData,
    },
  };
}

export function deleteGebinde(id) {
  return {
    type: DELETE_GEBINDE,
    request: {
      op: 'del',
      path: `/@gebinde/${id}`,
    },
  };
}
