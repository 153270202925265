import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link, NavLink } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CSSTransition } from 'react-transition-group';
import { Container, Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import qs from 'query-string';
import jwtDecode from 'jwt-decode';

import { ModalLoginForm, ModalRegisterForm } from '../../components';
import MobileHeader from './MobileHeader';

import logo from './logo.svg';
import logoWhite from './logo-white.svg';
import logoMenuBlue from './alternate-jz-menu-logo.svg';
import loggedInSVG from '../../icons/logged_in.svg';
import loggedOutSVG from '../../icons/logged_out.svg';

/**
 *
 *
 * @class Header
 * @extends {Component}
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    userId: PropTypes.string,
    openRegister: PropTypes.bool,
    userActions: PropTypes.array.isRequired,
  };

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static defaultProps = {
    userId: '',
    userActions: [],
  };

  state = {
    menuIsOpen: false,
    loginIsOpen: false,
    registerIsOpen: this.props.openRegister,
  };

  /**
   * @method componentDidUpdate
   * @param {Object} prevProps Previous properties
   * @param {Object} prevState Previous state
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.openRegister !== this.state.registerIsOpen) {
      this.setState({ registerIsOpen: this.props.openRegister });
    }
  }

  openMenu = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  closeMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState(() => ({
      menuIsOpen: false,
      loginIsOpen: false,
      registerIsOpen: false,
    }));

    this.props.history.replace({ search: '' });
  };

  openLogin = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      loginIsOpen: !prevState.loginIsOpen,
      menuIsOpen: false,
    }));
  };

  openRegister = (e) => {
    e.stopPropagation();
    this.props.history.push('?openRegister=1');
    this.setState((prevState) => ({
      loginIsOpen: false,
      registerIsOpen: true,
      menuIsOpen: false,
    }));
  };

  /**
   *
   *
   * @returns {string} Rendered component
   * @memberof Header
   */
  render() {
    let canReindex = false;
    this.props.userActions.forEach((action) => {
      if (action.id === 'plone_setup') {
        canReindex = true;
      }
    });
    return (
      <header>
        <MobileHeader
          pathname={this.props.pathname}
          userId={this.props.userId}
        />

        <div
          className={cx('desktop-header tablet or lower hidden', {
            'has-menu-opened': this.state.menuIsOpen,
          })}
        >
          <ul onClick={this.closeMenu}>
            <li>
              <a onClick={this.openMenu} onBlur={this.openMenu}>
                Produkte
              </a>
            </li>

            <li>
              <a onClick={this.openMenu} onBlur={this.openMenu}>
                Rezepte
              </a>
            </li>
            <li className="logo">
              <Link onClick={this.closeMenu} to="/">
                {this.state.menuIsOpen ? (
                  <img src={logoMenuBlue} alt="logo" />
                ) : (
                  <React.Fragment>
                    <img className="logo-standard" src={logo} alt="logo" />
                    <img className="logo-white" src={logoWhite} alt="logo" />
                  </React.Fragment>
                )}
              </Link>
            </li>
            <li>
              <a onClick={this.openMenu} onBlur={this.openMenu}>
                Kompetenzen
              </a>
            </li>
            <li>
              <a onClick={this.openMenu} onBlur={this.openMenu}>
                Wir über uns
              </a>
            </li>
          </ul>
          <div className="header-icons">
            <div className="header-icons-inner-wrapper">
              <div className="login-icon">
                {!this.props.userId ? (
                  <Icon
                    classname="icon-anonymous"
                    name={loggedOutSVG}
                    onClick={this.openLogin}
                  />
                ) : (
                  <Link to="/merkliste">
                    <Icon classname="icon-loggedin" name={loggedInSVG} />
                  </Link>
                )}
                {this.props.userId && (
                  <div className="profile-tools">
                    <span>Mein Profil</span>
                    <Link style={{ textDecoration: 'underline' }} to="/logout">
                      Abmelden
                    </Link>
                    {canReindex && (
                      <a
                        style={{ textDecoration: 'underline' }}
                        href="/ClassicUI/@@vist_to_solr"
                        target="_blank"
                      >
                        Indexieren
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <CSSTransition
            in={this.state.menuIsOpen}
            timeout={500}
            classNames="desktop-menu"
            unmountOnExit
          >
            <Container
              key="myuniquekey"
              style={{
                position: 'absolute',
                right: 0,
                left: 0,
                zIndex: 2,
                clipPath:
                  'polygon(47% 10%, 53% 0px, 100% 0px, 100% 100%, 0px 100%, 0px 0px, 41% 0px)',
              }}
            >
              <div className="desktop-menu-wrapper">
                <Grid>
                  <Grid.Column width={3}>
                    <h3>Produkte</h3>
                    <ul>
                      <li>
                        <Link onClick={this.closeMenu} to="/alle-produkte">
                          Alle Produkte
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/produkte/produktneuheiten"
                        >
                          Produktneuheiten
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/aktuelles/clean_label_produkte"
                        >
                          Clean Label
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/produkte/urgetreide"
                        >
                          Urgetreide
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/alle-produkte?category=17"
                        >
                          Serviceartikel
                        </Link>
                      </li>
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <h3>Rezepte</h3>
                    <ul>
                      <li>
                        <Link onClick={this.closeMenu} to="/alle-rezepte">
                          Alle Rezepte
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/alle-rezepte?aktuell=1"
                        >
                          Rezeptneuheiten
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/rezepte/rezeptwelten"
                        >
                          Rezeptwelten
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/rezepte/grundrezepte"
                        >
                          Grundrezepte
                        </Link>
                      </li> */}
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <h3>Kompetenzen</h3>
                    <ul>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/produkte/funktioniert-natuerlich"
                        >
                          funktioniert - natürlich!
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/kompetenzen/technologien"
                        >
                          Technologien
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/kompetenzen/qualitaetsmanagement"
                        >
                          Qualitätsmanagement
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/kompetenzen/individuelle-loesungen"
                        >
                          Individuelle Lösungen
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/kompetenzen/verkaufskonzepte"
                        >
                          Verkaufskonzepte
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/kompetenzen/mediathek"
                        >
                          Mediathek
                        </Link>
                      </li>
                    </ul>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <h3>Wir über uns</h3>
                    <ul>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/ueber-zeelandia" // link might change before release
                        >
                          Über Zeelandia
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/ihre-ansprechpartner"
                        >
                          Kontakt / Anfahrt
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/karriere"
                        >
                          Karriere
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/aktuelles"
                        >
                          Aktuelles
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.closeMenu}
                          to="/wir-ueber-uns/international"
                        >
                          International
                        </Link>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid>
              </div>
            </Container>
          </CSSTransition>
        </div>
        <CSSTransition
          in={this.state.menuIsOpen}
          timeout={500}
          classNames="desktop-menu"
          unmountOnExit
        >
          <div
            key="myotheruniquekey"
            className="backdrop"
            onClick={this.closeMenu}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              zIndex: '1',
              background: 'rgba(255,255,255,0.85)',
            }}
          />
        </CSSTransition>
        <CSSTransition
          in={this.state.loginIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <ModalLoginForm
            pathname={this.props.pathname}
            closeMenu={this.closeMenu}
            openRegister={this.openRegister}
          />
        </CSSTransition>
        <CSSTransition
          in={this.state.registerIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <ModalRegisterForm closeMenu={this.closeMenu} />
        </CSSTransition>
        <CSSTransition
          in={this.state.loginIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchauxmenubackdrop"
            className="backdrop"
            onClick={this.closeMenu}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              zIndex: '3',
              background: 'rgba(255, 240, 66, 0.75)',
            }}
          />
        </CSSTransition>
        <CSSTransition
          in={this.state.registerIsOpen}
          timeout={500}
          classNames="desktop-search"
          unmountOnExit
        >
          <div
            key="searchauxmenubackdrop"
            className="backdrop"
            onClick={this.closeMenu}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              zIndex: '3',
              background: 'rgba(255, 240, 66, 0.75)',
            }}
          />
        </CSSTransition>
      </header>
    );
  }
}

export default compose(
  withRouter,
  connect((state, props) => ({
    userActions: state.actions.actions.user,
    userId: state.userSession.token
      ? jwtDecode(state.userSession.token).sub
      : '',
    openRegister: qs.parse(props.location.search).openRegister || false,
  })),
)(Header);
