import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const UnsavedChangesWarning = ({
  condition,
  message = 'Sie haben das Objekt bearbeitet. Wollen Sie die Seite wirklich verlassen?',
}) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (condition) {
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [condition, message]);

  return <Prompt when={condition} message={message} />;
};

export default UnsavedChangesWarning;
