import { has } from 'lodash';
import {
  GET_GRUPPEN,
  GET_GRUPPEBYID,
  CREATE_GRUPPE,
  DELETE_GRUPPE,
  UPDATE_GRUPPE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {
    gruppen: [],
  },
};

export function gruppen(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_GRUPPEN}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_GRUPPEN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { gruppen: [], total_pages: 0 },
      };
    case `${GET_GRUPPEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {
          gruppen: [],
        },
      };
    default:
      return state;
  }
}

export function gruppeByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_GRUPPEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_GRUPPEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_GRUPPEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function gruppeCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_GRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_GRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_GRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function gruppeDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_GRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_GRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_GRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function gruppeUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_GRUPPE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_GRUPPE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_GRUPPE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
