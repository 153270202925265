/**
 * recipes items reducer.
 * @module reducers/recipes
 */
import { has } from 'lodash';

import { GET_RECIPES } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
  subrequests: {},
};

/**
 * recipes reducer.
 * @function recipes
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */

export default function recipes(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_RECIPES}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: [],
                }),
                loaded: false,
                loading: true,
                error: null,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_RECIPES}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: [],
                }),
                error: null,
                loading: false,
                loaded: true,
                data: has(action, 'result') ? [...action.result] : [],
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: false,
            loaded: true,
            data: has(action, 'result') ? [...action.result] : [],
          };
    case `${GET_RECIPES}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: [],
                }),
                error: action.error,
                loading: false,
                loaded: false,
                data: [],
              },
            },
          }
        : {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
            data: [],
          };
    default:
      return state;
  }
}
