import { has } from 'lodash';
import {
  GET_PRODUKTE,
  GET_PRODUKTBYID,
  CREATE_PRODUKT,
  CHECK_PRODUKTE,
  DELETE_PRODUKT,
  UPDATE_PRODUKT,
  GET_PRODUKTE_NAMES,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function produkte(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUKTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUKTE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { produkte: [], total_pages: 0 },
      };
    case `${GET_PRODUKTE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function produkteNames(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUKTE_NAMES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUKTE_NAMES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? [...action.result] : [],
      };
    case `${GET_PRODUKTE_NAMES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function produkteCheck(state = initialState, action = {}) {
  switch (action.type) {
    case `${CHECK_PRODUKTE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CHECK_PRODUKTE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? action.result : '',
      };
    case `${CHECK_PRODUKTE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function produktByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUKTBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_PRODUKTBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_PRODUKTBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function produktCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_PRODUKT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_PRODUKT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_PRODUKT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function produktDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_PRODUKT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_PRODUKT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_PRODUKT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function produktUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_PRODUKT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_PRODUKT}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_PRODUKT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
