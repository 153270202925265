/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { BodyClass } from '@plone/volto/helpers';
import { MixedTitle } from '../../../../../components';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => (
  <div className="notfound-wrapper">
    <BodyClass className="view-notfound" />
    <div>
      <div className="lead-pill">
        <h1>
          <MixedTitle
            light="Fehler 404"
            strong="Die Seite existiert leider nicht."
            reverse
            breakline
          />
        </h1>
      </div>
      <div className="secondary-pill">
        <p className="mobile hidden">
          <strong>
            Entschuldigung, aber die Webseite die Sie versucht haben zu
            erreichen ist hier nicht verfügbar.
          </strong>
          <br />
          <br />
          Bitte benutzen Sie die aufgeführten Verweise um zu finden was Sie
          gesucht haben. <br />
          <br />
          Wenn Sie sicher sind, dass Sie die richtige Adresse eingegeben haben,
          kontaktieren Sie bitte den Verantwortlichen für die Webseite.
          <br /> <br />
          Vielen Dank.
        </p>
        <p className="mobile only">
          Entschuldigung, aber die Webseite die Sie versucht haben zu erreichen
          ist hier nicht verfügbar.
        </p>
      </div>
    </div>
  </div>
);

export default withServerErrorCode(404)(NotFound);
