import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const enhanceInformationen = (text) =>
  text.replace('<ul>', '<ul class="squared-list">');

const Informationen = ({ content }) => (
  <div className="product-info">
    <Grid centered>
      <Grid.Column
        mobile={12}
        computer={8}
        dangerouslySetInnerHTML={{
          __html: enhanceInformationen(content.text.data),
        }}
      >
        {/* <p>
          <strong>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </strong>
          <br /> Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
          amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua.onsequat, vel dolore eu feugiat.
        </p>
        <ul className="squared-list">
          <li>
            Lorem ipsum dolor sit amet, sed nonummy nibh euismod tincidunt ut
            laoreet magna aliquam erat volutpat.
          </li>
          <li>Quis nostrud exerci tation ullamcorp suscipit lobortis</li>
          <li>Uis autem vel eum iriure dolor in hendrerit in vulputate </li>
        </ul> */}
      </Grid.Column>
    </Grid>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Informationen.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default Informationen;
