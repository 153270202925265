import {
  SET_BATCHING_PARAMETERS,
  RESET_BATCHING_PARAMETERS,
} from '../../constants/ActionTypes';

const initialState = {
  skip: 0,
  sort_by: 'crdate',
  asc: false,
  searchTerms: '',
  currentPage: 0,
  currentListing: null,
  filters: {},
};

const initialSkip = 10;

export function batchingParameters(
  state = { ...initialState, limit: initialSkip },
  action = {},
) {
  switch (action.type) {
    case SET_BATCHING_PARAMETERS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_BATCHING_PARAMETERS:
      return {
        limit: state.limit,
        ...initialState,
        ...action.payload, // Override default values with provided options
      };
    default:
      return state;
  }
}
