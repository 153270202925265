import React from 'react';

import { Form } from 'semantic-ui-react';

const RezeptgruppeForm = ({
  formData,
  handleInputChange,
  isRezeptgruppeNameDuplicate,
}) => {
  return (
    <Form>
      <div className="bezug-info">
        <Form.Field error={isRezeptgruppeNameDuplicate}>
          <label>
            <strong>Name (*):</strong>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isRezeptgruppeNameDuplicate && (
          <div className="speech-bubble warning">
            Rezeptgruppe mit diesem Namen existiert bereits
          </div>
        )}
      </div>
      <p className="disclaimer">* Pflichtfeld</p>
    </Form>
  );
};

export default RezeptgruppeForm;
