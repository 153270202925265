import reducers from '@plone/volto/reducers';

import recipe from './recipe/recipe';
import recipes from './recipes/recipes';

import recipecategory from './recipecategory/recipecategory';
import product from './product/product';
import products from './products/products';
import productcategory from './productcategory/productcategory';
import listings from './listings/listings';
import categories from './categories/categories';
import recipecategories from './recipecategories/recipecategories';

import aktuelles from './homepage/aktuelles';
import jobs from './homepage/jobs';
import onapplyJobs from './jobs/jobs';
import highlights from './homepage/highlights';

import cart from './cart/cart';
import onRegisterNewsletter from './newsletter/newsletter';

import {
  rohstoffe,
  rohstoffeNames,
  rohstoffByID,
  rohstoffCreate,
  rohstoffeCheck,
  rohstoffUpdate,
  rohstoffDelete,
} from './rohstoffe/rohstoffe';

import {
  benutzer,
  benutzerCheck,
  benutzerByID,
  benutzerCreate,
  benutzerDelete,
  benutzerUpdate,
  ploneVistUsers,
} from './benutzer/benutzer';

import {
  produkte,
  produktByID,
  produkteCheck,
  produkteNames,
  produktCreate,
  produktUpdate,
  produktDelete,
} from './produkte/produkte';

import {
  gebindeByID,
  gebindeCreate,
  gebindeUpdate,
  gebindeDelete,
} from './gebinde/gebinde';

import {
  rezepte,
  grundrezepte,
  deletedRezepte,
  rezeptByID,
  rezeptNutrients,
  rohstoffKosten,
  rezeptCreate,
  rezeptUpdate,
  rezeptDelete,
  rezepteCheck,
} from './rezepte/rezepte';

import {
  bezuege,
  bezugByID,
  bezugCreate,
  bezugUpdate,
  bezugDelete,
} from './bezuege/bezuege';

import {
  zutatengruppen,
  zutatengruppeByID,
  zutatengruppeCreate,
  zutatengruppeUpdate,
  zutatengruppeDelete,
} from './zutatengruppen/zutatengruppen';

import {
  zutaten,
  zutatByID,
  zutatCreate,
  zutatUpdate,
  zutatDelete,
} from './zutaten/zutaten';

import {
  verkaufsinformationByID,
  verkaufsinformationUpdate,
  verkaufsinformationCreate,
} from './verkaufsinformation/verkaufsinformation';

import {
  gruppen,
  gruppeByID,
  gruppeCreate,
  gruppeDelete,
  gruppeUpdate,
} from './gruppen/gruppen';

import {
  rezeptkategorieByID,
  rezeptkategorieCreate,
  rezeptkategorieDelete,
  rezeptkategorieUpdate,
  rezeptkategorien,
} from './rezeptkategorien/rezeptkategorien';

import {
  rezeptgruppeByID,
  rezeptgruppeCreate,
  rezeptgruppeDelete,
  rezeptgruppeUpdate,
  rezeptgruppen,
} from './rezeptgruppen/rezeptgruppen';

import {
  produktkategorieByID,
  produktkategorieCreate,
  produktkategorieDelete,
  produktkategorieUpdate,
  produktkategorien,
} from './produktkategorien/produktkategorien';

import {
  rohstoffkategorieByID,
  rohstoffkategorieCreate,
  rohstoffkategorieDelete,
  rohstoffkategorieUpdate,
  rohstoffkategorien,
} from './rohstoffkategorien/rohstoffkategorien';

import { permissions } from './permissions/permissions';

import { batchingParameters } from './batchingParameters/batchingParameters';

const localReducers = {
  ...reducers,
  aktuelles,
  cart,
  categories,
  recipecategories,
  highlights,
  jobs,
  onapplyJobs,
  listings,
  product,
  productcategory,
  products,
  recipe,
  recipes,
  recipecategory,
  onRegisterNewsletter,
  rohstoffe,
  rohstoffeNames,
  rohstoffByID,
  rohstoffeCheck,
  rohstoffCreate,
  rohstoffUpdate,
  rohstoffDelete,
  benutzer,
  benutzerByID,
  benutzerCheck,
  benutzerCreate,
  benutzerUpdate,
  benutzerDelete,
  ploneVistUsers,
  produkte,
  produkteNames,
  produktByID,
  produktCreate,
  produkteCheck,
  produktUpdate,
  produktDelete,
  gebindeByID,
  gebindeCreate,
  gebindeUpdate,
  gebindeDelete,
  rezepte,
  rezeptNutrients,
  rohstoffKosten,
  grundrezepte,
  deletedRezepte,
  rezeptByID,
  rezeptCreate,
  rezeptUpdate,
  rezeptDelete,
  rezepteCheck,
  bezuege,
  bezugByID,
  bezugCreate,
  bezugUpdate,
  bezugDelete,
  zutatengruppen,
  zutatengruppeByID,
  zutatengruppeCreate,
  zutatengruppeUpdate,
  zutatengruppeDelete,
  zutaten,
  zutatByID,
  zutatCreate,
  zutatUpdate,
  zutatDelete,
  verkaufsinformationByID,
  verkaufsinformationUpdate,
  verkaufsinformationCreate,
  gruppen,
  gruppeByID,
  gruppeCreate,
  gruppeDelete,
  gruppeUpdate,
  rezeptkategorieByID,
  rezeptkategorieCreate,
  rezeptkategorieDelete,
  rezeptkategorieUpdate,
  rezeptkategorien,
  rezeptgruppeByID,
  rezeptgruppeCreate,
  rezeptgruppeDelete,
  rezeptgruppeUpdate,
  rezeptgruppen,
  produktkategorieByID,
  produktkategorieCreate,
  produktkategorieDelete,
  produktkategorieUpdate,
  produktkategorien,
  rohstoffkategorieByID,
  rohstoffkategorieCreate,
  rohstoffkategorieDelete,
  rohstoffkategorieUpdate,
  rohstoffkategorien,
  permissions,
  batchingParameters,
};

export default localReducers;
