/**
 * Recipe category actions.
 * @module actions/recipecategory
 */

import {
  GET_RECIPE_CATEGORY,
  RESET_RECIPE_CATEGORY,
} from '../../constants/ActionTypes';

/**
 * Get recipe category items.
 * @function getRecipeCategory
 * @param {string} id Category id
 * @returns {Object} Recipe category action.
 */
export function getRecipeCategory(id, size = 500) {
  return {
    type: GET_RECIPE_CATEGORY,
    request: {
      op: 'get',
      path: `/@search?category=${id}&portal_type=jungzeelandia.Recipe&use_solr=true&b_size=${size}&all=1&sort_on=created&sort_order=descending`,
    },
  };
}

/**
 * Reset recipe category function.
 * @function resetRecipeCategory
 * @returns {Object} Reset recipe category action.
 */
export function resetRecipeCategory() {
  return {
    type: RESET_RECIPE_CATEGORY,
  };
}
