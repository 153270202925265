/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { BodyClass } from '@plone/volto/helpers';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { Container, Card, Image } from 'semantic-ui-react';
import { MixedTitle } from '../.';

import missingIMG from '../../static/missing.jpg';
import downloadSVG from '../../static/download.svg';
import textSVG from '../../static/texticon.svg';

class SocialMedia extends Component {
  static propTypes = {
    content: PropTypes.shape({
      layout: PropTypes.string,
      allow_discussion: PropTypes.bool,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ButtonCopy: 'Kopieren',
      CodeToCopy: this.props.content.text.data,
      loaded: false,
    };
  }

  isLoaded = () => {
    if (document) {
      this.setState({ loaded: true });
    }
  };

  componentDidMount() {
    this.isLoaded();
  }

  render() {
    const { content } = this.props;

    const GridBlock = ({ image, url }) => (
      <Card key={image.filename}>
        <Image src={image.scales.preview.download} className="socialmedia" />
        <Card.Content extra style={{ padding: '0' }}>
          <div style={{ textAlign: 'right' }}>
            <span>
              <a href={`${content['@id']}/@@download/${url}`}>
                <img src={downloadSVG} alt="download" />
              </a>
            </span>
          </div>
        </Card.Content>
      </Card>
    );

    return (
      <section style={{ marginBottom: '50px' }}>
        <BodyClass className="socialmedia" />
        <Helmet title={content.title} />
        <Container>
          <div className="product-hero">
            {content.big_image !== null && content.big_image.download ? (
              <img
                className="product-hero-img"
                src={content.big_image.download}
                alt="social-media"
              />
            ) : (
              <img
                className="product-hero-img"
                src={missingIMG}
                alt="placeholder"
              />
            )}
            <div className="product-hero-body">
              <h1>
                <MixedTitle reverse>{content.title}</MixedTitle>
              </h1>
              {content.description && (
                <p className="description">{content.description}</p>
              )}
            </div>
          </div>
          <div className="details">
            {content.big_image !== null && content.big_image.download && (
              <h3 className="image download">
                Bild-Download
                <span>
                  <a
                    href={`${content['@id']}/@@download/big_image`}
                    style={{ display: 'flex' }}
                  >
                    <div className="yellow imagetype">
                      {content.big_image.download === 'image/svg' ? (
                        <h4>SVG</h4>
                      ) : content.big_image.download === 'image/png' ? (
                        <h4>PNG</h4>
                      ) : (
                        <h4>JPG</h4>
                      )}
                    </div>
                    <img src={downloadSVG} alt="download" />
                  </a>
                </span>
              </h3>
            )}
            {content.big_image_no_logos !== null &&
              content.big_image_no_logos.download && (
                <h3
                  className="image download nologo"
                  style={{ marginTop: '10px' }}
                >
                  Bild-Download (ohne Logo & Schrift)
                  <span>
                    <a
                      href={`${content['@id']}/@@download/big_image_no_logos`}
                      style={{ display: 'flex' }}
                    >
                      <div className="yellow imagetype">
                        {content.big_image_no_logos.download === 'image/svg' ? (
                          <h4>SVG</h4>
                        ) : content.big_image_no_logos.download ===
                          'image/png' ? (
                          <h4>PNG</h4>
                        ) : (
                          <h4>JPG</h4>
                        )}
                      </div>
                      <img src={downloadSVG} alt="download" />
                    </a>
                  </span>
                </h3>
              )}
            <div
              className={cx('text-wrapper', {
                line:
                  content.instagram_image1 ||
                  content.instagram_image2 ||
                  content.instagram_image3 ||
                  content.instagram_image4 ||
                  content.instagram_image5 ||
                  content.instagram_image6 ||
                  content.instagram_image7 ||
                  content.instagram_image8,
              })}
            >
              <div className="headline">
                <h3>{content.title}</h3>
                <span>
                  {content.text.data.length > 22 &&
                    this.state.loaded &&
                    document.queryCommandSupported('copy') && (
                      <a
                        onClick={() => {
                          navigator.clipboard.writeText(
                            this.state.CodeToCopy.replace(/<li>/g, '\n')
                              .replace(/<a href="(.*?)">/g, ' ')
                              .replace(/<h2>/g, 'n')
                              .replace(/<h3>/g, 'n')
                              .replace(/<blockquote>/g, ' ')
                              .replace(/<\/p>/g, '\n')
                              .replace(/<p class="tile callout">/g, ' ')
                              .replace(/<(.*?)>/g, ''),
                          );
                          this.setState(() => ({
                            ButtonCopy: 'Kopiert',
                          }));
                        }}
                        className={cx('copy-to-clipboard', {
                          copied: this.state.ButtonCopy === 'Kopiert',
                        })}
                        style={{ display: 'flex' }}
                      >
                        {this.state.ButtonCopy !== 'Kopieren' ? (
                          <div className="yellow copy">
                            <h4>KOPIERT!</h4>
                          </div>
                        ) : (
                          <div className="yellow copy">
                            <h4>TEXT KOPIEREN</h4>
                          </div>
                        )}

                        <img className="text" src={textSVG} alt="download" />
                      </a>
                    )}
                </span>
              </div>
              {content.text.data.length >= 22 && (
                <div
                  id="text"
                  className="text"
                  dangerouslySetInnerHTML={{ __html: content.text.data }}
                />
              )}
            </div>
            {(content.instagram_image1 ||
              content.instagram_image2 ||
              content.instagram_image3 ||
              content.instagram_image4 ||
              content.instagram_image5 ||
              content.instagram_image6 ||
              content.instagram_image7 ||
              content.instagram_image8) && (
              <h3 style={{ marginTop: '15px' }}>
                Instagram Story Bilder Downloads
              </h3>
            )}
            <Card.Group
              stackable
              centered
              itemsPerRow={4}
              style={{ marginBottom: '24px' }}
            >
              {content.instagram_image1 && (
                <GridBlock
                  image={content.instagram_image1}
                  url="instagram_image1"
                />
              )}
              {content.instagram_image2 && (
                <GridBlock
                  image={content.instagram_image2}
                  url="instagram_image2"
                />
              )}
              {content.instagram_image3 && (
                <GridBlock
                  image={content.instagram_image3}
                  url="instagram_image3"
                />
              )}
              {content.instagram_image4 && (
                <GridBlock
                  image={content.instagram_image4}
                  url="instagram_image4"
                />
              )}
              {content.instagram_image5 && (
                <GridBlock
                  image={content.instagram_image5}
                  url="instagram_image5"
                />
              )}
              {content.instagram_image6 && (
                <GridBlock
                  image={content.instagram_image6}
                  url="instagram_image6"
                />
              )}
              {content.instagram_image7 && (
                <GridBlock
                  image={content.instagram_image7}
                  url="instagram_image7"
                />
              )}
              {content.instagram_image8 && (
                <GridBlock
                  image={content.instagram_image8}
                  url="instagram_image8"
                />
              )}
            </Card.Group>
          </div>
        </Container>
      </section>
    );
  }
}

export default SocialMedia;
