import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Grid,
  Button,
  Item,
  Card,
  Image,
  Container,
  Segment,
} from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import { MixedTitle } from '..';
import squaredArrowSVG from '../../static/squared-arrow.svg';
import arrowSVG from '../../icons/arrow.svg';

const HomepageNews = ({ news, jobs }) => (
  <Segment
    basic
    as="section"
    className="aktuelles"
    style={{ marginBottom: '100px' }}
  >
    <Container>
      <Grid stackable>
        <Grid.Column tablet={12} computer={6} className="main-column">
          <div style={{ paddingBottom: '40px' }}>
            <div style={{ marginBottom: '65px' }} className="underlined-title">
              <span>Aktuelles</span>
            </div>
            <Card.Group stackable centered itemsPerRow={2}>
              {news.map((item) => (
                <Card key={item['@id']} as={Link} to={item.url}>
                  {item.image && <Image src={item.image.download} />}
                  <Card.Content>
                    <div className="underlined-title">
                      <span>
                        {moment(item.effective || item.created).format(
                          'DD.M.YYYY',
                        )}
                      </span>
                    </div>
                    <Card.Header>{item.title}</Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    <div style={{ textAlign: 'right' }}>
                      <Button className="small brandButtonSecondary ">
                        <MixedTitle>Mehr lesen</MixedTitle>
                        <Icon name={arrowSVG} size="17px" className="right" />
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </div>
        </Grid.Column>
        <Grid.Column tablet={12} computer={1} />
        <Grid.Column tablet={12} computer={5} className="secondary-column">
          <div style={{ marginBottom: '65px' }} className="underlined-title">
            <span>Newsletter</span>
          </div>
          <div style={{ marginBottom: '55px' }}>
            <h3>Im Fokus</h3>
            <p>
              Erhalten Sie regelmäßig Neuigkeiten zu unseren Produkten, Rezepten
              und Anwendungsrezepturen.
            </p>
            <Link
              className="ui button large brandButtonSecondary"
              to="/mail-register"
              target="_blank"
            >
              <MixedTitle>Hier anmelden</MixedTitle>
              <Icon name={arrowSVG} size="23px" className="right" />
            </Link>
          </div>
          <div style={{ marginBottom: '65px' }} className="underlined-title">
            <span>Aktuelle Stellenangebote</span>
          </div>

          <Item.Group unstackable style={{ marginBottom: '34px' }}>
            {jobs.map((item) => (
              <Item key={item.linkJobAd}>
                <Item.Image src={squaredArrowSVG} />
                <Item.Content>
                  <Item.Header>
                    <a href={item.linkJobAd} target="_blank" rel="noreferrer">
                      {item.name}
                    </a>
                  </Item.Header>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>

          <Button
            className="medium brandButtonSecondary"
            as={Link}
            to="/wir-ueber-uns/karriere/stellenangebote"
          >
            <MixedTitle>Alle Stellenangebote</MixedTitle>
            <Icon name={arrowSVG} size="23px" className="right" />
          </Button>
        </Grid.Column>
      </Grid>
    </Container>
  </Segment>
);

HomepageNews.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
    }),
  ),
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
    }),
  ),
};

HomepageNews.defaultProps = {
  news: [],
  jobs: [],
};

export default HomepageNews;
