import React from 'react';

import { Button, Form } from 'semantic-ui-react';

const GebindeForm = ({
  gebinde,
  addGebinde,
  handleGebindeChange,
  removeGebinde,
  isIncompleteGebinde,
}) => {
  return (
    <div className="gebindeForm">
      <div className="row">
        <p>
          <strong>Artikelnummer (*):</strong>
        </p>
        <p>
          <strong>Gebindeart (*):</strong>
        </p>
        <p>Zugabe in %:</p>
      </div>
      {gebinde &&
        gebinde.map((gebinde_singular, index) => (
          <React.Fragment key={index}>
            <div className="row" key={index}>
              <Form.Field error={gebinde_singular.artikelnummer === ''}>
                <input
                  type="text"
                  name="artikelnummer"
                  maxLength={6}
                  onChange={(e) => handleGebindeChange(e, index)}
                  value={gebinde_singular.artikelnummer}
                />
              </Form.Field>
              <Form.Field error={gebinde_singular.name === ''}>
                <input
                  type="text"
                  name="name"
                  list="gebinde-suggestions"
                  onChange={(e) => handleGebindeChange(e, index)}
                  value={gebinde_singular.name}
                  autoComplete="off"
                />
                <datalist id="gebinde-suggestions">
                  <option value="Big Bag, 550 kg" />
                  <option value="Big Bag, 600 kg" />
                  <option value="Papiersack, 25 kg" />
                  <option value="Papiersack, 15 kg" />
                  <option value="Papiersack, 12,5 kg" />
                  <option value="Papiersack, 10 kg" />
                  <option value="Karton, 2,5 kg" />
                  <option value="Karton, 5 kg" />
                  <option value="Karton, 15 kg (3 x 5 kg Beutel)" />
                  <option value="Stange, 15 kg" />
                  <option value="Eimer, 6 kg" />
                  <option value="Eimer, 12 kg" />
                  <option value="Eimer, 12, 5 kg" />
                  <option value="Eimer, 25 kg" />
                  <option value="Karton, 3,6 L" />
                  <option value="Kanister, 5 L " />
                  <option value="Kanister, 15 L " />
                  <option value="Container, 1000 L" />
                </datalist>
              </Form.Field>
              <Form.Field>
                <input
                  type="text"
                  name="zugabe"
                  onChange={(e) => handleGebindeChange(e, index)}
                  value={gebinde_singular.zugabe}
                />
              </Form.Field>
              <Form.Field>
                <Button onClick={(e) => removeGebinde(e, index)}>
                  Löschen
                </Button>
              </Form.Field>
            </div>
          </React.Fragment>
        ))}

      <div className="row add">
        <Button onClick={addGebinde} disabled={isIncompleteGebinde}>
          Gebinde Hinzufügen
        </Button>
      </div>
    </div>
  );
};

export default GebindeForm;
