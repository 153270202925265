import React from 'react';

/**
 * This component returns the string given in the children with the markup for
 * making the last word bold and the rest in light (depending in CSS). If
 * parameters are given (and no children) then the light and strong props are
 * the valid ones
 *
 * @param {any} { light, strong, reverse, breakline, children }
 * @returns
 */
const MixedTitle = ({
  light,
  strong,
  reverse = false,
  breakline = false,
  children,
}) => {
  if (children) {
    strong = children.split(' ').pop(-1);
    light = children.substring(0, children.lastIndexOf(' '));
  }

  if (reverse) {
    return (
      <span>
        <strong>{light} </strong>
        {breakline && <br />}
        {strong}
      </span>
    );
  }

  return (
    <span>
      {light} {breakline && <br />}
      <strong>{strong}</strong>
    </span>
  );
};

export default MixedTitle;
