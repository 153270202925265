import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import {
  getRezepte,
  setBatchingParameters,
  getProdukte,
  getRohstoffe,
} from '../../actions';
const NextPreviousButtons = ({
  currentItem,
  itemlist,
  totalPages,
  batching_params,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (itemlist && batching_params) {
    const current_pos = itemlist.findIndex(
      (item) => item.id === parseInt(currentItem),
    );

    const load_new_listing_page = (for_previous_page) => {
      const newPage = for_previous_page
        ? batching_params.currentPage - 1
        : batching_params.currentPage + 1;

      const new_batching_params = {
        ...batching_params,
        skip: newPage * batching_params.limit,
        currentPage: newPage,
      };

      dispatch(setBatchingParameters(new_batching_params));

      if (batching_params.currentListing === 'rezepte') {
        dispatch(
          getRezepte({
            batchingParameters: new_batching_params,
          }),
        ).then((result) => {
          const target_id = for_previous_page
            ? result?.rezepte?.[result.rezepte.length - 1].id
            : result?.rezepte?.[0].id;

          history.push(
            `/controlpanel/rezeptdatenbank/${batching_params?.currentListing}/details?id=${target_id}`,
          );
        });
      } else if (batching_params.currentListing === 'produkte') {
        dispatch(
          getProdukte({
            batchingParameters: new_batching_params,
          }),
        ).then((result) => {
          const target_id = for_previous_page
            ? result?.produkte?.[result.produkte.length - 1].id
            : result?.produkte?.[0].id;

          history.push(
            `/controlpanel/rezeptdatenbank/${batching_params?.currentListing}/details?id=${target_id}`,
          );
        });
      } else if (batching_params.currentListing === 'rohstoffe') {
        dispatch(
          getRohstoffe({
            batchingParameters: new_batching_params,
          }),
        ).then((result) => {
          const target_id = for_previous_page
            ? result?.rohstoffe?.[result.rohstoffe.length - 1].id
            : result?.rohstoffe?.[0].id;

          history.push(
            `/controlpanel/rezeptdatenbank/${batching_params?.currentListing}/details?id=${target_id}`,
          );
        });
      }
    };

    const PrevItemButton = (props) => {
      const prevItem = itemlist[current_pos - 1];

      if (current_pos === 0 && batching_params.currentPage > 0) {
        // Rezept ist erstes auf Seite und es gibt davor weitere Seiten
        return (
          <Button onClick={() => load_new_listing_page(true)}>{'<'}</Button>
        );
      } else if (current_pos > 0) {
        // Rezept ist nicht erstes auf der Seite

        return (
          <Link
            className="ui button"
            to={`/controlpanel/rezeptdatenbank/${batching_params?.currentListing}/details?id=${prevItem.id}`}
          >
            {'<'}
          </Link>
        );
      }
      return null;
    };

    const NextItemButton = (props) => {
      const nextItem = itemlist[current_pos + 1];

      if (
        current_pos === itemlist.length - 1 &&
        batching_params.currentPage !== totalPages - 1
      ) {
        // Rezept ist letztes auf Seite und es gibt danach weitere Seiten
        return (
          <Button onClick={() => load_new_listing_page(false)}>{'>'}</Button>
        );
      } else if (current_pos < itemlist.length - 1) {
        // Rezept ist nicht letztes auf der Seite

        return (
          <Link
            className="ui button"
            to={`/controlpanel/rezeptdatenbank/${batching_params?.currentListing}/details?id=${nextItem.id}`}
          >
            {'>'}
          </Link>
        );
      }
      return null;
    };

    return (
      <div className="next-prev-buttons">
        <PrevItemButton />
        <NextItemButton />
      </div>
    );
  } else {
    return <></>;
  }
};

export default NextPreviousButtons;
