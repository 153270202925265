import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Table, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { getRecipe } from '../../actions';
import { MixedTitle } from '../.';
import arrowSVG from '../../icons/arrow.svg';

import config from '@plone/volto/registry';

const localStorageKey = 'rohstoffkostenrechner';

/**
 *
 *
 * @export
 * @class Rezept
 * @extends {Component}
 */
class Rezept extends Component {
  static propTypes = {
    rezept: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        '@id': PropTypes.string,
      }),
      data: PropTypes.shape({
        units: PropTypes.shape({
          int: PropTypes.number,
        }),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.required,
            amount: PropTypes.string.required,
            display_amount: PropTypes.string.required,
            ingredients: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string.required,
                amount: PropTypes.string.required,
                display_amount: PropTypes.string.required,
                is_base_recipe: PropTypes.bool.required,
                is_product: PropTypes.bool.required,
                pdf_display_name: PropTypes.string.required,
              }),
            ),
          }).required,
        ),
      }),
      recipe: PropTypes.shape(),
    }),
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      units: props.rezept.data.units.float,
      displayUnits: this.toDisplayUnits(props.rezept.data.units.float),
      amount: props.rezept.data.amount,
      displayAmount: this.toDisplayUnits(props.rezept.data.amount),
      calculateType: 'units',
      baseUnits: props.rezept.data.units.float,
      prices: {},
      displayPrices: {},
      totals: {},
      recipeTotal: 0,
      rezept: { ...props.rezept },
    };
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeUnits = this.handleChangeUnits.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    this.handleCalculate = this.handleCalculate.bind(this);
    this.handleChangeCalculateType = this.handleChangeCalculateType.bind(this);
    this.toDisplayUnits = this.toDisplayUnits.bind(this);
  }

  toFloatString(float, style = 'number') {
    if (isNaN(float)) {
      float = 1.0;
    }
    return this.props.intl.formatNumber(float, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: style,
      currency: style === 'currency' ? 'EUR' : undefined,
    });
  }

  toFloat(string) {
    string = string.replace(',', '.');
    let value = parseFloat(string);
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.recipe.loading && nextProps.recipe.loaded) {
      this.setState(
        {
          rezept: nextProps.rezept,
          units: nextProps.rezept.data.units.float,
          displayUnits: this.toDisplayUnits(nextProps.rezept.data.units.float),
          amount: nextProps.rezept.data.amount,
          displayAmount: this.toDisplayUnits(nextProps.rezept.data.amount),
        },
        () => {
          this.calculateAll();
        },
      );
    }
  }

  componentDidMount() {
    // localStorage is not available when a component is rendered
    // serverside. It should always be available here.
    let prices = {};
    let displayPrices = {};
    let storedPrices = localStorage.getItem(localStorageKey);
    if (storedPrices) {
      storedPrices = JSON.parse(storedPrices);
      prices = storedPrices.prices;
      displayPrices = storedPrices.displayPrices;
      this.setState({ prices: prices, displayPrices: displayPrices });
    }
  }

  toDisplayUnits(value, decimalPlaces = 2) {
    let rest = value % 1;
    let places = rest === 0 ? 0 : 2;
    let displayUnits = value.toFixed(places);
    return displayUnits.replace('.', ',');
  }

  calculateTotals() {
    let totals = {};
    let prices = this.state.prices;
    // eslint-disable-next-line array-callback-return
    this.state.rezept.data.ingredientGroups.map((group) => {
      // eslint-disable-next-line array-callback-return
      group.ingredients.map((ingredient) => {
        let key = ingredient.key;
        let price = prices[key] ? prices[key] : 0;
        totals[key] = (price * ingredient.amount) / 1000;
      });
    });
    return totals;
  }

  calculateAll() {
    let totals = this.calculateTotals();
    let recipeTotal = 0;
    Object.keys(totals).forEach((key) => {
      recipeTotal = recipeTotal + totals[key];
    });
    this.setState({
      totals: totals,
      recipeTotal: recipeTotal,
    });
  }

  handleCalculate(event) {
    event.preventDefault();
    if (this.state.calculateType === 'units') {
      this.props.getRecipe(this.state.rezept.id, {
        to_units: this.state.units.toFixed(4),
      });
    } else {
      this.props.getRecipe(this.state.rezept.id, {
        to_amount: this.state.amount.toFixed(4),
      });
    }
  }

  handleChangeCalculateType(event, { name, value }) {
    this.setState({
      calculateType: value,
    });
  }

  handleChangeUnits(event, { name, value }) {
    const unitsFloat = this.toFloat(value);
    this.setState({
      units: unitsFloat,
      displayUnits: value,
      amount: undefined,
      displayAmount: '',
    });
  }

  handleChangeAmount(event, { name, value }) {
    const amountFloat = this.toFloat(value);
    this.setState({
      amount: amountFloat,
      displayAmount: value,
      units: undefined,
      displayUnits: '',
    });
  }

  handleChangePrice(event, { name, value }) {
    let prices = { ...this.state.prices };
    let displayPrices = { ...this.state.displayPrices };
    prices[name] = this.toFloat(value);
    displayPrices[name] = value;
    let newData = {
      prices: prices,
      displayPrices: displayPrices,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(newData));
    this.setState(newData);
  }

  handleEnter(event) {
    if (event.key === 'Enter') {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index < form.elements.length - 1) {
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    }
  }

  render() {
    let download_value = this.state.units;
    let download_type = 'stueckzahl';
    if (this.state.calculateType === 'weight') {
      download_type = 'gewicht';
      download_value = this.state.amount;
    }
    const download_parameters = `id=${this.state.rezept.id}&${download_type}=${download_value}`;
    const download_url = `${config.settings.apiPath}/++api++/@@rezept-pdf?${download_parameters}`;
    return (
      <div className="rezepte-rezept">
        <Form onSubmit={this.handleCalculate}>
          <Container className="rezept-scale-controls">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={3} verticalAlign="top">
                  <Form.Field
                    className="units"
                    style={{
                      display:
                        this.state.calculateType === 'weight'
                          ? 'none'
                          : undefined,
                    }}
                  >
                    <Form.Input
                      onChange={this.handleChangeUnits}
                      name="units"
                      value={this.state.displayUnits}
                    />
                  </Form.Field>
                  <Form.Field
                    className="amount rezept-zutaten-only"
                    style={{
                      display:
                        this.state.calculateType === 'units'
                          ? 'none'
                          : undefined,
                    }}
                  >
                    <Form.Input
                      onChange={this.handleChangeAmount}
                      name="amount"
                      value={this.state.displayAmount}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={6} verticalAlign="top">
                  <label
                    htmlFor="units"
                    className="rezept-rohstoffkosten-only rezept-label-units"
                    style={{
                      display:
                        this.state.calculateType === 'weight'
                          ? 'none'
                          : undefined,
                    }}
                  >
                    Stück (Menge)
                  </label>
                  <Form.Radio
                    className="rezept-calculate-type rezept-zutaten-only"
                    label="Stück (Menge)"
                    value="units"
                    checked={this.state.calculateType === 'units'}
                    onChange={this.handleChangeCalculateType}
                  />
                  <Form.Radio
                    className="rezept-calculate-type rezept-zutaten-only"
                    label="Gramm (Gewicht)"
                    value="weight"
                    checked={this.state.calculateType === 'weight'}
                    onChange={this.handleChangeCalculateType}
                  />
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="top">
                  <Form.Field>
                    <Form.Button
                      className="small brandButtonSecondary"
                      type="submit"
                    >
                      <MixedTitle>Berechnen</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Form.Button>
                  </Form.Field>
                  <Form.Field className="rezept-zutaten-only">
                    <Button
                      className="small brandButtonSecondary download"
                      as="a"
                      href={download_url}
                    >
                      <MixedTitle>Herunterladen</MixedTitle>
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Table className="rezepte-rezept-table">
            {this.props.rohstoffkosten && (
              <Table.Header className="rezept-rohstoffkosten-only">
                <Table.Row>
                  <Table.HeaderCell>Rohstoff</Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="right"
                    className="rezept-rohstoffkosten-only"
                  >
                    Kosten/Kg
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Menge</Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="right"
                    className="rezept-rohstoffkosten-only"
                  >
                    Kosten
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}
            {this.state.rezept.data.ingredientGroups.map((group) => {
              return (
                <Table.Body key={group.name} className="rezepte-rezept-table">
                  <Table.Row className="ingredient-group-title">
                    <Table.Cell colSpan="4">{group.name}</Table.Cell>
                  </Table.Row>
                  {group.ingredients.map((ingredient) => {
                    let ingredientUrl;
                    if (ingredient.available && ingredient.product_id) {
                      ingredientUrl = `/alle-produkte/${ingredient.product_id}`;
                    } else if (ingredient.available && ingredient.recipe_id) {
                      ingredientUrl = `/alle-rezepte/${ingredient.recipe_id}`;
                    }
                    return (
                      <Table.Row key={ingredient.key} className="ingredient">
                        <Table.Cell>
                          {ingredientUrl ? (
                            <a
                              href={ingredientUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {ingredient.name}
                            </a>
                          ) : (
                            ingredient.name
                          )}
                        </Table.Cell>
                        {this.props.rohstoffkosten && (
                          <Table.Cell
                            textAlign="right"
                            className="rezept-rohstoffkosten-only rezepte-rezept-price"
                          >
                            <Form.Group>
                              {'€ '}
                              <Form.Input
                                name={ingredient.key}
                                className="price"
                                value={
                                  this.state.displayPrices[ingredient.key]
                                    ? this.state.displayPrices[ingredient.key]
                                    : ''
                                }
                                onChange={this.handleChangePrice}
                                onKeyPress={this.handleEnter}
                              />
                            </Form.Group>
                          </Table.Cell>
                        )}
                        <Table.Cell textAlign="right">
                          {ingredient.display_amount}
                        </Table.Cell>
                        {this.props.rohstoffkosten && (
                          <Table.Cell
                            textAlign="right"
                            className="rezept-rohstoffkosten-only"
                          >
                            {this.toFloatString(
                              this.state.totals[ingredient.key]
                                ? this.state.totals[ingredient.key]
                                : 0,
                              'currency',
                            )}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
                  <Table.Row className="rezept-zutaten-only ingredient-gesamtgewicht">
                    <Table.Cell>Gesamtgewicht</Table.Cell>
                    {this.props.rohstoffkosten && (
                      <Table.Cell className="rezept-rohstoffkosten-only" />
                    )}
                    <Table.Cell textAlign="right">
                      {group.display_amount}
                    </Table.Cell>
                    {this.props.rohstoffkosten && (
                      <Table.Cell className="rezept-rohstoffkosten-only" />
                    )}
                  </Table.Row>
                </Table.Body>
              );
            })}
            <Table.Body className="rezept-zutaten-only">
              <Table.Row className="recipe-gesamtgewicht">
                <Table.Cell>Gesamtgewicht Zutaten</Table.Cell>
                {this.props.rohstoffkosten && (
                  <Table.Cell className="rezept-rohstoffkosten-only" />
                )}
                <Table.Cell textAlign="right">
                  {this.state.rezept.data.display_amount}
                </Table.Cell>
                {this.props.rohstoffkosten && (
                  <Table.Cell className="rezept-rohstoffkosten-only" />
                )}
              </Table.Row>
            </Table.Body>
            {this.props.rohstoffkosten && (
              <Table.Body className="rezept-rohstoffkosten-only">
                <Table.Row className="ingredient-gesamtkosten">
                  <Table.Cell colSpan="3">Gesamtkosten</Table.Cell>
                  <Table.Cell colSpan="1" textAlign="right">
                    {this.toFloatString(this.state.recipeTotal, 'currency')}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </Form>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      recipe: state.recipe,
    }),
    { getRecipe },
  ),
)(Rezept);
