import { useEffect, useState } from 'react';
import { Modal, Search, Button, Form } from 'semantic-ui-react';
import { solrSearchContent } from '@kitconcept/volto-solr/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';

const ImageWidget = ({ handleInputChange, formData }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedImage({
      id: flattenToAppURL(formData.image?.['@id']),
      image_uuid: formData.image?.image_uuid,
    });
  }, [formData.image]);

  useEffect(() => {
    searchTerm.length > 0 &&
      dispatch(
        solrSearchContent(
          '/kompetenzen/mediathek/bilddatenbank',
          {
            SearchableText: searchTerm,
            allow_local: 'false',
            facet_conditions: '',
            group_select: '0',
            local: 'true',
            sort_order: '',
            types: ['Image'],
          },
          'image-selection-search',
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const results = useSelector(
    (state) => state.solrsearch.subrequests['image-selection-search'],
  );

  const resultItems = (results) =>
    results.items.map((item) => ({
      image: item.extras?.image_path?.replace('/Plone', ''),
      title: item.title,
      id: item?.['@id'],
      image_uuid: item.UID,
    }));

  const onResultSelect = (e, { result }) => {
    setSelectedImage(result);
  };

  const onSaveImage = (image) => {
    handleInputChange({
      target: {
        name: 'image_uuid',
        value: image ? image.image_uuid : '',
      },
    });
    handleInputChange({
      target: {
        name: 'image',
        value: image
          ? { '@id': image.id, '@type': 'Image', image_uuid: image.image_uuid }
          : {},
      },
    });
  };

  return (
    <>
      <Form.Field className="image-field">
        <label>Bild:</label>
        {formData.image?.image_uuid && (
          <img
            src={`${formData.image['@id']}/@@images/image`}
            alt=""
            className="hero-image"
          />
        )}
        <div className="image-buttons">
          {formData.image?.image_uuid && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSaveImage({});
              }}
            >
              Bild entfernen
            </Button>
          )}
          <Button
            onClick={(e) => {
              setOpen(true);
            }}
          >
            {formData.image_uuid ? 'Bild ersetzen' : 'Bild auswählen'}
          </Button>
        </div>
      </Form.Field>

      <Modal open={open} className="vist-image-modal">
        <Modal.Header>
          {formData.image_uuid ? 'Bild ersetzen' : 'Bild auswählen'}
        </Modal.Header>
        <Modal.Content>
          <Form>
            {selectedImage && (
              <img
                src={`${selectedImage.id}/@@images/image`}
                alt=""
                className="hero-image"
              />
            )}
            <p>
              <a
                target="_blank"
                href="/kompetenzen/mediathek/bilddatenbank/add?type=Image"
              >
                Neues Bild in Bilddatenbank hochladen
              </a>
            </p>
            <Form.Field>
              <label>Bild auswählen:</label>
              <Search
                loading={results?.loading}
                onResultSelect={onResultSelect}
                onSearchChange={(e) => setSearchTerm(e.target.value)}
                results={results && resultItems(results)}
                value={searchTerm}
              ></Search>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={(e) => {
              e.preventDefault();
              onSaveImage(selectedImage);
              setOpen(false);
            }}
          >
            Speichern
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            Abbrechen
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ImageWidget;
