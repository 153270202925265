import React from 'react';
import { Image, Grid, Item, Segment } from 'semantic-ui-react';

import telephoneSVG from '../../static/telephone.svg';
import faxSVG from '../../static/fax.svg';
import envelopeSVG from '../../static/envelope.svg';
import worldSVG from '../../static/world.svg';
import youtubeSVG from '../../static/youtube.svg';
import xingSVG from '../../static/xing.svg';
import linkedinSVG from '../../static/linkedin.svg';
import facebookSVG from '../../static/icn_facebook.svg';
import instagramSVG from '../../static/icn_instagram.svg';

const currentYear = new Date().getFullYear();

const MobileFooter = () => {
  return (
    <footer className="mobile-footer mobile only">
      <Segment basic className="footer">
        <Grid>
          <Grid.Column width={8} className="prefooter">
            <Item.Group divided unstackable>
              <Item as="a" href="tel:069 95055-0">
                <Item.Image src={telephoneSVG} />
                <Item.Content>
                  <Item.Header>069 95055-0</Item.Header>
                </Item.Content>
              </Item>
              <Item>
                <Item.Image src={faxSVG} />
                <Item.Content>
                  <Item.Header>069 95055-80</Item.Header>
                </Item.Content>
              </Item>
              <Item as="a" href="mailto:info@zeelandia.de">
                <Item.Image src={envelopeSVG} />
                <Item.Content>
                  <Item.Header>info@zeelandia.de</Item.Header>
                </Item.Content>
              </Item>
              <Item as="div">
                <Item.Image src={worldSVG} />
                <Item.Content>
                  <Item.Header>
                    Website Zeelandia International: <br />
                    <br /> <a href="https://zeelandia.com">www.zeelandia.com</a>
                  </Item.Header>
                  <br />
                  <br />
                  <Item.Header>
                    Wissensplattform Explore Zeelandia:
                    <br />
                    <br />
                    <a href="https://explore.zeelandia.com/de">
                      explore.zeelandia.com/de
                    </a>
                  </Item.Header>
                </Item.Content>
              </Item>
            </Item.Group>
            <p>Folgen Sie uns auf unseren Zeelandia Social Media Kanälen:</p>
            <div className="social-media">
              <ul>
                <li>
                  <Image
                    as="a"
                    href="https://www.youtube.com/c/ZeelandiaGmbHCoKG"
                    src={youtubeSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.xing.com/companies/zeelandiagmbh&co.kg"
                    src={xingSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.linkedin.com/company/zeelandia-gmbh-co-kg/"
                    src={linkedinSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.facebook.com/Zeelandia.Deutschland"
                    src={facebookSVG}
                    alt=""
                  />
                </li>
                <li>
                  <Image
                    as="a"
                    href="https://www.instagram.com/zeelandia_deutschland/"
                    src={instagramSVG}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </Grid.Column>
        </Grid>

        <div className="address">
          <p>
            Zeelandia GmbH & Co. KG <br />
            Homburger Landstraße 602 <br />
            Frankfurt am Main 60437
          </p>
        </div>

        <div className="subfooter">
          <ul>
            <li>
              <a href="/impressum">Impressum</a>
            </li>
            <li>
              <a href="/datenschutz">Datenschutz</a>
            </li>
            <li>
              <a href="/einkaufs-verkaufsbedingungen">Bedingungen</a>
            </li>
          </ul>
          <div>© {currentYear} Zeelandia GmbH & Co. KG</div>
        </div>
      </Segment>
    </footer>
  );
};

export default MobileFooter;
