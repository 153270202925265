import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { map } from 'lodash';

import pdfSVG from '../../static/product-pdf-yellow.svg';
import downloadSVG from '../../static/download.svg';
import xlsSVG from '../../static/xls.svg';

const prepareDownloadItems = (downloads) => {
  let existingDownloads = [];
  let mapping = [
    {
      title: 'Rezept',
      key: 'rezept',
      icon: pdfSVG,
    },
    {
      title: 'Rezept (Excel)',
      key: 'rezept_xls',
      icon: xlsSVG,
    },
    {
      title: 'Nährwerte',
      key: 'naehrwerte',
      icon: pdfSVG,
    },
    {
      title: 'Verkaufsinformation',
      key: 'verkaufsinformation',
      icon: pdfSVG,
    },
    {
      title: 'Sicherheitsdatenblatt',
      key: 'sicherheitsdatenblatt',
      icon: pdfSVG,
    },
    {
      title: 'Produktdatenblatt',
      key: 'produktdatenblatt',
      icon: pdfSVG,
    },
  ];
  mapping.forEach((item) => {
    let url = downloads[item.key];
    if (url) {
      existingDownloads.push({
        title: item.title,
        url: hardcodeURL(url),
        icon: item.icon,
      });
    }
  });
  return existingDownloads;
};

const hardcodeURL = (url) =>
  url &&
  url
    .replace(
      'https://zeelandia.kitconcept.io/api',
      'http://www.zeelandia.de/api',
    )
    .replace(
      'https://dev.zeelandia.kitconcept.io/api',
      'http://www.zeelandia.de/api',
    )
    .replace('http://localhost:8080/Plone', 'http://www.zeelandia.de/api');

const Downloads = ({ downloads, localPDF, canEdit }) => (
  <div className="product-downloads">
    <Grid centered>
      <Grid.Column mobile={12} computer={8}>
        <ul>
          {localPDF && (
            <li>
              Produktdatenblatt{' '}
              {canEdit && (
                <a className="change-pdf-link" href={`${localPDF['@id']}/edit`}>
                  {' '}
                  PDF ändern
                </a>
              )}
              <span>
                <a href={localPDF?.file?.download}>
                  <img src={pdfSVG} alt="pdf" />
                  <img src={downloadSVG} alt="download" />
                </a>
              </span>
            </li>
          )}
          {map(prepareDownloadItems(downloads), (download) => {
            if (download.title === 'Produktdatenblatt') {
              if (!localPDF?.file) {
                return (
                  <li key={download.url}>
                    {download.title}
                    <span>
                      <a href={download.url}>
                        <img src={download.icon} alt="pdf" />
                        <img src={downloadSVG} alt="download" />
                      </a>
                    </span>
                  </li>
                );
              }
            } else {
              return (
                <li key={download.url}>
                  {download.title}
                  <span>
                    <a href={download.url}>
                      <img src={download.icon} alt="pdf" />
                      <img src={downloadSVG} alt="download" />
                    </a>
                  </span>
                </li>
              );
            }
          })}
        </ul>

        {/* <ul>
              <li>
                Rezept
                <span>
                  <img src={xlsSVG} alt="pdf" />
                  <img src={pdfSVG} alt="pdf" />
                  <img src={downloadSVG} alt="download" />
                </span>
              </li>
              <li>
                Rezeptbild
                <span>
                  <img src={jpgSVG} alt="pdf" />
                  <img src={downloadSVG} alt="download" />
                </span>
              </li>
              <li>
                Nährwerte
                <span>
                  <img src={pdfSVG} alt="pdf" />
                  <img src={downloadSVG} alt="download" />
                </span>
              </li>
              <li>
                Verkaufsinformationen
                <span>
                  <img src={pdfSVG} alt="pdf" />
                  <img src={downloadSVG} alt="download" />
                </span>
              </li>
            </ul> */}
      </Grid.Column>
    </Grid>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Downloads.propTypes = {
  downloads: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default Downloads;
