import React from 'react';
import PropTypes from 'prop-types';

const VerkaufsinformationDetails = ({ verkaufsinformation }) => {
  return (
    <div className="sales-info-details">
      {verkaufsinformation?.name && (
        <div className="sales-info-container">
          <h3>Hauptbestandteile:</h3>
          <div className="sales-info">
            <pre>{verkaufsinformation.hauptbestandteile}</pre>
          </div>
          <h3>besondere Merkmale:</h3>
          <p className="info">Geschmack, Krume, Kruste, Füllung, Auflage</p>
          <div className="sales-info">
            <pre>{verkaufsinformation.merkmale}</pre>
          </div>
          <h3>Lagerung:</h3>
          <div className="sales-info">
            <pre>{verkaufsinformation.lagerung}</pre>
          </div>
          <h3>Verzehrtipps:</h3>
          <div className="sales-info">
            <pre>{verkaufsinformation.verzehrtipps}</pre>
          </div>
          <h3>Hinweise:</h3>
          <p className="info">für die Ernährung</p>
          <div className="sales-info">
            <pre>{verkaufsinformation.hinweise}</pre>
          </div>
        </div>
      )}
    </div>
  );
};

VerkaufsinformationDetails.propTypes = {
  verkaufsinformation: PropTypes.any.isRequired,
};

export default VerkaufsinformationDetails;
