/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Tab } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { BodyClass } from '@plone/volto/helpers';
import { addMessage } from '@plone/volto/actions';

import { addFavorite } from '../../actions';

// import printSVG from '../../static/print.svg';
// import starSVG from '../../static/star.svg';

import missingIMG from '../../static/missing.jpg';

import {
  Beschreibung,
  MoreInfoFooter,
  VerkaufskonzeptDetails,
  MixedTitle,
} from '../.';

class Verkaufskonzept extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    /**
     * Content of the object
     */
    content: PropTypes.shape({
      title: PropTypes.string,
      UID: PropTypes.string,
      description: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      image: PropTypes.object,
      products: PropTypes.array,
      recipes: PropTypes.array,
    }).isRequired,
    addFavorite: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    favoriteLoaded: PropTypes.bool.isRequired,
    favoriteLoading: PropTypes.bool.isRequired,
  };

  state = {};

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.favoriteLoading && nextProps.favoriteLoaded) {
      this.props.addMessage(
        'Verkaufskonzept gemerkt',
        'Verkaufskonzept wurde in Ihrem Profil gemerkt.',
        'success',
      );
    }
  }

  panes = [
    {
      menuItem: 'Beschreibung',
      render: () => (
        <Tab.Pane basic attached={false}>
          <Beschreibung content={this.props.content} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Produkte/Rezepte',
      render: () => (
        <Tab.Pane basic attached={false}>
          <VerkaufskonzeptDetails
            products={this.props.content.products}
            recipes={this.props.content.recipes}
          />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: 'Verkaufsförderung',
    //   render: () => (
    //     <Tab.Pane basic attached={false}>
    //       <Zubehor item={this.props.content} />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  addFavorite = () => {
    this.props.addFavorite('sales_concept', this.props.content.UID);
  };

  render() {
    return (
      <section style={{ marginBottom: '50px' }}>
        <Helmet title={`Zeelandia - ${this.props.content.title}`} />
        <BodyClass className="product" />
        <Container>
          <div className="product-hero">
            {this.props.content.image ? (
              <img
                className="product-hero-img"
                src={this.props.content.image.download}
                alt="Verkaufskonzept"
              />
            ) : (
              <img
                className="product-hero-img"
                src={missingIMG}
                alt="Verkaufskonzept"
              />
            )}
            <div className="product-hero-body">
              <h1>
                <MixedTitle>{this.props.content.title}</MixedTitle>
              </h1>
              <p>{this.props.content.description}</p>
            </div>
            <div className="product-hero-addons">
              {/*<img src={starSVG} alt="star" onClick={this.addFavorite} />*/}
              {/* <img src={printSVG} alt="print" /> */}
            </div>
          </div>

          <Tab
            className="product-tabs"
            style={{
              textAlign: 'center',
              marginBottom: '134px',
            }}
            menu={{
              secondary: true,
              pointing: true,
              compact: true,
              borderless: true,
              stackable: true,
            }}
            panes={this.panes}
          />
        </Container>
        <MoreInfoFooter name="koncept" />
      </section>
    );
  }
}

export default connect(
  (state) => ({
    favoriteLoaded: state.cart.add.loaded,
    favoriteLoading: state.cart.add.loading,
  }),
  {
    addFavorite,
    addMessage,
  },
)(Verkaufskonzept);
