import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';
import { Link } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Loader, Dimmer } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getProdukte,
  updateProdukt,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
  getProduktkategorien,
} from '../../../actions';
import Filters from '../Filters';
import { BasicTable } from '../../ReactTableContent/ReactTableContent';
import warningTriangle from '../icons/unvollstaendige_naehrwerte.gif';

import { createColumnHelper } from '@tanstack/react-table';
import NavHeader from '../NavHeader';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const ProdukteListing = ({ intl }) => {
  const currentListing = 'produkte';
  const dispatch = useDispatch();

  const pathname = useSelector((state) => state.router.location.pathname);
  const produkte = useSelector((state) => state.produkte.data.produkte);
  const totalPages = useSelector((state) => state.produkte.data.total_pages);
  const token = useSelector((state) => state.userSession.token);
  const permissions = useSelector((state) => state.permissions.data) || null;
  const batchingParameters = useSelector((state) => state.batchingParameters);

  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 15, 2, 4, 6];
  // Define the allowed user groups for adding new Rohstoffe
  const showAddButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // Define the allowed user groups for toggle Listing to show deleted
  const showShowDeletedButton = [1].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // table config
  const [tableConfig, setTableConfig] = useState({
    COLUMNS: [],
    showDeleted: false,
    data: [],
    totalPages: 0,
    linkToEdit: false,
  });

  const deletedButton = !tableConfig.showDeleted
    ? 'Gelöschte Produkte anzeigen'
    : 'Produkte anzeigen';

  const [refreshFlag, setRefreshFlag] = useState(false);

  const columnHelper = createColumnHelper();

  const hasIncompleteNutrients = (produkt) => {
    return (
      !produkt.brennwert_kcal ||
      produkt.brennwert_kcal === 0 ||
      !produkt.brennwert_kj ||
      produkt.brennwert_kj === 0
    );
  };

  const COLUMNS = [
    columnHelper.accessor('produktnummer', {
      id: 'produktnummer',
      header: () => <span>Produktummer</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('bezeichnung', {
      id: 'bezeichnung',
      header: () => <span>Bezeichnung</span>,
      cell: (info) => (
        <p>
          {' '}
          {hasIncompleteNutrients(info.row.original) ? (
            <img
              style={{ height: '10px', marginRight: '2px' }}
              src={warningTriangle}
              title="unvollständige Nährwerte"
            />
          ) : (
            ''
          )}
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <span>Status</span>,
      cell: (info) => {
        if (info.getValue() === 1) {
          return (
            <div className="status">
              <div className="green" /> <p>Aktuell</p>
            </div>
          );
        } else if (info.getValue() === 2) {
          return (
            <div className="status">
              <div className="red" />
              <p>Stillgelegt</p>
            </div>
          );
        } else if (info.getValue() === 3) {
          return (
            <div className="status">
              <div className="yellow" />
              <p>In Bearbeitung</p>
            </div>
          );
        } else {
          return <p>{info.getValue()}</p>;
        }
      },
    }),
    columnHelper.accessor('intern_extern', {
      id: 'intern_extern',
      header: () => <span>Intern Extern</span>,
      cell: (info) => <p>{info.getValue() ? 'Intern' : 'Extern'}</p>,
    }),
    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        if (timestamp > 0) {
          return <p>{formattedDate}</p>;
        } else {
          return <p></p>;
        }
      },
    }),
    columnHelper.accessor('benutzer', {
      id: 'benutzer',
      header: () => <span>Ersteller</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
  ];

  const COLUMNS_DELETED = [
    columnHelper.accessor('produktnummer', {
      id: 'produktnummer',
      header: () => <span>Produktummer</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('bezeichnung', {
      id: 'bezeichnung',
      header: () => <span>Bezeichnung</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('deleted', {
      id: 'deleted',
      header: () => <span></span>,
      cell: (info) => (
        <Button onClick={() => unDelete(info.row.original.id)}>
          Wiederherstellen
        </Button>
      ),
    }),
  ];

  // Helper function to pad zeroes for single-digit numbers
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      dispatch(getVistPermissions())
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      getProdukte({
        showDeleted: tableConfig.showDeleted,
        batchingParameters: batchingParameters,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFlag, batchingParameters, tableConfig.showDeleted]);

  // attatch bezüge to tableConfig when finished loading
  useEffect(() => {
    const columns = tableConfig.showDeleted ? COLUMNS_DELETED : COLUMNS;
    setTableConfig({
      ...tableConfig,
      data: produkte,
      COLUMNS: columns,
      totalPages: totalPages,
    });

    if (
      batchingParameters &&
      batchingParameters.currentListing !== currentListing
    ) {
      dispatch(resetBatchingParameters({ currentListing: currentListing }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produkte, tableConfig.showDeleted]);

  const toggleShowDeleted = () => {
    if (permissions.includes(1)) {
      setTableConfig({
        ...tableConfig,
        showDeleted: !tableConfig.showDeleted,
      });
      dispatch(resetBatchingParameters({ currentListing: currentListing }));
      setRefreshFlag(!refreshFlag);
    }
  };

  const unDelete = (id) => {
    if (permissions.includes(1)) {
      dispatch(updateProdukt(id, { deleted: 0 }));
      dispatch(
        getProdukte({
          showDeleted: true,
          batchingParameters: batchingParameters,
        }),
      );
      setRefreshFlag(!refreshFlag);
    }
  };

  if (error) {
    return <Error error={error} />;
  }
  if (!token) {
    setError({ status: 401 });
  }
  // check if user has the sufficient permissions to view this page
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    setError({ status: 401 });
  }
  const searchableFields = {
    default: { label: 'Produktname', value: 'produktname' },
    fields: [
      { label: 'Produktname', value: 'produktname' },
      { label: 'produktnummer', value: 'produktnummer' },
      { label: 'Produktbeschreibung lang', value: 'produktbeschreibung_lang' },
      { label: 'Produktbeschreibung kurz', value: 'produktbeschreibung_kurz' },
    ],
  };

  return (
    (produkte && (
      <div className="view-wrapper">
        <Helmet title="Produkte" />
        <NavHeader permissions={permissions} />
        <Container className="controlpanel">
          <Segment.Group raised>
            <Segment className="primary">
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>
                    {tableConfig.showDeleted
                      ? 'Gelöschte Produkte'
                      : 'Produkte'}
                  </h1>
                </div>
                <div className="button-container">
                  <div className="buttonArea">
                    {showAddButton && (
                      <Link to="produkte/add">
                        <Button className="rdb-new" primary type="New">
                          Neues Produkt
                        </Button>
                      </Link>
                    )}
                    {showShowDeletedButton && (
                      <Button
                        className="rdb-show-deleted"
                        primary
                        onClick={() => toggleShowDeleted()}
                      >
                        {deletedButton}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Segment>
            {produkte && tableConfig.data && (
              <Segment>
                <Filters
                  filters={[
                    'status',
                    'intern',
                    'ersteller',
                    'produktkategorien',
                    'unv_naehr',
                  ]}
                  filterActions={{
                    produktkategorien: getProduktkategorien,
                  }}
                  batchingParameters={batchingParameters}
                />
                <BasicTable
                  tableConfig={tableConfig}
                  searchableFields={searchableFields}
                  setTableConfig={setTableConfig}
                  batchingParameters={batchingParameters}
                  setBatchingParameters={setBatchingParameters}
                />
              </Segment>
            )}
          </Segment.Group>
        </Container>
        {isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={pathname}
              hideDefaultViewButtons
              inner={
                <Link to="/controlpanel/rezeptdatenbank" className="item">
                  <Icon
                    name={backSVG}
                    className="contents circled"
                    size="30px"
                    title={intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </div>
    )) || (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )
  );
};

ProdukteListing.propTypes = {
  pathname: PropTypes.string.isRequired,
  produkte: PropTypes.array.isRequired,
  totalPages: PropTypes.any.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
};

export default injectIntl(ProdukteListing);
