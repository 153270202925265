/**
 * Rohstoffe actions.
 * @module actions/Rohstoff
 */

import {
  GET_ROHSTOFFE,
  GET_ROHSTOFFBYID,
  CREATE_ROHSTOFF,
  UPDATE_ROHSTOFF,
  DELETE_ROHSTOFF,
  GET_ROHSTOFFE_NAMES,
  CHECK_ROHSTOFFE,
} from '../../constants/ActionTypes';

import { isEmpty } from 'lodash';

/**
 * Get Rohstoff items.
 * @function getRohstoffe
 * @param {string} id Rohstoff id
 * @returns {Object} Rohstoff action.
 */
export function getRohstoffe({ showDeleted = false, batchingParameters = {} }) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
    searchField = {},
    filters = {},
  } = batchingParameters;
  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  if (searchField.value) {
    queryParams.push(`search_kategorie=${searchField.value}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }

  //Apply filters from batching params
  Object.entries(filters).map((filter) => {
    const filterValue = filter[1];
    let filterValues;
    if (!isEmpty(filterValue)) {
      if (Array.isArray(filter[1])) {
        filterValues = filterValue.map((singleValue) => {
          return singleValue.value;
        });
      } else if (typeof filterValue === 'object') {
        filterValues = filterValue.value;
      } else if (typeof filterValue === 'number') {
        filterValues = filterValue;
      } else if (typeof filterValue === 'boolean') {
        if (filterValue == true) {
          filterValues = 1;
        } else {
          filterValues = 0;
        }
      }

      pathSuffix += `&${filter[0]}=${filterValues}`;
    }
  });

  if (showDeleted) {
    return {
      type: GET_ROHSTOFFE,
      request: {
        op: 'get',
        path: `/@rohstoffe/deleted${pathSuffix}`,
      },
    };
  } else {
    return {
      type: GET_ROHSTOFFE,
      request: {
        op: 'get',
        path: `/@rohstoffe${pathSuffix}`,
      },
    };
  }
}

/**
 * Get Rohstoff items.
 * @function getRohstoffeNames
 * @returns {Object} Rohstoff action.
 */
export function getRohstoffeNames() {
  return {
    type: GET_ROHSTOFFE_NAMES,
    request: {
      op: 'get',
      path: `/@rohstoffe/names`,
    },
  };
}

/**
 * Check Rohstoffe for specific value in specific field.
 * @function checkProdukte
 * @param {string} field, specified field
 * @param {string} value, specified value
 * @returns {Object} Rohstoffe action.
 */
export function checkRohstoffe(field, value) {
  return {
    type: CHECK_ROHSTOFFE,
    request: {
      op: 'get',
      path: `/@rohstoffe_check/${field}=${value}`,
    },
  };
}

/**
 * Get Benutzer item.
 * @function getRohstoffByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getRohstoffByID(id) {
  return {
    type: GET_ROHSTOFFBYID,
    request: {
      op: 'get',
      path: `/@rohstoffe/${id}`,
    },
  };
}

/**
 * Create a new Rohstoff.
 * @function createRohstoff
 * @param {Object} formData The data of the new Rohstoff
 * @returns {Object} Rohstoff action.
 */
export function createRohstoff(formData) {
  return {
    type: CREATE_ROHSTOFF,
    request: {
      op: 'post',
      path: '/@rohstoffe',
      data: formData,
    },
  };
}

/**
 * Update a new Rohstoff.
 * @function createRohstoff
 * @param {Object} formData The data of the new Rohstoff
 * @returns {Object} Rohstoff action.
 */
export function updateRohstoff(id, formData) {
  return {
    type: UPDATE_ROHSTOFF,
    request: {
      op: 'patch',
      path: `/@rohstoffe/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Rohstoff.
 * @function createRohstoff
 * @returns {Object} Success Message;
 */
export function deleteRohstoff(id) {
  return {
    type: DELETE_ROHSTOFF,
    request: {
      op: 'del',
      path: `/@rohstoffe/${id}`,
    },
  };
}
