import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { Rezept } from '../.';

/**
 *
 *
 * @class Zutaten
 * @extends {Component}
 */
class Zutaten extends Component {
  state = { name: '', email: '', message: '' };

  handleChange = () => {
    return '';
  };

  handleSubmit = () => {
    return '';
  };

  render() {
    return (
      <div className="rezepte-zutaten">
        <Grid centered>
          <Grid.Column mobile={12} computer={8}>
            <h3>Zutaten für</h3>
            <Rezept rezept={this.props.rezept} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Zutaten;
