import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { MixedTitle } from '..';
import anfahrtSVG from '../../static/anfahrt_icon.svg';
import AnsprechpartnerSVG from '../../static/kontakt_icon.svg';

class AuxPartners extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
  };

  state = {};
  render() {
    return (
      <div className="auxmenu-partners">
        <h1>
          <MixedTitle>Ihre Ansprechpartner.</MixedTitle>
        </h1>
        <div className="partners-list">
          <Link
            to="/wir-ueber-uns/ihre-ansprechpartner/ihr-verkaufsservice"
            onClick={this.props.closeMenu}
          >
            <div className="partner">
              <div className="icon-wrapper">
                <Image src={AnsprechpartnerSVG} alt="" />
              </div>
              <h2>
                <strong>Ansprechpartner</strong>
              </h2>
            </div>
          </Link>
          <Link
            to="/wir-ueber-uns/ihre-ansprechpartner/ihr-weg-zu-uns"
            onClick={this.props.closeMenu}
          >
            <div className="partner">
              <div className="icon-wrapper">
                <Image src={anfahrtSVG} alt="" />
              </div>
              <h2>
                <strong>Ihr Weg zu uns</strong>
              </h2>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default AuxPartners;
