/**
 * Product category reducer.
 * @module reducers/productcategory/productcategory
 */

import { map } from 'lodash';
import {
  GET_PRODUCT_CATEGORY,
  RESET_PRODUCT_CATEGORY,
} from '../../constants/ActionTypes';

import config from '@plone/volto/registry';

const initialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
};

/**
 * Product category reducer.
 * @function productcategory
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function productcategory(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_PRODUCT_CATEGORY}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
        batching: {},
        total: 0,
      };
    case `${GET_PRODUCT_CATEGORY}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: map(action.result.items, (item) => ({
          ...item,
          '@id': item['@id'].replace(config.settings.apiPath, ''),
        })),
        total: action.result.items_total,
        loaded: true,
        loading: false,
        batching: { ...action.result.batching },
      };
    case `${GET_PRODUCT_CATEGORY}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    case RESET_PRODUCT_CATEGORY:
      return {
        ...state,
        error: null,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    default:
      return state;
  }
}
