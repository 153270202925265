/**
 * Register container.
 * @module components/theme/Register/Register
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Ref } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import { createUser, addMessage, login } from '@plone/volto/actions';
import { MixedTitle } from '../.';

import arrowSVG from '../../icons/arrow.svg';
import clearSVG from '../../icons/clear.svg';
import infoSVG from '../../icons/info.svg';

/**
 * Register class.
 * @class Register
 * @extends Component
 */
class Register extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  state = {
    error: null,
    email: '',
    password: '',
    emailRepeat: '',
    passwordRepeat: '',
    fullname: '',
    clientId: '',
    agreed: false,
    isSuccessful: false,
    scrolling: false,
  };

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading && nextProps.loaded) {
      this.setState({ isSuccessful: true });
      window.scrollTo(0, 0);
    }
  }

  handleRef = (ref) => {
    this.ref = ref;
  };

  setScrolling = () => {
    if (this.ref) {
      const { height } = this.ref.getBoundingClientRect();

      const scrolling = height >= window.innerHeight;

      if (this.state.scrolling !== scrolling) {
        this.setState({ scrolling: scrolling });
      }
    }

    this.animationRequestId = requestAnimationFrame(this.setScrolling);
  };

  componentDidMount = () => {
    this.setScrolling();
  };

  componentWillUnmount = () => {
    if (this.state.isSuccessful) {
      /*
      login on unmount as we have no control over what is executed
      when the backdrop is clicked.
      */
      this.props.login(this.state.email, this.state.password);
    }
    cancelAnimationFrame(this.animationRequestId);
  };

  /**
   * On submit handler
   * @method onSubmit
   * @param {object} data Form data.
   * @returns {undefined}
   */
  onSubmit = () => {
    const {
      email,
      password,
      emailRepeat,
      passwordRepeat,
      fullname,
      kundennummer,
      agreed,
    } = this.state;

    if (email && password && agreed) {
      if (emailRepeat === email && passwordRepeat === password) {
        this.props.createUser({
          fullname: fullname ? fullname : email,
          email,
          password,
          kundennummer,
        });
        this.setState({ error: null });
      } else {
        this.setState({
          error: 'Validierungsfehler: Bitte überprüfen Sie die Formularfelder',
        });
      }
    } else {
      this.setState({
        error: 'Validierungsfehler: Bitte überprüfen Sie die Formularfelder',
      });
    }
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, error: null });
  handleCheckboxChange = (e, data) => this.setState({ agreed: data.checked });

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      error,
      email,
      password,
      emailRepeat,
      passwordRepeat,
      agreed,
      isSuccessful,
    } = this.state;

    const scrollingClass = this.state.scrolling ? ' scrolling' : '';

    if (isSuccessful) {
      return (
        <Ref innerRef={this.handleRef}>
          <div className={`modal-login ${scrollingClass}`}>
            <Icon
              name={clearSVG}
              size="60px"
              color="#fff"
              className="close"
              onClick={this.props.closeMenu}
            />

            <h1>
              <MixedTitle>Vielen Dank.</MixedTitle>
            </h1>

            <h3>
              Vielen Dank für Ihre Registrierung. Sobald Sie das Fenster
              schließen, sind Sie eingeloggt.
            </h3>
          </div>
        </Ref>
      );
    }
    return (
      <Ref innerRef={this.handleRef}>
        <div className={`modal-login ${scrollingClass}`}>
          <Icon
            name={clearSVG}
            size="60px"
            color="#fff"
            className="close"
            onClick={this.props.closeMenu}
          />

          <h1>
            <MixedTitle>Werden Sie Mitglied.</MixedTitle>
          </h1>

          <h2 style={{ paddingLeft: '14px' }}>
            Bitte füllen Sie die folgenden Felder aus:
          </h2>

          <Form onSubmit={this.onSubmit}>
            <Grid stackable>
              <Grid.Column width={6}>
                <Form.Input
                  label="E-Mail-Adresse*"
                  name="email"
                  value={email}
                  placeholder="Hier eingeben"
                  onChange={this.handleChange}
                />
                <Form.Input
                  label="E-Mail-Adresse wiederholen*"
                  name="emailRepeat"
                  value={emailRepeat}
                  placeholder="Hier eingeben"
                  onChange={this.handleChange}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Input
                  label="Passwort*"
                  name="password"
                  value={password}
                  type="password"
                  placeholder="Hier eingeben"
                  onChange={this.handleChange}
                />
                <Form.Input
                  className="with-help"
                  label="Passwort wiederholen*"
                  name="passwordRepeat"
                  value={passwordRepeat}
                  type="password"
                  placeholder="Hier eingeben"
                  onChange={this.handleChange}
                />
                <p>*Pflichtfeld</p>
              </Grid.Column>
            </Grid>
            <Grid stackable style={{ marginTop: '50px' }}>
              <Grid.Column width={12}>
                <Form.Checkbox
                  label={
                    <label>
                      Für einige Angebote auf zeelandia.de ist eine kostenlose
                      Anmeldung erforderlich. Hiermit bestätige ich, dass ich
                      Ihre{' '}
                      <Link to="/datenschutz" onClick={this.props.closeMenu}>
                        Datenschutzerklärung
                      </Link>{' '}
                      gelesen habe und diese akzeptiere. Ich willige ein, dass
                      die Zeelandia GmbH & Co. KG die Daten, die sich aus dem
                      Registrierungsformular sowie aus dem Besuch des
                      geschützten Webbereichs (Nutzer- und Nutzungsdaten wie
                      z.B. Name, E-Mail, Datum, Uhrzeit, IP-Adresse) ergeben,
                      speichert und für die Bearbeitung und Beantwortung von
                      Anfragen und darüber hinaus für die Beratung und Betreuung
                      nutzen darf.
                    </label>
                  }
                  checked={agreed}
                  onChange={this.handleCheckboxChange}
                />
              </Grid.Column>
            </Grid>
            <Form.Field className="register-actions">
              <Button
                className={cx('large brandButtonPrimary', {
                  disabled: !this.state.agreed,
                })}
                loading={this.props.loading}
                style={{ marginBottom: '25px' }}
                disabled={!this.state.agreed}
              >
                <MixedTitle>Mitglied werden</MixedTitle>
                <Icon name={arrowSVG} size="20px" className="right" />
              </Button>
            </Form.Field>
            {this.props.error && (
              <Button
                className="small brandButtonPrimary contact-form-message"
                style={{
                  backgroundColor: 'red',
                  marginTop: '20px',
                  marginLeft: '0',
                }}
              >
                <Icon name={infoSVG} size="17px" className="left" />
                {this.props.error.message}
              </Button>
            )}
            {error && (
              <Button
                className="small brandButtonPrimary contact-form-message"
                style={{
                  backgroundColor: 'red',
                  marginTop: '20px',
                  marginLeft: '0',
                }}
              >
                <Icon name={infoSVG} size="17px" className="left" />
                {error}
              </Button>
            )}
          </Form>
        </div>
      </Ref>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      loading: state.users.create.loading,
      loaded: state.users.create.loaded,
      error: state.users.create.error,
    }),
    { createUser, addMessage, login },
  ),
)(Register);
