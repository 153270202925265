import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { getRecipe } from '../../actions';
import { Rezept } from '../.';

class Rohstoffkosten extends Component {
  static propTypes = {
    rezept: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        '@id': PropTypes.string,
      }),
      data: PropTypes.shape({
        units: PropTypes.shape({
          int: PropTypes.number,
        }),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.required,
            amount: PropTypes.string.required,
            display_amount: PropTypes.string.required,
            ingredients: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string.required,
                amount: PropTypes.string.required,
                display_amount: PropTypes.string.required,
                is_base_recipe: PropTypes.bool.required,
                is_product: PropTypes.bool.required,
                pdf_display_name: PropTypes.string.required,
              }),
            ),
          }).required,
        ),
      }),
    }),
  };

  static defaultProps = {};

  render() {
    return (
      <div className="rezepte-rohstoffkosten">
        <Grid centered>
          <Grid.Column mobile={12} computer={8}>
            <h3>Berechnung für</h3>
            <Rezept rezept={this.props.rezept} rohstoffkosten={true} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect((state, props) => ({}), { getRecipe }),
)(Rohstoffkosten);
