import React from 'react';

import RichTextField from '../RichTextField/RichTextField';
import GebindeWidget from './GebindeWidget';
import ImageWidget from '../ImageWidget';

import { Form, Dropdown } from 'semantic-ui-react';

const ProduktForm = ({
  formData,
  isBezeichnungDuplicate,
  isProduktnummerDuplicate,
  handleInputChange,
  gebinde,
  addGebinde,
  handleGebindeChange,
  removeGebinde,
  isIncompleteGebinde,
  kategorien = [],
  handleKategorienChange,
  selectedKategorien,
  produknummer_suggestions,
}) => {
  const renderOptions = (option) => {
    const level = option.level || 0;
    const indent = `${level * 1}em`;

    const style = {
      fontWeight: level === 0 ? 'bold' : 'normal',
      paddingLeft: indent,
    };

    return <span style={style}>{option.name}</span>;
  };

  const dropdownOptions = kategorien.map((kategorie) => ({
    key: kategorie.id,
    text: kategorie.name,
    value: kategorie.id,
    level: kategorie.level || 0,
    content: renderOptions(kategorie),
  }));

  const handleKeyDown = (e) => {
    // Prevent form submission on "Enter" key press so "delete button" does not get triggered accidentially
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Form onKeyDown={handleKeyDown}>
      <div className="meta-info">
        <Form.Field error={isProduktnummerDuplicate}>
          <label>
            <strong>Produktnummer (*):</strong>
          </label>
          <input
            type="number"
            name="produktnummer"
            value={formData.produktnummer}
            onChange={(e) => handleInputChange(e)}
            list="suggestions"
            required
            autoComplete="off"
          />
          <datalist id="suggestions">
            {formData.produktnummer > 99 &&
              produknummer_suggestions.map((suggestion) => (
                <option value={suggestion} />
              ))}
          </datalist>
        </Form.Field>
        {isProduktnummerDuplicate && (
          <div className="speech-bubble warning">
            Produktnummer bereits vergeben
          </div>
        )}
        <Form.Field error={isBezeichnungDuplicate}>
          <label>
            <strong>Bezeichnung (*):</strong>
          </label>
          <input
            type="text"
            name="bezeichnung"
            value={formData.bezeichnung}
            onChange={handleInputChange}
            required
          />
        </Form.Field>
        {isBezeichnungDuplicate && (
          <div className="speech-bubble warning">
            Produkt mit dieser Bezeichnung existiert bereits
          </div>
        )}

        <Form.Field>
          <label>Status:</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value={1}>aktuell</option>
            <option value={2}>stillgelegt</option>
            <option value={3}>in Bearbeitung</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Aktuelles Produkt:</label>
          <select
            name="aktuell"
            value={formData.aktuell}
            onChange={handleInputChange}
          >
            <option value={0}>Nein</option>
            <option value={2}>Ja</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Intern/Extern:</label>
          <select
            name="intern_extern"
            value={formData.intern_extern ? 'intern' : 'extern'}
            onChange={handleInputChange}
          >
            <option value="intern">Intern</option>
            <option value="extern">Extern</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Produktkategorien:</label>
          <Dropdown
            placeholder="Produktkategorie auswählen"
            name="produktkategorien"
            fluid
            multiple
            selection
            options={dropdownOptions}
            value={selectedKategorien}
            onChange={(e, { value }) => {
              handleKategorienChange(value);
            }}
          />
        </Form.Field>
        <ImageWidget
          handleInputChange={handleInputChange}
          formData={formData}
        />
      </div>
      <div className="trenner" />
      <p>
        <strong>Nährwerteangaben ( Pro 100g ):</strong>
      </p>
      <div className="nutrients-info">
        <Form.Field>
          <label>Brennwert ( kJ ):</label>
          <input
            type="number"
            name="brennwert_kj"
            min={0}
            value={formData.brennwert_kj}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Brennwert ( kcal ):</label>
          <input
            type="number"
            name="brennwert_kcal"
            min={0}
            value={formData.brennwert_kcal}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Eiweiss ( g ):</label>
          <input
            type="number"
            name="eiweiss"
            min={0}
            value={formData.eiweiss}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Kohlenhydrate ( g ):</label>
          <input
            type="number"
            name="kohlenhydrate"
            min={0}
            value={formData.kohlenhydrate}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>davon Zucker:</label>
          <input
            type="number"
            name="zucker"
            min={0}
            value={formData.zucker}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Fett ( g ):</label>
          <input
            type="number"
            name="fett"
            min={0}
            value={formData.fett}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>davon gesättigte Fettsäuren:</label>
          <input
            type="number"
            name="ges_fettsaeuren"
            min={0}
            value={formData.ges_fettsaeuren}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Ballaststoffe ( g )</label>
          <input
            type="number"
            name="ballaststoffe"
            min={0}
            value={formData.ballaststoffe}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Salz ( g ):</label>
          <input
            type="number"
            name="natrium"
            min={0}
            value={formData.natrium}
            onChange={handleInputChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Kalkulationspreis [Euro/kg]:</label>
          <input
            type="number"
            name="basis_euro"
            min={0}
            value={formData.basis_euro}
            onChange={handleInputChange}
          />
        </Form.Field>
      </div>
      <div className="trenner" />
      <GebindeWidget
        gebinde={gebinde}
        addGebinde={addGebinde}
        handleGebindeChange={handleGebindeChange}
        removeGebinde={removeGebinde}
        isIncompleteGebinde={isIncompleteGebinde}
      />
      <div className="trenner" />
      <div className="info">
        <Form.Field>
          <label>
            <strong>Produktbeschreibung (kurz)</strong>
          </label>
          <textarea
            name="produktbeschreibung_kurz"
            value={formData.produktbeschreibung_kurz}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
        <RichTextField
          id="produktbeschreibung_lang"
          title="Produktbeschreibung Lang"
          value={{ data: formData.produktbeschreibung_lang }}
          onChange={handleInputChange}
        />
        <Form.Field>
          <label>
            <strong>Zusatzinformationen</strong>
          </label>
          <textarea
            name="zusatzinformationen"
            value={formData.zusatzinformationen}
            onChange={handleInputChange}
            placeholder="..."
          />
        </Form.Field>
      </div>
    </Form>
  );
};

export default ProduktForm;
