/**
 * View title tile.
 * @module components/manage/Tiles/Title/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { MixedTitle } from '../../../../../../components';
import Countdown from 'react-countdown';
import moment from 'moment';

/**
 * View title tile class.
 * @class View
 * @extends Component
 */
const View = ({ properties }) => (
  <div className="document-header">
    <h1 className="documentFirstHeading">
      <MixedTitle
        light={properties.title}
        strong={properties.subtitle}
        breakline
      />
    </h1>
    {properties.image && (
      <Image className="document-leadimage" src={properties.image.download} />
    )}
    {properties.countdown && (
      <span className="countdown-leadimage">
        <Countdown
          date={moment(properties.countdown || undefined, [
            'DD.MM.YYYY',
            'DD.MM.YYYY HH:mm:ss',
            'DD.MM.YYYY HH:mm',
          ])}
        />
      </span>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
