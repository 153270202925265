// reducers/Gebinde.js

import { has } from 'lodash';
import {
  GET_GEBINDEBYID,
  CREATE_GEBINDE,
  DELETE_GEBINDE,
  UPDATE_GEBINDE,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function gebindeByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_GEBINDEBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_GEBINDEBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_GEBINDEBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: {},
      };
    default:
      return state;
  }
}

export function gebindeCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_GEBINDE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_GEBINDE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_GEBINDE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function gebindeDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_GEBINDE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_GEBINDE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_GEBINDE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function gebindeUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_GEBINDE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_GEBINDE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_GEBINDE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
