import { has } from 'lodash';
import {
  GET_BEZUEGE,
  GET_BEZUGBYID,
  CREATE_BEZUG,
  DELETE_BEZUG,
  UPDATE_BEZUG,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

export function bezuege(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_BEZUEGE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_BEZUEGE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result')
          ? { ...action.result }
          : { total_pages: 0, bezuege: [] },
      };
    case `${GET_BEZUEGE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function bezugByID(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_BEZUGBYID}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_BEZUGBYID}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${GET_BEZUGBYID}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        data: [],
      };
    default:
      return state;
  }
}

export function bezugCreate(state = initialState, action = {}) {
  switch (action.type) {
    case `${CREATE_BEZUG}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CREATE_BEZUG}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${CREATE_BEZUG}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function bezugDelete(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_BEZUG}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_BEZUG}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${DELETE_BEZUG}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function bezugUpdate(state = initialState, action = {}) {
  switch (action.type) {
    case `${UPDATE_BEZUG}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${UPDATE_BEZUG}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        data: has(action, 'result') ? { ...action.result } : {},
      };
    case `${UPDATE_BEZUG}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
