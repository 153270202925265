/**
 * View hero2 block.
 * @module components/Blocks/Hero2/View
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className={['block', 'tile', 'hero'].filter((e) => !!e).join(' ')}>
    <div className="product-hero-2">
      <img src={`${data.url}/@@images/image`} alt="" />
      <div className="product-hero-2-body">
        <h1>{data.title}</h1>
        <p>{data.description}</p>
      </div>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
