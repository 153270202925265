/**
 * Breadcrumbs component.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl } from '@plone/volto/helpers';

/**
 * Breadcrumbs container class.
 * @class Breadcrumbs
 * @extends Component
 */
class Breadcrumbs extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
  }

  /**
   * Component did update
   * @method componentDidUpdate
   * @param {Object} prevProps Previous properties
   * @param {Object} prevState Previous state
   * @returns {undefined}
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pathname !== this.props.pathname) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="breadcrumbs">
        <span>Sie sind hier:</span>
        <ul>
          <li>
            <Link to="/">Startseite</Link>
          </li>
          {this.props.items.map((item, index, items) => [
            index < items.length - 1 ? (
              <li key={item.url}>
                <Link to={item.url} className="section">
                  {item.title}
                </Link>
              </li>
            ) : (
              <li key={item.url}>{item.title}</li>
            ),
          ])}
        </ul>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
    }),
    { getBreadcrumbs },
  ),
)(Breadcrumbs);
