/**
 * Homepage jobs actions.
 * @module actions/homepage/jobs
 */

import { GET_JOBS } from '../../constants/ActionTypes';

/**
 * Get Homepage jobs items.
 * @function getJobs
 * @returns {Object} Homepage jobs action.
 */
export default function getJobs() {
  return {
    type: GET_JOBS,
    request: {
      op: 'get',
      path:
        '/wir-ueber-uns/karriere/stellenangebote/@search?portal_type=Document&sort_on=effective&sort_order=descending&fullobjects',
    },
  };
}
