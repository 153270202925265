/**
 * Product category actions.
 * @module actions/productcategory
 */

import {
  GET_PRODUCT_CATEGORY,
  RESET_PRODUCT_CATEGORY,
} from '../../constants/ActionTypes';

/**
 * Get product category items.
 * @function getProductCategory
 * @param {string} id Category id
 * @returns {Object} Product category action.
 */
export function getProductCategory(id, size = 500) {
  return {
    type: GET_PRODUCT_CATEGORY,
    request: {
      op: 'get',
      path: `/@search?category=${id}&portal_type=jungzeelandia.Product&use_solr=true&b_size=${size}&all=1&sort_on=created&sort_order=descending`,
    },
  };
}

/**
 * Reset product category function.
 * @function resetProductCategory
 * @returns {Object} Reset product category action.
 */
export function resetProductCategory() {
  return {
    type: RESET_PRODUCT_CATEGORY,
  };
}
