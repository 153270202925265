import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, Image, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import { MixedTitle } from '../.';

import missingIMG from '../../static/missing.jpg';

import arrowSVG from '../../icons/arrow.svg';
import breads1Img from '../../static/breads1.png';

// import dinkelEkeIMG from '../../static/dinkel_ecke.jpg';
// import dinkelLaibIMG from '../../static/dinkel_laib.jpg';
// import dinkelMelonenkernIMG from '../../static/dinkel_melonenkern.jpg';
// import DinkelvollkornPurIMG from '../../static/Dinkelvollkorn_Pur.jpg';

// import static1 from '../../static/jz-mr-korn-dinkel34418.jpg';
// import static2 from '../../static/chias-weizenmisch-brot2.jpg';
// import static3 from '../../static/mr-_korn_gruenkern_titel_go_pur.jpg';
// import static4 from '../../static/mr-_korn_einkorn-pur_titel_go_pur.jpg';

// const staticData = {
//   rezepte: [
//     {
//       title: 'Dinkel-Ecke',
//       image: dinkelEkeIMG,
//       url: 'https://www.jungzeelandia.de/rezepte?item=7352',
//     },
//     {
//       title: 'Dinkel Laib',
//       image: dinkelLaibIMG,
//       url: 'https://www.jungzeelandia.de/rezepte?item=7353',
//     },
//     {
//       title: 'Dinkel-Melonenkern-Brot',
//       image: dinkelMelonenkernIMG,
//       url: 'https://www.jungzeelandia.de/rezepte?item=4618',
//     },
//     {
//       title: 'Dinkelvollkorn',
//       image: DinkelvollkornPurIMG,
//       url: 'https://www.jungzeelandia.de/rezepte?item=2760',
//     },
//   ],
//   product: [
//     {
//       title: 'Jung MR: KORN "Einkorn" Pur',
//       image: static1,
//       url: 'https://www.jungzeelandia.de/sortiment?category=0&item=862',
//     },
//     {
//       title: 'Jung MR. KORN  "Grünkern"',
//       image: static2,
//       url: 'https://www.jungzeelandia.de/sortiment?category=0&item=816',
//     },
//     {
//       title: 'Jung MR. KORN "Chia"',
//       image: static3,
//       url: 'https://www.jungzeelandia.de/sortiment?category=0&item=744',
//     },
//     {
//       title: 'Jung MR. KORN "Dinkel" Pur',
//       image: static4,
//       url: 'https://www.jungzeelandia.de/sortiment?category=0&item=652',
//     },
//   ],
// };

const Related = ({ type, relatedItems }) => {
  let titleLiteral;
  switch (type) {
    case 'product':
      titleLiteral = 'Produkte';
      break;
    case 'rezepte':
      titleLiteral = 'Rezepte';
      break;
    case 'koncept':
      titleLiteral = 'Konzepte';
      break;
    default:
      break;
  }
  return (
    <section className="related-items">
      <Container>
        <h3>
          <span>{titleLiteral}, die Sie interessieren könnten</span>
        </h3>
        {type === 'product' && (
          <Card.Group stackable centered itemsPerRow={4}>
            {relatedItems.map((item) => (
              <Card key={item.id} as={Link} to={`/alle-produkte/${item.id}`}>
                {item.image ? (
                  <Image src={`${item.image['@id']}/@@images/image/mini`} />
                ) : (
                  <Image src={missingIMG} />
                )}
                <Card.Content>
                  <Card.Header>{item.name}</Card.Header>
                  <div
                    style={{ position: 'absolute', bottom: '0', right: '0' }}
                  >
                    <Button className="small brandButtonPrimary">
                      <MixedTitle>Produkt entdecken</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
        {type === 'rezepte' && (
          <Card.Group stackable centered itemsPerRow={4}>
            {relatedItems.map((item) => (
              <Card key={item.id} as={Link} to={`/alle-rezepte/${item.id}`}>
                {item.image ? (
                  <Image src={`${item.image['@id']}/@@images/image/mini`} />
                ) : (
                  <Image src={missingIMG} />
                )}
                <Card.Content>
                  <Card.Header>{item.name}</Card.Header>
                  <div
                    style={{ position: 'absolute', bottom: '0', right: '0' }}
                  >
                    <Button className="small brandButtonPrimary">
                      <MixedTitle>Rezepte entdecken</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
        {type === 'koncept' && (
          <Card.Group stackable centered itemsPerRow={4}>
            {[1, 2, 3, 4].map((item) => (
              <Card key={item} as={Link} to="/">
                <Image src={breads1Img} />
                <Card.Content>
                  <Card.Header>Winterliche Köstlichkeiten</Card.Header>
                  <div
                    style={{ position: 'absolute', bottom: '0', right: '0' }}
                  >
                    <Button className="small brandButtonPrimary">
                      <MixedTitle>Konzept entdecken</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
      </Container>
    </section>
  );
};

Related.propTypes = {
  type: PropTypes.string.isRequired,
  relatedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default Related;
