/**
 * Messages component.
 * @module components/manage/Messages/Messages
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toast } from '@plone/volto/components';
import { toast } from 'react-toastify';

import { removeMessage } from '@plone/volto/actions';

/**
 * Messages container class.
 * @class Messages
 * @extends Component
 */
class Messages extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    removeMessage: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.string,
        level: PropTypes.string,
      }),
    ).isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Messages
   */
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
  }
  //Really dirty hack to show messages in Toasts. Refactor to hook when there is time
  componentWillReceiveProps(nextProps) {
    if (nextProps.messages.length > this.props.messages.length) {
      const newestMessage = nextProps.messages[nextProps.messages.length - 1];
      toast.success(
        <Toast info title={newestMessage.title} content={newestMessage.body} />,
      );
    }
  }

  /**
   * On dismiss
   * @method onDismiss
   * @param {Object} event Event object
   * @param {number} value Index of message
   * @returns {undefined}
   */
  onDismiss(event, { value }) {
    this.props.removeMessage(value);
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return <></>;
  }
}

export default connect(
  (state) => ({
    messages: state.messages.messages,
  }),
  { removeMessage },
)(Messages);
